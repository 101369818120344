import * as React from 'react'
import './evaluation.css'
import Icon from '../../components/Icon'
import cx from 'classnames'

const Evaluation = ({ name, data }) => {

    return (
        <div>
            <div className='eva-title flex align-center'>
                <Icon style={{width: '12px', height: '12px', marginRight: '3px'}} name={name}></Icon>
                <div style={{minWidth: '55px'}}>{ data.title }</div>
            </div>
            <div className='eva-box'>
                {
                    data.data.map((item, index) => (
                        <div className='flex eva-item-box' key={index}>
                            <div className='flex-1 flex align-center flex-wrap eva-desc'>{ index + 1 }. { item.comment }</div>
                            <div className={cx('eva-num', {
                                'ad-num': name === 'advantage',
                                'de-num': name === 'defect'
                            })}>{ item.count }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Evaluation