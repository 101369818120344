import * as React from 'react';
import { TableRow, Button } from '@material-ui/core';
import TableWrapper from '../components/TableWrapper';
import StyledTableCell from '../components/StyledTableCell';
import StyledTableRow from '../components/StyledTableRow';
import cx from 'classnames';

const RoleTable = ({ columns, data, total, changePage, isLoading, onOperator, current }) => {
    const renderHeader = () => {
        return (
            <TableRow>
                {columns.map((column) => (
                    <StyledTableCell
                        key={column.name}
                        align={column.align}
                        style={{
                            minWidth: column.minWidth || column.width,
                            maxWidth: column.maxWidth || column.width,
                            position: column.position,
                            left: column.left,
                            right: column.right,
                            width: column.width,
                        }}
                    >
                        {column.label}
                    </StyledTableCell>
                ))}
            </TableRow>
        );
    };

    const handleTd = (row, column) => {
        const item = row[column.name];
        if (column.name === 'operator') {
            return (
                <div className="flex align-center justify-between">
                    <div
                        className={cx({
                            'pointer-disable': row.roleType === 1,
                        })}
                    >
                        <Button
                            disabled={row.roleType === 1}
                            variant="outlined"
                            style={{ color: '#fff', border: '1px solid #fff' }}
                            size="small"
                            onClick={() =>
                                onOperator &&
                                onOperator({
                                    type: 'edit',
                                    data: row,
                                })
                            }
                        >
                            edit
                        </Button>
                    </div>

                    <div
                        className={cx({
                            'pointer-disable': row.roleType === 1 || row.userCount > 0,
                        })}
                    >
                        <Button
                            disabled={row.roleType === 1 || row.userCount > 0}
                            variant="outlined"
                            style={{ color: '#fff', border: '1px solid #fff' }}
                            size="small"
                            onClick={() =>
                                onOperator &&
                                onOperator({
                                    type: 'delete',
                                    data: row,
                                })
                            }
                        >
                            delete
                        </Button>
                    </div>
                </div>
            );
        } else {
            return item;
        }
    };

    const renderBody = () => {
        return (
            <React.Fragment>
                {data.map((row, index) => (
                    <StyledTableRow key={index}>
                        {columns.map((column, i) => {
                            return (
                                <StyledTableCell
                                    key={`${index}_${i}`}
                                    style={{
                                        wordBreak: 'break-all',
                                        position: column.position,
                                        left: column.left,
                                        right: column.right,
                                    }}
                                >
                                    {handleTd(row, column)}
                                </StyledTableCell>
                            );
                        })}
                    </StyledTableRow>
                ))}
            </React.Fragment>
        );
    };

    return <TableWrapper headerCellRender={() => renderHeader()} bodyCellRender={() => renderBody()} total={total} changePage={changePage} isLoading={isLoading} current={current} />;
};

export default RoleTable;
