import * as React from 'react';
import { Menu } from '@material-ui/core';
import cx from 'classnames';
import _ from 'underscore';
import './dropBox.css';

import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />);

const SingleDrop = ({ total = 6, onConfirm = () => {}, title = 'Brands', data, currentSelected, currentTab, tabList }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [list, setList] = React.useState([]);
    const [menu, setMenu] = React.useState([]);
    const [subMenu, setSubMenu] = React.useState([]);
    const [attributeIndex, setAttributeIndex] = React.useState(null);
    const [activeMenu, setActiveMenu] = React.useState('');
	const [gender, setGender] = React.useState('');
	const [selected, setSelected] = React.useState([]);
	const subMenuRef = React.useRef({});

    React.useEffect(() => {
        if (tabList) {
            const firstTab = tabList[0]['alias'];
            setGender(currentTab || firstTab);
            setList((currentTab && data[currentTab]) || data[firstTab] || []);
        } else {
            setList(data['default'] || []);
        }
        if (currentSelected) {
			setSelected(currentSelected)
			if (currentSelected.length === 1) {
				const current = _.findWhere(((currentTab && data[currentTab]) || []), { value: currentSelected[0].value })
				if (current) {
					setAttributeIndex(current.value - 1);
					setMenu(current.children);
					setActiveMenu(current.children[0].value);
					setSubMenu(current.children[0].children);
				}
			}
        }
    }, [data, tabList, currentTab, currentSelected]);

    const handleCancel = () => {
		setAnchorEle(null);
		if (tabList) {
            const firstTab = tabList[0]['alias'];
            setGender(currentTab || firstTab);
            setList((currentTab && data[currentTab]) || data[firstTab] || []);
        } else {
            setList(data['default'] || []);
        }
        if (currentSelected) {
			setSelected(currentSelected)
			if (currentSelected.length === 1) {
				const current = _.findWhere(((currentTab && data[currentTab]) || []), { value: currentSelected[0].value })
				if (current) {
					setAttributeIndex(current.value - 1);
					setMenu(current.children);
					setActiveMenu(current.children[0].value);
					setSubMenu(current.children[0].children);
				}
			}
        }
    };

    const handleConfirm = () => {
        onConfirm(selected, gender);
        setAnchorEle(null);
    };

    const handleAttribute = (attr, index) => {
        setMenu(attr.children);
		setAttributeIndex(index);
		setSubMenu([])
    };

    const handleAttributeRemove = (attribute, index) => {
		const newSelect = selected.filter(f => f.value !== attribute.value)
		setSelected(newSelect)
        if (attributeIndex === index) {
            setMenu([]);
            setSubMenu([]);
        }
	};
	
	const selectSubMenu = (item) => {
		let newSelect = []
		if (selected.length) {
			const attributeObj = _.findWhere(selected, { value: attributeIndex + 1 })
			if (attributeObj) {
				newSelect = selected.map(attribute => {
					if (attribute.gender === gender && attribute.value === attributeIndex + 1) {
						const menuObj = _.findWhere(attribute.children , { value: activeMenu })
						if (menuObj) {
							const data = attribute.children.map(menu => {
								if (menu.value === activeMenu) {
									const subMenuObj = _.findWhere(menu.children, { value: item.value })
									if (subMenuObj) {
										const subData = menu.children.filter(f => f.value !== item.value)
										if (!subData.length) {
											return false
										}
										return {
											name: menuObj.name,
											value: activeMenu,
											children: subData
										}
									}
									return {
										name: menuObj.name,
										value: activeMenu,
										children: [...menu.children, item]
									}
								}
								return menu
							}).filter(f => f)
							return {
								name: `Attribute ${attributeIndex + 1}`,
								value: attributeIndex + 1,
								gender,
								children: data
							}
						}

						const menuObj2 = _.findWhere(menu, { value: activeMenu })

						return {
							name: `Attribute ${attributeIndex + 1}`,
							value: attributeIndex + 1,
							gender,
							children: [...attribute.children, {
								name: menuObj2.name,
								value: activeMenu,
								children: [item]
							}]
						}
						
					}
					return attribute
				})
			} else {
				const menuObj = _.findWhere(menu, { value: activeMenu })
				newSelect = [...selected, {
					name: `Attribute ${attributeIndex + 1}`,
					value: attributeIndex + 1,
					gender,
					children: [{
						name: menuObj.name,
						value: activeMenu,
						children: [item]
					}]
				}]
			}
		} else {
			const menuObj = _.findWhere(menu, { value: activeMenu }) || {}
			newSelect = [{
				name: list[attributeIndex].name,
				value: list[attributeIndex].value,
				gender,
				children: [{
					name: menuObj.name,
					value: activeMenu,
					children: [item]
				}]
			}]
		}
		const arr = newSelect.filter(f => f.children.length && f)
		setSelected(arr)
	}

    const handleMenu = (item) => {
		setActiveMenu(item.value);
		setSubMenu(item.children)
		if (selected.length) {
			const attributeObj = _.findWhere(selected, { value: attributeIndex + 1 })
			if (attributeObj) {
				const newSelected = selected.map(attribute => {
					if (attribute.gender === gender && attribute.value === attributeIndex + 1) {
						const menuObj = _.findWhere(attribute.children, { value: item.value })
						if (!menuObj) {
							return {
								name: list[attributeIndex].name,
								value: attributeIndex + 1,
								gender,
								children: [...attribute.children, item]
							}
						}
					}
					return attribute
				})
				setSelected(newSelected)
			} else {
				const attributeObj = {
					name: list[attributeIndex].name,
					value: attributeIndex + 1,
					gender,
					children: [item]
				}
				setSelected([...selected, attributeObj])
			}
		} else {
			const attributeObj = {
				name: list[attributeIndex].name,
				value: attributeIndex + 1,
				gender,
				children: [item]
			}
			setSelected([attributeObj])
		}
    };

    const isMenuActive = (item) => {
		const attributeObj = _.findWhere(selected, { value: attributeIndex + 1 })
		if (attributeObj) {
			const children = attributeObj.children
			const menuObj = _.findWhere(children, { value: item.value })
			return menuObj ? true: false
		}
		return false
    };

    const isSubMenuActive = (item) => {
		const attributeObj = _.findWhere(selected, { value: attributeIndex + 1 })
		if (attributeObj) {
			const children = attributeObj.children;
			const menuObj = _.findWhere(children, { value: activeMenu })
			if (menuObj) {
				const subMenuObj = _.findWhere(menuObj.children, { value: item.value })
				return subMenuObj ? true: false
			}
			return false
		}
        return false;
	};

	const isTotal = (list) => {
		if (!list.length) {
			return false
		}
		const attributeObj = _.findWhere(selected, { value: attributeIndex + 1 })
		if (attributeObj) {
			const menuObj = _.findWhere(attributeObj.children, { value: activeMenu })
			if (menuObj) {
				const children = menuObj.children
				if (children.length && children.length === list.length) {
					return true
				}
				return false
			}
			return false
		}
		return false
	}

    const handleTabChange = (gender) => {
        setGender(gender);
        setList(data[gender]);
		setSelected([]);
		setMenu([]);
		setSubMenu([]);
		setAttributeIndex(null);
		setActiveMenu('');
	};

	const isSelected = (value) => {
		const selectObj = _.findWhere(selected, { value })
		if (selectObj && selectObj.children.length) {
			return true
		}
		return false
	}

	const handleSelectAll = (subMenu) => {
		const isSelectAll = isTotal(subMenu)
		let newSelect = []

		if (isSelectAll) {
			// current select all now need cancel all select
			const attributeObj = _.findWhere(selected, { value: attributeIndex + 1 })
			const data = attributeObj.children.filter(f => f.value !== activeMenu)
			const selectTemp = selected.filter(f => f.value !== attributeObj.value)

			if (data.length) {
				newSelect = [...selectTemp, {
					name: attributeObj.name,
					value: attributeObj.value,
					gender,
					children: data
				}]
			} else {
				newSelect = selected.filter(f => f.value !== attributeObj.value)
			}
		} else {
			const attributeObj = _.findWhere(selected, { value: attributeIndex + 1 })
			if (attributeObj) {
				const menuObj = _.findWhere(attributeObj.children, { value: activeMenu })
				const selectTemp = selected.filter(f => f.value !== attributeObj.value)
				if (menuObj) {
					const temp = attributeObj.children.filter(f => f.value !== menuObj.value)
					newSelect = [...selectTemp, {
						name: attributeObj.name,
						value: attributeObj.value,
						gender,
						children: [...temp, {
							name: menuObj.name,
							value: activeMenu,
							children: subMenu
						}]
					}]
				} else {
					const menuObj = _.findWhere(menu, { value: activeMenu })
					newSelect = [...selectTemp, {
						name: attributeObj.name,
						value: attributeObj.value,
						gender,
						children: [...attributeObj.children, {
							name: menuObj.name,
							value: activeMenu,
							children: subMenu
						}]
					}]
				}
			} else {
				const menuObj = _.findWhere(menu, { value: activeMenu }) || {}
				newSelect = [...selected, {
					name: list[attributeIndex].name,
					value: list[attributeIndex].value,
					gender,
					children: [{
						name: menuObj.name,
						value: activeMenu,
						children: subMenu
					}]
				}]
			}
		}
		setSelected(newSelect)
	}

    return (
        <div className="drop-box">
            <div className="hover flex align-center" onClick={(e) => setAnchorEle(e.currentTarget)}>
                <div className="select-icon"></div>
                <div>{title}</div>
            </div>
            <StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={handleCancel}>
                <div className="py10 px20 drop-content flex flex-column" style={{ maxWidth: '400px' }}>
                    {(tabList && (
                        <div className="flex align-center">
                            {tabList.map((tabItem, index) => (
                                <div
                                    key={index}
                                    className={cx('flex-1 tab-item border-box flex align-center justify-center hover px10', {
                                        'tab-item-active': tabItem.alias === gender,
                                    })}
                                    onClick={() => handleTabChange(tabItem.alias)}
                                >
                                    {tabItem.name}
                                </div>
                            ))}
                        </div>
                    )) ||
                        null}
                    <div className="pt10 mt10 attributes-list flex align-center flex-wrap">
                        {list.map((attribute, index) => (
                            <div
                                className={cx('attribute-item flex align-center justify-between hover', {
                                    'attribute-selected': isSelected(attribute.value),
                                    'attribute-active': gender === 'ALL' && selected.length === 1 ? selected[0].value - 1 === index : attributeIndex === index,
                                })}
                                key={index}
                            >
                                <div
									onClick={(e) => {
										handleAttribute(attribute, index);
									}}
								>
									{attribute.name}
								</div>
                                {(isSelected(attribute.value) && (
                                    <div
                                        className="attribute-icon"
                                        onClick={(e) => {
                                            handleAttributeRemove(attribute, index);
                                        }}
                                    ></div>
                                )) ||
                                    null}
                            </div>
                        ))}
                    </div>
                    <div className="flex-1 py10 flex hidden" style={{ maxHeight: '300px', minHeight: '200px' }}>
                        <div className="flex-1 flex flex-column overflow-y">
                            {menu.map((item, index) => (
                                <div
                                    className={cx('attribute-menu-item hover', {
                                        'attribute-item-active': isMenuActive(item),
                                    })}
                                    key={index}
                                    onClick={() => {
                                        handleMenu(item);
                                    }}
                                >
                                    {item.name}
                                </div>
                            ))}
                        </div>
						<div className="flex-1 flex flex-column attribute-right-box">
							<div ref={f => subMenuRef.current[activeMenu] = f} className="flex-1 flex flex-column overflow-y">
								{subMenu.map((sub, index) => (
									<div
									className={cx('attribute-menu-item flex justify-between', {
										'attribute-subItem-active': isSubMenuActive(sub),
									})}
									key={index}
									>
										<div className="flex align-center">{sub.name}</div>
										<div
											className={cx('icon-check-box hover', {
												'icon-check': isSubMenuActive(sub),
												'icon-uncheck': !isSubMenuActive(sub),
											})}
											onClick={() => selectSubMenu(sub)}
											></div>
									</div>
								))}
							</div>
							{
								subMenu.length ? (
									<div
										className={cx('flex align-center justify-end mt10 py4 px8', {
											'attribute-subItem-active': isTotal(subMenu),
										})}
										onClick={() => handleSelectAll(subMenu)}
									>
										<div className="flex align-center mr10">Select All</div>
										<div
											className={cx('icon-check-box hover', {
												'icon-check': isTotal(subMenu),
												'icon-uncheck': !isTotal(subMenu),
											})}
											style={{marginRight: subMenu.length > 8 ? '12px': '4px'}}
										></div>
									</div>
								) : null
							}
						</div>
                    </div>
                    <div className="flex align-center footer-box">
                        <div className="flex-1 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                            Cancel
                        </div>
                        <div className="ml10 flex-1 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default SingleDrop;
