import {
    observable,
    action,
    // computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';

import { loginRequest, logoutRequest, resetUrlValidateRequest, resetPasswordRequest, forgetPasswordImgRequest, forgetPasswordResetRequest, updatePasswordRequest } from '../api/login';
import { userListRequest, roleDictRequest, userResetPasswordRequest, userSaveRequest, roleListRequest, permissionDictRequest, roleSaveRequest, roleInfoRequest, roleRemoveRequest, userEnableRequest, userRemoveRequest, userInfoRequest } from '../api/user';

export class UserStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
    }

    @observable
    userInfo = {};

    @observable
    userList = [];

    @observable
    roleDict = [];

    @observable
    roleList = [];

    @observable
    permissionDict = [];

    @action
    async login({ username, password, rememberMe }) {
        const response = await loginRequest({
            username,
            password,
            rememberMe,
        });
        if (response.code === '00000') {
            this.userInfo = response.data;
        } else {
            this.userInfo = {};
        }
        return response;
    }

    @action
    async userInfoRequest() {
        const response = await userInfoRequest();
        if (response.code === '00000') {
            this.userInfo = response.data;
        } else {
            this.userInfo = {};
        }
        return response;
    }

    @action
    clearUserInfo() {
        this.userInfo = {};
    }

    @action
    async logoutRequest() {
        return await logoutRequest();
    }

    @action
    async resetUrlValidateRequest({ type, key, time, identity, code }) {
        return await resetUrlValidateRequest({ type, key, time, identity, code });
    }

    @action
    async resetPasswordRequest({ identityKey, newPassword }) {
        return await resetPasswordRequest({ identityKey, newPassword });
    }

    @action
    async forgetPasswordImgRequest() {
        return await forgetPasswordImgRequest();
    }

    @action
    async forgetPasswordResetRequest({ account, verificationCode }) {
        return await forgetPasswordResetRequest({
            account,
            verificationCode,
        });
    }

    @action
    async updatePasswordRequest({ oldPassword, newPassword }) {
        return await updatePasswordRequest({
            oldPassword,
            newPassword,
        });
    }

    @action
    async userListRequest({ email = '', roleId = '' }) {
        const response = await userListRequest({
            email,
            roleId,
        });
        const { code, data } = response;
        if (code === '00000') {
            this.userList = data;
        }
    }

    @action
    async roleDictRequest() {
        const response = await roleDictRequest();
        const { code, data } = response;
        if (code === '00000') {
            this.roleDict = data;
        }
    }

    @action
    async userResetPasswordRequest({ userId }) {
        return await userResetPasswordRequest({
            userId,
        });
    }

    @action
    async userSaveRequest({ userId = null, nickname, avatar = '', password, email, roleIds }) {
        return await userSaveRequest({
            userId,
            nickname,
            avatar,
            password,
            email,
            roleIds,
        });
    }

    @action
    async userEnableRequest({ userId, enableFlag }) {
        return await userEnableRequest({
            userId,
            enableFlag,
        });
    }

    @action
    async roleListRequest({ roleName = '' }) {
        const response = await roleListRequest({
            roleName,
        });
        const { code, data } = response;
        if (code === '00000') {
            this.roleList = data;
        }
    }

    @action
    async permissionDictRequest() {
        const response = await permissionDictRequest();
        const { code, data } = response;
        if (code === '00000') {
            this.permissionDict = data;
        }
    }

    @action
    async roleSaveRequest({ roleId = null, roleName, permissionIds }) {
        return await roleSaveRequest({
            roleId,
            roleName,
            permissionIds,
        });
    }

    @action
    async roleInfoRequest({ id }) {
        return await roleInfoRequest({
            id,
        });
    }

    @action
    async roleRemoveRequest({ id }) {
        return await roleRemoveRequest({ id });
    }

    @action
    async userRemoveRequest({ id }) {
        return await userRemoveRequest({
            id,
        });
    }
}
