import * as React from 'react'
import './basicInformation.css'

const BasicInformation = ({ name, data }) => {

    return (
        <div className='basic-wrapper'>
            <div className='basic-title'>{ data.title }</div>
            <div className='basic-box'>
                {
                    data.data.map((item, index) => (
                        <div className='basic-item' key={index}>
                            { item.name }: 
                            <span className='basic-value'> { item.value || '-' }</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default BasicInformation