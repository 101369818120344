import * as React from 'react';
import { Menu, Radio } from '@material-ui/core';
import cx from 'classnames';
import './dropBox.css';

import { withStyles, createStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} {...props} />);

const StyledRadio = withStyles((theme) =>
    createStyles({
        root: {
            color: 'rgba(255,255,255,0.5)',
            padding: 0,
            '&.MuiRadio-colorSecondary.Mui-checked': {
                color: '#FF7334',
            },
        },
    })
)(Radio);

const PPIComponent = ({ onConfirm = () => {}, title = 'PPI', data }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [menu, setMenu] = React.useState([]);
    const [subMenu, setSubMenu] = React.useState([]);
    const [menuActive, setMenuActive] = React.useState({});
	const [subMenuActive, setSubMenuActive] = React.useState([]);

    React.useEffect(() => {
        setMenu((data && data.data) || []);
        setSubMenu((data && data.data && data.data.length && data.data[0]['children']) || []);
        setMenuActive((data && data.data && data.data.length && data.data[0]) || {});
        setSubMenuActive((data && data.data && data.data.length && data.data[0]['children'] && data.data[0]['children'][0] && [data.data[0]['children'][0]]) || []);
    }, [data]);

    const handleCancel = () => {
        setAnchorEle(null);
    };

    const handleConfirm = () => {
        const obj = {
            type: menuActive.type,
            key: menuActive.value,
            value: subMenuActive,
            originKey: menuActive,
            data: menuActive.value === 'Demand by premium range' ? subMenuActive.map((item) => item.value) : data.data[2]['children'].map(item => item.value),
            ppi: menuActive.value === 'Filter premium range' ? subMenuActive.map((item) => item.value) : (menuActive.value === 'Demand' ? ['TOTAL'] : data.data[1]['children'].map(item => item.value))
        };
        onConfirm(obj);
        setAnchorEle(null);
    };

    const handleMenu = (item) => {
        if (item.name !== menuActive.name) {
            setMenuActive(item);
            setSubMenuActive(item.children[0] ? [item.children[0]] : []);
            setSubMenu(item.children || []);
        }
    };

    const handleSubMenu = (item) => {
        // let index = null;
        // subMenuActive.forEach((sub, i) => {
        //     if (sub.name === item.name) {
        //         index = i;
        //     }
        // });
        // if (index !== null) {
        //     const newList = [...subMenuActive.slice(0, index), ...subMenuActive.slice(index + 1)];
        //     setSubMenuActive(newList);
        // } else {
        //     const newList = [...subMenuActive, item];
        //     setSubMenuActive(newList);
        // }
        setSubMenuActive([item])
    };

    const handleSubMenuActive = (item) => {
        let flag = false;
        subMenuActive.forEach((sub) => {
            if (item.name === sub.name) {
                flag = true;
            }
        });
        return flag;
    };

    return (
        <div className="drop-box">
            <div className="hover flex align-center" onClick={(e) => setAnchorEle(e.currentTarget)}>
                <div className="ppi-icon"></div>
                <div style={{marginTop: '2px'}}>{title}</div>
            </div>
            <StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={() => setAnchorEle(null)}>
                <div className="p10 drop-content flex flex-column">
                    <div className="flex-1 py10 flex hidden" style={{ maxHeight: '300px', minHeight: '200px' }}>
                        <div className="flex-1 flex flex-column">
                            {menu.map((item, index) => (
                                <div
                                    className={cx('ppi-menu-item-new hover flex justify-between align-center', {
                                        'ppi-item-active': menuActive.name === item.name,
                                    })}
                                    key={index}
                                    onClick={() => {
                                        handleMenu(item);
                                    }}
                                >
                                    {item.name}
                                    {
                                        item.name === 'Demand' && <StyledRadio checked={menuActive.name === item.name} style={{ fontSize: '12px', padding: 0, width: '18px', height: '18px' }} value={item.name} />
                                    }
                                </div>
                            ))}
                        </div>

                        {
                            subMenu.length ? (
                                <div className="flex-1 flex flex-column attribute-right-box">
                                    {subMenu.map((sub, index) => (
                                        <div
                                            className={cx('ppi-menu-item-new flex justify-between hover', {
                                                'ppi-item-active': handleSubMenuActive(sub),
                                            })}
                                            onClick={() => {
                                                handleSubMenu(sub);
                                            }}
                                            key={index}
                                        >
                                            <div className="flex align-center">{sub.name}</div>
                                            <StyledRadio checked={handleSubMenuActive(sub) || false} style={{ fontSize: '12px', padding: 0, width: '18px', height: '18px' }} />
                                        </div>
                                    ))}
                                </div>
                            ): null
                        }
                    </div>
                    <div className="flex align-center justify-center footer-box">
                        <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                            Cancel
                        </div>
                        <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default PPIComponent;
