import {
    observable,
    action,
    computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';
import _ from 'underscore';
import { franchiseOverviewRequest, franchiseNikeLcaRequest, franchiseModelPopupRequest } from '../api/franchise';
import { completeMissingData } from './utils';
export class FranchiseStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
    }

    @observable
    nikeSkuData = [];

    @observable
    franchiseData = [];

    @observable
    franchiseOverviewData = [];

    @observable
    franchiseDataGroup = [];

    @observable
    franchiseLca = [];

    @observable
	franchiseTimeline = [];

	@observable
	dateArrOrigin = []

	@observable
	dateRangeArr1 = []

	@observable
	dateRangeArr2 = []

	@observable
	dateRangeArr3 = []

	@observable
	dateRangeArr4 = []

	@observable
	popData = null

	@action
	changeDateRange1(dateRange) {
		this.dateRangeArr1 = dateRange
	}

	@action
	changeDateRange2(dateRange) {
		this.dateRangeArr2 = dateRange
	}
	@action
	changeDateRange3(dateRange) {
		this.dateRangeArr3 = dateRange
	}

	@action
	changeDateRange4(dateRange) {
		this.dateRangeArr4 = dateRange
	}
	
	@computed
	get franchiseOverviewResult() {
		const ppiTemp = [];
		const rviTemp = [];
		const diTemp = [];
		const demandTemp = [];
		this.franchiseOverviewData.forEach((item) => {
			demandTemp.push({
				name: item.model,
				franchise: item.franchise,
				brand: item.brandClean,
				category: item.category,
				date: item.date,
				value: Number(item.demand),
				yoy: item.demandYoy,
				origin: item,
			});
			ppiTemp.push({
				name: item.model,
				franchise: item.franchise,
				brand: item.brandClean,
				category: item.category,
				date: item.date,
				value: Number(item.ppi),
				yoy: item.ppiYoy,
				origin: item,
			});
			rviTemp.push({
				name: item.model,
				franchise: item.franchise,
				brand: item.brandClean,
				category: item.category,
				date: item.date,
				value: Number(item.resaleVolumeIndex),
				yoy: item.resaleVolumeIndexYoy,
				origin: item,
			});
			diTemp.push({
				name: item.model,
				franchise: item.franchise,
				brand: item.brandClean,
				category: item.category,
				date: item.date,
				value: Number(item.diversity),
				yoy: item.diversityYoy,
				origin: item,
			});
		});
		const de = Object.entries(_.groupBy(demandTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr1, value),
		}));
		const ppi = Object.entries(_.groupBy(ppiTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr2, value),
		}));
		const rvi = Object.entries(_.groupBy(rviTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr3, value),
		}));
		const di = Object.entries(_.groupBy(diTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr4, value),
		}));
		return [
			[
				{
					title: 'DEMAND',
					data: de,
					value: 'demand',
					yoy: 'demandYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr1
				},
				{
					title: 'PREMIUM PRICE INDEX',
					data: ppi,
					value: 'ppi',
					yoy: 'ppiYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr2
				},
			],
			[
				{
					title: 'RESALE VOLUME INDEX',
					data: rvi,
					value: 'resaleVolumeIndex',
					yoy: 'resaleVolumeIndexYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr3
				},
				{
					title: 'DIVERSITY INDEX',
					data: di,
					value: 'diversity',
					yoy: 'diversityYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr4
				},
			],
		];
	}

    @action
    async franchiseOverviewRequest({ dateType, startDate, endDate, dataType, brandList, franchiseList, modelList }) {
        const response = await franchiseOverviewRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            brandList,
            franchiseList,
			modelList
        });
        if (response.code === '00000') {
            this.franchiseOverviewData = response.data;
			this.dateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr1 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr2 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr3 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr4 = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async franchiseNikeLcaRequest({ dateType, startDate, endDate, brandList, franchiseList }) {
        const response = await franchiseNikeLcaRequest({
            dateType,
            startDate,
            endDate,
            brandList,
            franchiseList,
        });
        const { code, data } = response;
        if (code === '00000') {
            const timeline = [];
            const arr = data.map((item) => {
                timeline.push(item.date);
                return {
                    ...item,
                    // value: [Number(item.ppi), Number(item.unitSoldIndex)],
                    value: [Number(item.ppi) - 1, Number(item.salesVolume) - Number(item.salesVolumeAvg)],
                };
            });
            const temp = _.groupBy(arr, (d) => d.franchise);
            this.franchiseLca = Object.entries(temp).map(([key, value]) => ({
                name: key,
                data: value,
            }));
            this.franchiseTimeline = Array.from(new Set(timeline))
                .sort()
                .map((item) => ({
                    name: item,
                }));
        }
    }

	@action
	async franchiseModelPopupRequest({ model }) {
		const response = await franchiseModelPopupRequest({
			model
		})
		this.popData = response.data
	}

	@computed
	get popInfo() {
		if (this.popData) {
			const getInfo = (attribute, type) => {
				let score = null
				let data = []
				let basicInformation = []
				let attributes = []
				let configuration = []
				if (attribute.score) {
					score = attribute.score.Total
					const scoreArr = Object.entries(attribute.score).map(([key, value]) => ({
						name: key,
						value	
					})).filter((f) => f.name !== 'Total')
					data = scoreArr
				}
				if (attribute.config_info) {
					const basicArr = Object.entries(attribute.config_info.basic_info).map(([key, value]) => ({
						name: key,
						value
					}))
					basicInformation = basicArr
					const attrArr = Object.entries(attribute.config_info.attribute).map(([key, value]) => ({
						name: key,
						value
					}))
					attributes = attrArr
					const confArr = Object.entries(attribute.config_info.tech).map(([key, value]) => ({
						name: key,
						value
					}))
					configuration = confArr
				}

				return {
					title: attribute.title,
					commentsTitle: type === 'cn' ? '实战评价': 'Actual evaluation',
					advantage: {
						title: type === 'cn' ? '优点': 'Advantage',
						data: attribute.comments.pros || []
					},
					defect: {
						title: type === 'cn' ? '缺点': 'Defect',
						data: attribute.comments.cons || []
					},
					score,
					radarData: data,
					basicInformation: {
						title: 'Basic Information',
						data: basicInformation
					},
					attributes: {
						title: 'Attributes',
						data: attributes
					},
					configuration: {
						title: 'Configuration',
						data: configuration
					},
					basicTitle: type === 'cn' ? '产品参数': 'Product parameters'
				}
			}
			const cn = JSON.parse(this.popData.attributeJsonZh)
			const en = JSON.parse(this.popData.attributeJsonEn)
			const cnInfo = getInfo(cn, 'cn')
			const enInfo = getInfo(en, 'en')
			return {
				type: this.popData.type,
				model: this.popData.model,
				imgUrl: this.popData.modelImageAccessUrl,
				enInfo,
				cnInfo
			}
		}
		return {}
	}
}
