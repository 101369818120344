import { withStyles, createStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const StyledTableCell = withStyles((theme) =>
    createStyles({
        head: {
            backgroundColor: 'rgba(94, 94, 94, 0.5)',
            color: '#fff',
            fontSize: '12px',
            fontFamily: 'Helvetica-Bold, Helvetica',
            fontWeight: 'bold',
            padding: '8px',
            borderRight: '1px dashed rgba(151, 151, 151, 0.2)',
            borderBottom: '1px solid transparent',
            boxSizing: 'border-box',
        },
        body: {
            fontSize: 12,
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            fontWeight: '400',
            padding: '10px 8px',
            borderRight: '1px dashed rgba(151, 151, 151, 0.2)',
            borderBottom: '1px solid transparent',
            color: '#fff',
        },
    })
)(TableCell);

export default StyledTableCell;
