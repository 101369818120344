import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import _ from 'underscore';
import moment from 'moment';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
// import { Select, MenuItem, Tooltip } from '@material-ui/core';
import '../../assets/css/sku.css';
import { Visualization } from '../visualization/index';
import SingleDrop from '../components/SingleDrop';
import StyledCheckBox from '../components/StyledCheckBox';
import DatePicker from '../components/DatePicker';
import PPIComponent from '../components/PPIComponent';
import { formatM, formatPercent, transformDate, groupedByNumber, handleDateStartEnd, handleLegend, handlePPI, downloadXlsx, getColsWpx } from '../../utils/index';
import {
	attributeDemandColumns,
	attributePPIColumns,
	attributeRVIColumns,
	attributeDIColumns,
	attributeColumns,
	handleAttributeDemandData,
	handleAttributePPIData,
	handleAttributeRVIData,
	handleAttributeDIData,
	handleAttributeData
} from '../../utils/skuAnalysisDownload';
import CommonDialog from '../components/CommonDownloadDialog';
import Slider from '../components/Slider';
import CommonTootip from '../components/StyledTooltip';

import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

const timeTabList = [
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: '12M',
        alias: 'M12',
    },
];

const dropTabList = [
    {
        name: 'ALL',
        alias: 'ALL',
    },
    {
        name: 'MALE',
        alias: 'MALE',
    },
    {
        name: 'FEMALE',
        alias: 'FEMALE',
    },
    {
        name: 'KIDS',
        alias: 'KIDS',
	},
	{
        name: 'ATTRIBUTE',
        alias: 'ATTRIBUTE',
    },
];

@inject('skuStore', 'commonStore', 'userStore')
@observer
export default class NikeProductAttribute extends React.Component {
    state = {
        selectGroupList: [],
        demandTimeTab: 'MONTH',
		currentTab: 'ALL',
        type: 'MONTH',
        startDate: null,
        endDate: null,
        originalDate: {},
		ppiInfo: {
            type: 'attribute',
            key: 'Demand',
            name: 'Demand',
            value: [],
        },
        ppiData: {},
        colors: {
            resaleVolumeIndex: {},
            diversity: {},
            ppi: {},
            demand: {},
		},
		selectList: []
    };
    async componentWillMount() {
        const { timeRangeMonth } = await this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 12);
        this.setState({
            startDate,
            endDate,
            originalDate,
		});
		await this.props.commonStore.skuAttributeDictRequest()
		const last = await this.props.commonStore.skuAttributeDictHistoryLatestRequest()
		if (last.code === '00000') {
			if (last.data) {
				const { gender, attributeList } = last.data;
				const genderInfo = gender === 'NONE' ? 'ATTRIBUTE': gender
				const data = []
				attributeList.forEach(list => {
					const children = list.attributeDataList.sort((a, b) => a.attributeName.localeCompare(b.attributeName)).map(f => ({
						name: f.attributeName,
						value: f.attributeName,
						children: f.attributeValueList.sort((a, b) => a.localeCompare(b)).map(item => ({
							name: item,
							value: item
						}))
					}))
					const obj = {
						name: `Attribute ${list.attributeNo}`,
						value: list.attributeNo,
						gender: genderInfo,
						children
					}
					data.push(obj)
				})
				
				const newGroup = groupedByNumber(2, data, '');
				// const ppiData = handlePPIData(data.map(f => f.name), genderInfo, 'attribute');
				const ppiData = handlePPI(data.map(f => f.name), genderInfo, 'attribute');
				const ppiInfo = {
					type: 'attribute',
					key: 'Demand',
					name: 'Demand',
					value: data.map(f => f.name),
					gender: genderInfo === 'ALL' ? ['MALE', 'FEMALE', 'KIDS']: null,
					data: data.map(f => f.name),
            		ppi: ['TOTAL']
				};
				const { type, demandTimeTab } = this.state

				this.setState({
					currentTab: genderInfo,
					selectList: data,
					selectGroupList: newGroup,
					ppiData,
					ppiInfo
				})
				await this.getRequest(type, startDate, endDate, demandTimeTab, genderInfo, ppiInfo, data)
			} else {
				const response = await this.props.commonStore.skuAttributeDictDefaultRequest()
				const { code, data } = response;
				if (code === '00000') {
					const list = data.sort(this.sortByLetter).map(item => ({
						name: item.display,
						value: item.value,
						children: (item.extraData || []).sort(this.sortByLetter).map(sub => ({
							name: sub.display,
							value: sub.value
						}))
					}))
					const attributeList = []
					for(let i = 0; i < 1; i++) {
						attributeList.push({
							name: `Attribute ${i + 1}`,
							gender: 'ALL',
							value: i + 1,
							children: list
						})
					}
					const { type, demandTimeTab, currentTab } = this.state
					const newGroup = groupedByNumber(2, attributeList, '');
					const ppiData = handlePPI(attributeList.map(f => f.name), currentTab, 'attribute');
					const ppiInfo = {
						type: 'attribute',
						key: 'Demand',
						name: 'Demand',
						value: attributeList.map(f => f.name),
						gender: currentTab === 'ALL' ? ['MALE', 'FEMALE', 'KIDS']: null,
						data: data.map(f => f.name),
            			ppi: ['TOTAL']
					};
					this.setState({
						selectList: attributeList,
						selectGroupList: newGroup,
						ppiData,
						ppiInfo
					})
					await this.getRequest(type, startDate, endDate, demandTimeTab, currentTab, ppiInfo, attributeList)
				}
			}
		}
	}

	getRequest = async (type, startDate, endDate, demandTimeTab, currentTab, ppiInfo, selectedList) => {
		const attributeList = selectedList.map(select => ({
			attributeNo: select.value,
			attributeDataList: select.children.map(child =>  ({
				attributeName: child.value,
				attributeValueList: child.children.map(c => c.value)
			}))
		}))
		await this.props.skuStore.skuAnalysisAttributeDemandRequest({
			dateType: type,
			startDate,
			endDate,
			dataType: demandTimeTab,
			gender: (currentTab !== 'ATTRIBUTE' && currentTab) || undefined,
			ppiList: ppiInfo.ppi,
			subGenderList: (currentTab === 'ALL' && ppiInfo.gender) || undefined,
			ppiInfo,
			attributeList,
			genderTab: currentTab
		})
		await this.props.skuStore.skuAnalysisAttributeIndexRequest({
			dateType: type,
			startDate,
			endDate,
			dataType: demandTimeTab,
			gender: (currentTab !== 'ATTRIBUTE' && currentTab) || undefined,
			attributeList,
			genderTab: currentTab
		})
	}
	
	sortByLetter = (a, b) => a.display.localeCompare(b.display)

    handleRemoveSelected = (item) => {
        const { selectList, currentTab } = this.state;
        const newList = selectList.filter((f) => f.value !== item.value);
        const newGroup = groupedByNumber(2, newList, '');
		const ppiData = handlePPI(newList.map(f => f.name), currentTab, 'attribute');
		const ppiInfo = {
			type: 'attribute',
			key: 'Demand',
			name: 'Demand',
			value: newList.map(f => f.name),
			gender: currentTab === 'ALL' ? ['MALE', 'FEMALE', 'KIDS']: null,
			data: newList.map(f => f.name),
            ppi: ['TOTAL']
		};

        this.setState({
            selectedList: newList,
            selectGroupList: newGroup,
            ppiData,
            ppiInfo,
        });
    };

    handlePPIConfirm = async (selected) => {
		const { type, startDate, endDate, demandTimeTab, currentTab, selectList } = this.state
		const genderList = Array.from(new Set(selected.value.map((item) => item.gender).filter((f) => f)));
		// const singleGender = selected.originKey.gender;
		const newGenderList = selected.key !== 'Demand by premium range' && ["MALE", "FEMALE", "KIDS"]
		const ppiInfo = {
            type: selected.type,
            key: selected.key,
            value: Array.from(new Set(selected.value.map((item) => item.value))),
            gender: genderList,
            origin: selected.originKey,
            name: selected.originKey && selected.originKey.name,
			data: selected.data,
            ppi: selected.ppi
        };
        this.setState({
            ppiInfo,
		});

		const attributeList = selectList.map(select => ({
			attributeNo: select.value,
			attributeDataList: select.children.map(child =>  ({
				attributeName: child.value,
				attributeValueList: child.children.map(c => c.value)
			}))
		}))

		await this.props.skuStore.skuAnalysisAttributeDemandRequest({
			dateType: type,
			startDate,
			endDate,
			dataType: demandTimeTab,
			gender: (currentTab !== 'ATTRIBUTE' && currentTab) || undefined,
			ppiList: ppiInfo.ppi,
			subGenderList: (currentTab === 'ALL' && (newGenderList || (genderList.length && genderList))) || undefined,
			ppiInfo,
			attributeList,
			genderTab: currentTab
		})
    };

    handleConfirm = async (selected, gender) => {
		const ppiData = handlePPI(selected.map(f => f.name), gender, 'attribute');
		const ppiInfo = {
			type: 'attribute',
			key: 'Demand',
			name: 'Demand',
			value: selected.map(f => f.name),
			gender: gender === 'ALL' ? ['MALE', 'FEMALE', 'KIDS']: null,
			data: selected.map(f => f.name),
            ppi: ['TOTAL']
		};
        const groupByList = groupedByNumber(2, selected, '');
        this.setState({
            selectList: selected,
            selectGroupList: groupByList,
			currentTab: gender,
			ppiData,
			ppiInfo
		});
		const { demandTimeTab, startDate, endDate, type } = this.state;
		await this.getRequest(type, startDate, endDate, demandTimeTab, gender, ppiInfo, selected)
    };

    handleDateConfirm = async (selected, type, original) => {
		const { start, end } = handleDateStartEnd(selected, type);
		if (start && end) {
			this.setState({
                startDate: start,
                endDate: end,
                type,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
			const { demandTimeTab, ppiInfo, selectList, currentTab } = this.state;
			await this.getRequest(type, start, end, demandTimeTab, currentTab, ppiInfo, selectList)
		}
	};

    handleDemandTimeTab = async (demandTimeTab) => {
        this.setState({
            demandTimeTab,
		});
		const { type, startDate, endDate, currentTab, ppiInfo, selectList } = this.state
		await this.getRequest(type, startDate, endDate, demandTimeTab, currentTab, ppiInfo, selectList)
	};
	
	transformImg = (element) => {
		return new Promise(async (resolve, reject) => {
			const data = await domtoimage.toPng(element)
			const img = new Image()
			img.onload = () => {
				const { width, height } = element.getBoundingClientRect();
				const canvas = document.createElement('canvas')
				const ctx = canvas.getContext('2d')
				canvas.width = width
				canvas.height = height
				ctx.fillStyle = '#2e2e2e'
				ctx.fillRect(0, 0, canvas.width, canvas.height)
				ctx.drawImage(img, 0, 0, width, height, 0, 0, width, height)
				resolve(canvas)
			}
			img.src = data
		})
	}

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'pdf') {
			const pdf = new jsPDF('', 'pt', 'a4');

			Promise.all([this.transformImg(this.ele), this.transformImg(this.attr)]).then(res => {
				const [canvas1, canvas2] = res
				const dataUrl1 = canvas1.toDataURL('image/jpeg')
				const dataUrl2 = canvas2.toDataURL('image/jpeg')

				const imgWidth = 555.28;
				const imgHeight1 = (552.28 / canvas1.width) * canvas1.height;
				const imgHeight2 = (552.28 / canvas2.width) * canvas2.height;

				pdf.addImage(dataUrl1, 'JPEG', 20, 20, imgWidth, imgHeight1);
				pdf.addPage();
				pdf.addImage(dataUrl2, 'JPEG', 20, 20, imgWidth, imgHeight2);

				this.props.commonStore.setDownloadDialog(false);
				this.props.commonStore.setMenuName('');
				pdf.save(`${name || document.title}.pdf`);
			})
        } else if (type === 'xlsx') {
			const { attributeResult } = this.props.skuStore;
			const { skuAttributeDict } = this.props.commonStore;
			const { type, demandTimeTab, selectList } = this.state;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(7)
			}
			let sheetData = []
			let rowsNum = 0

			const attributeColumn = attributeColumns;
			const { data, rowsNumber } = handleAttributeData(attributeColumn, selectList, skuAttributeDict);
			sheetData = [...sheetData, ...data];
			rowsNum = rowsNumber + rowsNum;

			const demandColumn = attributeDemandColumns(type);
			const { data: demandData, colsNumber: demandColsNumber, rowsNumber: demandRowsNumber } = handleAttributeDemandData(demandColumn, type, demandTimeTab, attributeResult);
			sheetData = [...sheetData, ...demandData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: demandColsNumber - 1 }
			})
			rowsNum = demandRowsNumber + rowsNum;

			const ppiColumn = attributePPIColumns(type);
			const { data: ppiData, colsNumber: ppiColsNumber, rowsNumber: ppiRowsNumber } = handleAttributePPIData(ppiColumn, type, demandTimeTab, attributeResult);
			sheetData = [...sheetData, ...ppiData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: ppiColsNumber - 1 }
			})
			rowsNum = ppiRowsNumber + rowsNum;

			const rviColumn = attributeRVIColumns(type);
			const { data: rviData, colsNumber: rviColsNumber, rowsNumber: rviRowsNumber } = handleAttributeRVIData(rviColumn, type, demandTimeTab, attributeResult);
			sheetData = [...sheetData, ...rviData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: rviColsNumber - 1 }
			})
			rowsNum = rviRowsNumber + rowsNum;

			const diColumn = attributeDIColumns(type);
			const { data: diData, colsNumber: diColsNumber, rowsNumber: diRowsNumber } = handleAttributeDIData(diColumn, type, demandTimeTab, attributeResult);
			sheetData = [...sheetData, ...diData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: diColsNumber - 1 }
			})
			rowsNum = diRowsNumber + rowsNum;

			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
	};
	
	getPPIDesc = (dateRange, attributeDemandData, ppiInfo, currentTab) => {
		const lastDate = dateRange[dateRange.length - 1];
		const descMap = {
            attribute: 'name',
            ppi: 'ppiTier',
        };

        const genderDescMap = {
            attribute: 'gender',
            ppi: 'ppiTier',
		};

		return handleLegend(attributeDemandData, currentTab === 'ALL' ? genderDescMap[ppiInfo.type] : descMap[ppiInfo.type], lastDate, ppiInfo, currentTab, 'name');
	}

	getDesc = (dateRange, attributeIndexData, currentTab) => {
		const lastDate = dateRange[dateRange.length - 1];
		return handleLegend(attributeIndexData, currentTab === 'ALL' ? 'gender' : 'name', lastDate, '', currentTab, 'name');
	}

	getAttributeInfo = (num) => {
		const { selectList } = this.state;
		const { skuAttributeDict } = this.props.commonStore;
		const selectObj = _.findWhere(selectList, { value: num })
		const dictObj = _.findWhere(skuAttributeDict, { value: num })
		if (selectObj && dictObj) {
			const arr = selectObj.children.map(item => {
				const dictItemObj = _.findWhere(dictObj.children, { value: item.value })
				const value = dictItemObj && dictItemObj.children.length && dictItemObj.children.length === item.children.length ? 'total': item.children.length > 3 ? `${item.children.slice(0,3).map(f => f.value).join(',')}...`: item.children.map(f => f.value).join(',')
				return {
					name: item.name,
					value
				}
			})
			return arr
		}
		return []
	}

	handleDownloadAttributeInfo = (list, item) => {
		const { skuAttributeDict } = this.props.commonStore;
		const dictObj = _.findWhere(skuAttributeDict, { value: list.value })
		if (dictObj) {
			const dictItemObj = _.findWhere(dictObj.children, { value: item.value })
			const value = dictItemObj && dictItemObj.children.length && dictItemObj.children.length === item.children.length ? 'total': item.children.map(f => f.value).join(',')
			return value
		}
		return item.children.map(f => f.value).join(',')
	}

	handleChangeColor = (color, type) => {
        const c = this.state.colors;
        c[type] = color;

        this.setState({
            colors: c,
        });
	};
	
	handleSliderChange = (sliderArr, type) => {
		if (type === 'demand') {
			this.props.skuStore.changeDemandDateRange(sliderArr)
		} else if (type === 'ppi') {
			this.props.skuStore.changeIndexDateRange1(sliderArr)
		} else if (type === 'resaleVolumeIndex') {
			this.props.skuStore.changeIndexDateRange2(sliderArr)
		} else if (type === 'diversity') {
			this.props.skuStore.changeIndexDateRange3(sliderArr)
		}
	}

    render() {
        const { attributeResult, attributeIndexData, attributeDemandData } = this.props.skuStore;
        const { isDialogOpen, timeRangeMonth, timeRangeWeek, skuAttributeDict } = this.props.commonStore;
        const { demandTimeTab, selectGroupList, currentTab, colors, ppiInfo, startDate, endDate, type, originalDate, selectList, ppiData } = this.state;
        const data = {
            ALL: (skuAttributeDict.length && [skuAttributeDict[0]]) || [],
            MALE: skuAttributeDict,
            FEMALE: skuAttributeDict,
            KIDS: skuAttributeDict,
            ATTRIBUTE: skuAttributeDict,
		};

        return (
            <div className="flex-1 flex flex-column hidden p20 sku-bg" ref={(_f) => (this.ele = _f)}>
                <div className="flex align-center title-box">
					<div className="attribute-title"></div>
				</div>
                <div className="flex mb14">
                    <div className="flex-1 mr18">
                        <div className="select-item-box p8 flex border-box">
                            <div className="brand-select-box border-box flex align-center justify-center">
                                <SingleDrop title="Select Attribute" data={data} tabList={dropTabList} currentTab={currentTab} currentSelected={selectList} onConfirm={this.handleConfirm}></SingleDrop>
                            </div>
							<div className="flex flex-1 hidden">
								{selectGroupList.map((group, index) => (
									<div key={index} className="flex flex-column ml30 flex-1 hidden">
										{group.map((item, i) => (
											<div className="flex-1 flex align-center select-brand-item" key={`${index}-${i}`}>
												{(item && (
													<React.Fragment>
														<div className="mr4">
															<StyledCheckBox checked={true} indeterminate={true} onChange={() => this.handleRemoveSelected(item)} />
														</div>
														<div className="text-overflow hover">{currentTab !== 'ALL' && currentTab !== 'ATTRIBUTE' ? `${item.name}-${currentTab}` : item.name}</div>
													</React.Fragment>
												)) ||
													null}
											</div>
										))}
									</div>
								))}
							</div>
                        </div>
                    </div>
                    <div className="flex-1 flex align-center border-box">
                        <div className="flex-1 select-item-box mr18 flex align-center px20 border-box">
                            <div className="icon-calendar brand-calendar-item mr40"></div>
                            <DatePicker isCenter={true} onConfirm={this.handleDateConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                        </div>
                        <div className="select-item-box flex align-center p8 border-box">
                            {timeTabList.map((tab, index) => (
                                <div
                                    key={index}
                                    className={cx('brand-analysis-time border-box hover flex align-center justify-center', {
                                        'brand-analysis-time-active': demandTimeTab === tab.alias,
                                    })}
                                    onClick={() => this.handleDemandTimeTab(tab.alias)}
                                >
                                    {tab.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {attributeResult.map((brand, index) => (
                    <div
                        key={index}
                        className={cx('flex-1 flex', {
                            mb20: index !== attributeResult.length - 1,
                        })}
                    >
                        {brand.map((item, i) => {
                            const LineComponent = Visualization.get('line_chart');
                            return (
                                <div
                                    key={`${index}-${i}`}
                                    className={cx('flex-1 card-box flex flex-column', {
                                        mr18: i !== brand.length - 1,
                                    })}
                                >
                                    <div className="flex align-center brand-chart-title-box">
                                        <div>{item.title}</div>
                                        {(index === 0 && i === 0 && (
                                            <div className="brand-chart-ppi-box flex align-center justify-center ml10">
                                                <PPIComponent data={ppiData} onConfirm={this.handlePPIConfirm}></PPIComponent>
                                            </div>
                                        )) ||
                                            null}
                                    </div>
                                    <div className="visualization flex-1 p10 flex flex-column">
										<div className="flex-1">
											{LineComponent && <LineComponent type={2} from='attribute' gender={currentTab} ppiInfo={(index === 0 && i === 0 && ppiInfo) || null} data={item.data} changeColor={(color) => this.handleChangeColor(color, item.value)} colors={colors[item.value]} />}
										</div>
										<Slider range={item.dateRange} onChange={(value, sliderArr) => this.handleSliderChange(sliderArr, item.value)} />
									</div>
                                    <div className="info-box flex flex-column justify-between">
                                        {((index === 0 && i === 0 && this.getPPIDesc(item.currentDateRange, attributeDemandData, ppiInfo, currentTab)) || this.getDesc(item.currentDateRange, attributeIndexData, currentTab)).map((group, m) => (
                                            <div key={m} className="flex-1 flex align-center">
                                                {group.map((desc, k) => {
                                                    return desc.key ? (
                                                        <div
                                                            style={{ width: '33%' }}
                                                            className={cx('flex full-height px5', {
                                                                'info-item-border': k !== group.length - 1,
                                                            })}
                                                            key={`${index}-${m}-${k}`}
                                                        >
                                                            <CommonTootip
                                                                placement="top"
                                                                title={
                                                                    <div>
																		<div className="flex align-center mb10">
																			<div
																				className="dot"
																				style={{
																					background: colors[item.value][desc.key],
																				}}
																			/>
																			<div className="ml4 info-item-name-tooltip">{desc.key}</div>
																		</div>
																		<div>
																			{
																				this.getAttributeInfo(desc.list[0].num).map(info => (
																					<div className="flex align-center justify-between mb10" key={info.name}>
																						<div className="mr40">{info.name}</div>
																						<div>{info.value}</div>
																					</div>
																				))
																			}
																		</div>
																	</div>
                                                                }
                                                            >
                                                                <div className={cx('border-box flex align-center hover flex-1 hidden')}>
                                                                    <div
                                                                        className="dot"
                                                                        style={{
                                                                            background: colors[item.value][desc.key],
                                                                        }}
                                                                    />
                                                                    <div className="ml4 info-item-name flex-1">{desc.key}</div>
                                                                </div>
                                                            </CommonTootip>
                                                            <div className="flex align-center">
                                                                <div className="mr6 info-item-value">{formatM(desc.list[0][item.value])}</div>
                                                                {(desc.list[0][item.yoy] && (
                                                                    <div className="flex align-center mt4">
                                                                        {desc.list[0][item.yoy].indexOf('-') !== -1 ? (
                                                                            <ArrowDownward
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: '#FF5F60',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <ArrowUpward
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: '#4D9B4D',
                                                                                }}
                                                                            />
                                                                        )}
                                                                        <div
                                                                            className={cx('brand-percent', {
                                                                                'up-percent': desc.list[0][item.yoy].indexOf('-') === -1,
                                                                                'down-percent': desc.list[0][item.yoy].indexOf('-') !== -1,
                                                                            })}
                                                                        >
                                                                            {formatPercent(desc.list[0][item.yoy].replace('-', '')) || 0}
                                                                        </div>
                                                                    </div>
                                                                )) ||
                                                                    null}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex-1 flex full-height justify-between px5" key={`${index}-${m}-${k}`}></div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
				<div style={{position: 'fixed', left: 0, top: '10000px', width: '100%'}}>
					<div className="p20" ref={re => this.attr = re}>
					{
						selectList.map((list, index) => (
							<div key={index}>
								<div className="mb10">{list.name}</div>
								{
									list.children.map((item, i) => (
										<div key={`${index}-${i}`} className="flex align-center justify-between mb10">
											<div className="mr40">{item.name}</div>
											<div>{this.handleDownloadAttributeInfo(list, item)}</div>
										</div>
									))
								}
							</div>
						))
					}
					</div>
				</div>
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
            </div>
        );
    }
}
