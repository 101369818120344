import * as React from 'react';
import * as Yup from 'yup';
import { MobXProviderContext, observer } from 'mobx-react';
import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import './login.css';

import { Box } from '@material-ui/core';

import UserTextField from '../components/TextField';
import UserColorButton from './UserColorButton';
import DialogTextField from './DialogTextField';
import StyledDialog from '../components/StyledDialog';

const pathMap = {
    'user:management': '/user',
    'role_permission:management': '/role',
    'market_overview:index': '/overview',
    'brand_analysis:index': '/brand',
    'franchise_analysis:overview': '/franchise-overview',
    'franchise_analysis:nike': '/franchise-lca',
    'gender_analysis:index': '/gender',
    'sku_analysis:overview': '/sku-overview',
    'sku_analysis:top': '/top-sku',
    'sku_analysis:nike_product': '/nike-attribute',
    'sku_analysis:nike_new': 'nike-launch',
    'data_management:download': '/data-download',
    'data_management:upload': '/data-upload',
    'sub_category_overview:index': '/sub-category',
    'attribute_exploration:index': '/explor',
};

const LoginForm = ({ history }) => {
    const store = React.useContext(MobXProviderContext);
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [remembered, setRemembered] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [validate, setValidate] = React.useState('');
    const [messageOpen, setMessageOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [path, setPath] = React.useState('');
    const [baseImg, setBaseImg] = React.useState('');
    window.localStorage.removeItem('dewu_token');
    window.localStorage.removeItem('t');

    React.useEffect(() => {
        const dHeight = document.body.clientHeight;
        store.commonStore.setDocumentInfo({
            height: dHeight + 'px',
            width: '100%',
        });
    }, [store.commonStore]);

    const handleLogin = async (values) => {
        const { username, password } = values;
        const params = {
            username,
            password,
            rememberMe: remembered ? 1 : 2,
        };
        try {
            const result = await store.userStore.login(params);
            const { data, code, message } = result;
            if (code === 'AUTH_1007') {
                setMessageOpen(true);
                setMessage(message);
                setErrorText('');
                setPath(data);
            } else if (code === '00000') {
                setErrorText('');
                if (remembered) {
                    window.localStorage.setItem('t', new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
                    window.localStorage.setItem('r', 1);
                } else {
                    window.localStorage.removeItem('t');
                    window.localStorage.setItem('r', 2);
                }
                window.localStorage.setItem('dewu_token', data.authToken);
                const pathArr = data.permissionCodes.filter((f) => ['user:management', 'role_permission:management'].indexOf(f) === -1 && f);
                const path = (pathArr.length && pathMap[pathArr[0]]) || (data.permissionCodes.length && pathMap[data.permissionCodes[0]]) || '/overview';
                await store.commonStore.timeRangeMonthRequest();
                await store.commonStore.timeRangeWeekRequest();
                await store.commonStore.timeRangeSkuLcaRequest();
                await store.commonStore.timeRangeSkuPriceRequest();
                history.push(path);
            } else {
                setErrorText(message);
                formik.setSubmitting(false);
            }
        } catch (error) {
            setErrorText('');
            formik.setSubmitting(false);
        }
    };

    const loginType = process.env.REACT_APP_LOGIN

    const formik = useFormik({
        initialValues: loginType === 'dev' ? {
            username: 'admin@admin.com',
            password: '123123',
        } : {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required('E-mail is required!'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: (values) => {
            handleLogin(values);
        },
    });

    const handleForgetPassword = async () => {
        const response = await store.userStore.forgetPasswordImgRequest();
        if (response.code === '00000') {
            setBaseImg(response.data);
            setOpen(true);
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEmail('');
        setValidate('');
    };

    const handleToReset = () => {
        if (path) {
            history.push(path);
        }
    };

    const handleReflesh = async () => {
        const response = await store.userStore.forgetPasswordImgRequest();
        if (response.code === '00000') {
            setBaseImg(response.data);
        }
    };

    const handleForgetPasswordConfirm = async () => {
        if (email && validate) {
            const response = await store.userStore.forgetPasswordResetRequest({
                account: email,
                verificationCode: validate,
            });
            if (response.code === '00000') {
                setOpen(false);
                setEmail('');
                setValidate('');
            }
        }
    };

    return (
        <div className="flex full-height full-width login-wrapper">
            <div className="flex-1 flex flex-column justify-center align-center">
                <div>
                    <div className="login-text mb20">Log in</div>
                    <div className="login-text-sub">of your account</div>
                    <form onSubmit={formik.handleSubmit}>
                        <UserTextField size="small" error={Boolean(formik.touched.username && formik.errors.username)} fullWidth={true} name="username" type="text" id="outlined-username" margin="normal" label="Account" defaultValue={formik.getFieldProps('username').value} helperText={(formik.touched.username && formik.errors.username) || ''} variant="outlined" onBlur={formik.handleBlur} onChange={formik.handleChange} />
                        <UserTextField
                            size="small"
                            error={Boolean(formik.touched.password && formik.errors.password)}
                            fullWidth={true}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            id="outlined-password"
                            margin="normal"
                            label="Password"
                            defaultValue={formik.getFieldProps('password').value}
                            helperText={(formik.touched.password && formik.errors.password) || ''}
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <div className="hover" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box py={1}>
                            <div className="flex align-center justify-between">
                                <div className="flex align-center hover" onClick={() => setRemembered(!remembered)}>
                                    <div className="remember-icon flex align-center justify-center">{(remembered && <div className="remember-yes"></div>) || null}</div>
                                    <div className="remember-text">Remember me</div>
                                </div>
                                <div className="forget-text hover" onClick={() => handleForgetPassword()}>
                                    Forgot Password?
                                </div>
                            </div>
                        </Box>
                        <Box py={2}>
                            <UserColorButton color="primary" disableRipple={true} disabled={formik.isSubmitting} fullWidth={true} size="small" type="submit" variant="contained">
                                LOG IN
                            </UserColorButton>
                        </Box>
                    </form>
                    {(errorText && (
                        <div className="flex align-center">
                            <div className="login-error-icon"></div>
                            <div className="login-error-text">{errorText}</div>
                        </div>
                    )) ||
                        null}
                </div>
            </div>
            <div className="login-text-info">
                <div className="login-logo"></div>
                <div className="login-logo-text-box">
                    <div className="login-logo-text-title mb20">Hello!</div>
                    <div className="login-logo-text-desc">It's great to see you again!</div>
                    <div className="login-logo-text-desc">We have so many captivating projects for you.</div>
                </div>
            </div>
            <StyledDialog open={open}>
                <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                    <div className="login-dialog-content-icon mb10"></div>
                    <div className="dialog-content-title mb6">Forgot Password?</div>
                    <div className="dialog-content-desc text-center mb16">Enter the account email address to send the password change link</div>
                    <div className="full-width mb10">
                        <DialogTextField size="small" fullWidth={true} name="email" type="text" id="email" value={email} variant="outlined" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail Address" />
                    </div>
                    <div className="full-width mb20 flex align-center">
                        <div className="flex-1 mr10">
                            <DialogTextField size="small" fullWidth={true} name="validate" type="text" id="email" value={validate} variant="outlined" onChange={(e) => setValidate(e.target.value)} />
                        </div>
                        <div className="img-box hover" onClick={() => handleReflesh()}>
                            <img src={baseImg} alt="code" />
                        </div>
                    </div>
                    <div className="flex align-center justify-between full-width">
                        <div className="flex-1 dialog-operator-item flex align-center justify-center hover mr10" onClick={() => handleCancel()}>
                            Cancel
                        </div>
                        <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => handleForgetPasswordConfirm()}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledDialog>
            <StyledDialog open={messageOpen}>
                <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                    <div className="dialog-content-title mb16">{message}</div>
                    <div className="flex align-center justify-between full-width">
                        <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => handleToReset()}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledDialog>
        </div>
    );
};

export default withRouter(observer(LoginForm));
