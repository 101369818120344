import {
    observable,
    action,
    computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';
import _ from 'underscore';
import { subCategoryIndexRequest, subCategorySummaryRequest, subCategoryBlockRequest, subCategoryLineRequest } from '../api/subCategory'
import { completeMissingData } from './utils';
export class SubCategoryStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
    }

    @observable
    subCategoryIndexData = [];

    @observable
    subCategorySummaryData = [];

    @observable
    subCategoryPriceTierTreemap = []

    @observable
    subCategoryPriceTierLine = []

    @observable
	treemapLineDateArr = [];
	
	@observable
	treemapLineDateArrTemp = [];

	@observable
	dateArrOrigin = []

	@observable
	dateRangeArr1 = []

	@observable
	dateRangeArr2 = []

	@observable
	dateRangeArr3 = []

	@observable
	dateRangeArr4 = []

	@action
	changeDateRange1(dateRange) {
		this.dateRangeArr1 = dateRange
	}

	@action
	changeDateRange2(dateRange) {
		this.dateRangeArr2 = dateRange
	}
	@action
	changeDateRange3(dateRange) {
		this.dateRangeArr3 = dateRange
	}

	@action
	changeDateRange4(dateRange) {
		this.dateRangeArr4 = dateRange
	}

    @action
	changeTreemapLineDateRange(dateRange) {
		this.treemapLineDateArrTemp = dateRange;
	}

    @action
    async subCategorySummaryRequest({ dateType, startDate, endDate, priceType }) {
        const response = await subCategorySummaryRequest({
            dateType,
            startDate,
            endDate,
            priceType
        });
        const { code, data } = response;
        if (code === '00000') {
            this.subCategorySummaryData = data;
        }
    }
	
	@computed
	get subCategoryIndexResult() {
		const ppiTemp = [];
		const rviTemp = [];
		const diTemp = [];
		const demandTemp = [];
		this.subCategoryIndexData.forEach((item) => {
			demandTemp.push({
				name: item.subCategory,
				franchise: item.franchise,
				brand: item.brandClean,
				category: item.category,
				date: item.date,
				value: Number(item.demand),
				yoy: item.demandYoy,
				origin: item,
			});
			ppiTemp.push({
				name: item.subCategory,
				franchise: item.franchise,
				brand: item.brandClean,
				category: item.category,
				date: item.date,
				value: Number(item.ppi),
				yoy: item.ppiYoy,
				origin: item,
			});
			rviTemp.push({
				name: item.subCategory,
				franchise: item.franchise,
				brand: item.brandClean,
				category: item.category,
				date: item.date,
				value: Number(item.resaleVolumeIndex),
				yoy: item.resaleVolumeIndexYoy,
				origin: item,
			});
			diTemp.push({
				name: item.subCategory,
				franchise: item.franchise,
				brand: item.brandClean,
				category: item.category,
				date: item.date,
				value: Number(item.diversity),
				yoy: item.diversityYoy,
				origin: item,
			});
		});
		const de = Object.entries(_.groupBy(demandTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr1, value),
		}));
		const ppi = Object.entries(_.groupBy(ppiTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr2, value),
		}));
		const rvi = Object.entries(_.groupBy(rviTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr3, value),
		}));
		const di = Object.entries(_.groupBy(diTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr4, value),
		}));
		return [
			[
				{
					title: 'DEMAND',
					data: de,
					value: 'demand',
					yoy: 'demandYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr1
				},
				{
					title: 'PREMIUM PRICE INDEX',
					data: ppi,
					value: 'ppi',
					yoy: 'ppiYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr2
				},
			],
			[
				{
					title: 'RESALE VOLUME INDEX',
					data: rvi,
					value: 'resaleVolumeIndex',
					yoy: 'resaleVolumeIndexYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr3
				},
				{
					title: 'DIVERSITY INDEX',
					data: di,
					value: 'diversity',
					yoy: 'diversityYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr4
				},
			],
		];
	}

    @action
    async subCategoryIndexRequest({ dateType, startDate, endDate, dataType, brandList, franchiseList, modelList }) {
        const response = await subCategoryIndexRequest({
            dateType,
            startDate,
            endDate,
            dataType
        });
        if (response.code === '00000') {
            this.subCategoryIndexData = response.data;
			this.dateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr1 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr2 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr3 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr4 = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async subCategoryBlockRequest({ dateType, startDate, endDate, priceType, subCategory }) {
        const response = await subCategoryBlockRequest({
            dateType,
            startDate,
            endDate,
            priceType,
            subCategoryList: [subCategory]
        });
        const { code, data } = response;
        if (code === '00000') {
            this.subCategoryPriceTierTreemap = data.sort((a, b) => a.priceTierCode - b.priceTierCode);
        }
	}

    @computed
	get subCategoryPriceTierLineResult() {
		const data = this.subCategoryPriceTierLine.filter(f => this.treemapLineDateArrTemp.indexOf(f.date) !== -1 && f);
		const groupByData = _.groupBy(data, (d) => d.brandClean);
        return groupByData;
	}

    @action
    async subCategoryLineRequest({ dateType, startDate, endDate, priceType, priceTierCode, brandList, subCategory }) {
        const response = await subCategoryLineRequest({
            dateType,
            startDate,
            endDate,
            priceType,
            priceTierCode,
            brandList,
            subCategoryList: [subCategory]
        });
        const { code, data } = response;
        if (code === '00000') {
			this.treemapLineDateArr = Array.from(new Set(data.map((d) => d.date))).sort();
			this.treemapLineDateArrTemp = Array.from(new Set(data.map((d) => d.date))).sort();
			this.subCategoryPriceTierLine = data;
        }
    }

}
