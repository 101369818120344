import * as React from 'react';
import { Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import '../../assets/css/home.css';
import './datePicker.css';
import moment from 'moment';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} {...props} />);

const DownloadDatePicker = ({ onConfirm = () => {}, currentStart, currentEnd, currentOrigin, dayRange, error }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [original, setOriginal] = React.useState([]);

    const handleCancel = () => {
        setAnchorEle(null);
        setOpen(false);
    };

    const handleConfirm = () => {
        if (selected.length === 2) {
            setAnchorEle(null);
            setOpen(false);
            onConfirm(selected, original);
        }
    };

    const handleChange = (data, formattedData) => {
        setSelected(formattedData);
        setOriginal(data);
    };

    const disabledDate = (current) => {
		const isFuture = current && current > moment().endOf('day');
		if (isFuture) {
			return isFuture;
		}
        if (dayRange) {
            const isLater = current && (dayRange.max ? current > moment(dayRange.max).endOf('day') : current > moment().endOf('day'));
            const isEarlier = current && (dayRange.min ? current < moment(dayRange.min).endOf('day') : false);
            return isLater || isEarlier;
        }
    };

    return (
        <div className="picker-box">
            <div
                className="flex align-center hover"
                onClick={(e) => {
                    setAnchorEle(e.currentTarget);
                    setOpen(true);
                    setSelected([]);
                    setOriginal([]);
                }}
            >
                <div className={cx("time-item-box-download flex align-center px10", {
					'time-box-error': error
				})}>
                    <div className="icon-calendar picker-item"></div>
                    <div>{(currentOrigin && currentOrigin.startDate) || ''}</div>
                </div>
                <div className="px8">-</div>
                <div className={cx("time-item-box-download flex align-center px10", {
					'time-box-error': error
				})}>
                    <div className="icon-calendar picker-item"></div>
                    <div>{(currentOrigin && currentOrigin.endDate) || ''}</div>
                </div>
            </div>
            <StyledMenu
                keepMounted
                anchorEl={anchorEle}
                open={Boolean(anchorEle)}
                onClose={() => {
                    setAnchorEle(null);
                    setOpen(false);
                }}
            >
                <div className="px10 picker-content flex flex-column">
                    <div style={{ width: '560px', height: '340px', position: 'relative', top: '20px' }}>{<RangePicker value={original} open={open} disabledDate={disabledDate} popupStyle={{ zIndex: 9999 }} onChange={handleChange} />}</div>
                    <div className="flex align-center mt30">
                        <div className="flex-1 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                            Cancel
                        </div>
                        <div className="flex-1 ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default DownloadDatePicker;
