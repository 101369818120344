import { $get, $post } from './index';

export const brandDictRequest = () => {
    return $get(
        '/data/dict/brand',
        {},
        {
            queryName: 'brandDictRequest',
        }
    );
};

export const franchiseDictRequest = () => {
    return $get(
        '/data/dict/brand/franchise',
        {},
        {
            queryName: 'franchiseDictRequest',
        }
    );
};

export const franchiseModelDictRequest = () => {
    return $get(
        '/data/dict/brand/franchise/model',
        {},
        {
            queryName: 'franchiseModelDictRequest',
        }
    );
};

export const franchiseNikeDictRequest = () => {
    return $get(
        '/data/dict/brand/franchise/nike',
        {},
        {
            queryName: 'franchiseNikeDictRequest',
        }
    );
};

export const skuDictOverviewDefaultRequest = () => {
    return $get(
        '/data/dict/sku/default/overview',
        {},
        {
            queryName: 'skuDictOverviewDefaultRequest',
        }
    );
};

export const skuDictOverviewRequest = (data) => {
    return $get('/data/dict/sku/overview', data, {
        queryName: 'skuDictOverviewRequest',
    });
};

export const skuDictImgUrlRequest = (data) => {
    return $post('/data/dict/sku/image/url', data, {
        queryName: 'skuDictImgUrlRequest'
    })
}

export const skuDictNikeDefaultRequest = () => {
    return $get(
        '/data/dict/sku/default/lca',
        {},
        {
            queryName: 'skuDictNikeDefaultRequest',
        }
    );
};

export const skuDictNikeRequest = (data) => {
    return $get('/data/dict/sku/lca', data, {
        queryName: 'skuDictNikeRequest',
    });
};

export const timeRangeMonthRequest = () => {
    return $get(
        '/data/time/range/month',
        {},
        {
            queryName: 'timeRangeMonthRequest',
        }
    );
};

export const timeRangeWeekRequest = () => {
    return $get(
        '/data/time/range/week',
        {},
        {
            queryName: 'timeRangeWeekRequest',
        }
    );
};

export const timeRangeSkuLcaRequest = () => {
    return $get(
        '/data/time/range/sku/lca',
        {},
        {
            queryName: 'timeRangeSkuLcaRequest',
        }
    );
};

export const timeRangeSkuPriceRequest = () => {
    return $get(
        '/data/time/range/sku/price',
        {},
        {
            queryName: 'timeRangeSkuPriceRequest',
        }
    );
};

export const skuDictLcaSearch = (data) => {
    return $get('/data/dict/sku/lca/product', data, {
        queryName: 'skuDictLcaSearch',
    });
};

export const skuAttributeDictDefaultRequest = () => {
	return $get('/data/dict/attribute/default', {}, {
		queryName: 'skuAttributeDictDefaultRequest'
	})
}

export const skuAttributeDictRequest = () => {
	return $get('/data/dict/attribute', {}, {
		queryName: 'skuAttributeDictRequest'
	})
}

export const skuAttributeDictHistoryLatestRequest = () => {
	return $get('/data/sku/analysis/nike/product/attribute/parameter/latest', {}, {
		queryName: 'skuAttributeDictHistoryLatestRequest'
	})
}