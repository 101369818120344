import * as React from 'react';
import { Menu, TextField, Checkbox, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import cx from 'classnames';
import './dropBox.css';

import { withStyles, createStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => {
    return <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />;
});

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '3.5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

const StyledCheckBox = withStyles((theme) =>
    createStyles({
        root: {
            color: '#fff',
            opacity: 0.5,
            background: '#000',
            padding: '0px',
            fontSize: '12px',
            borderRadius: '100%',
            overflow: 'hidden',
            width: '16px',
            height: '16px',
            '&.MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#fff',
            },
            '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
                opacity: 1,
            },
        },
    })
)(Checkbox);

const CascadeDrop = ({ onConfirm = () => {}, title = 'Brands', data, currentList, currentTab, tabList, position }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('');
    const [checkedList, setCheckedList] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [subMenus, setSubMenus] = React.useState([]);
    const [modelMenus, setModelMenus] = React.useState([]);
    const [tempChecked, setTempChecked] = React.useState([]);
    const [searchList, setSearchList] = React.useState([]);
    const [tab, setTab] = React.useState('');
    const [total, setTotal] = React.useState(6);

    React.useEffect(() => {
        if (tabList) {
            const firstTab = tabList[1]['alias'];
            setTab(currentTab || firstTab);
            setList((currentTab && data[currentTab]) || data[firstTab] || []);
            if ((currentTab || firstTab) === 'ALL') {
                setTotal(1);
            } else {
                setTotal(6);
            }
        } else {
            setList(data['default'] || []);
        }
        setCheckedList([]);
        setSearchValue('');
        setSubMenus([]);
        setModelMenus([])
        setTempChecked([]);
        setSearchList([]);

        if (currentList) {
            setCheckedList(currentList);
        }
    }, [data, tabList, currentList, currentTab]);

    const handleCancel = () => {
        setAnchorEle(null);
        setCheckedList(currentList || []);
        setTab(currentTab);
        setSearchValue('');
        setSubMenus([]);
        setModelMenus([])
        setTempChecked([]);
        if (currentTab === 'ALL') {
            setTotal(1);
        } else {
            setTotal(6);
        }
    };

    const handleConfirm = () => {
        onConfirm(checkedList, tab);
        setAnchorEle(null);
        setSearchValue('');
    };

    const handleRemoveSelected = (list) => {
        const currentLast = list[list.length - 1];
        let removeIndex = null;
        checkedList.forEach((checked, index) => {
            const checkedLast = checked[checked.length - 1];
            if (`${checkedLast.name}_${checkedLast.position}` === `${currentLast.name}_${currentLast.position}`) {
                removeIndex = index;
            }
        });
        const checkList = remove(checkedList, removeIndex);
        setCheckedList(checkList);
        const tempLast = tempChecked.length && tempChecked[tempChecked.length - 1];
        if (tempLast && (`${tempLast.name}_${tempLast.position}` === `${currentLast.name}_${currentLast.position}`)) {
            const temp = removeAndUpdate(tempChecked, { ...tempLast, checked: false }, tempChecked.length - 1);
            setTempChecked(temp);
        }
    };

    const recursionTreeData = (item, searchObj, value, pos) => {
        if (item.isLeaf && item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            searchObj[pos] = item;
        } else if (!item.isLeaf) {
            searchObj[pos] = item;
            item.children.forEach((d) => {
                recursionTreeData(d, searchObj, value, `${pos}#%%#${d.name}_${d.position}`);
            });
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        const result = [];
        if (value) {
            const searchObj = {};
            JSON.parse(JSON.stringify(list)).forEach((l) => {
                recursionTreeData(l, searchObj, value, `${l.name}_${l.position}`);
            });

            const obj = JSON.parse(JSON.stringify(searchObj));

            Object.entries(obj).forEach(([key, values]) => {
                if (values.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && values.isLeaf) {
                    const arr = [];
                    Object.entries(obj).forEach(([k, v]) => {
                        if (key.indexOf(k) === 0) {
                            arr.push(v);
                        }
                    });
                    result.push(arr);
                }
            });
        }
        setSearchList(result);
    };

    const handleChange = (list) => {
        const currentLast = list[list.length - 1];

        let removeIndex = null;
        checkedList.forEach((l, index) => {
            const lastCheck = l[l.length - 1];
            if (`${lastCheck.name}_${lastCheck.position}` === `${currentLast.name}_${currentLast.position}`) {
                removeIndex = index;
            }
        });

        let checkList = checkedList;
        if (removeIndex !== null) {
            checkList = remove(checkedList, removeIndex);
        } else if (checkedList.length < total) {
            checkList = add(checkedList, list);
        }
        setCheckedList(checkList);
    };

    const update = (list, item, index) => {
        const arr = [...list.slice(0, index), item, ...list.slice(index + 1)];
        return arr;
    };

    const removeAndUpdate = (list, item, index) => {
        const arr = [...list.slice(0, index), item];
        return arr;
    };

    const add = (list, item) => {
        const arr = [...list, item];
        return arr;
    };

    const remove = (list, index) => {
        const arr = [...list.slice(0, index), ...list.slice(index + 1)];
        return arr;
    };

    const handleMenuClick = (item, index) => {
        // subMenus = [[],[]]
        // tempChecked = [[],[],[]]  length is no more than 3
        // while current click item is not leaf
        // position of item is 1  new subMenus and new tempCheckedList
        // position of item is more than 1   update subMenus and tempCheckedList by index
        if (item.children.length && !item.isLeaf) {
            if (item.position === 1) {
                const arr = [];
                arr.push(item.children);
                setSubMenus(arr);
                setModelMenus([])
                setTempChecked([
                    {
                        ...item,
                        checked: true,
                    },
                ]);
            } else {
                // const arr = removeAndUpdate(subMenus, item.children, item.position - 1);
                // setSubMenus(arr);
                const arr = []
                arr.push(item.children)
                setModelMenus(arr)

                const temp = removeAndUpdate(tempChecked, { ...item, checked: true }, item.position - 1);
                setTempChecked(temp);
            }
        } else {
            // current item is leaf
            // if current item is exist in checkedList then find the index and remove item in checkedList else add item to checkedList
            // set tempChecked  checked or not checked
            let removeIndex = null;
            checkedList.forEach((l, index) => {
                const lastCheck = l[l.length - 1];
                if (`${lastCheck.parentValue}_${lastCheck.name}_${lastCheck.position}` === `${item.parentValue}_${item.name}_${item.position}`) {
                    removeIndex = index;
                }
            });

            const temp = update(tempChecked, { ...item, checked: removeIndex !== null ? false : true }, item.position - 1);
            if (checkedList.length < total) {
                setTempChecked(temp);
            }

            let checkList = checkedList;
            if (removeIndex !== null) {
                checkList = remove(checkedList, removeIndex);
            } else {
                if (checkedList.length < total) {
                    checkList = add(checkedList, temp);
                }
            }
            setCheckedList(checkList);
        }
    };

    const checkedObj = {};
    // leaf is checked and parent is checked
    checkedList.forEach((list) => {
        list.forEach((check) => {
            checkedObj[`${check.parentValue}-${check.name}_${check.position}`] = true;
        });
    });

    // contains leaf is not checked but parent is checked
    tempChecked.forEach((check) => {
        checkedObj[`${check.parentValue}-${check.name}_${check.position}`] = check.checked;
    });

    const groupedByNumber = (num = 2, list, emptyObj = {}) => {
        const group = [];
        for (let i = 0; i < list.length; i += num) {
            group.push(list.slice(i, i + num));
        }
        const last = (group.length && group[group.length - 1]) || [];
        const empty = [];
        if (last.length) {
            for (let i = 0; i < num - last.length; i++) {
                empty.push(emptyObj);
            }
            empty.forEach((e) => {
                group[group.length - 1].push(e);
            });
        }
        return group;
    };

    const groupedCheckedList = groupedByNumber(2, checkedList, null);

    const handleTabChange = (tabItem) => {
        setTab(tabItem);
        setList(data[tabItem]);
        setCheckedList([]);
        if (tabItem === 'ALL') {
            setTotal(1);
        } else {
            setTotal(6);
        }
    };

    return (
        <div className="drop-box">
            <div className="hover flex align-center" onClick={(e) => setAnchorEle(e.currentTarget)}>
                <div className="select-icon-model"></div>
                {/* <div>{title}</div> */}
            </div>
            <StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={() => setAnchorEle(null)}>
                <div className="py10 px20 drop-content flex flex-column">
                    {(tabList && (
                        <div className="flex align-center">
                            {tabList.map((tabItem, index) => (
                                <div
                                    key={index}
                                    className={cx('flex-1 tab-item border-box flex align-center justify-center hover', {
                                        'tab-item-active': tabItem.alias === tab,
                                    })}
                                    onClick={() => handleTabChange(tabItem.alias)}
                                >
                                    {tabItem.name}
                                </div>
                            ))}
                        </div>
                    )) ||
                        null}
                    <div className="py10 flex align-center">
                        <div className="flex-1">
                            <StyledTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth={true}
                                value={searchValue}
                                onChange={(e) => handleSearch(e.target.value)}
                                id="search"
                                type="search"
                                variant="outlined"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="flex-1 py10 flex hidden" style={{ maxHeight: '300px', minHeight: '100px' }}>
                        {searchValue === '' ? (
                            <div className="flex-1 flex flex-column overflow-y drop-item-box">
                                {list.map((item, index) => (
                                    <div
                                        className={cx('flex align-center justify-between drop-items pb15 hidden hover', {
                                            'drop-active': checkedObj[`${item.parentValue}-${item.name}_${item.position}`],
                                        })}
                                        key={index}
                                        onClick={() => handleMenuClick(item, index)}
                                    >
                                        <div className="flex align-center">{item.name}</div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex-1 flex flex-column overflow-y drop-item-box">
                                {searchList.map((search, index) => {
                                    const searchItems = JSON.parse(JSON.stringify(search)).reverse();
                                    const checkLabel = searchItems[0];
                                    return (
                                        <div
                                            className={cx('flex align-center justify-between hidden', {
                                                'drop-active': checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                            })}
                                            key={index}
                                            style={{ minHeight: '30px' }}
                                        >
                                            <div className="flex align-center flex-1 py10">
                                                {searchItems.map((item, i) => (
                                                    <div className="pr40 flex-1 search-content-item" key={`${index}_${i}`}>
                                                        {item.name}
                                                    </div>
                                                ))}
                                            </div>
                                            {checkLabel.description ? <div className="flex align-center flex-1 search-content-description mr20 py10">{checkLabel.description}</div> : null}
                                            <div
                                                className={cx('icon-check-box hover', {
                                                    'icon-check': checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                                    'icon-uncheck': !checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                                })}
                                                onClick={() => handleChange(search)}
                                            ></div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {searchValue === ''
                            ? 	(
                                <>
                                    {
                                        subMenus.length ? (
                                            <div className="flex flex-1 flex-column drop-sub-menu drop-item-box">
                                                <div style={{ fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '4px' }}>Nike Franchise</div>
                                                {
                                                    subMenus.map((subMenu, index) => (
                                                        <div className="flex-1 flex flex-column overflow-y" key={index}>
                                                            {subMenu.map((menu, i) => (
                                                                <div
                                                                    className={cx('flex align-center justify-between drop-items pb15 hover', {
                                                                        'drop-active': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                    })}
                                                                    key={`${index}-${i}`}
                                                                    onClick={() => handleMenuClick(menu, i)}
                                                                >
                                                                    <div className="flex align-center">{menu.name}</div>
                    
                                                                    {menu.isLeaf ? (
                                                                        <div
                                                                            className={cx('icon-check-box hover', {
                                                                                'icon-check': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                                'icon-uncheck': !checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                            })}
                                                                        ></div>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ): null
                                    }
                                    {
                                        modelMenus.length ? (
                                            <div className="flex flex-1 flex-column drop-sub-menu drop-item-box">
                                                <div style={{ fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '4px' }}>Model</div>
                                                {
                                                    modelMenus.map((modelMeny, index) => (
                                                        <div className="flex-1 flex flex-column overflow-y" key={index}>
                                                            {modelMeny.map((menu, i) => (
                                                                <div
                                                                    className={cx('flex align-center justify-between drop-items pb15 hover', {
                                                                        'drop-active': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                    })}
                                                                    key={`${index}-${i}`}
                                                                    onClick={() => handleMenuClick(menu, i)}
                                                                >
                                                                    <div className="flex align-center">{menu.name}</div>
                    
                                                                    {menu.isLeaf ? (
                                                                        <div
                                                                            className={cx('icon-check-box hover', {
                                                                                'icon-check': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                                'icon-uncheck': !checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                            })}
                                                                        ></div>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ): null
                                    }
                                </>
                            )
                            : null}
                    </div>
                    {checkedList.length ? (
                        <div className="flex flex-column py10 select-content">
                            {groupedCheckedList.map((group, index) => (
                                <div className="flex align-center mb10" key={index}>
                                    {group.map((list, i) => {
                                        if (list) {
                                            // const reserveArr = JSON.parse(JSON.stringify(list)).reverse();
                                            return (
                                                <div className="flex-1 flex align-center" key={`${index}_${i}`} style={{ minWidth: '260px' }}>
                                                    <StyledCheckBox checked={true} indeterminate={true} onChange={() => handleRemoveSelected(list)} />
                                                    <div className="pl4 drop-sub-item flex align-center flex-1">
                                                        {list[list.length - 1].name}
                                                        {/* {reserveArr.map((reverseItem, ind) => (
                                                            <div className="mr10 flex-1" key={`${index}_${i}_${ind}`}>
                                                                {reverseItem.name}
                                                            </div>
                                                        ))} */}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return <div className="flex-1 flex align-center" key={`${index}_${i}`} style={{ minWidth: '260px' }} />;
                                    })}
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div className="flex align-center justify-between footer-box">
                        <div className="flex align-center select-text">
                            {(!position && (
                                <React.Fragment>
                                    <div>Selected</div>
                                    <div className="select-value">
                                        {checkedList.length}/{total}
                                    </div>
                                </React.Fragment>
                            )) ||
                                null}
                        </div>
                        <div className="flex align-center">
                            <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                                Cancel
                            </div>
                            <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default CascadeDrop;
