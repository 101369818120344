import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

const StyledDialog = withStyles((theme) => ({
    root: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.76)',
        },
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
        },
    },
}))(Dialog);

export default StyledDialog;
