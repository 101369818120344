import * as React from 'react';
import { Menu, TextField, Checkbox, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import cx from 'classnames';
import _ from 'underscore';
import './dropBox.css';

import { withStyles, createStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => {
    return <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />;
});

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '3.5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

const StyledCheckBox = withStyles((theme) =>
    createStyles({
        root: {
            color: '#fff',
            opacity: 0.5,
            background: '#000',
            padding: '0px',
            fontSize: '12px',
            borderRadius: '100%',
            overflow: 'hidden',
            width: '16px',
            height: '16px',
            '&.MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#fff',
            },
            '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
                opacity: 1,
            },
        },
    })
)(Checkbox);

const menus = [
    {
        name: 'CATEGORY',
        value: 'CATEGORY'
    }, 
    {
        name: 'BRAND',
        value: 'BRAND'
    },
    {
        name: 'FRANCHISE',
        value: 'FRANCHISE'
    }
]

const genderMenus = [
    {
        name: 'ALL GENDERS',
        value: 'ALL'
    },
    {
        name: 'MALE',
        value: 'MALE'
    },
    {
        name: 'FEMALE',
        value: 'FEMALE'
    },
    {
        name: 'KIDS',
        value: 'KIDS'
    }
]

const categoryMenus = [
    {
        name: 'Total',
        value: 'TOTAL'
    },
    {
        name: 'Lifestyle',
        value: 'LIFESTYLE'
    },
    {
        name: 'Running',
        value: 'RUNNING'
    },
    {
        name: 'Basketball',
        value: 'BASKETBALL'
    },
    {
        name: 'Others',
        value: 'OTHERS'
    }
]

const GenderCascadeDrop = ({ onConfirm = () => {}, title = 'BRAND', brandDict, franchiseDict, data, currentList, currentTab, gender, type, tabList, position }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('');
    const [checkedList, setCheckedList] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [subMenus, setSubMenus] = React.useState([]);
    // const [genderMenus, setGenderMenus] = React.useState([genderMenusDict])
    const [modelMenus, setModelMenus] = React.useState([]);
    const [tempChecked, setTempChecked] = React.useState([]);
    const [searchList, setSearchList] = React.useState([]);
    // const [tab, setTab] = React.useState('');
    const [total, setTotal] = React.useState(6);
    const [currentTypeMenu, setCurrentTypeMenu] = React.useState({})
    const [currentGenderMenu, setCurrentGenderMenu] = React.useState({})
    const [brands, setBrands] = React.useState([])
    const [franchises, setFranchises] = React.useState([])

    React.useEffect(() => {
        const dict = brandDict.map((item) => ({
            ...item,
            name: item.display
        }))
        setBrands(dict)
    }, [brandDict])

    React.useEffect(() => {
        const dict = franchiseDict
        setFranchises(dict)
    }, [franchiseDict])

    React.useEffect(() => {
        if (currentList) {
            setCheckedList(currentList);
        }
    }, [currentList])

    React.useEffect(() => {
        const typeArr = menus.filter((item) => item.value === type) || []
        setCurrentTypeMenu((typeArr.length && typeArr[0]) || {})
        const genderArr = genderMenus.filter((item) => item.value === gender) || []
        setCurrentGenderMenu((genderArr.length && genderArr[0]) || {})
        if (type === 'BRAND') {
            setList(brands)
        } else if (type === 'FRANCHISE') {
            setList(franchises)
        } else if (type === 'CATEGORY') {
            setList(categoryMenus)
        }
    }, [gender, type, brands, franchises])

    const handleCancel = () => {
        setAnchorEle(null);
        setCheckedList(currentList || []);
        // setTab(currentTab);
        setSearchValue('');
        setSubMenus([]);
        setModelMenus([])
        setTempChecked([]);
        if (gender === 'ALL') {
            setTotal(1);
        } else {
            setTotal(6);
        }
    };

    const handleConfirm = () => {
        onConfirm(checkedList, currentGenderMenu.value, currentTypeMenu.value);
        setAnchorEle(null);
        setSearchValue('');
    };

    const handleRemoveSelected = (item, type) => {
        if (type === 'BRAND') {
            const list = removeByName(checkedList, item);
            setCheckedList(list);
        } else {
            const currentLast = list[list.length - 1];
            let removeIndex = null;
            checkedList.forEach((checked, index) => {
                const checkedLast = checked[checked.length - 1];
                if (`${checkedLast.name}_${checkedLast.position}` === `${currentLast.name}_${currentLast.position}`) {
                    removeIndex = index;
                }
            });
            const checkList = remove(checkedList, removeIndex);
            setCheckedList(checkList);
            const tempLast = tempChecked.length && tempChecked[tempChecked.length - 1];
            if (tempLast && (`${tempLast.name}_${tempLast.position}` === `${currentLast.name}_${currentLast.position}`)) {
                const temp = removeAndUpdate(tempChecked, { ...tempLast, checked: false }, tempChecked.length - 1);
                setTempChecked(temp);
            }
        }
    };

    const recursionTreeData = (item, searchObj, value, pos) => {
        if (item.isLeaf && item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            searchObj[pos] = item;
        } else if (!item.isLeaf) {
            searchObj[pos] = item;
            item.children.forEach((d) => {
                recursionTreeData(d, searchObj, value, `${pos}#%%#${d.name}_${d.position}`);
            });
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        if (currentTypeMenu.value === 'BRAND') {
            const list = brands.filter((f) => {
                if (f.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    return f;
                }
                return false;
            }) || [];
            setList(list);
        } else if (currentTypeMenu.value === 'CATEGORY') {
            const list = categoryMenus.filter((f) => {
                if (f.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    return f;
                }
                return false;
            }) || [];
            setList(list);
        } else {
            const result = [];
            if (value) {
                const searchObj = {};
                JSON.parse(JSON.stringify(franchises)).forEach((l) => {
                    recursionTreeData(l, searchObj, value, `${l.name}_${l.position}`);
                });
    
                const obj = JSON.parse(JSON.stringify(searchObj));
    
                Object.entries(obj).forEach(([key, values]) => {
                    if (values.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && values.isLeaf) {
                        const arr = [];
                        Object.entries(obj).forEach(([k, v]) => {
                            if (key.indexOf(k) === 0) {
                                arr.push(v);
                            }
                        });
                        result.push(arr);
                    }
                });
            }
            setSearchList(result);
        }
    };

    const handleChange = (list) => {
        const currentLast = list[list.length - 1];

        let removeIndex = null;
        checkedList.forEach((l, index) => {
            const lastCheck = l[l.length - 1];
            if (`${lastCheck.name}_${lastCheck.position}` === `${currentLast.name}_${currentLast.position}`) {
                removeIndex = index;
            }
        });

        let checkList = checkedList;
        if (removeIndex !== null) {
            checkList = remove(checkedList, removeIndex);
        } else if (checkedList.length < total) {
            checkList = add(checkedList, list);
        }
        setCheckedList(checkList);
    };

    const update = (list, item, index) => {
        const arr = [...list.slice(0, index), item, ...list.slice(index + 1)];
        return arr;
    };

    const removeAndUpdate = (list, item, index) => {
        const arr = [...list.slice(0, index), item];
        return arr;
    };

    const add = (list, item) => {
        const arr = [...list, item];
        return arr;
    };

    const remove = (list, index) => {
        const arr = [...list.slice(0, index), ...list.slice(index + 1)];
        return arr;
    };

    const removeByName = (list, item) => {
        const index = _.findIndex(list, (p) => p === item);
        const arr = [...list.slice(0, index), ...list.slice(index + 1)];
        return arr;
    };

    const handleMenuClick = (item, index) => {
        // subMenus = [[],[]]
        // tempChecked = [[],[],[]]  length is no more than 3
        // while current click item is not leaf
        // position of item is 1  new subMenus and new tempCheckedList
        // position of item is more than 1   update subMenus and tempCheckedList by index
        if (item.children.length && !item.isLeaf) {
            if (item.position === 1) {
                const arr = [];
                arr.push(item.children);
                setSubMenus(arr);
                setModelMenus([])
                setTempChecked([
                    {
                        ...item,
                        checked: true,
                    },
                ]);
            } else {
                // const arr = removeAndUpdate(subMenus, item.children, item.position - 1);
                // setSubMenus(arr);
                const arr = []
                arr.push(item.children)
                setModelMenus(arr)

                const temp = removeAndUpdate(tempChecked, { ...item, checked: true }, item.position - 1);
                setTempChecked(temp);
            }
        } else {
            // current item is leaf
            // if current item is exist in checkedList then find the index and remove item in checkedList else add item to checkedList
            // set tempChecked  checked or not checked
            let removeIndex = null;
            checkedList.forEach((l, index) => {
                const lastCheck = l[l.length - 1];
                if (`${lastCheck.parentValue}_${lastCheck.name}_${lastCheck.position}` === `${item.parentValue}_${item.name}_${item.position}`) {
                    removeIndex = index;
                }
            });

            const temp = update(tempChecked, { ...item, checked: removeIndex !== null ? false : true }, item.position - 1);
            if (checkedList.length < total) {
                setTempChecked(temp);
            }

            let checkList = checkedList;
            if (removeIndex !== null) {
                checkList = remove(checkedList, removeIndex);
            } else {
                if (checkedList.length < total) {
                    checkList = add(checkedList, temp);
                }
            }
            setCheckedList(checkList);
        }
    };

    const checkedObj = {};
    if (currentTypeMenu.value !== 'FRANCHISE') {
        checkedList.forEach((item) => {
            checkedObj[item] = true
        })
    } else {
        // leaf is checked and parent is checked
        checkedList.forEach((list) => {
            list.forEach((check) => {
                checkedObj[`${check.parentValue}-${check.name}_${check.position}`] = true;
            });
        });
    
        // contains leaf is not checked but parent is checked
        tempChecked.forEach((check) => {
            checkedObj[`${check.parentValue}-${check.name}_${check.position}`] = check.checked;
        });
    }

    const groupedByNumber = (num = 2, list, emptyObj = {}) => {
        const group = [];
        for (let i = 0; i < list.length; i += num) {
            group.push(list.slice(i, i + num));
        }
        const last = (group.length && group[group.length - 1]) || [];
        const empty = [];
        if (last.length) {
            for (let i = 0; i < num - last.length; i++) {
                empty.push(emptyObj);
            }
            empty.forEach((e) => {
                group[group.length - 1].push(e);
            });
        }
        return group;
    };

    const groupedCheckedList = groupedByNumber(2, checkedList, null);

    const handleTypeMenuClick = (item) => {
        setCurrentTypeMenu(item)
        setCheckedList([])
        setCurrentGenderMenu({})
        setSearchValue('')
    }

    const handleGenderMenuClick = (item) => {
        setCurrentGenderMenu(item)
        setCheckedList([])
        setSearchValue('')
        setTempChecked([])
        setSubMenus([])
        setModelMenus([])
        if (item.value === 'ALL') {
            setTotal(1)
        } else {
            setTotal(6)
        }
        if (currentTypeMenu.value === 'BRAND') {
            setList(brands)
        } else if (currentTypeMenu.value === 'FRANCHISE') {
            setList(franchises)
        } else if (currentTypeMenu.value === 'CATEGORY') {
            setList(categoryMenus)
        }
    }

    const handleCheckBox = (name) => {
        const checked = checkedObj[name];
        if (!checked) {
            if (checkedList.length < total) {
                const list = add(checkedList, name);
                setCheckedList(list);
            }
        } else {
            const list = removeByName(checkedList, name);
            setCheckedList(list);
        }
    };

    return (
        <div className="drop-box" style={{width: '190px'}}>
            <div className="hover flex align-center" onClick={(e) => setAnchorEle(e.currentTarget)}>
                <div className="select-icon-gender"></div>
                <div style={{paddingLeft: '8px'}}>{currentTypeMenu.value}</div>
                <div className='flex-1'></div>
                {
                    anchorEle ? (
                        <div className='select-arrow'></div>
                    ) : (
                        <div className='select-arrow' style={{transform: 'rotate(180deg)'}}></div>
                    )
                }
            </div>
            <StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={() => setAnchorEle(null)}>
                <div className="py10 px20 drop-content flex flex-column">
                    <div className="flex-1 py10 flex hidden" style={{ maxHeight: '300px', minHeight: '100px', overflowX: 'auto' }}>
                        <div className="flex-1 flex flex-column overflow-y drop-item-box-fix">
                            {menus.map((item, index) => (
                                <div
                                    className={cx('flex align-center justify-between drop-items pb15 hidden hover', {
                                        'drop-active': currentTypeMenu.value === item.value,
                                    })}
                                    key={index}
                                    onClick={() => handleTypeMenuClick(item, index)}
                                >
                                    <div className="flex align-center">{item.name}</div>
                                </div>
                            ))}
                        </div>
                        
                        <div className="flex flex-1 flex-column drop-sub-menu drop-item-box-fix">
                            <div style={{ fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '4px' }}>Gender</div>
                            <div className="flex-1 flex flex-column overflow-y">
                                {genderMenus.map((item, index) => (
                                    <div
                                        className={cx('flex align-center justify-between drop-items pb15 hidden hover', {
                                            'drop-active': currentGenderMenu.value === item.value,
                                        })}
                                        key={index}
                                        onClick={() => handleGenderMenuClick(item, index)}
                                    >
                                        <div className="flex align-center">{item.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {
                            currentGenderMenu.value ? (
                                <div className='flex flex-column drop-sub-menu'>
                                    <div className="pb10 flex align-center">
                                        <div className="flex-1">
                                            <StyledTextField
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth={true}
                                                value={searchValue}
                                                onChange={(e) => handleSearch(e.target.value)}
                                                id="search"
                                                type="search"
                                                variant="outlined"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    {
                                        currentTypeMenu.value !== 'FRANCHISE' ? (
                                            <div className="flex-1 py10 flex hidden" style={{ maxHeight: '300px' }}>
                                                <div className="flex-1 flex flex-column overflow-y drop-item-box">
                                                    {list.map((item, index) => (
                                                        <div
                                                            className={cx('flex align-center justify-between drop-items hidden', {
                                                                'drop-active': checkedObj[item.name],
                                                            })}
                                                            key={index}
                                                        >
                                                            <div className="flex align-center py10 no-wrap flex-1">{item.name}</div>
                                                            <div
                                                                className={cx('icon-check-box hover', {
                                                                    'icon-check': checkedObj[item.name] && total !== 1,
                                                                    'icon-uncheck': !checkedObj[item.name] && total !== 1,
                                                                    'icon-single-check': checkedObj[item.name] && total === 1,
                                                                    'icon-single-uncheck': !checkedObj[item.name] && total === 1,
                                                                })}
                                                                onClick={() => handleCheckBox(item.name)}
                                                            ></div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="flex-1 flex flex-column overflow-y drop-sub-menu drop-item-box">
                                                    <div className="flex align-center justify-between mb20">
                                                        <div>Selected {currentTypeMenu.value}</div>
                                                        <div className="flex align-center select-text">
                                                            <div>Selected</div>
                                                            <div className="select-value">
                                                                {checkedList.length}/{total}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {checkedList.map((item, index) => (
                                                        <div className="flex align-center pb15" key={index}>
                                                            <StyledCheckBox checked={true} indeterminate={true} onChange={() => handleRemoveSelected(item, 'BRAND')} />
                                                            <div className="pl4 drop-sub-item no-wrap">{item}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : searchValue === '' ? (
                                            <div className="flex-1 py10 flex hidden" style={{ maxHeight: '300px', minHeight: '100px' }}>
                                                <div className="flex-1 flex flex-column overflow-y drop-item-box">
                                                    {list.map((item, index) => (
                                                        <div
                                                            className={cx('flex align-center justify-between drop-items pb15 hidden hover', {
                                                                'drop-active': checkedObj[`${item.parentValue}-${item.name}_${item.position}`],
                                                            })}
                                                            key={index}
                                                            onClick={() => handleMenuClick(item, index)}
                                                        >
                                                            <div className="flex align-center">{item.name}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {
                                                    subMenus.length ? (
                                                        <div className="flex flex-1 flex-column drop-sub-menu drop-item-box">
                                                            <div style={{ fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '4px' }}>Nike Franchise</div>
                                                            {
                                                                subMenus.map((subMenu, index) => (
                                                                    <div className="flex-1 flex flex-column overflow-y" key={index}>
                                                                        {subMenu.map((menu, i) => (
                                                                            <div
                                                                                className={cx('flex align-center justify-between drop-items pb15 hover', {
                                                                                    'drop-active': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                                })}
                                                                                key={`${index}-${i}`}
                                                                                onClick={() => handleMenuClick(menu, i)}
                                                                            >
                                                                                <div className="flex align-center">{menu.name}</div>
                                
                                                                                {menu.isLeaf ? (
                                                                                    <div
                                                                                        className={cx('icon-check-box hover', {
                                                                                            'icon-check': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                                            'icon-uncheck': !checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                                        })}
                                                                                    ></div>
                                                                                ) : null}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ): null
                                                }
                                                {
                                                    modelMenus.length ? (
                                                        <div className="flex flex-1 flex-column drop-sub-menu drop-item-box">
                                                            <div style={{ fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '4px' }}>Model</div>
                                                            {
                                                                modelMenus.map((modelMeny, index) => (
                                                                    <div className="flex-1 flex flex-column overflow-y" key={index}>
                                                                        {modelMeny.map((menu, i) => (
                                                                            <div
                                                                                className={cx('flex align-center justify-between drop-items pb15 hover', {
                                                                                    'drop-active': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`],
                                                                                })}
                                                                                key={`${index}-${i}`}
                                                                                onClick={() => handleMenuClick(menu, i)}
                                                                            >
                                                                                <div className="flex align-center">{menu.name}</div>
                                
                                                                                {menu.isLeaf ? (
                                                                                    <div
                                                                                        className={cx('icon-check-box hover', {
                                                                                            'icon-check': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`] && total !== 1,
                                                                                            'icon-uncheck': !checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`] && total !== 1,
                                                                                            'icon-single-check': checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`] && total === 1,
                                                                                            'icon-single-uncheck': !checkedObj[`${menu.parentValue}-${menu.name}_${menu.position}`] && total === 1,
                                                                                        })}
                                                                                    ></div>
                                                                                ) : null}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ): null
                                                }
                                            </div>
                                        ) : (
                                            <div className="flex-1 flex flex-column overflow-y drop-item-box">
                                                {searchList.map((search, index) => {
                                                    const searchItems = JSON.parse(JSON.stringify(search)).reverse();
                                                    const checkLabel = searchItems[0];
                                                    return (
                                                        <div
                                                            className={cx('flex align-center justify-between hidden', {
                                                                'drop-active': checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                                            })}
                                                            key={index}
                                                            style={{ minHeight: '30px' }}
                                                        >
                                                            <div className="flex align-center flex-1 py10">
                                                                {searchItems.map((item, i) => (
                                                                    <div className="pr40 flex-1 search-content-item" key={`${index}_${i}`}>
                                                                        {item.name}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {checkLabel.description ? <div className="flex align-center flex-1 search-content-description mr20 py10">{checkLabel.description}</div> : null}
                                                            <div
                                                                className={cx('icon-check-box hover', {
                                                                    'icon-check': checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                                                    'icon-uncheck': !checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                                                })}
                                                                onClick={() => handleChange(search)}
                                                            ></div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )
                                    }
                                </div>
                            ): null
                        }
                    </div>
                    {currentTypeMenu.value === 'FRANCHISE' && checkedList.length ? (
                        <div className="flex py10 select-content">
                            {groupedCheckedList.map((group, index) => (
                                <div className="flex flex-column align-center" key={index}>
                                    {group.map((list, i) => {
                                        if (list) {
                                            // const reserveArr = JSON.parse(JSON.stringify(list)).reverse();
                                            return (
                                                <div className="flex-1 flex align-center mb10" key={`${index}_${i}`} style={{ minWidth: '260px' }}>
                                                    <StyledCheckBox checked={true} indeterminate={true} onChange={() => handleRemoveSelected(list, 'FRANCHISE')} />
                                                    <div className="pl4 drop-sub-item flex align-center flex-1">
                                                        {list[list.length - 1].name}
                                                        {/* {reserveArr.map((reverseItem, ind) => (
                                                            <div className="mr10 flex-1" key={`${index}_${i}_${ind}`}>
                                                                {reverseItem.name}
                                                            </div>
                                                        ))} */}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return <div className="flex-1 flex align-center" key={`${index}_${i}`} style={{ minWidth: '260px' }} />;
                                    })}
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div className="flex align-center justify-between footer-box">
                        <div className="flex align-center select-text">
                            {(!position && (
                                <React.Fragment>
                                    <div>Selected</div>
                                    <div className="select-value">
                                        {checkedList.length}/{total}
                                    </div>
                                </React.Fragment>
                            )) ||
                                null}
                        </div>
                        <div className="flex align-center">
                            <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                                Cancel
                            </div>
                            <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default GenderCascadeDrop;
