import * as React from 'react';
import { observer, inject } from 'mobx-react';
import StyledDialog from '../components/StyledDialog';
import cx from 'classnames';
import DownloadDatePicker from '../components/DownloadDatePicker';
import DownloadTable from '../components/DownloadTable';
import CategorySelect from '../components/CategorySelect';
import Select from '../components/Select';
import ModelSelect from '../components/ModelSelect'
import { open, downloadTemplate } from '../../utils/index';
import { DropzoneArea } from 'material-ui-dropzone';

import '../../assets/css/dataManage.css';

const list = [
	{
		name: 'FTW',
		value: 'FTW',
        children: [
            {
                name: 'Lifestyle',
                value: 'LIFESTYLE'
            },
            {
                name: 'Running',
                value: 'RUNNING'
            },
            {
                name: 'Basketball',
                value: 'BASKETBALL'
            },
            {
                name: 'Non-sportswear',
                value: 'NON-SPORTSWEAR'
            },
            {
                name: 'Others',
                value: 'OTHERS'
            }
        ]
	},
	{
		name: 'APP',
		value: 'APP'
	}
]

const tabList = [
	{
		name: 'Filter by Conditions',
		alias: 'condition',
        value: 1
	},
	{
		name: 'Filter by Product Code',
		alias: 'code',
        value: 2
	},
    {
        name: 'Keyword Group Definition',
        alias: 'group',
        value: 3
    }
]

@inject('dataManageStore', 'commonStore')
@observer
export default class DataDownload extends React.Component {
    state = {
        cautionOpen: false,
        warningOpen: false,
        showDetails: false,
        generateOpen: false,
        category: [],
        subCategory: [],
        model: [],
        brandList: [],
        brand: [],
        franchise: [],
        franchiseList: [],
        startDate: null,
        endDate: null,
        originalDate: {},
        attributeList: [],
        attributeObj: {},
        isEdit: true,
        isLoading: false,
		isDisable: false,
		categoryError: false,
		brandError: false,
		dateError: false,
		tabActive: 'condition',
		file: null,
		warningMessage: ''
    };

    async componentWillMount() {
		const { tabActive } = this.state
        await this.getHistory(tabActive === 'condition' ? 1 : 2)
	}
	
	getHistory = async (type) => {
		await this.props.dataManageStore.dataManageDownloadHistoryListRequest({
			type
		});
	}

    handleCautionCancel = () => {
        this.setState({
            cautionOpen: false,
        });
    };

    handleTableReview = async () => {
        const { category, brand, franchise, startDate, endDate, attributeObj, model, subCategory } = this.state;
        const arr = Object.entries(attributeObj)
            .map(([key, value]) => {
                if (value.length) {
                    return {
                        attributeName: key,
                        attributeValueList: value,
                    };
                }
                return false;
            })
            .filter((f) => f);
        try {
            const response = await this.props.dataManageStore.dataManageDownloadOverviewRequest({
                categoryList: category,
                brandList: brand,
                franchiseList: franchise,
                startDate,
                endDate,
                attributeDataList: arr,
                modelList: model,
                subCategoryList: subCategory
            });
            if (response.code === 'DATA_1003') {
                this.setState({
					isLoading: false,
					isDisable: false,
					warningOpen: true,
					warningMessage: response.message,
                });
            }
            if (response.code === '00000') {
				this.setState({
					isLoading: false,
					isDisable: false,
					isEdit: false,
				});
			}
        } catch (error) {
            this.setState({
                isLoading: false,
				isDisable: false,
            });
        }
    };

    handleCautionConfirm = () => {
		this.props.dataManageStore.clearTable()
		const { tabActive } = this.state;
        this.setState({
            cautionOpen: false,
            showDetails: true,
            isLoading: true,
            isDisable: true,
		});
		if (tabActive === 'condition') {
			this.handleTableReview();
		} else {
			this.handleTableReviewByProductCode();
		}
	};
	
	handleTableReviewByProductCode = async () => {
		const { startDate, endDate, file } = this.state;
		try {
			const response = await this.props.dataManageStore.dataManageDownloadProductCodeOverviewRequest({
				attachment: file,
				startDate,
				endDate
			})
			const { code, message } = response
			if (code === 'DATA_1007' || code === 'DATA_1003' || code === 'DATA_1008') {
				this.setState({
					isLoading: false,
					isDisable: false,
					warningOpen: true,
					warningMessage: message,
				})
			}
			this.setState({
				isLoading: false,
				isDisable: false,
				isEdit: false,
			});
		} catch (error) {
			this.setState({
				isLoading: false,
				isDisable: false,
			});
		}
	}

    handleSearchConfirm = () => {
        const { category, brand, startDate, endDate, file } = this.state;
        if (((category.length && brand.length) || file) && startDate && endDate) {
            this.setState({
                cautionOpen: true,
            });
        } else {
			if (!category.length) {
				this.setState({
					categoryError: true
				})
			}
			if (!brand.length) {
				this.setState({
					brandError: true
				})
			}
			if (!startDate || !endDate) {
				this.setState({
					dateError: true
				})
			}
		}
    };

    handleReset = () => {
        this.setState({
            startDate: null,
            endDate: null,
            originalDate: {},
            category: [],
            subCategory: [],
            model: [],
            brand: [],
            brandList: [],
            franchiseList: [],
            franchise: [],
            attributeList: [],
            attributeObj: {},
			isEdit: true,
			file: null
        });
    };

    handleGenerateCancel = () => {
        this.setState({
            generateOpen: false,
        });
    };

    handleDownloadData = async () => {
        const { category, brand, franchise, startDate, endDate, attributeObj, model, subCategory } = this.state;
        const arr = Object.entries(attributeObj)
            .map(([key, value]) => {
                if (value.length) {
                    return {
                        attributeName: key,
                        attributeValueList: value,
                    };
                }
                return false;
            })
            .filter((f) => f);
        try {
            const response = await this.props.dataManageStore.dataManageDownloadSubmitRequest({
                categoryList: category,
                brandList: brand,
                franchiseList: franchise,
                startDate,
                endDate,
                attributeDataList: arr,
                modelList: model,
                subCategoryList: subCategory
            });
            if (response.code === '00000') {
                this.setState({
					isDisable: false,
					showDetails: false,
                });
                await this.props.dataManageStore.dataManageDownloadHistoryListRequest();
            }
        } catch (error) {
            this.setState({
                isDisable: false,
            });
        }
	};
	
	handleDownloadDataByCode = async () => {
		const { startDate, endDate, file } = this.state;
        try {
            const response = await this.props.dataManageStore.dataManageDownloadProductCodeUploadRequest({
                attachment: file,
				startDate,
				endDate
            });
            if (response.code === '00000') {
                this.setState({
					isDisable: false,
					showDetails: false,
                });
                await this.props.dataManageStore.dataManageDownloadHistoryListRequest();
            }
        } catch (error) {
            this.setState({
                isDisable: false,
            });
        }
	}

    handleGenerateConfirm = () => {
		const { tabActive } = this.state;
        this.setState({
            generateOpen: false,
            isDisable: true,
		});
		if (tabActive === 'condition') {
			this.handleDownloadData();
		} else {
			this.handleDownloadDataByCode();
		}
    };

    handleCategoryChange = async (value, sub) => {
        this.setState({
			category: value,
            subCategory: sub,
			isEdit: true,
			categoryError: false,
			brand: [],
			franchiseList: [],
			franchise: [],
			attributeList: [],
			attributeObj: {},
		});
        if (value.length) {
            await this.props.dataManageStore.dataManageBrandFranchiseRequest({
                category: value,
                subCategory: sub
            });

            const { dataManageBrand } = this.props.dataManageStore;
            this.setState({
                brandList: dataManageBrand,
            });
        } else {
            this.setState({
                brandList: [],
            });
		}
	};
	
    handleBrandChange = async (value) => {
        this.setState({
            brand: value,
			isEdit: true,
			brandError: false,
			franchiseList: [],
			franchise: [],
			attributeList: [],
			attributeObj: {},
        });
        const arr = value.filter((f) => ['NIKE', 'JORDAN', 'CONVERSE'].indexOf(f) === -1 && f);
        if (value.length && arr.length === 0) {
            const { category } = this.state;
            await this.props.dataManageStore.dataManageAttributeRequest({
                categoryList: category,
                brandList: value,
                franchiseList: [],
            });
            const { attributeList } = this.props.dataManageStore;
            const obj = {};
            attributeList.forEach((item) => {
                obj[item.value] = [];
            });
            this.setState({
                attributeList,
                attributeObj: obj,
            });
        } else {
            this.setState({
                attributeList: [],
                attributeObj: {},
            });
        }

        if (value.length === 1) {
            const { brandList } = this.state;
            const list = brandList.filter((f) => f.value === value[0]);
            this.setState({
                franchiseList: list[0].extraData.map(item => ({ ...item, name: item.display })),
            });
        } else {
            this.setState({
                franchise: [],
                franchiseList: [],
            });
        }
    };

    handleFranchiseChange = async (value, model) => {
        this.setState({
            franchise: value,
			isEdit: true,
			attributeList: [],
			attributeObj: {},
            model
        });
        const { category, brand } = this.state;
        await this.props.dataManageStore.dataManageAttributeRequest({
            categoryList: category,
            brandList: brand,
            franchiseList: value,
        });
        const { attributeList } = this.props.dataManageStore;
        const obj = {};
        attributeList.forEach((item) => {
            obj[item.value] = [];
        });
        this.setState({
            attributeList,
            attributeObj: obj,
        });
    };

    handleDateConfirm = (selected, original) => {
        this.setState({
            startDate: selected[0],
            endDate: selected[1],
            originalDate: {
                startDate: selected[0],
                endDate: selected[1],
                origin: original,
            },
			isEdit: true,
			dateError: false
        });
    };

    handleAttributeDisable = (brand) => {
		const arr = brand.filter((f) => ['NIKE', 'JORDAN', 'CONVERSE'].indexOf(f) === -1 && f);
        if (arr.length) {
            return true;
        }
        return false;
    };

    handleAttributeChange = (value, list) => {
        const { attributeObj } = this.state;
        attributeObj[list.value] = value;
        this.setState({
            attributeObj,
            isEdit: true,
        });
    };

    handleDownloadSubmit = () => {
        const { isEdit } = this.state;
        if (!isEdit) {
            this.setState({ generateOpen: true });
        }
    };

    handleDownload = async (item) => {
        const response = await this.props.dataManageStore.dataManageDownloadUrlRequest({
            uid: item.uid,
        });
        if (response.code === '00000') {
            open(response.data, true);
        }
	};
	
	changeTab = async (tab) => {
		this.setState({
			tabActive: tab.alias
		})
		await this.getHistory(tab.value)
	}

	handleUploadChange = (files) => {
        if (files.length) {
            this.setState({
                file: files[0],
            });
        }
	};
	
	handleTemplateDownload = async () => {
		const response = await this.props.dataManageStore.dataManageDownloadTemplateRequest()
		if (response) {
			downloadTemplate(response, 'product_code_template')
		}
	}

    render() {
        const { cautionOpen, warningOpen, showDetails, generateOpen, category, subCategory, brandList, brand, franchise, franchiseList, model, startDate, endDate, originalDate, attributeList, attributeObj, isEdit, isLoading, isDisable, categoryError, brandError, dateError, tabActive, file, warningMessage } = this.state;
        const { downloadTableData, downloadHistoryList } = this.props.dataManageStore;
		const { timeRangeSkuPrice } = this.props.commonStore;
        return (
            <div className="flex-1 flex hidden p20 data-manage-bg align-center">
                <div
                    className={cx('flex-1 flex flex-column card-box full-height full-width', {
                        py20: showDetails,
                    })}
                >
                    {(!showDetails && (
                        <div className="px30 flex align-center flex-column mb60">
                            <div className="download-logo"></div>
							<div className="full-width flex align-center justify-center">
								{
									tabList.map((tab, index) => (
										<div key={index} className={cx("flex-1 text-center tab-border py20 hover", {
												'tab-border-active': tabActive === tab.alias
											})}
											onClick={() => this.changeTab(tab)}
										>{tab.name}</div>
									))
								}
							</div>
                        </div>
                    )) ||
                        null}
                    {
                        tabActive !== 'group' && (
                            <div
                                className={cx('flex full-width px30 border-box align-center', {
                                    mb90: !showDetails,
                                    mb20: showDetails,
                                })}
                                style={{height: '128px'}}
                            >
                                {
                                    tabActive === 'condition' ? (
                                        <div className="flex-1 hidden">
                                            <div className="flex mb14">
                                                <div className="flex-1 mr10 hidden">
                                                    <div className="input-category">Category</div>
                                                    <CategorySelect data={list} onConfirm={this.handleCategoryChange} currentSubCategory={subCategory} currentList={category} error={categoryError}></CategorySelect>
                                                </div>
                                                <div className="flex-1 mr10 hidden">
                                                    <div className="input-category">Brand</div>
                                                    <Select data={brandList} onConfirm={this.handleBrandChange} currentList={brand} error={brandError}></Select>
                                                </div>
                                                <div className="flex-1 mr10 hidden">
                                                    <div className="input-category">Franchise</div>
                                                    <ModelSelect data={franchiseList} onConfirm={this.handleFranchiseChange} currentModelList={model} currentList={franchise}></ModelSelect>
                                                </div>

                                                <div className="flex-1 mr10">
                                                    <div className="input-category">Time</div>
                                                    <div>
                                                        <DownloadDatePicker error={dateError} onConfirm={this.handleDateConfirm} dayRange={timeRangeSkuPrice} currentStart={startDate} currentEnd={endDate} currentOrigin={originalDate}></DownloadDatePicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="input-category">Attribute</div>
                                                <div className="flex align-center">
                                                    {(attributeList.length &&
                                                        attributeList.map((list, index) => (
                                                            <div className="flex-1 mr10 hidden" key={index}>
                                                                <Select placeholder={list.display || ''} disabled={this.handleAttributeDisable(brand)} data={list.extraData.map(item => ({...item, name: item.display}))} onConfirm={(newValue) => this.handleAttributeChange(newValue, list)} currentList={attributeObj[list.value] || []}></Select>
                                                            </div>
                                                        ))) || (
                                                        <React.Fragment>
                                                            <div className="flex-1 mr10">
                                                                <Select disabled={true}></Select>
                                                            </div>
                                                            <div className="flex-1 mr10">
                                                                <Select disabled={true}></Select>
                                                            </div>
                                                            <div className="flex-1 mr10">
                                                                <Select disabled={true}></Select>
                                                            </div>
                                                            <div className="flex-1 mr10">
                                                                <Select disabled={true}></Select>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ): (
                                        <div className="flex-1 flex">
                                            <div className="flex-1 flex flex-column mr30">
                                                <div className="input-category">Product Code</div>
                                                <div className="upload-code-box">
                                                    <div style={{display: file ? 'none': 'block'}}>
                                                        <DropzoneArea
                                                            filesLimit={1}
                                                            showAlerts={false}
                                                            maxFileSize={10 * 1024 * 1024}
                                                            showPreviewsInDropzone={false}
                                                            acceptedFiles={['.xlsx']}
                                                            dropzoneText="Drag and drop or browse to choose your Product Code"
                                                            onChange={(files) => {
                                                                this.handleUploadChange(files);
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{display: file ? 'flex': 'none'}} className="upload-code-file flex align-center justify-center">
                                                        {file && file.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-column">
                                                <div className="input-category">Time</div>
                                                <div className="mb30">
                                                    <DownloadDatePicker error={dateError} onConfirm={this.handleDateConfirm} dayRange={timeRangeSkuPrice} currentStart={startDate} currentEnd={endDate} currentOrigin={originalDate}></DownloadDatePicker>
                                                </div>
                                                <div className="template-download flex align-center">
                                                    <div className="mr6">Product Code Template:</div>
                                                    <div className="mr6 template-hover" onClick={() => this.handleTemplateDownload()}>product_code_template.xlsx</div>
                                                    <div className="template-icon"></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="ml20 mr20" style={{ borderRight: '1px solid rgba(255,255,255,0.3)' }}></div>
                                <div className="flex flex-column full-height" style={{maxHeight: '100px'}}>
                                    {(showDetails && (
                                        <div className="flex-1 download-button-item flex justify-center align-center mb12 hover" onClick={() => this.handleReset()}>
                                            Reset
                                        </div>
                                    )) ||
                                        null}
                                    <div
                                        className={cx('flex-1 download-button-item flex justify-center align-center hover')}
                                        onClick={() => this.handleSearchConfirm()}
                                    >
                                        Confirm
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        tabActive === 'group' && (
                            <div className='group-download-desc'>This page is for downloading group definitions from page Attribute Exploration.</div>
                        )
                    }
                    {(!showDetails && (
                        <div className="full-width pl30 pr10 flex-1 overflow-y">
                            {downloadHistoryList.map((group, index) => (
                                <div className="flex align-center" key={index}>
                                    {group.map((item, i) => {
                                        return item.uid ? (
                                            <div key={`${index}-${i}`} className="download-table-box flex align-center p20 border-box flex-1 mr20 mb20">
                                                <div className={cx("mr14", {
													'download-file-calculation': item.downloadStatus === 1,
													'download-file-failed': item.downloadStatus === 3,
													'download-file-icon': item.downloadStatus === 2
												})}></div>
                                                <div className="flex-1 flex flex-column justify-between">
                                                    <div className="file-title mb10">{item.displayName}</div>
                                                    <div className="flex justify-between align-center">
                                                        <div className="flex align-center download-file-time">
                                                            <div>{item.createTime}</div>
                                                        </div>
                                                        {item.downloadStatus === 1 ? (
                                                            <div style={{ color: '#7AB561' }}>In the calculation...</div>
                                                        ) : item.downloadStatus === 2 ? (
                                                            <div className="flex align-center download-button hover" onClick={() => this.handleDownload(item)}>
                                                                <div className="download-button-icon mr6"></div>
                                                                <div>Download</div>
                                                            </div>
                                                        ) : (
                                                            <div style={{ color: '#FF4647' }}>Calculation failed</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={`${index}-${i}`} className="flex align-center p20 border-box flex-1 mr20 mb20"></div>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    )) ||
                        null}
                    {(showDetails && (
                        <React.Fragment>
                            <div className="flex-1 mb10 px30 hidden">
                                <DownloadTable isLoading={isLoading} columns={downloadTableData.columns} data={downloadTableData.data}></DownloadTable>
                            </div>
                            <div className="flex align-center justify-between px30">
								<div className="back-button flex align-center justify-center hover"
									onClick={() => this.setState({
										showDetails: false
									})}
								>
									<div className="back-button-icon mr6"></div>
                                    <div>Back</div>
								</div>
                                <div
                                    className={cx('download-details-button flex align-center justify-center', {
                                        'pointer-disable': isEdit,
                                        hover: !isEdit,
                                    })}
                                    onClick={() => this.handleDownloadSubmit()}
                                >
                                    <div className="download-button-icon mr6"></div>
                                    <div>Download</div>
                                </div>
                            </div>
                        </React.Fragment>
                    )) ||
                        null}
                </div>
                <StyledDialog open={cautionOpen}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-caution-icon mb10"></div>
                        <div className="dialog-content-title mb6">CAUTION</div>
                        <div className="dialog-content-desc text-center mb16">The document is too computationally heavy and may require longer computationally load times</div>

                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 dialog-operator-item flex align-center justify-center hover mr10" onClick={() => this.handleCautionCancel()}>
                                Cancel
                            </div>
                            <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => !isDisable && this.handleCautionConfirm()}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </StyledDialog>
                <StyledDialog open={warningOpen}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-caution-icon mb10"></div>
                        <div className="dialog-content-title mb6">CAUTION</div>
                        <div className="dialog-content-desc text-center mb16">{warningMessage}</div>

                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => this.setState({ warningOpen: false, isEdit: true })}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </StyledDialog>
                <StyledDialog open={generateOpen}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-generate-icon mb10"></div>
                        <div className="dialog-content-title mb6">IN THE GENERATED</div>
                        <div className="dialog-content-desc text-center mb16">It takes about 10-20 minutes to calculate the data. Please be aware that the table will be truncated at 1,000,000 rows.</div>

                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 dialog-operator-item flex align-center justify-center hover mr10" onClick={() => this.handleGenerateCancel()}>
                                Cancel
                            </div>
                            <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => !isDisable && this.handleGenerateConfirm()}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </StyledDialog>
            </div>
        );
    }
}
