import * as echarts from 'echarts';
import { completeMissingData } from '../../../store/utils';

const formatterByUnit = (value, unit) => {
    if (unit === 'M') {
        return `${Math.round(value / 1000000)
            .toString()
            .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}M`;
    }
};

export const barRender = (element, props) => {
    const ftwDataTemp = (props.data['FTW'] || []).map((item) => {
        return {
            name: item.category,
            value: Number(item.demand),
            yoy: item.demandYoy,
            date: item.date,
        };
    });
    const appDataTemp = (props.data['APP'] || []).map((item) => {
        return {
            name: item.category,
            value: Number(item.demand),
            yoy: item.demandYoy,
            date: item.date,
        };
    });
    const ftwData = completeMissingData(props.allDate, ftwDataTemp);
    const appData = completeMissingData(props.allDate, appDataTemp);
    const xAxisData = props.allDate;

    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    chart.setOption({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: 'line', // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: (params) => {
                let content = `<div>${params && params[0] && params[0].axisValueLabel}</div>`;
                params.forEach((p) => {
                    if (p.data.value) {
                        const name = p.data.name;
                        const v = formatterByUnit(p.data.value, 'M');
                        content += `<div class="flex align-center"><div class="dot" style="background: ${p.color.type === 'linear' ? p.color.colorStops[0].color : p.color}"></div>${name}：${v}</div>`;
                    }
                });
                return content;
            },
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: '#979797',
                },
            },
            axisLabel: {
                color: '#fff',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
        },

        yAxis: {
            type: 'value',
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#979797',
                    opacity: 0.3,
                },
            },
            scale: true,
            axisLabel: {
                formatter: (value) => {
                    if (typeof value === 'number') {
                        if (Math.abs(value) >= 1000 && Math.abs(value) < 1000000) {
                            const formattedValue = Math.round(value / 1000);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} K`;
                        } else if (Math.abs(value) >= 1000000) {
                            const formattedValue = Math.round(value / 1000000);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} M`;
                        } else {
                            const formattedValue = Math.round(value);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}`;
                        }
                    }
                    return value;
                },
                color: 'rgba(255,255,255,0.7)',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
        },
        // dataZoom: [
        //     {
        //         start: 0,
        //         end: 100,
        //         height: 4,
        //         backgroundColor: '#000',
        //         opacity: 0.3,
        //         moveHandleSize: 0,
        //         brushSelect: false,
        //         fillerColor: 'rgba(255,255,255,0.8)',
        //         handleIcon:
        //             'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAUCAYAAADlep81AAABYWlDQ1BrQ0dDb2xvclNwYWNlRGlzcGxheVAzAAAokWNgYFJJLCjIYWFgYMjNKykKcndSiIiMUmB/yMAOhLwMYgwKicnFBY4BAT5AJQwwGhV8u8bACKIv64LMOiU1tUm1XsDXYqbw1YuvRJsw1aMArpTU4mQg/QeIU5MLikoYGBhTgGzl8pICELsDyBYpAjoKyJ4DYqdD2BtA7CQI+whYTUiQM5B9A8hWSM5IBJrB+API1klCEk9HYkPtBQFul8zigpzESoUAYwKuJQOUpFaUgGjn/ILKosz0jBIFR2AopSp45iXr6SgYGRiaMzCAwhyi+nMgOCwZxc4gxJrvMzDY7v////9uhJjXfgaGjUCdXDsRYhoWDAyC3AwMJ3YWJBYlgoWYgZgpLY2B4dNyBgbeSAYG4QtAPdHFacZGYHlGHicGBtZ7//9/VmNgYJ/MwPB3wv//vxf9//93MVDzHQaGA3kAFSFl7jXH0fsAAAA4ZVhJZk1NACoAAAAIAAGHaQAEAAAAAQAAABoAAAAAAAKgAgAEAAAAAQAAACSgAwAEAAAAAQAAABQAAAAAqOd5QAAAAeRJREFUSA3NVr1qAkEQntvLgR4Hoo2QNp1gZ5fO0sqkyQsELCxEwZBHSCIolr5EYmEjeQAtBKuUVsHUggGFwzPzXbLLetyBB8Fz4NjZb372273ZH4NCZDAYWJvNpup5XpXNJcMwLvf7vRPiejTEOb45xxcHzIQQw3Q6PazVam4wgREE+v3+DRPpcPBV0PaffSa4YGLtRqPxpudVhJiA6PV6T2xs6w4n0DvNZvORCXoYy5QDZjKZZ9ZPTQbDX0+nU3s8Hr+j468QftNut3sFECX5fJ6KxSKlUimaz+e0XC5917h4VH7TNG/x+wQKGDUT5SjxQqFAjuNQLpejcrksYYqLq8CAAg7gcoHddEwBY2W4CMmyLLJtW6WLi6vAgAIO4CL+tnbAHN7lwiP56R4SQ6tLFK776Dq4CAZKOpiwXhI8i8uESajhwQUrdFYiuJhwnJ+FgAtWaHYWbH5J4J4Tw2MJ8QxIfnqMxNDqEoXrProOLgK3LhfTQjeE6dvtlnhbkuu6tFqtlEtcXAUGFHAAF3M0GnmVSuWTZ3MX8Dno8qFF2WzWJzSZTGi9Xvv2uPhBUq3DV8d9vV7/UCdZt9t9YXsSlytodVqt1gMUte3xBIAB4InFf37IMdUKSeBsHmiSENokn7A/rJQSNHKxQ8YAAAAASUVORK5CYII=',
        //         handleSize: '15',
        //         showDetail: false,
        //         bottom: 10,
        //         right: '10%',
        //         left: '18%',
        //     },
        // ],
        grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: 10,
            containLabel: true,
        },
        series: [
            {
                type: 'bar',
                stack: 'DEMAND',
                name: 'APP',
                // showBackground: true,
                // backgroundStyle: {
                //     borderRadius: 100,
                // },
                barWidth: 12,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        { offset: 0, color: '#4B6ACF' },
                        { offset: 1, color: '#8D72FF' },
                    ]),
                },
                data: appData,
            },
            {
                type: 'bar',
                // showBackground: true,
                name: 'FTW',
                stack: 'DEMAND',
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        { offset: 0, color: '#FF7334' },
                        { offset: 1, color: '#FFAB64' },
                    ]),
                    // color: '#ff7334',
                    // borderRadius: [100, 100, 0, 0],
                },
                data: ftwData,
            },
        ],
    });
};

export const barRender2 = (element, props) => {
    const colors = {};

    const data = props.data.map((item) => {
        const arr =
            (item.data &&
                item.data.map((d) => {
                    return {
                        name: d.brandMerge,
                        value: Number(d.demandShare),
                        yoy: d.demandShareYoy,
                        date: d.date,
                    };
                })) ||
			[];
        return {
            type: 'bar',
            stack: 'DEMAND',
            name: item.name,
            barWidth: 12,
            itemStyle: {
                color: (params) => {
                    if (!colors[params.data.name] || colors[params.data.name] !== params.color) {
                        colors[params.data.name] = params.color;
                    }
                    return params.color;
                },
            },
            data: completeMissingData(props.allDate, arr),
        };
    });

    const xAxisData = props.allDate;
    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    chart.setOption({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: 'line', // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: (params) => {
                let content = `<div>${params && params[0] && params[0].axisValueLabel}</div>`;
                params.forEach((p) => {
                    if (p.data.value) {
                        const name = p.data.name;
                        const v = `${(p.data.value * 100).toFixed(2)}%`;
                        content += `<div class="flex align-center"><div class="dot" style="background: ${p.color.type === 'linear' ? p.color.colorStops[0].color : p.color}"></div>${name}：${v}</div>`;
                    }
                });
                return content;
            },
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: '#979797',
                },
            },
            axisLabel: {
                color: '#fff',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
        },

        yAxis: {
            type: 'value',
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#979797',
                    opacity: 0.3,
                },
            },
            scale: true,
            axisLabel: {
                formatter: (value) => {
                    if (typeof value === 'number') {
                        return `${(value * 100).toFixed(0)}%`;
                    }
                    return value;
                },
                color: 'rgba(255,255,255,0.7)',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
            max: 1,
        },
        // dataZoom: [
        //     {
        //         start: 0,
        //         end: 100,
        //         height: 4,
        //         backgroundColor: '#000',
        //         opacity: 0.3,
        //         moveHandleSize: 0,
        //         brushSelect: false,
        //         fillerColor: 'rgba(255,255,255,0.8)',
        //         handleIcon:
        //             'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAUCAYAAADlep81AAABYWlDQ1BrQ0dDb2xvclNwYWNlRGlzcGxheVAzAAAokWNgYFJJLCjIYWFgYMjNKykKcndSiIiMUmB/yMAOhLwMYgwKicnFBY4BAT5AJQwwGhV8u8bACKIv64LMOiU1tUm1XsDXYqbw1YuvRJsw1aMArpTU4mQg/QeIU5MLikoYGBhTgGzl8pICELsDyBYpAjoKyJ4DYqdD2BtA7CQI+whYTUiQM5B9A8hWSM5IBJrB+API1klCEk9HYkPtBQFul8zigpzESoUAYwKuJQOUpFaUgGjn/ILKosz0jBIFR2AopSp45iXr6SgYGRiaMzCAwhyi+nMgOCwZxc4gxJrvMzDY7v////9uhJjXfgaGjUCdXDsRYhoWDAyC3AwMJ3YWJBYlgoWYgZgpLY2B4dNyBgbeSAYG4QtAPdHFacZGYHlGHicGBtZ7//9/VmNgYJ/MwPB3wv//vxf9//93MVDzHQaGA3kAFSFl7jXH0fsAAAA4ZVhJZk1NACoAAAAIAAGHaQAEAAAAAQAAABoAAAAAAAKgAgAEAAAAAQAAACSgAwAEAAAAAQAAABQAAAAAqOd5QAAAAeRJREFUSA3NVr1qAkEQntvLgR4Hoo2QNp1gZ5fO0sqkyQsELCxEwZBHSCIolr5EYmEjeQAtBKuUVsHUggGFwzPzXbLLetyBB8Fz4NjZb372273ZH4NCZDAYWJvNpup5XpXNJcMwLvf7vRPiejTEOb45xxcHzIQQw3Q6PazVam4wgREE+v3+DRPpcPBV0PaffSa4YGLtRqPxpudVhJiA6PV6T2xs6w4n0DvNZvORCXoYy5QDZjKZZ9ZPTQbDX0+nU3s8Hr+j468QftNut3sFECX5fJ6KxSKlUimaz+e0XC5917h4VH7TNG/x+wQKGDUT5SjxQqFAjuNQLpejcrksYYqLq8CAAg7gcoHddEwBY2W4CMmyLLJtW6WLi6vAgAIO4CL+tnbAHN7lwiP56R4SQ6tLFK776Dq4CAZKOpiwXhI8i8uESajhwQUrdFYiuJhwnJ+FgAtWaHYWbH5J4J4Tw2MJ8QxIfnqMxNDqEoXrProOLgK3LhfTQjeE6dvtlnhbkuu6tFqtlEtcXAUGFHAAF3M0GnmVSuWTZ3MX8Dno8qFF2WzWJzSZTGi9Xvv2uPhBUq3DV8d9vV7/UCdZt9t9YXsSlytodVqt1gMUte3xBIAB4InFf37IMdUKSeBsHmiSENokn7A/rJQSNHKxQ8YAAAAASUVORK5CYII=',
        //         handleSize: '15',
        //         showDetail: false,
        //         bottom: 10,
        //         right: '10%',
        //         left: '14%',
        //     },
        // ],
        grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: 10,
            containLabel: true,
        },
        series: data,
    });
    props.changeColor && props.changeColor(colors);
};
