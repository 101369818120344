import * as React from 'react';
import * as Yup from 'yup';
import { MobXProviderContext, observer } from 'mobx-react';
import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import './login.css';

import { Box } from '@material-ui/core';
import UserTextField from '../components/TextField';
import UserColorButton from './UserColorButton';
import StyledDialog from '../components/StyledDialog';

const ResetPassword = ({ history, location }) => {
    const store = React.useContext(MobXProviderContext);
    const [errorText, setErrorText] = React.useState('');

    const [showOne, setShowOne] = React.useState(false);
    const [showTwo, setShowTwo] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [identityKey, setIdentityKey] = React.useState('');

    const handleResetPassword = async (values) => {
        try {
            const response = await store.userStore.resetPasswordRequest({
                identityKey,
                newPassword: values.newPassword,
            });
            const { code, message } = response;
            if (code === '00000') {
                setErrorText('');
                setOpen(true);
            } else {
                setErrorText(message);
                formik.setSubmitting(false);
            }
        } catch (error) {
            setErrorText('');
            formik.setSubmitting(false);
        }
    };

    const handleResetOK = () => {
        history.replace('/login');
        setOpen(false);
    };

    React.useEffect(() => {
        const { type, key, identity, code, time } = qs.parse(location.search.replace(/^\?/, ''));
        if (type) {
            (async () => {
                const response = await store.userStore.resetUrlValidateRequest({
                    type,
                    key,
                    identity,
                    code,
                    time,
                });
                if (response.code === '00000') {
                    setIdentityKey(response.data);
                }
            })();
        }
    }, [location.search, store.userStore]);

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            passwordAgain: '',
        },
        validationSchema: Yup.object({
            newPassword: Yup.string().test('', 'Must have at least 8 characters, at most 16 characters, and include A-Z, a-z, and 0-9', (value) => {
                if (value) {
                    const isValidate = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}/.test(value);
                    if (isValidate) {
                        return true;
                    }
                    return false;
                }
                return false;
            }),
            passwordAgain: Yup.string().test('', 'Both passwords must match', (value, context) => {
                if (value) {
                    const pre = context.parent.newPassword;
                    if (pre && value === pre) {
                        return true;
                    }
                    return false;
                }
                return false;
            }),
        }),
        onSubmit: (values) => {
            handleResetPassword(values);
        },
    });

    return (
        <div className="flex full-height full-width login-wrapper">
            <div className="flex-1 flex flex-column justify-center align-center">
                <div>
                    <div className="reset-text mb60">Reset Password</div>
                    <div style={{ width: '320px' }}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modify-label">New Password</div>
                            <UserTextField
                                size="small"
                                error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                                fullWidth={true}
                                name="newPassword"
                                type={showOne ? 'text' : 'password'}
                                id="outlined-password"
                                margin="normal"
                                defaultValue={formik.getFieldProps('newPassword').value}
                                helperText={(formik.touched.newPassword && formik.errors.newPassword) || ''}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <div className="hover" onClick={() => setShowOne(!showOne)}>
                                                {showOne ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className="modify-label">New Password Again</div>
                            <UserTextField
                                size="small"
                                error={Boolean(formik.touched.passwordAgain && formik.errors.passwordAgain)}
                                fullWidth={true}
                                name="passwordAgain"
                                type={showTwo ? 'text' : 'password'}
                                id="outlined-password"
                                margin="normal"
                                defaultValue={formik.getFieldProps('passwordAgain').value}
                                helperText={(formik.touched.passwordAgain && formik.errors.passwordAgain) || ''}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <div className="hover" onClick={() => setShowTwo(!showTwo)}>
                                                {showTwo ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box py={2}>
                                <UserColorButton color="primary" disableRipple={true} disabled={formik.isSubmitting} fullWidth={true} size="small" type="submit" variant="contained">
                                    CONFIRM
                                </UserColorButton>
                            </Box>
                        </form>
                    </div>

                    {(errorText && (
                        <div className="flex align-center">
                            <div className="login-error-icon"></div>
                            <div className="login-error-text">{errorText}</div>
                        </div>
                    )) ||
                        null}
                </div>
            </div>
            <div className="reset-bg"></div>
            <StyledDialog open={open}>
                <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                    <div className="login-dialog-reset-icon mb10"></div>
                    <div className="dialog-content-title mb6">Password resetting succeeded</div>
                    <div className="dialog-content-desc text-center mb16">Click ok to jump to the login page</div>

                    <div className="flex align-center justify-between full-width">
                        <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => handleResetOK()}>
                            OK
                        </div>
                    </div>
                </div>
            </StyledDialog>
        </div>
    );
};

export default withRouter(observer(ResetPassword));
