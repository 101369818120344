import { OverviewStore } from './overviewStore';
import { BrandAndGenderStore } from './brandAndGenderStore';
import { SkuStore } from './skuStore';
import { FranchiseStore } from './franchiseStore';
import { SubCategoryStore } from './subCategoryStore'
import { ExplorStore } from './explorStore'
import { UserStore } from './userStore';
import { DataManageStore } from './dataManageStore';
import { CommonStore } from './commonStore';
import { observable, action, makeObservable } from 'mobx';

export class RootStore {
    @observable overviewStore;
    @observable brandAndGenderStore;
    @observable skuStore;
    @observable franchiseStore;
    @observable subCategoryStore;
    @observable explorStore;
    @observable userStore;
    @observable dataManageStore;
    @observable commonStore;
    constructor() {
        makeObservable(this);
        const local = sessionStorage.getItem('cache') || '{}';
        const cache = JSON.parse(local);
        this.overviewStore = Object.assign(new OverviewStore(), cache.overviewStore);
        this.brandAndGenderStore = Object.assign(new BrandAndGenderStore(), cache.brandAndGenderStore);
        this.skuStore = Object.assign(new SkuStore(), cache.skuStore);
        this.franchiseStore = Object.assign(new FranchiseStore(), cache.franchiseStore);
        this.subCategoryStore = Object.assign(new SubCategoryStore(), cache.subCategoryStore);
        this.explorStore = Object.assign(new ExplorStore(), cache.explorStore);
        this.userStore = Object.assign(new UserStore(), cache.userStore);
        this.dataManageStore = Object.assign(new DataManageStore(), cache.dataManageStore);
        this.commonStore = Object.assign(new CommonStore(), cache.commonStore);
        sessionStorage.removeItem('cache');
    }
    @action
    setToLocal() {
        sessionStorage.setItem('cache', JSON.stringify(this));
    }
    @action
    clearStore() {
        this.overviewStore = new OverviewStore();
        this.brandAndGenderStore = new BrandAndGenderStore();
        this.skuStore = new SkuStore();
        this.franchiseStore = new FranchiseStore();
        this.subCategoryStore = new SubCategoryStore();
        this.explorStore = new ExplorStore();
        this.userStore = new UserStore();
        this.dataManageStore = new DataManageStore();
        this.commonStore = new CommonStore();
    }
}

export default new RootStore();
