import { handleData } from './index';

export const demandColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'franchise',
			value: 'name'
		},
		{
			name: 'demand',
			value: 'value'
		},
		{
			name: 'demand_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const ppiColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'franchise',
			value: 'name'
		},
		{
			name: 'ppi',
			value: 'value'
		},
		{
			name: 'ppi_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const rviColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'franchise',
			value: 'name'
		},
		{
			name: 'resale_volume_index',
			value: 'value'
		},
		{
			name: 'resale_volume_index_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const diColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'franchise',
			value: 'name'
		},
		{
			name: 'diversity',
			value: 'value'
		},
		{
			name: 'diversity_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const lcaColumns = (type) => {
	const arr = [
		{
			name: type === 'QUARTER' ? 'season': type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'brand',
			value: 'brand'
		},
		{
			name: 'franchise',
			value: 'franchise'
		},
		{
			name: 'sales_volume',
			value: 'salesVolume'
		},
		{
			name: 'ppi',
			value: 'ppi'
		},
		{
			name: 'demand',
			value: 'demand'
		}
	]
	return arr
}

export const handleDemandData = (columns, type, demandTimeTab, data, needTitle = true) => {
	const filterArr = ['period_type', 'demand_yoy']
	const lineData = data[0][0];

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'DEMAND', needTitle)
}

export const handlePPIData = (columns, type, demandTimeTab, data, needTitle = true) => {
	const filterArr = ['period_type', 'ppi_yoy']
	const lineData = data[0][1];

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'PREMIUM PRICE INDEX', needTitle)
}

export const handleRVIData = (columns, type, demandTimeTab, data, needTitle = true) => {
	const filterArr = ['period_type', 'resale_volume_index_yoy']
	const lineData = data[1][0];

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'RESALE VOLUME INDEX', needTitle)
}

export const handleDIData = (columns, type, demandTimeTab, data, needTitle = true) => {
	const filterArr = ['period_type', 'diversity_yoy']
	const lineData = data[1][1];

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'DIVERSITY INDEX', needTitle)
}

export const handleLcaData = (columns, data) => {
	const title = []
	const headerData = columns.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'NIKE FRANCHISE LCA'
	const arr = [];
	arr.push(title)
	arr.push(headerData)

	data.forEach(list => {
		list.data.forEach(item => {
			const itemArr = [];
			columns.forEach(head => {
				itemArr.push(item[head.value] || '')
			})
			arr.push(itemArr)
		})
	})

	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}