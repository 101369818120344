import * as React from 'react';
import { Menu } from '@material-ui/core';
import { ExpandMore, Refresh } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import './datePicker.css';
import moment from 'moment';
import { transformSingleDate } from '../../utils/index';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} {...props} />);

const dateTypes = ['MONTH', 'WEEK'];
const dateTypesDay = ['MONTH', 'WEEK', 'DAY'];

const SelfDatePicker = ({ onConfirm = () => {}, showDay, currentStartDate, currentEndDate, currentType, currentOrigin, parentOrigin, position, onRefresh, monthRange, weekRange, dayRange, isCenter }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [dateType, setDateType] = React.useState(position ? 'DAY' : 'MONTH');
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [original, setOriginal] = React.useState([]);
    const [showDate, setShowDate] = React.useState([]);
    const handleCancel = () => {
        setAnchorEle(null);
        setOpen(false);
    };

    const handleConfirm = () => {
        if (selected.length === 2) {
            setShowDate(selected);
            setAnchorEle(null);
            setOpen(false);
            onConfirm(selected, dateType, original);
        }
    };

    const handleChange = (data, formattedData) => {
        setSelected(formattedData);
        setOriginal(data);
    };

    const disabledDate = (current) => {
		const isFuture = current && currentType && current > moment().endOf(dateType);
        if (isFuture) {
			return isFuture;
		}
        if (position) {
            if (dayRange) {
                const isLater = current && (dayRange.max ? current > moment(dayRange.max).endOf(dateType) : current > moment().endOf('day'));
                const isEarlier = current && (dayRange.min ? current < moment(dayRange.min).endOf(dateType) : false);
                return isLater || isEarlier;
            }
            return current && current > moment().endOf('day');
        }
        if (parentOrigin && parentOrigin.origin) {
            const isEarlier = (current && parentOrigin && parentOrigin.origin && current.valueOf() < moment(parentOrigin.origin[0]).startOf(currentType).valueOf()) || false;
            const isLater = (current && parentOrigin && parentOrigin.origin && current.valueOf() > moment(parentOrigin.origin[1]).endOf(currentType).valueOf()) || false;

            return isEarlier || isLater;
        }

        if (dateType === 'MONTH' && monthRange) {
            const end = monthRange.max ? transformSingleDate(monthRange.max, 'month') : null;
            const start = monthRange.min ? transformSingleDate(monthRange.min, 'month') : null;
            const isLater = current && currentType && (end ? current > moment(end).endOf(dateType) : current > moment().endOf(dateType));
            const isEarlier = current && currentType && (start ? current < moment(start).startOf(dateType) : false);
            return isEarlier || isLater;
        } else if (dateType === 'WEEK' && weekRange) {
            const end = weekRange.max ? transformSingleDate(weekRange.max, 'week') : null;
            const start = weekRange.min ? transformSingleDate(weekRange.min, 'week') : null;
            let isLater = false;
            let isEarlier = false;
            if (end && current) {
                const { year, week } = end;
                const timeStamp = moment(`${year}`).add(week, 'weeks').startOf('week').valueOf()
                if (current.valueOf() >= timeStamp) {
                    isLater = true
                } else {
                    isLater = false
                }
            }
            if (start && current) {
                const { year, week } = start;
                const timeStamp = moment(`${year}`).add(week - 1, 'weeks').startOf('week').valueOf()
                if (current.valueOf() < timeStamp) {
                    isEarlier = true
                } else {
                    isEarlier = false
                }
            }

            return isEarlier || isLater;
        }
    };

    const handleRefresh = () => {
        setOriginal([]);
        setSelected([]);
        setShowDate([]);
        onRefresh && onRefresh();
    };

    return (
        <div className={cx("picker-box", {
			'full-width': isCenter
		})}>
            <div className="flex align-center date-picker-text">
                <div
                    className="flex align-center hover flex-1"
                    onClick={(e) => {
                        setAnchorEle(e.currentTarget);
                        setOpen(true);
                    }}
                >
                    <div className={cx("mr10 flex-1 date-text-box", {
						'flex align-center justify-center': isCenter
					})}>{(showDate.length === 2 && `${original[0].format('YYYY MMM')} - ${original[1].format('YYYY MMM')}`) || (currentOrigin && currentType === 'MONTH' && `${moment(currentOrigin.startDate).format('YYYY MMM')} - ${moment(currentOrigin.endDate).format('YYYY MMM')}`) || (currentOrigin && currentType === 'WEEK' && `${currentOrigin.startDate} - ${currentOrigin.endDate}`) || ''}</div>
                    <ExpandMore fontSize="small" />
                </div>
                {(position && showDate.length === 2 && (
                    <div className="hover" onClick={() => handleRefresh()}>
                        <Refresh style={{ fontSize: '14px' }} />
                    </div>
                )) ||
                    null}
            </div>
            <StyledMenu
                keepMounted
                anchorEl={anchorEle}
                open={Boolean(anchorEle)}
                onClose={() => {
                    setAnchorEle(null);
                    setOpen(false);
                }}
            >
                <div className="px10 picker-content flex flex-column">
                    <div className="flex align-center pb10" style={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                        {(!position &&
                            (showDay ? dateTypesDay : dateTypes).map((type, index) => (
                                <div
                                    className={cx('flex-1 header-items flex align-center justify-center hover', {
                                        'header-active': type === dateType,
                                    })}
                                    key={index}
                                    onClick={() => {
                                        setDateType(type);
                                        if (dateType !== type) {
                                            setSelected([]);
                                            setOriginal([]);
                                        }
                                    }}
                                >
                                    {type}
                                </div>
                            ))) ||
                            null}
                    </div>
                    <div style={{ width: '560px', height: '340px', position: 'relative', top: '20px' }}>{position ? <RangePicker value={original} disabledDate={disabledDate} open={open} popupStyle={{ zIndex: 9999 }} onChange={handleChange} /> : <RangePicker picker={dateType.toLowerCase()} disabledDate={disabledDate} open={open} popupStyle={{ zIndex: 9999 }} onChange={handleChange} />}</div>
                    <div className="flex align-center mt30">
                        <div className="flex-1 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                            Cancel
                        </div>
                        <div className="flex-1 ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default SelfDatePicker;
