import * as React from 'react';
import './menuList.css';
import Icon from './Icon';
import cx from 'classnames';

const MenuList = ({ data, selectedList, onChange, colorsData }) => {
    const [list, setList] = React.useState([]);
    const [colors, setColors] = React.useState({});
    const [menuExpand, setMenuExpand] = React.useState({});
    const itemRef = React.useRef([]);

    React.useEffect(() => {
        setList(data || []);
        const obj = {};
        if (data) {
            data.forEach((item) => {
                obj[item.display] = true;
            });
        }
        setMenuExpand(obj);
        (data || []).forEach((item, index) => {});
    }, [data]);

    React.useEffect(() => {
        setColors(colorsData || {});
    }, [colorsData]);

    const handleExpand = (item) => {
        const obj = {
            ...menuExpand,
            [item.display]: !menuExpand[item.display],
        };
        setMenuExpand(obj);
    };

    const handleSelected = (menu, item) => {
        let bool = false;
        selectedList.forEach((select) => {
            if (select.length) {
                if (select[0].value === menu.value && select[1].value === item.value) {
                    bool = true;
                }
            }
        });
        return bool;
    };

    const handleChoose = (menu, item) => {
        onChange && onChange(menu, item);
    };

    return list.map((menu, index) => (
        <div className="dewu-menu" key={index}>
            <div className="flex align-center justify-between dewu-menu-title" onClick={() => handleExpand(menu)}>
                <div>{menu.display}</div>
                <div className="dewu-menu-title-icon">{menuExpand[menu.display] ? <Icon name="up-arrow"></Icon> : <Icon name="down-arrow"></Icon>}</div>
            </div>
            <div>
                <div
                    className="dewu-menu-item-transition"
                    style={{ height: menuExpand[menu.display] ? (itemRef.current && itemRef.current[index] ? itemRef.current[index].scrollHeight : 'auto') : 0 }}
                    ref={(f) => {
                        itemRef.current[index] = f;
                    }}
                >
                    {menu.extraData.map((item, i) => (
                        <div className="flex align-center justify-between dewu-menu-item hover" key={`${index}-${i}`} onClick={() => handleChoose(menu, item)}>
                            <div className="flex align-center">
                                <div className="dewu-menu-item-dot" style={{ background: colors[`${menu.value}&${item.value}`] || '#343434' }} />
                                <div>{item.display}</div>
                            </div>
                            <div
                                className={cx('icon-check-box', {
                                    'icon-check': handleSelected(menu, item),
                                    'icon-uncheck': !handleSelected(menu, item),
                                })}
                            ></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ));
};
export default MenuList;
