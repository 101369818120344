import { headerCol } from './index';
import _ from 'underscore';

export const overviewDemandColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'brand'
		},
		{
			name: 'franchise',
			value: 'franchise'
		},
		{
			name: 'product_code',
			value: 'productCode'
		},
		{
			name: 'product_name',
			value: 'productName'
		},
		{
			name: 'demand',
			value: 'demand'
		},
		{
			name: 'demand_yoy',
			value: 'demandYoy'
		}
	]
	return arr
}

export const overviewPPIColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'brand'
		},
		{
			name: 'franchise',
			value: 'franchise'
		},
		{
			name: 'product_code',
			value: 'productCode'
		},
		{
			name: 'product_name',
			value: 'productName'
		},
		{
			name: 'ppi',
			value: 'ppi'
		},
		{
			name: 'ppi_yoy',
			value: 'ppiYoy'
		}
	]
	return arr
}

export const overviewSVColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'brand'
		},
		{
			name: 'franchise',
			value: 'franchise'
		},
		{
			name: 'product_code',
			value: 'productCode'
		},
		{
			name: 'product_name',
			value: 'productName'
		},
		{
			name: 'sales_volume',
			value: 'salesVolume'
		},
		{
			name: 'sales_volume_yoy',
			value: 'salesVolumeYoy'
		}
	]
	return arr
}

export const launchSkuColumns = [
	{
		name: 'brand',
		value: 'brand'
	},
	{
		name: 'franchise',
		value: 'franchise'
	},
	{
		name: 'product_code',
		value: 'productCode'
	},
	{
		name: 'ppi',
		value: 'ppi'
	},
	{
		name: 'unit_sold_index',
		value: 'unitSoldIndex'
	},
	{
		name: 'demand',
		value: 'demand'
	}
]

export const topSkuTimeColumns = [
	{
		name: null,
		value: 'type'
	},
	{
		name: 'start',
		value: 'startDate'
	},
	{
		name: 'end',
		value: 'endDate'
	}
]

export const columns = [
	{
		name: 'product_code',
		value: 'productCode'
	},
	{
		name: 'product_name',
		value: 'productName'
	},
	{
		name: 'release_date',
		value: 'releaseDate'
	},
	{
		name: 'gender',
		value: 'gender'
	},
	{
		name: 'msrp',
		value: 'msrp'
	},
	{
		name: 'aur',
		value: 'demandByUnitsSold'
	},
	{
		name: 'demand',
		value: 'demand'
	},
	{
		name: 'units_sold',
		value: 'unitsSold'
	},
	{
		name: 'rank_in_FTW',
		value: 'rank'
	}
]

export const attributeDemandColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'attribute_group_name',
			value: 'name'
		},
		{
			name: 'gender',
			value: 'gender'
		},
		{
			name: 'ppi_tier',
			value: 'ppiTier'
		},
		{
			name: 'demand',
			value: 'value'
		},
		{
			name: 'demand_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const attributePPIColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'attribute_group_name',
			value: 'name'
		},
		{
			name: 'gender',
			value: 'gender'
		},
		{
			name: 'ppi',
			value: 'value'
		},
		{
			name: 'ppi_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const attributeRVIColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'attribute_group_name',
			value: 'name'
		},
		{
			name: 'gender',
			value: 'gender'
		},
		{
			name: 'resale_volume_index',
			value: 'value'
		},
		{
			name: 'resale_volume_index_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const attributeDIColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'attribute_group_name',
			value: 'name'
		},
		{
			name: 'gender',
			value: 'gender'
		},
		{
			name: 'diverstiy',
			value: 'value'
		},
		{
			name: 'diverstiy_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const attributeColumns = [
	{
		name: 'attribute_group_name',
		value: 'attribute'
	},
	{
		name: 'attribute_name',
		value: 'key'
	},
	{
		name: 'attribute_value',
		value: 'value'
	},
]

export const handleOverviewDemandData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'demand_yoy']
	const lineData = data.demand[0].data;

	return handleOverviewData(columns, type, demandTimeTab, lineData, filterArr, 'DEMAND')
}

export const handleOverviewPPIData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'ppi_yoy']
	const lineData = data.data[0].data;

	return handleOverviewData(columns, type, demandTimeTab, lineData, filterArr, 'PREMIUM PRICE INDEX')
}

export const handleOverviewSVData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'sales_volume_yoy']
	const lineData = data.data[1].data;

	return handleOverviewData(columns, type, demandTimeTab, lineData, filterArr, 'SALES VOLUME')
}

export const handleOverviewData = (columns, type, demandTimeTab, data, filterArr, titleName, options) => {
	const header = headerCol(columns, type, filterArr, demandTimeTab, options);
	const title = []
	const headerData = header.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = titleName
	const arr = [];
	arr.push(title)
	arr.push(headerData)
	
	data.forEach(list => {
		list.data.forEach(item => {
			if (item.value) {
				const itemArr = [];
				header.forEach(head => {
					if (head.name === 'period_type') {
						itemArr.push(demandTimeTab === 'M12' ? '12M': demandTimeTab)
					} else {
						itemArr.push(item.origin[head.value] || '')
					}
				})
				arr.push(itemArr)
			}
		})
	})

	return {
		data: arr,
		colsNumber: header.length,
		rowsNumber: arr.length
	}
}

export const handleOverviewLaunchSkuData = (columns, data) => {
	const title = []
	const headerData = columns.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'NIKE New Launch SKU'
	const arr = [];
	arr.push(title)
	arr.push(headerData)

	data.forEach(list => {
		list.data.forEach(item => {
			const itemArr = [];
			columns.forEach(head => {
				itemArr.push(item[head.value] || '')
			})
			arr.push(itemArr)
		})
	})

	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleTopSkuTimeData = (columns, type, startDate, endDate) => {
	const headerData = columns.map(h => {
		return h.name
	});
	const arr = [];
	arr.push(headerData)
	arr.push([type.toLowerCase(), startDate, endDate])
	
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleTotalData = (columns, data) => {
	return handleData(columns, 'TOP SELLING ITEMS IN FTW', data)
}

export const handleMaleData = (columns, data) => {
	return handleData(columns, 'TOP SELLING ITEMS IN MALE FTW', data)
}

export const handleFemaleData = (columns, data) => {
	return handleData(columns, 'TOP SELLING ITEMS IN FEMALE FTW', data)
}

export const handleKidData = (columns, data) => {
	return handleData(columns, 'TOP SELLING ITEMS IN KIDS FTW', data)
}

const handleData = (columns, titleName, data) => {
	const title = []
	const headerData = columns.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = titleName
	const arr = [];
	arr.push(title)
	arr.push(headerData)
	
	data.forEach(list => {
		list.forEach(item => {
			const itemArr = [];
			columns.forEach(head => {
				itemArr.push(item[head.value] || '')
			})
			arr.push(itemArr)
		})
	})

	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleAttributeDemandData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'demand_yoy']
	const lineData = data[0][0];

	return handleAttributeCommonData(columns, type, demandTimeTab, lineData.data, filterArr, 'DEMAND BY PPI')
}

export const handleAttributePPIData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'ppi_yoy']
	const lineData = data[0][1];

	return handleAttributeCommonData(columns, type, demandTimeTab, lineData.data, filterArr, 'PREMIUM PRICE INDEX')
}

export const handleAttributeRVIData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'resale_volume_index_yoy']
	const lineData = data[1][0];

	return handleAttributeCommonData(columns, type, demandTimeTab, lineData.data, filterArr, 'RESALE VOLUME INDEX')
}

export const handleAttributeDIData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'diverstiy_yoy']
	const lineData = data[1][1];

	return handleAttributeCommonData(columns, type, demandTimeTab, lineData.data, filterArr, 'DIVERSITY INDEX')
}

const handleAttributeCommonData = (columns, type, demandTimeTab, data, filterArr, titleName) => {
	const header = headerCol(columns, type, filterArr, demandTimeTab);
	const title = []
	const headerData = header.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = titleName
	const arr = [];
	arr.push(title)
	arr.push(headerData)
	
	data.forEach(list => {
		list.data.forEach(item => {
			if (item.value) {
				const itemArr = [];
				header.forEach(head => {
					if (head.name === 'period_type') {
						itemArr.push(demandTimeTab === 'M12' ? '12M': demandTimeTab)
					} else if (head.name === 'gender') {
						itemArr.push(item[head.value] || 'TOTAL')
					} else {
						itemArr.push(item[head.value] || '')
					}
				})
				arr.push(itemArr)
			}
		})
	})

	return {
		data: arr,
		colsNumber: header.length,
		rowsNumber: arr.length
	}
}

export const handleAttributeData = (columns, selectList, attributeDictList) => {
	const headerData = columns.map(h => {
		return h.name
	});
	const arr = [];
	arr.push(headerData)

	selectList.forEach(list => {
		list.children.forEach(item => {
			const itemArr = []
			columns.forEach(column => {
				if (column.name === 'attribute_group_name') {
					itemArr.push(list.name)
				} else if (column.name === 'attribute_name') {
					itemArr.push(item.value)
				} else {
					const attributeObj = _.findWhere(attributeDictList, { value: list.value }) || {}
					const franchiseObj = _.findWhere(attributeObj.children || [], { value: item.value }) || {}
					if (franchiseObj.children && franchiseObj.children.length === item.children.length) {
						itemArr.push('total')
					} else {
						itemArr.push(item.children.map(f => f.value).join(','))
					}
				}
			})
			arr.push(itemArr)
		})
	})
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}