import { $post } from './index';

export const overviewDemandRequest = (data) => {
    return $post('/data/market/overview/performance/demand', data, {
        queryName: 'overviewDemandRequest',
    });
};

export const overviewBrandShareRequest = (data) => {
    return $post('/data/market/overview/performance/brand/share', data, {
        queryName: 'overviewBrandShareRequest',
    });
};

export const overviewPPIRequest = (data) => {
    return $post('/data/market/overview/performance/premium/price/index', data, {
        queryName: 'overviewPPIRequest',
    });
};

export const overviewPriceTierSummaryRequest = (data) => {
    return $post('/data/market/overview/brand/share/by/price/tier/summary', data, {
        queryName: 'overviewPriceTierSummaryRequest',
    });
};

export const overviewPriceTierTreemapRequest = (data) => {
    return $post('/data/market/overview/brand/share/by/price/tier/data/block', data, {
        queryName: 'overviewPriceTierTreemapRequest',
    });
};

export const overviewPriceTierLineRequest = (data) => {
    return $post('/data/market/overview/brand/share/by/price/tier/data/line', data, {
        queryName: 'overviewPriceTierLineRequest',
    });
};
