import axios from 'axios';
import sha1 from 'sha1';
import qs from 'qs';

export const baseUrl = process.env.REACT_APP_ENV;

const pending = new Map();

function uuid() {
    var s = [];
    var hexDigits = '0123456789abcdef';
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    var uuid = s.join('');
    return uuid;
}

/**
 * 添加请求的取消方法
 * @param {*} config axios 的 config
 */
const addPending = (config) => {
    const queryName = config.queryName;
    config.cancelToken =
        config.cancelToken ||
        new axios.CancelToken((cancelId) => {
            if (queryName && !pending.has(queryName)) {
                pending.set(queryName, cancelId);
            }
        });
};

/**
 * 移除取消请求的方法
 * @param {*} config axios 的config
 */
const removePending = (config) => {
    const queryName = config.queryName;
    if (queryName && pending.has(queryName)) {
        const cancelFunc = pending.get(queryName);
        if (cancelFunc) cancelFunc(queryName);
        pending.delete(queryName);
    }
};

/**
 * 单个请求取消
 * @param {*} queryName 请求的名称
 */
export const cancelQuery = (queryName) => {
    const cancelFunc = pending.get(queryName);
    if (cancelFunc) cancelFunc(queryName);
};

/**
 * 取消所有的请求  多用于路由跳转
 */
export const clearAllQuery = () => {
    for (const [queryName, cancelFunc] of pending) {
        cancelFunc(queryName);
    }
    pending.clear();
};

const service = axios.create({
    baseURL: baseUrl, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 180000, // request timeout
});

/**
 * REQUEST interceptor
 */
service.interceptors.request.use(
    (config) => {
        if (config.method === 'get') {
            config.paramsSerializer = function (params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            };
        }
        removePending(config);
        addPending(config);
        const uid = uuid().replace(/-/g, '');
        const timestamp = new Date().getTime();
        const path = config.url;
        const temp = `${uid}${path}${timestamp}`;
        const reverse = temp.split('').reverse().join('');
        config.headers['timestamp'] = timestamp;
        config.headers['sn'] = uid;
        config.headers['code'] = sha1(reverse);
        // 登陆后权限验证
        const token = localStorage.getItem('dewu_token');
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);
/**
 * RESPONSE interceptor
 */
service.interceptors.response.use(
    (response) => {
        removePending(response.config);
        return Promise.resolve(response);
    },
    // RESPONSE ERROR
    (error) => {
        if (axios.isCancel(error)) {
            console.log('request canceled: ', error.message);
        } else if (error.response) {
            console.log(error.response, '====error.response');
        } else if (error.config) {
            // 超时重新请求
            const config = error.config;
            // 全局的请求次数,请求的间隙
            const [RETRY_COUNT, RETRY_DELAY] = [3, 1000];

            // 设置用于跟踪重试计数的变量
            config.__retryCount = config.__retryCount || 0;
            // 检查是否已经把重试的总数用完
            if (config.__retryCount >= RETRY_COUNT) {
                return Promise.reject('请求超时' || { message: error.message });
            }
            // 增加重试计数
            config.__retryCount++;
            // 创造新的Promise来处理指数后退
            const backoff = new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, RETRY_DELAY || 1);
            });
            // instance重试请求的Promise
            return backoff.then(() => {
                return service(config);
            });
        } else {
            console.log('====error', error);
        }

        return Promise.reject(error.response || error);
    }
);

export default service;
