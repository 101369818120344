import * as React from 'react';
import withChart from '../../hoc/withChart';
import ReactDOM from 'react-dom';
import _ from 'underscore';

@withChart()
export default class ChartRender extends React.Component {
    componentDidMount() {
        this.renderChart();
    }

    componentDidUpdate() {
        this.renderChart();
    }

    shouldComponentUpdate(nextProps) {
        const sameSize = this.props.width === nextProps.width && this.props.height === nextProps.height;
        const sameData = _.isEqual(this.props.data, nextProps.data);
		const sameCurrentDate = (nextProps.currentDate && !_.isEqual(this.props.currentDate, nextProps.currentDate)) || false;
		const samegender = (nextProps.gender && !_.isEqual(this.props.gender, nextProps.gender)) || false;
		const sameDateRange = (nextProps.allDate && !_.isEqual(this.props.allDate, nextProps.allDate)) || false
        return !sameSize || !sameData || sameCurrentDate || sameDateRange || samegender;
    }

    componentWillUnmount() {
        this._deregisterChart();
    }

    _deregisterChart() {
        if (this._deregister) {
            this._deregister();
            delete this._deregister;
        }
    }

    renderChart() {
        const { width, height } = this.props;
        if (width == null || height == null) {
            return;
        }

        const parent = ReactDOM.findDOMNode(this);

        // deregister previous chart:
        this._deregisterChart();

        // reset the DOM:
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }

        // create a new container element
        const element = document.createElement('div');
        parent.appendChild(element);
        element.classList.add('relative');

        try {
            this._deregister = this.props.chartRender(element, this.props);
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        return <div className="full-width full-height chart"></div>;
    }
}
