import { handleData, headerCol } from './index';

export const demandColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'name'
		},
		{
			name: 'ppi_tier',
			value: 'ppiTier'
		},
		{
			name: 'demand',
			value: 'value'
		},
		{
			name: 'demand_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const ppiColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'name'
		},
		{
			name: 'ppi',
			value: 'value'
		},
		{
			name: 'ppi_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const rviColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'name'
		},
		{
			name: 'resale_volume_index',
			value: 'value'
		},
		{
			name: 'resale_volume_index_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const diColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'name'
		},
		{
			name: 'diversity',
			value: 'value'
		},
		{
			name: 'diversity_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const handleDemandData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'demand_yoy']
	const header = headerCol(columns, type, filterArr, demandTimeTab);
	const title = []
	const headerData = header.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'DEMAND BY PPI'
	const arr = [];
	arr.push(title)
	arr.push(headerData)

	const lineData = data[0][0];
	lineData.data.forEach(list => {
		list.data.forEach(item => {
			if (item.value) {
				const itemArr = [];
				header.forEach(head => {
					if (head.name === 'period_type') {
						itemArr.push(demandTimeTab === 'M12' ? '12M': demandTimeTab)
					} else if (head.name === 'ppi_tier') {
						itemArr.push(item[head.value] ? item[head.value] : 'TOTAL')
					} else {
						itemArr.push(item[head.value] || '')
					}
				})
				arr.push(itemArr)
			}
		})
	})

	return {
		data: arr,
		colsNumber: header.length,
		rowsNumber: arr.length
	}
}

export const handlePPILineData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'ppi_yoy']
	const lineData = data[0][1];

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'PREMIUM PRICE INDEX')
}

export const handleRVIData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'resale_volume_index_yoy']
	const lineData = data[1][0];

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'RESALE VOLUME INDEX')
}

export const handleDIData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'diversity_yoy']
	const lineData = data[1][1];

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'DIVERSITY INDEX')
}