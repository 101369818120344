import _ from 'underscore';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import XLSX from 'xlsx';

export const add = (list, item) => {
    const arr = [...list, item];
    return arr;
};

export const remove = (list, item) => {
    const index = _.findIndex(list, (p) => p === item);
    const arr = [...list.slice(0, index), ...list.slice(index + 1)];
    return arr;
};

export const brandColor = (brand) => {
    const colors = {
        nike: '#FF552C',
        adidas: '#2B62DE',
        vans: '#EAC733',
        converse: '#3D9832',
        anta: '#EF8BB4',
        martens: '#2795A2',
        jordan: '#B98B00',
        lining: '#84CD9A',
        timberland: '#FF5F60',
        "li'ning": '#84CD9A',
        "an'ta": '#EF8BB4',
        other: '#2D4374',
    };
    const color = colors[brand.toLowerCase()];
    return color;
};

export const getMonth = (number) => {
    const obj = {
        1: 'JAN',
        2: 'FEB',
        3: 'MAR',
        4: 'APR',
        5: 'MAY',
        6: 'JUN',
        7: 'JUL',
        8: 'AUG',
        9: 'SEP',
        10: 'OCT',
        11: 'NOV',
        12: 'DEC',
    };
    return obj[number];
};

const ppiNameObj = {
    L9: 'PPI<0.9',
    G9L1: 'PPI[0.9,1.1)',
    G1: 'PPI>=1.1'
}

export const formatM = (data, type) => {
    if (data) {
        const number = Number(data);
        if (number && number >= 1000000) {
            return `${Math.round(number / 1000000)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}M`;
        } else if (number && number < 1000000 && type === 'M') {
            return `${(number / 1000000).toFixed(1).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}M`;
        } else if (number && number < 1000000) {
            const num = number;
            return num.toString().indexOf('.') !== -1 ? num.toFixed(2).toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
        }
        return data;
    }
    return data;
};

export const formatPercent = (data) => {
    const num = Number(data);
    if (num) {
        return `${(num * 100).toFixed(2)}%`;
    }
    return data;
};

export const formatByThousands = (data) => {
    return data.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
};

export const getColsWpx = (colsNumber) => {
	const arr = []
	for (let i = 0; i < colsNumber; i++) {
		arr.push({
			wpx: 100
		})
	}
	return arr
}

const _workbook2blob = (workbook) => {
	// 生成excel的配置项
	const wopts = {
		// 要生成的文件类型
		bookType: "xlsx",
		// // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
		bookSST: false,
		type: "binary"
	};
	const wbout = XLSX.write(workbook, wopts);
	// 将字符串转ArrayBuffer
	const s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	}
	const blob = new Blob([s2ab(wbout)], {
		type: "application/octet-stream"
	});
	return blob;
}

const _downloadBlob = (blob, fileName) => {
    if (typeof blob == "object" && blob instanceof Blob) {
      blob = URL.createObjectURL(blob); // 创建blob地址
    }
    const aLink = document.createElement("a");
    aLink.href = blob;
    // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
    aLink.download = fileName || "";
    
    const event = document.createEvent("MouseEvents");
    event.initMouseEvent( "click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null );
    aLink.dispatchEvent(event);
}

export const downloadXlsx = (sheetData, sheetName, fileName, options) => {
	const wb = XLSX.utils.book_new();
	const sheet = XLSX.utils.aoa_to_sheet(sheetData);
	sheet['!cols'] = options['!cols'];
	sheet['!merges'] = options['!merges'];
	XLSX.utils.book_append_sheet(wb, sheet, sheetName);
	// XLSX.utils.book_append_sheet(wb, sheet, '00000000');
	const workbookblob = _workbook2blob(wb);
	_downloadBlob(workbookblob, `${fileName || document.title}.xlsx`);
}

// 多sheet 下载
export const downloadXlsx2 = (allSheet, sheetName, fileName, allOptions, allName) => {
	const wb = XLSX.utils.book_new();
    Object.entries(allSheet).forEach(([key, sheetData]) => {
        const sheet = XLSX.utils.aoa_to_sheet(sheetData);
        sheet['!cols'] = allOptions[key]['!cols'];
        sheet['!merges'] = allOptions[key]['!merges'];
        XLSX.utils.book_append_sheet(wb, sheet, allName[key]);
    })
	// XLSX.utils.book_append_sheet(wb, sheet, '00000000');
	const workbookblob = _workbook2blob(wb);
	_downloadBlob(workbookblob, `${fileName || document.title}.xlsx`);
}

export const downloadPDF = (element, onSuccess = () => {}, onFailure = () => {}, name) => {
    html2canvas(element, {
        allowTaint: false,
		useCORS: true,
		// proxy: 'http://dewu-data.oss-cn-hangzhou.aliyuncs.com/dev/image/'
        // height: targetDom.scrollHeight,
        // width: targetDom.scrollWidth,
        // scale: 0.5
    }).then((canvas) => {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;

        //一页pdf显示html页面生成的canvas高度;
        var pageHeight = (contentWidth / 592.28) * 841.89;
        //未生成pdf的html页面高度
        var leftHeight = contentHeight;
        //页面偏移
        var position = 0;
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 555.28;
        var imgHeight = (552.28 / contentWidth) * contentHeight;

        domtoimage
            .toPng(element)
            .then(function (pageData) {
                var pdf = new jsPDF('', 'pt', 'a4');

                //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                //当内容未超过pdf一页显示的范围，无需分页
                if (leftHeight < pageHeight) {
                    pdf.addImage(pageData, 'png', 20, 20, imgWidth, imgHeight);
                } else {
                    while (leftHeight > 0) {
                        pdf.addImage(pageData, 'png', 20, position, imgWidth, imgHeight);
                        leftHeight -= pageHeight;
                        position -= 841.89;
                        //避免添加空白页
                        if (leftHeight > 0) {
                            pdf.addPage();
                        }
                    }
                }
                pdf.save(`${name || document.title}.pdf`);
                onSuccess();
            })
            .catch(function (error) {
                onFailure();
                console.error('download fail', error);
            });
    });
};

export const getCurrentDate = (year, month) => {
    const currentYear = moment().format('YYYY');
    const currentMonth = moment().format('MM');
    const lastYear = moment()
        .add(-(year === 0 ? 0 : 1), 'y')
        .format('YYYY');
    const lastMonth = moment()
        .add(-(month - 1 || 11), 'M')
        .format('MM');
    return {
        startDate: Number(`${lastYear}${lastMonth}`),
        endDate: Number(`${currentYear}${currentMonth}`),
        originalDate: {
            startDate: `${lastYear}-${lastMonth}`,
            endDate: `${currentYear}-${currentMonth}`,
        },
    };
};

export const transformDate = (date, monthNum) => {
    if (date) {
        const start = date.substring(0, 4);
        const end = date.substring(4);
        const startTime = moment(`${start}-${end}`).add(-(monthNum - 1), 'month');
        const endTime = moment(`${start}-${end}`);
        return {
            startDate: Number(startTime.format('YYYYMM')),
            endDate: Number(endTime.format('YYYYMM')),
            originalDate: {
                startDate: startTime.format('YYYY-MM'),
                endDate: endTime.format('YYYY-MM'),
                origin: [startTime.format('YYYY-MM'), endTime.format('YYYY-MM')],
            },
        };
    }
    return {
        startDate: Number(
            moment()
                .add(-(monthNum - 1), 'month')
                .format('YYYYMM')
        ),
        endDate: Number(moment().format('YYYYMM')),
        originalDate: {
            startDate: moment()
                .add(-(monthNum - 1), 'month')
                .format('YYYY-MM'),
            endDate: moment().format('YYYY-MM'),
            origin: [
                moment()
                    .add(-(monthNum - 1), 'month')
                    .format('YYYY-MM'),
                moment().format('YYYY-MM'),
            ],
        },
    };
};

export const transformSingleDate = (date, type) => {
    if (type === 'month') {
        const start = date.substring(0, 4);
        const end = date.substring(4);
        return moment(`${start}-${end}`).format('YYYY-MM');
    } else if (type === 'week') {
        const start = date.substring(0, 4);
        const end = date.substring(4);
        return {
            year: Number(start),
            week: Number(end),
        };
    }
};

export const getWeekStartEnd = (selected) => {
    const startWeek = selected[0];
    const startWeekTemp = startWeek.split('-');
    const startWeekYear = startWeekTemp[0];
    const startWeekNumber = Number(startWeekTemp[1].replace('st', '').replace('nd', '').replace('rd', '').replace('th', ''));
    const startWeekNum = startWeekNumber > 9 ? `${startWeekNumber}` : `0${startWeekNumber}`;
    const start = Number(`${startWeekYear}${startWeekNum}`);
    const endWeek = selected[1];
    const endWeekTemp = endWeek.split('-');
    const endWeekYear = endWeekTemp[0];
    const endWeekNumber = Number(endWeekTemp[1].replace('st', '').replace('nd', '').replace('rd', '').replace('th', ''));
    const endWeekNum = endWeekNumber > 9 ? `${endWeekNumber}` : `0${endWeekNumber}`;
    const end = Number(`${endWeekYear}${endWeekNum}`);
    return {
        start,
        end,
    };
};

export const handleDateStartEnd = (selected, type) => {
    let start = null;
    let end = null;
    if (type === 'MONTH') {
        start = Number(selected[0].replace('-', ''));
        end = Number(selected[1].replace('-', ''));
    } else if (type === 'WEEK') {
        const { start: startValue, end: endValue } = getWeekStartEnd(selected);
        start = startValue;
        end = endValue;
    } else if (type === 'QUARTER') {
        start = selected[0].replace('-', '');
        end = selected[1].replace('-', '');
    }
    return {
        start,
        end,
    };
};

export const groupedByNumber = (num = 2, list, emptyObj = {}) => {
    const group = [];
    for (let i = 0; i < list.length; i += num) {
        group.push(list.slice(i, i + num));
    }
    const last = (group.length && group[group.length - 1]) || [];
    const empty = [];
    if (last.length) {
        for (let i = 0; i < num - last.length; i++) {
            empty.push(emptyObj);
        }
        empty.forEach((e) => {
            group[group.length - 1].push(e);
        });
    }
    return group;
};

export const handleDesc = (data, type, gender, columnKey) => {
    if (data.length) {
        const filterArr = data.filter((f) => f.date === data[data.length - 1].date);
        const groupData = _.groupBy(filterArr, (d) => d[type]);
        const arr = Object.entries(groupData)
            .map(([key, value]) => {
                const ppiText = gender ? `${value[0].brandClean}-${value[0].gender}-${key}` : `${value[0].brandClean}-${key}`;
                const text = gender ? (gender === 'ALL' ? (columnKey ? `${value[0][columnKey]}-${key}` : `${value[0][type]}-${key}`) : `${value[0][type]}-${value[0].gender}`) : key;
                const title = type === 'ppiTier' ? ppiText : text;
                return {
                    key: title,
                    list: value,
                };
            })
            .sort((a, b) => a.key.localeCompare(b.key));

        const temp = groupedByNumber(3, arr, {});
        return temp;
    }
    return [];
};

/**
 *
 * @param {*} data
 * @param {*} column the column need group by in data object  ex data.name  column === name
 * @param {*} lastDate
 * @param {*} ppiInfo
 * @param {*} gender
 * @param {*} anotherColumn
 */
export const handleLegend = (data, column, lastDate, ppiInfo, gender, anotherColumn) => {
    if (data.length && lastDate) {
        const filterArr = data.filter((f) => f.date === lastDate);
        const groupData = _.groupBy(filterArr, (d) => d[column]);
        const legendList = Object.entries(groupData)
            .map(([key, value]) => {
                let title = '';
                if (gender) {
                    if (gender === 'ALL') {
                        if (ppiInfo && (ppiInfo.type === 'brand' || ppiInfo.type === 'attribute')) {
                            title = ppiInfo.name !== 'Demand' ? `${value[0][anotherColumn]}-${key}-${ppiNameObj[ppiInfo.value[0]]}` : `${value[0][anotherColumn]}-${key}`;
                        } else if (ppiInfo && ppiInfo.type === 'ppi') {
                            title = `${value[0][anotherColumn]}-${ppiInfo.gender[0]}-PPI${key}`;
                        } else {
                            title = `${value[0][anotherColumn]}-${key}`;
                        }
                    } else {
                        if (ppiInfo && (ppiInfo.type === 'brand' || ppiInfo.type === 'attribute')) {
                            title = ppiInfo.name !== 'Demand' ? `${key}-${gender}-${ppiNameObj[ppiInfo.value[0]]}` : `${key}-${gender}`;
                        } else if (ppiInfo && ppiInfo.type === 'ppi') {
                            title = `${ppiInfo.value[0]}-${gender}-PPI${key}`;
                        } else {
                            title = `${key}-${gender}`;
                        }
                    }
                } else {
                    if (ppiInfo && (ppiInfo.type === 'brand' || ppiInfo.type === 'attribute')) {
                        title = ppiInfo.name !== 'Demand' ? `${key}-${ppiNameObj[ppiInfo.value[0]]}` : key;
                    } else if (ppiInfo && ppiInfo.type === 'ppi') {
                        title = `${ppiInfo.value[0]}-PPI${key}`;
                    } else {
                        title = key;
                    }
                }

                return {
                    key: title,
                    list: value,
                };
            })
            .sort((a, b) => a.key.localeCompare(b.key));
        const list = groupedByNumber(3, legendList, {});
        return list;
    }
    return [];
};

export const handleRouters = (permissionCodes, routers, routerWhiteList) => {
    if (permissionCodes.length) {
        const routes = routers.filter((item) => {
            if (routerWhiteList.indexOf(item.path) !== -1) {
                return item;
            } else if (item.permission && permissionCodes.indexOf(item.permission) !== -1) {
                return item;
            } else if (item.children.length) {
                const newChildren = item.children.filter((sub) => {
                    if (routerWhiteList.indexOf(sub.path) !== -1) {
                        return sub;
                    } else if (sub.permission && permissionCodes.indexOf(sub.permission) !== -1) {
                        return sub;
                    }
                    return false;
                });
                item.children = newChildren;
                return item;
            }
            return false;
        });
        return routes;
    }
    return [];
};

export const open = (url, blank = false) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    try {
        a.href = url;
        a.rel = 'noopener';
        if (blank) {
            a.target = '_blank';
        }
        a.click();
    } finally {
        a.remove();
    }
};

export const genderDict = ['MALE', 'FEMALE', 'KIDS'];
export const ppiDict = [
    {
        name: 'PPI<0.9',
        value: 'L9',
    },
    {
        name: 'PPI[0.9,1.1)',
        value: 'G9L1',
    },
    {
        name: 'PPI>=1.1',
        value: 'G1',
    },
];

const handleChildren = (selected, gender) => {
    if (gender && gender === 'ALL' && selected.length) {
        return genderDict.map((dict) => {
            return {
                value: selected[0],
                name: `${selected[0]}-${dict}`,
                gender: dict,
            };
        });
    }
    if (gender && selected.length) {
        return selected.map((d) => ({
            name: `${d}-${gender}`,
            value: d,
            gender,
        }));
    }
    return selected.map((d) => ({ name: d, value: d }));
};

const handlePPIChildren = (selected, gender) => {
    if (gender && gender === 'ALL' && selected.length) {
        return genderDict.map((dict) => {
            return {
                value: selected[0],
                name: `${selected[0]}-${dict}`,
                gender: dict,
                children: ppiDict,
            };
        });
    }
    if (gender && selected.length) {
        return selected.map((item) => ({
            name: `${item}-${gender}`,
            value: item,
            gender,
            children: ppiDict,
        }));
    }
    return selected.map((item) => ({
        name: item,
        value: item,
        children: ppiDict,
    }));
};

export const handlePPI = (selected, gender, type = 'brand') => {
    const ppi = [
        {
            name: 'Demand',
            value: 'Demand',
            type: type === 'brand' ? 'brand': 'attribute',
            children: []
        },
        {
            name: 'Filter premium range',
            value: 'Filter premium range',
            type: type === 'brand' ? 'brand': 'attribute',
            children: ppiDict,
        },
        {
            name: 'Demand by premium range',
            value: 'Demand by premium range',
            type: 'ppi',
            children: handleChildren(selected, gender),
        }
    ];
	
    return {
        data: ppi,
    };
};

export const handlePPIData = (selected, gender, type) => {
    const brand = [
        {
            name: 'TOTAL',
            value: 'TOTAL',
            children: handleChildren(selected, gender),
        },
        {
            name: 'PPI<0.9',
            value: 'L9',
            children: handleChildren(selected, gender),
        },
        {
            name: 'PPI[0.9,1.1)',
            value: 'G9L1',
            children: handleChildren(selected, gender),
        },
        {
            name: 'PPI>=1.1',
            value: 'G1',
            children: handleChildren(selected, gender),
        },
    ];
	const ppi = handlePPIChildren(selected, gender);
	if (type) {
		return {
			attribute: brand,
			ppi
		}
	}
    return {
        brand,
        ppi,
    };
};


export const headerCol = (columns, dateType, filterArr, timeTab, options) => {
	const headerCol = columns.map(column => {
		if (options && options.filterColumn) {
			if (!options.filterColumn(column)) {
				return false
			}
		}
		if (dateType === 'MONTH' && timeTab === 'MONTH') {
			return column
		}
		if (dateType === 'MONTH' && timeTab !== 'MONTH' && filterArr.filter(f => f !== 'period_type').indexOf(column.name) === -1) {
			return column
		}
		if (filterArr.indexOf(column.name) === -1) {
			return column
		}
		return false
	}).filter(f => f)
	return headerCol
}

export const handleData = (columns, type, demandTimeTab, data, filterArr, titleName, options, needTitle) => {
	const header = headerCol(columns, type, filterArr, demandTimeTab, options);
	const title = []
	const headerData = header.map(h => {
		title.push(null)
		return h.name
	});
    
	title[0] = titleName
	const arr = [];
	needTitle && arr.push(title)
	arr.push(headerData)
	
	data.forEach(list => {
		list.data.forEach(item => {
			if (item.value) {
				const itemArr = [];
				header.forEach(head => {
					if (head.name === 'period_type') {
						itemArr.push(demandTimeTab === 'M12' ? '12M': demandTimeTab)
					} else {
						itemArr.push(item[head.value] || '')
					}
				})
				arr.push(itemArr)
			}
		})
	})

	return {
		data: arr,
		colsNumber: header.length,
		rowsNumber: arr.length,
        sheetName: titleName
	}
}

export const downloadTemplate = (data, name) => {
	const blob = new Blob([data]); //构造一个blob对象来处理数据
	const fileName = `${name}.xlsx`;
	//对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
	//IE10以上支持blob但是依然不支持download
	if ('download' in document.createElement('a')) {
		//支持a标签download的浏览器
		const link = document.createElement('a'); //创建a标签
		link.download = fileName; //a标签添加属性
		link.style.display = 'none';
		link.href = URL.createObjectURL(blob);
		document.body.appendChild(link);
		link.click(); //执行下载
		URL.revokeObjectURL(link.href); //释放url
		document.body.removeChild(link); //释放标签
	} else {
		//其他浏览器
		navigator.msSaveBlob(blob, fileName);
	}
}