import _ from 'underscore';

export const completeMissingData = (referenceArr, list) => {
    const arr = referenceArr.map((date) => {
        const obj = _.findWhere(list, { date });
        if (obj) {
            return obj;
        }
        return {
            date,
            value: null,
        };
    });
    return arr;
};

export const sortByLetter = (a, b) => a.display.localeCompare(b.display);