import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment';
import { ArrowDownward, ArrowUpward, Search } from '@material-ui/icons';
// import Dialog from '@material-ui/core/Dialog'
// import { Modal } from '@material-ui/core'
// import Icon from '../components/Icon'
import StyledDialog from '../components/StyledDialog';
import { Visualization } from '../visualization/index';
// import CascadeDrop from '../components/CascadeDrop';
// import StyledCheckBox from '../components/StyledCheckBox';
import DatePicker from '../components/DatePicker';
import CommonDialog from '../components/CommonDownloadDialog';
import CommonTootip from '../components/StyledTooltip';
import Slider from '../components/Slider'
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Menu, TextField, InputAdornment } from '@material-ui/core';
import ExplorAttribute from './ExplorAttribute';

import { downloadPDF, transformDate, formatM, formatPercent, handleDateStartEnd, handleLegend, downloadXlsx2, getColsWpx } from '../../utils/index';
import {
	handleDemandData,
	handlePPIData,
	handleRVIData,
	handleDIData,
} from '../../utils/franchiseAnalysisDownload';
import '../../assets/css/explor.css';
import {
    demandColumns,
	ppiColumns,
	rviColumns,
	diColumns
} from '../../utils/explorationDownload';

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => {
    return <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />;
});

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

const timeTabList = [
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: '12M',
        alias: 'M12',
    },
];

@inject('commonStore', 'userStore', 'explorStore')
@observer
export default class Explor extends React.Component {
    state = {
        demandTimeTab: 'MONTH',
        selectedList: [],
        selectGroupList: [],
        startDate: null,
        endDate: null,
        originalDate: {},
        type: 'MONTH',
        colors: {
            DEMAND: {},
            'PREMIUM PRICE INDEX': {},
            'RESALE VOLUME INDEX': {},
            'DIVERSITY INDEX': {},
        },
        open: false,
        language: 'enInfo',
        tooltipOpen: {},
        getStart: false,
        currentGroupNum: 1,
        groupList: [],
        currentGroup: [],
        groupOpen: false,
        anchorEle: null,
        searchValue: '',
        page: {
            pageNum: 1,
            pageSize: 10
        },
        currentGroupId: '',
        groupItemData: null,
        checkObj: {},
        groupDownloadOpen: false,
        showLoading: false,
        showStart: false,
        searchLoading: false
    };
    async componentWillMount() {
        const { timeRangeMonth } = this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 12);
        this.setState({
            startDate,
            endDate,
            originalDate
        });
        await this.getAllRequest()
    }

    getAllRequest = async () => {
        this.setState({
            showLoading: true,
            showStart: false
        })
        await this.props.explorStore.explorGroupRequest()
        const { explorGroupData } = this.props.explorStore
        if (explorGroupData.length) {
            const groupList = this.groupedByNumber(3, explorGroupData, '');

            this.setState({
                groupList,
                currentGroup: groupList[0],
                showStart: false
            });
            const { type, demandTimeTab, startDate, endDate } = this.state;
            const ids = explorGroupData.map((item) => item.groupId)
            await this.getRequest(startDate, endDate, type, demandTimeTab, ids)
        } else {
            this.setState({
                showStart: true,
                groupList: [],
                currentGroup: [],
                showLoading: false
            })
        }
    }

    getRequest = async (startDate, endDate, dateType, dataType, groupIdList) => {
        try {
            this.setState({
                showLoading: true
            })
            await this.props.explorStore.explorResultRequest({
                startDate,
                endDate,
                dateType,
                dataType,
                groupIdList
            })
            this.setState({
                showLoading: false
            })
        } catch (error) {
            this.setState({
                showLoading: false
            })
        }
    }

    groupedByNumber = (num = 2, list, emptyObj = {}) => {
        const group = [];
        for (let i = 0; i < list.length; i += num) {
            group.push(list.slice(i, i + num));
        }
        const last = (group.length && group[group.length - 1]) || [];
        const empty = [];
        if (last.length) {
            for (let i = 0; i < num - last.length; i++) {
                empty.push(emptyObj);
            }
            empty.forEach((e) => {
                group[group.length - 1].push(e);
            });
        }
        return group;
    };

    handleConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        const { demandTimeTab } = this.state;
        if (start && end) {
            this.setState({
                startDate: start,
                endDate: end,
                type,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });

            const { explorGroupData } = this.props.explorStore
            const ids = explorGroupData.map((item) => item.groupId)
            await this.getRequest(start, end, type, demandTimeTab, ids)
        }
    };

    handleDemandTimeTab = async (tab) => {
        this.setState({
            demandTimeTab: tab,
        });
        const { startDate, endDate, type } = this.state;

        const { explorGroupData } = this.props.explorStore
        const ids = explorGroupData.map((item) => item.groupId)
        await this.getRequest(startDate, endDate, type, tab, ids)
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'pdf') {
            this.ele &&
                downloadPDF(
                    this.ele,
                    () => {
                        console.log('download success');
						this.props.commonStore.setDownloadDialog(false);
						this.props.commonStore.setMenuName('');
                    },
                    () => {
                        console.log('download fail');
					},
					name
                );
        } else if (type === 'xlsx') {
            const { explorIndexResult } = this.props.explorStore;
			
            const { type, demandTimeTab } = this.state;

            const allOptions = {
                demand: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                },
                ppi: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                },
                rvi: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                },
                di: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                }
            }

            const allSheet = {
                demand: [],
                ppi: [],
                rvi: [],
                di: [],
            }

            const allName = {
                demand: '',
                ppi: '',
                rvi: '',
                di: '',
            }

			const demandColumn = demandColumns(type);
			const { data, colsNumber, sheetName } = handleDemandData(demandColumn, type, demandTimeTab, explorIndexResult, false);
            allSheet.demand = data
			allOptions.demand['!merges'].push({
				s: { r: 0, c: colsNumber - 1 },
				e: { r: 0, c: colsNumber - 1 }
			})
            allName.demand = sheetName

			const ppiColumn = ppiColumns(type);
			const { data: ppiLineData, colsNumber: ppiColsNumber, sheetName: ppiName } = handlePPIData(ppiColumn, type, demandTimeTab, explorIndexResult, false);
            allSheet.ppi = ppiLineData
			allOptions.ppi['!merges'].push({
				s: { r: 0, c: ppiColsNumber - 1 },
				e: { r: 0, c: ppiColsNumber - 1 }
			})
            allName.ppi = ppiName

			const rviColumn = rviColumns(type);
			const { data: rviData, colsNumber: rviColsNumber, sheetName: rviName } = handleRVIData(rviColumn, type, demandTimeTab, explorIndexResult, false);
            allSheet.rvi = rviData
			allOptions.rvi['!merges'].push({
				s: { r: 0, c: rviColsNumber - 1 },
				e: { r: 0, c: rviColsNumber - 1 }
			})
            allName.rvi = rviName

			const diColumn = diColumns(type);
			const { data: diData, colsNumber: diColsNumber, sheetName: diName } = handleDIData(diColumn, type, demandTimeTab, explorIndexResult, false);
            allSheet.di = diData
			allOptions.di['!merges'].push({
				s: { r: 0, c: diColsNumber - 1 },
				e: { r: 0, c: diColsNumber - 1 }
			})
            allName.di = diName

			downloadXlsx2(allSheet, menuName, name, allOptions, allName);
			
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    handleChangeColor = (color, type) => {
        const c = this.state.colors;
        c[type] = color;

        this.setState({
            colors: c,
        });
	};
	
	handleSliderChange = (sliderArr, type) => {
		if (type === 'demand') {
			this.props.explorStore.changeDateRange1(sliderArr)
		} else if (type === 'ppi') {
			this.props.explorStore.changeDateRange2(sliderArr)
		} else if (type === 'resaleVolumeIndex') {
			this.props.explorStore.changeDateRange3(sliderArr)
		} else if (type === 'diversity') {
			this.props.explorStore.changeDateRange4(sliderArr)
		}
	}

	getDesc = (dateRange, franchiseOverviewData) => {
		const lastDate = dateRange[dateRange.length - 1]
		return handleLegend(franchiseOverviewData, 'groupName', lastDate);
	}

    handleGroupUp = (num) => {
        const { groupList } = this.state
        if (groupList.length === 2 && num === 2) {
            this.setState({
                currentGroupNum: 1,
                currentGroup: groupList[0]
            })
        }
    }

    handleGroupDown = (num) => {
        const { groupList } = this.state
        if (groupList.length === 2 && num === 1) {
            this.setState({
                currentGroupNum: 2,
                currentGroup: groupList[1]
            })
        }
    }

    handleGroupDownload = () => {
        this.setState({
            groupDownloadOpen: true
        })
    }

    handleGroupDownloadConfirm = async () => {
        await this.props.explorStore.explorGroupDownload()
        this.setState({
            groupDownloadOpen: false
        })
    }

    handleGroupCreate = () => {
        const { explorGroupData } = this.props.explorStore
        if (explorGroupData.length === 6) {
            this.setState({
                groupOpen: true
            })
        } else {
            this.setState({
                getStart: true
            })
        }
    }

    getGroupItemRequest = async({ groupId, search, page }) => {
        try {
            this.setState({
                searchLoading: true
            })
            const { data, page: pageInfo } = await this.props.explorStore.explorGroupSearch({
                groupId,
                search,
                page
            })
            this.setState({
                groupItemData: data,
                page: pageInfo,
                searchLoading: false
            })
        } catch (error) {
            this.setState({
                searchLoading: false
            })
        }
        
    }

    handleGroupItem = async (item, target) => {
        if (item) {
            this.setState({
                anchorEle: target,
            })
            await this.getGroupItemRequest({
                groupId: item.groupId,
                search: undefined,
                page: {
                    pageNum: 1,
                    pageSize: 10
                }
            })
            this.setState({
                currentGroupId: item.groupId
            })
        }
    }

    handleSearch = async (e) => {
        if (e.code === 'Enter') {
            const { currentGroupId, searchValue } = this.state
            await this.getGroupItemRequest({
                groupId: currentGroupId,
                search: searchValue || undefined,
                page: {
                    pageNum: 1,
                    pageSize: 10
                }
            })
        }
    }

    handleCheckBox = (item) => {
        const { checkObj } = this.state
        const newObj = {
            ...checkObj,
            [item.id]: checkObj.hasOwnProperty([item.id]) ? (checkObj[item.id] === 1 ? 2 : 1) : (item.selectFlag === 1 ? 2 : 1)
        }
        this.setState({
            checkObj: newObj
        })
    }

    handleCancel = () => {
        this.setState({
            checkObj: {},
            anchorEle: null
        })
    }

    handleGroupConfirm = async () => {
        const { currentGroupId, checkObj } = this.state
        const arr = Object.entries(checkObj).map(([id, selectFlag]) => ({
            id,
            selectFlag
        }))
        await this.props.explorStore.updateSkuSelectRequest({
            groupId: currentGroupId,
            selectedList: arr
        })
        this.handleCancel()
        await this.getAllRequest()
    }

    handlePre = async () => {
        const { page, currentGroupId, searchValue } = this.state
        if (page.pageNum > 1) {
            const newPage = {
                pageNum: page.pageNum - 1,
                pageSize: page.pageSize
            }
            await this.getGroupItemRequest({
                groupId: currentGroupId,
                search: searchValue || undefined,
                page: newPage
            })
            this.setState({
                page: newPage,
            })
        }
    }

    handleNext = async () => {
        const { page, groupItemData, currentGroupId, searchValue } = this.state
        if (page.pageNum < Math.ceil(groupItemData.totalCount / 10)) {
            const newPage = {
                pageNum: page.pageNum + 1,
                pageSize: page.pageSize
            }
            await this.getGroupItemRequest({
                groupId: currentGroupId,
                search: searchValue || undefined,
                page: newPage
            })
            this.setState({
                page: newPage,
            })
        }
    }

    handleRemove = async (e, item) => {
        e.stopPropagation()
        try {
            this.setState({
                showLoading: true
            })
            await this.props.explorStore.removeGroupRequest({
                groupId: item.groupId
            })
            await this.getAllRequest()
        } catch (error) {
            this.setState({
                showLoading: false
            })
        }
    }

    handleStart = () => {
        this.setState({
            getStart: true
        })
    }

    onCancel = () => {
        this.setState({
            getStart: false
        })
    }

    onConfirm = async () => {
        this.setState({
            getStart: false
        })
        await this.getAllRequest()
    }

    handleSearchValueChange = (value) => {
        this.setState({
            searchValue: value
        })
    }

    render() {
        const { demandTimeTab, colors, startDate, endDate, type, originalDate, getStart, currentGroupNum, currentGroup, groupList, groupOpen, anchorEle, groupItemData, checkObj, page, groupDownloadOpen, showLoading, searchValue, showStart, searchLoading } = this.state;
        const { isDialogOpen, timeRangeMonth, timeRangeWeek } = this.props.commonStore;
        const { explorIndexResult, explorResultData, explorGroupData } = this.props.explorStore;
        const loadingUrl = process.env.PUBLIC_URL + '/static/publicResource/img/loading.gif';

        return (
            <div className="flex-1 flex flex-column hidden p20 franchise-bg relative" ref={(_f) => (this.ele = _f)}>
                {
                    showLoading && (
                        <div className='absolute flex align-center justify-center' style={{width: '100%', height: '100%', zIndex: 999}}>
                            <div style={{width: '110px', height: '110px', borderRadius: '8px', overflow: 'hidden'}}>
                                <img src={loadingUrl} alt="" />
                            </div>
                        </div>
                    )
                }
                <div className="flex align-center title-box">
					<div className="explor-title"></div>
				</div>
                <div className="flex mb14">
                    <div className="flex-1 mr18 hidden">
                        <div className="select-item-box p8 flex border-box full-width">
                            <div className="franchise-select-box border-box flex align-center justify-center mr20">
                                <div className='icon-explor-select hover' onClick={() => this.handleGroupCreate()}></div>
                            </div>
                            <div className="flex-1 flex hidden">
                                <div className='flex-1 flex'>
                                    {
                                        currentGroup.map((item, index) => (
                                            <div className={cx('flex-1 mr10 flex align-center hidden', {
                                                'explor-group-item': item
                                            })} key={index} onClick={(e) => this.handleGroupItem(item, e.currentTarget)}>
                                                {
                                                    item && (
                                                        <>
                                                            <div className='explor-group-delete hover' onClick={(e) => this.handleRemove(e, item)}></div>
                                                            <div className='flex-1 explor-group-name hover'>{item.groupName}</div>
                                                            <div className='explor-group-item-arrow hover'></div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                    <StyledMenu anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={() => this.setState({ anchorEle: null })}>
                                        <div className="py10 px20 drop-content flex flex-column">
                                            <div className="pt10">
                                                <StyledTextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Search fontSize="small" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth={true}
                                                    value={searchValue}
                                                    onChange={(e) => this.handleSearchValueChange(e.target.value)}
                                                    onKeyDown={(e) => this.handleSearch(e)}
                                                    id="search"
                                                    type="search"
                                                    variant="outlined"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className='py14 mb10 relative' style={{borderBottom: '1px solid rgba(154, 157, 167, 0.5)', minHeight: '200px'}}>
                                                {
                                                    searchLoading && (
                                                        <div className='absolute flex align-center justify-center' style={{width: '100%', height: '100%', zIndex: 999}}>
                                                            <div style={{width: '110px', height: '110px', borderRadius: '8px', overflow: 'hidden'}}>
                                                                <img src={loadingUrl} alt="" />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                <div className='flex align-center'>
                                                    <div className='flex-1 explor-group-search-title'>Brand</div>
                                                    <div className='flex-1 explor-group-search-title'>Franchise</div>
                                                    <div className='flex-1 explor-group-search-title'>Model</div>
                                                    <div className='flex-1 explor-group-search-title'>SKU</div>
                                                    <div className='explor-group-search-select-box'></div>
                                                </div>
                                                {
                                                    groupItemData && groupItemData.attributeGroupDataList.map((item, i) => (
                                                        <div className='flex align-center' key={i}>
                                                            <div className='flex-1 explor-group-search-detail'>{item.brand}</div>
                                                            <div className='flex-1 explor-group-search-detail'>{item.franchise}</div>
                                                            <div className='flex-1 explor-group-search-detail'>{item.model}</div>
                                                            <div className='flex-1 explor-group-search-detail'>{item.productCode}</div>
                                                            <div className='explor-group-search-select-box'>
                                                                <div
                                                                    className={cx('icon-check-box hover', {
                                                                        'icon-check': checkObj[item.id] === 1 || (!checkObj[item.id] && item.selectFlag === 1),
                                                                        'icon-uncheck': checkObj[item.id] === 2 || (!checkObj[item.id] && item.selectFlag === 2),
                                                                    })}
                                                                    onClick={() => this.handleCheckBox(item)}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className='flex align-end'>
                                                <div className={cx('explor-page-button explor-page-button-left flex align-center justify-center', {
                                                    'explor-page-button-avalible hover': page.pageNum > 1
                                                })} onClick={() => this.handlePre()}>
                                                    <div className='explor-arrow-left'></div>
                                                </div>
                                                <div className={cx('explor-page-button explor-page-button-right flex align-center justify-center', {
                                                    'explor-page-button-avalible hover': groupItemData && page.pageNum < Math.ceil(groupItemData.totalCount / 10)
                                                })} onClick={() => this.handleNext()}>
                                                    <div className='explor-arrow-right'></div>
                                                </div>
                                                <div className='explor-page-info'><span>{page.pageNum} / </span>{groupItemData && Math.ceil(groupItemData.totalCount / 10)}</div>
                                                <div className='flex-1'></div>
                                                <div className='explor-page-info'>Selected <span>{groupItemData && groupItemData.totalSelectedCount}</span></div>
                                                <div className='explor-pop-button flex align-center justify-center hover' onClick={() => this.handleCancel()}>Cancel</div>
                                                <div className='explor-pop-button flex align-center justify-center hover' onClick={() => this.handleGroupConfirm()}>Confirm</div>
                                            </div>
                                        </div>
                                    </StyledMenu>
                                </div>
                                <div className='flex flex-column explor-arrow-box align-center'>
                                    <div className={cx('flex-1 explor-arrow explor-arrow-up', {
                                        hover: groupList.length === 2 && currentGroupNum === 2,
                                        'explor-arrow-up-active': groupList.length === 2 && currentGroupNum === 2
                                    })} onClick={() => this.handleGroupUp(currentGroupNum)}></div>
                                    <div className={cx('flex-1 explor-arrow explor-arrow-down', {
                                        hover: groupList.length === 2 && currentGroupNum === 1,
                                        'explor-arrow-down-active': groupList.length === 2 && currentGroupNum === 1
                                    })} onClick={() => this.handleGroupDown(currentGroupNum)}></div>
                                </div>
                                <div className='explor-download flex align-center justify-center hover' onClick={() => this.handleGroupDownload()}>
                                    <div className='icon-explor-download'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex align-center border-box hidden">
                        <div
                            className={cx('flex-1 select-item-box flex align-center px20 border-box', {
                                mr18: type === 'MONTH',
                            })}
                        >
                            <div className="icon-calendar brand-calendar-item mr40"></div>
                            <DatePicker isCenter={true} onConfirm={this.handleConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                        </div>
                        {(type === 'MONTH' && (
                            <div className="select-item-box flex align-center p8 border-box">
                                {timeTabList.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={cx('brand-analysis-time border-box hover flex align-center justify-center', {
                                            'brand-analysis-time-active': demandTimeTab === tab.alias,
                                        })}
                                        onClick={() => this.handleDemandTimeTab(tab.alias)}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </div>
                        )) ||
                            null}
                    </div>
                </div>
                {
                    showStart && explorGroupData.length === 0 && !getStart && (
                        <div className='flex-1 explor-start flex flex-column align-center justify-center'>
                            <div className='explor-start-img'></div>
                            <div className='explor-start-title'>Attribute Exploration</div>
                            <div className='explor-start-desc'>This page enables user to explore attributes by defining filtrations and keywords in product name.</div>
                            <div className='explor-start-button flex align-center justify-center hover' onClick={() => this.handleStart()}>Get started</div>
                        </div>
                    )
                }

                {
                    getStart && (
                        <div className='flex-1 explor-start flex flex-column align-center justify-center'>
                            <ExplorAttribute cancel={this.onCancel} confirm={this.onConfirm}></ExplorAttribute>
                        </div>
                    )
                }
                
                {
                    !getStart && explorGroupData.length !== 0 && explorIndexResult.map((group, k) => (
                        <div
                            key={k}
                            className={cx('flex-1 flex', {
                                mb20: k !== explorIndexResult.length - 1,
                            })}
                        >
                            {group.map((data, index) => {
                                const VisvalComponent = Visualization.get('line_chart');
                                return (
                                    <div
                                        className={cx('flex-1 card-box flex flex-column', {
                                            mr18: index !== group.length - 1,
                                        })}
                                        key={`${k}-${index}`}
                                    >
                                        <div className="flex align-center brand-chart-title-box">
                                            <div>{data.title}</div>
                                        </div>
                                        <div className="visualization flex-1 p10 flex flex-column">
                                            <div className="flex-1">
                                                {VisvalComponent && <VisvalComponent type={2} data={data.data} changeColor={(color) => this.handleChangeColor(color, data.title)} colors={colors[data.title]} />}
                                            </div>
                                            <Slider range={data.dateRange} onChange={(value, sliderArr) => this.handleSliderChange(sliderArr, data.value)} />
                                        </div>
                                        <div className="info-box flex flex-column justify-between">
                                            {this.getDesc(data.currentDateRange, explorResultData).map((group, m) => (
                                                <div className="flex-1 flex align-center" key={`${k}-${index}-${m}`}>
                                                    {group.map((brand, i) => {
                                                        return brand.key ? (
                                                            
                                                            <div
                                                                style={{ width: '33%' }}
                                                                className={cx('flex full-height px5', {
                                                                    'info-item-border': i !== group.length - 1,
                                                                })}
                                                                key={`${index}-${m}-${i}`}
                                                            >
                                                                <CommonTootip
                                                                    placement="top"
                                                                    title={
                                                                        <div className="flex align-center">
                                                                            <div
                                                                                className="dot"
                                                                                style={{
                                                                                    background: colors[data.title][brand.key],
                                                                                }}
                                                                            />
                                                                            <div className="ml4 info-item-name-tooltip">{brand.key}</div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className={cx('border-box flex align-center hover flex-1 hidden')}>
                                                                        <div
                                                                            className="dot"
                                                                            style={{
                                                                                background: colors[data.title][brand.key],
                                                                            }}
                                                                        />
                                                                        <div className="ml4 info-item-name flex-1">{brand.key}</div>
                                                                    </div>
                                                                </CommonTootip>
                                                                <div className="flex align-center">
                                                                    <div className="mr6 info-item-value">{formatM(brand.list[0][data.value])}</div>
                                                                    {(brand.list[0][data.yoy] && (
                                                                        <div className="flex align-center mt4">
                                                                            {brand.list[0][data.yoy].indexOf('-') !== -1 ? (
                                                                                <ArrowDownward
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        color: '#FF5F60',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <ArrowUpward
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        color: '#4D9B4D',
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            <div
                                                                                className={cx('brand-percent', {
                                                                                    'up-percent': brand.list[0][data.yoy].indexOf('-') === -1,
                                                                                    'down-percent': brand.list[0][data.yoy].indexOf('-') !== -1,
                                                                                })}
                                                                            >
                                                                                {formatPercent(brand.list[0][data.yoy].replace('-', '')) || 0}
                                                                            </div>
                                                                        </div>
                                                                    )) ||
                                                                        null}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={cx('flex-1 flex full-height justify-between px5')}
                                                                key={`${index}-${m}-${i}`}
                                                            ></div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))
                }
                <StyledDialog open={groupOpen}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-caution-icon mb10"></div>
                        <div className="dialog-content-title mb6">CAUTION</div>
                        <div className="dialog-content-desc text-center mb16">No more than 6 groups. Please remove one group first.</div>

                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => this.setState({ groupOpen: false })}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </StyledDialog>
                <StyledDialog open={groupDownloadOpen}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-generate-icon mb10"></div>
                        <div className="dialog-content-title mb6">Downloading Group Definition</div>
                        <div className="dialog-content-desc text-center mb16">It takes 10-20 minutes. Please see the Download Data Page of Data Management for the progress of extracting group definition.</div>

                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => this.handleGroupDownloadConfirm()}>
                                OK
                            </div>
                        </div>
                    </div>
                </StyledDialog>
                
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
                {/* <Modal
                    open={false}
                    BackdropProps= {{
                        style: {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <div></div>
                </Modal> */}
            </div>
        );
    }
}
