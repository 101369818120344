import { handleData, headerCol } from './index';

export const demandColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'name'
		},
		{
			name: 'franchise',
			value: 'franchise'
		},
		{
			name: 'gender',
			value: 'gender'
		},
		{
			name: 'ppi_tier',
			value: 'ppiTier'
		},
		{
			name: 'demand',
			value: 'value'
		},
		{
			name: 'demand_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const ppiColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'name'
		},
		{
			name: 'franchise',
			value: 'franchise'
		},
		{
			name: 'gender',
			value: 'gender'
		},
		{
			name: 'ppi',
			value: 'value'
		},
		{
			name: 'ppi_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const rviColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'name'
		},
		{
			name: 'franchise',
			value: 'franchise'
		},
		{
			name: 'gender',
			value: 'gender'
		},
		{
			name: 'resale_volume_index',
			value: 'value'
		},
		{
			name: 'resale_volume_index_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const diColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'brand',
			value: 'name'
		},
		{
			name: 'franchise',
			value: 'franchise'
		},
		{
			name: 'gender',
			value: 'gender'
		},
		{
			name: 'diversity',
			value: 'value'
		},
		{
			name: 'diversity_yoy',
			value: 'yoy'
		}
	]
	return arr
}

export const handleDemandData = (columns, type, demandTimeTab, data, typeTab) => {
	const filterArr = ['period_type', 'demand_yoy']
	const options = {
		filterColumn: (column) => {
			if (column.name === 'franchise' && typeTab === 'BRAND') {
				return false
			}
			return true
		}
	}
	const header = headerCol(columns, type, filterArr, demandTimeTab, options);
	const title = []
	const headerData = header.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'DEMAND BY PPI'
	const arr = [];
	arr.push(title)
	arr.push(headerData)

	const lineData = data[0][0];
	lineData.data.forEach(list => {
		list.data.forEach(item => {
			if (item.value) {
				const itemArr = [];
				header.forEach(head => {
					if (head.name === 'period_type') {
						itemArr.push(demandTimeTab === 'M12' ? '12M': demandTimeTab)
					} else if (head.name === 'ppi_tier') {
						itemArr.push(item[head.value] ? item[head.value] : 'TOTAL')
					} else {
						itemArr.push(item[head.value] || '')
					}
				})
				arr.push(itemArr)
			}
		})
	})

	return {
		data: arr,
		colsNumber: header.length,
		rowsNumber: arr.length
	}
}

export const handlePPILineData = (columns, type, demandTimeTab, data, typeTab) => {
	const filterArr = ['period_type', 'ppi_yoy']
	const lineData = data[0][1];
	const options = {
		filterColumn: (column) => {
			if (column.name === 'franchise' && typeTab === 'BRAND') {
				return false
			}
			return true
		}
	}

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'PREMIUM PRICE INDEX', options)
}

export const handleRVIData = (columns, type, demandTimeTab, data, typeTab) => {
	const filterArr = ['period_type', 'resale_volume_index_yoy']
	const lineData = data[1][0];
	const options = {
		filterColumn: (column) => {
			if (column.name === 'franchise' && typeTab === 'BRAND') {
				return false
			}
			return true
		}
	}

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'RESALE VOLUME INDEX', options)
}

export const handleDIData = (columns, type, demandTimeTab, data, typeTab) => {
	const filterArr = ['period_type', 'diversity_yoy']
	const lineData = data[1][1];
	const options = {
		filterColumn: (column) => {
			if (column.name === 'franchise' && typeTab === 'BRAND') {
				return false
			}
			return true
		}
	}

	return handleData(columns, type, demandTimeTab, lineData.data, filterArr, 'DIVERSITY INDEX', options)
}