import * as React from 'react';
import ChartRender from './ChartRender';
import { bubbleRender } from './chartRender/bubbleRender';

export default class BubbleChart extends React.Component {
    static indentify = 'bubble_chart';
    render() {
        return (
            <div className="full-height full-width">
                <ChartRender chartRender={bubbleRender} {...this.props} />
            </div>
        );
    }
}
