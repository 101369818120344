import * as React from 'react';
import ChartRender from './ChartRender';
import { barRender, barRender2 } from './chartRender/barRender';

export default class BarChart extends React.Component {
    static indentify = 'bar_chart';
    render() {
        return (
            <div className="full-height full-width">
                <ChartRender chartRender={this.props.barType === 2 ? barRender2 : barRender} {...this.props} />
            </div>
        );
    }
}
