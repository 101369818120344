import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    props: {
        MuiButtonBase: {
            // The properties to apply
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
        MuiButton: {
            disableElevation: true,
        },
    },
});
