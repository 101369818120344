import * as React from 'react';
import { TableRow, Button } from '@material-ui/core';
import TableWrapper from '../components/TableWrapper';
import StyledTableCell from '../components/StyledTableCell';
import StyledTableRow from '../components/StyledTableRow';
import StyledSwitch from '../components/StyledSwitch';
import cx from 'classnames';

const UserTable = ({ columns, data, total, changePage, isLoading, onOperator, current }) => {
    const [values, setValues] = React.useState({});

    const renderHeader = () => {
        return (
            <TableRow>
                {columns.map((column) => (
                    <StyledTableCell
                        key={column.name}
                        align={column.align}
                        style={{
                            minWidth: column.minWidth || column.width,
                            maxWidth: column.maxWidth || column.width,
                            position: column.position,
                            left: column.left,
                            right: column.right,
                            width: column.width,
                        }}
                    >
                        {column.label}
                    </StyledTableCell>
                ))}
            </TableRow>
        );
    };

    const handleTd = (row, column) => {
        const item = row[column.name];
        if (column.name === 'lockFlag') {
            return item === 1 ? 'yes' : 'no';
        } else if (column.name === 'firstLoginFlag') {
            return item === 1 ? 'yes' : 'no';
        } else if (column.name === 'enableFlag') {
            const status = values[row.userId];
            return (
                <div
                    className={cx({
                        'pointer-disable': row.userType === 1,
                    })}
                >
                    <StyledSwitch
                        disabled={row.userType === 1}
                        name="user-table"
                        checked={Boolean(status ? (status === 1 ? true : false) : row.enableFlag === 1)}
                        onChange={(event) => {
                            setValues({
                                ...values,
                                [row.userId]: event.target.checked ? 1 : 2,
                            });
                            if (onOperator)
                                onOperator(
                                    {
                                        type: 'switch',
                                        data: row,
                                        options: event.target.checked,
                                    },
                                    () => {
                                        // callback
                                        // change fail
                                        setValues({
                                            ...values,
                                            [row.userId]: event.target.checked ? 2 : 1,
                                        });
                                    }
                                );
                        }}
                    />
                </div>
            );
        } else if (column.name === 'reset') {
            return (
                <div className="flex align-center justify-center">
                    <Button
                        variant="outlined"
                        style={{ color: '#fff', border: '1px solid #fff' }}
                        size="small"
                        onClick={() =>
                            onOperator &&
                            onOperator({
                                type: 'reset',
                                data: row,
                            })
                        }
                    >
                        reset
                    </Button>
                </div>
            );
        } else if (column.name === 'operator') {
            return (
                <div className="flex align-center justify-between">
                    <Button
                        variant="outlined"
                        style={{ color: '#fff', border: '1px solid #fff' }}
                        size="small"
                        onClick={() =>
                            onOperator &&
                            onOperator({
                                type: 'edit',
                                data: row,
                            })
                        }
                    >
                        edit
                    </Button>

                    <div
                        className={cx({
                            'pointer-disable': row.userType === 1 || (values[row.userId] ? values[row.userId] === 1 : row.enableFlag === 1),
                        })}
                    >
                        <Button
                            disabled={row.userType === 1 || (values[row.userId] ? values[row.userId] === 1 : row.enableFlag === 1)}
                            variant="outlined"
                            style={{ color: '#fff', border: '1px solid #fff' }}
                            size="small"
                            onClick={() =>
                                onOperator &&
                                onOperator({
                                    type: 'delete',
                                    data: row,
                                })
                            }
                        >
                            delete
                        </Button>
                    </div>
                </div>
            );
        } else {
            return item || '--';
        }
    };

    const renderBody = () => {
        return (
            <React.Fragment>
                {data.map((row, index) => (
                    <StyledTableRow key={index}>
                        {columns.map((column, i) => {
                            return (
                                <StyledTableCell
                                    key={`${index}_${i}`}
                                    style={{
                                        wordBreak: 'break-all',
                                        position: column.position,
                                        left: column.left,
                                        right: column.right,
                                    }}
                                >
                                    {handleTd(row, column)}
                                </StyledTableCell>
                            );
                        })}
                    </StyledTableRow>
                ))}
            </React.Fragment>
        );
    };

    return <TableWrapper headerCellRender={() => renderHeader()} bodyCellRender={() => renderBody()} total={total} changePage={changePage} isLoading={isLoading} current={current} />;
};

export default UserTable;
