import { withStyles, createStyles } from '@material-ui/core/styles';
import { TableRow } from '@material-ui/core';

const StyledTableRow = withStyles((theme) =>
    createStyles({
        root: {
            '&:hover': {
                // backgroundColor: 'rgba(255, 255, 255, 0.06)',
            },
            '&:nth-of-type(even)': {
                backgroundColor: 'rgba(255, 255, 255, 0.06);',
            },
        },
    })
)(TableRow);

export default StyledTableRow;
