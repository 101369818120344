import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import _ from 'underscore'

import { Visualization } from '../visualization/index';
import SkuDropBox from '../components/SkuDropBox';
import StyledCheckBox from '../components/StyledCheckBox';
import DatePicker from '../components/DatePicker';
import CommonDialog from '../components/CommonDownloadDialog';
import Slider from '../components/Slider'

import { downloadPDF, transformDate, handleDateStartEnd, groupedByNumber, formatM, formatPercent, handleLegend, downloadXlsx, getColsWpx } from '../../utils/index';
import {
	overviewDemandColumns,
	overviewPPIColumns,
	overviewSVColumns,
	handleOverviewDemandData,
	handleOverviewPPIData,
	handleOverviewSVData
} from '../../utils/skuAnalysisDownload';
import '../../assets/css/sku.css';
import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 220,
        fontSize: '12px',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '10px',
        padding: '10px',
    },
}))(Tooltip);

const timeTabList = [
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: '12M',
        alias: 'M12',
    },
];

@inject('commonStore', 'skuStore', 'userStore')
@observer
export default class SkuOverview extends React.Component {
    state = {
        demandTimeTab: 'MONTH',
        selectedList: [],
        selectGroupList: [],
        startDate: null,
        endDate: null,
        type: 'MONTH',
        originalDate: {},
        data: [],
        brandList: [],
        franchiseList: [],
        skuList: [],
        searchList: [],
        colors: {
            salesVolume: {},
            ppi: {},
            demand: {},
        },
    };
    async componentWillMount() {
        const { timeRangeMonth } = await this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 12);
        this.setState({
            startDate,
            endDate,
            originalDate,
        });
        const response = await this.props.commonStore.skuDictOverviewDefaultRequest();
        if (response.code === '00000') {
            const groupByList = groupedByNumber(3, response.data, '');
            this.setState({
                selectedList: response.data,
                selectGroupList: groupByList,
            });
            const idList = response.data.map((f) => f.diaSkuId);
            const { type, demandTimeTab } = this.state;
            await this.getRequest(type, startDate, endDate, demandTimeTab, idList);
        }
        const brand = await this.props.commonStore.skuDictOverviewRequest();
        if (brand.code === '00000') {
            this.setState({
                brandList: brand.data,
            });
        }
    }

    getRequest = async (type, startDate, endDate, demandTimeTab, idList) => {
        await this.props.skuStore.skuAnalysisOverviewRequest({
            dateType: type,
            startDate,
            endDate,
            dataType: demandTimeTab,
            diaSkuIdList: idList,
        });
    };

    handleRemoveSelected = async (item) => {
        const { selectedList, type, startDate, endDate, demandTimeTab } = this.state;
        const newList = selectedList.filter((f) => f.diaSkuId !== item.diaSkuId);
        const newGroup = groupedByNumber(3, newList, '');
        this.setState({
            selectedList: newList,
            selectGroupList: newGroup,
        });
        const idList = newList.map((f) => f.diaSkuId);
        await this.getRequest(type, startDate, endDate, demandTimeTab, idList);
    };

    handleSelectedConfirm = async (checkedList) => {
        const skuId = checkedList.map(item => item.diaSkuId)
        const data = await this.props.commonStore.skuDictImgUrlRequest({
            diaSkuIds: skuId
        })
        const checkedArr = checkedList.map(item => {
            const obj = _.findWhere(data.data, { diaSkuId: item.diaSkuId })
            if (obj) {
                return {
                    ...item,
                    skuImageUrl: obj.accessUrl
                }
            }
            return item
        })
        const groupByList = groupedByNumber(3, checkedArr, '');
        this.setState({
            selectedList: checkedArr,
            selectGroupList: groupByList,
        });

        const idList = checkedList.map((f) => f.diaSkuId);
        const { type, demandTimeTab, startDate, endDate } = this.state;
        await this.getRequest(type, startDate, endDate, demandTimeTab, idList);
    };

    handleConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        if (start && end) {
            this.setState({
                startDate: start,
                endDate: end,
                type,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            const { demandTimeTab, selectedList } = this.state;
            const idList = selectedList.map((f) => f.diaSkuId);
            await this.getRequest(type, start, end, demandTimeTab, idList);
        }
    };

    handleDemandTimeTab = async (tab) => {
        this.setState({
            demandTimeTab: tab,
        });
        const { type, startDate, endDate, selectedList } = this.state;
        const idList = selectedList.map((f) => f.diaSkuId);
        await this.getRequest(type, startDate, endDate, tab, idList);
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = encodeURI(`${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`)
        if (type === 'pdf') {
            this.ele &&
                downloadPDF(
                    this.ele,
                    () => {
                        console.log('download success');
						this.props.commonStore.setDownloadDialog(false);
						this.props.commonStore.setMenuName('');
                    },
                    () => {
                        console.log('download fail');
					},
					name
                );
        } else if (type === 'xlsx') {
			const { skuOverviewResult } = this.props.skuStore;
			const { demandTimeTab, type } = this.state;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(8)
			}
			let rowsNum = 0
			let sheetData = []

			const demandColumn = overviewDemandColumns(type);
			const { data, colsNumber, rowsNumber } = handleOverviewDemandData(demandColumn, type, demandTimeTab, skuOverviewResult);
			sheetData = [...sheetData, ...data];
			options['!merges'].push({
				s: { r: 0, c: 0 },
				e: { r: 0, c: colsNumber - 1 }
			})
			rowsNum = rowsNumber + rowsNum;

			const ppiColumn = overviewPPIColumns(type);
			const { data: ppiLineData, colsNumber: ppiColsNumber, rowsNumber: ppiRowsNumber } = handleOverviewPPIData(ppiColumn, type, demandTimeTab, skuOverviewResult);
			sheetData = [...sheetData, ...ppiLineData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: ppiColsNumber - 1 }
			})
			rowsNum = ppiRowsNumber + rowsNum;

			const svColumn = overviewSVColumns(type);
			const { data: svData, colsNumber: svColsNumber, rowsNumber: svRowsNumber } = handleOverviewSVData(svColumn, type, demandTimeTab, skuOverviewResult);
			sheetData = [...sheetData, ...svData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: svColsNumber - 1 }
			})
			rowsNum = svRowsNumber + rowsNum;

			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    handleSearch = async (value) => {
        const response = await this.props.commonStore.skuDictOverviewRequest({ productCode: value });
        if (response.code === '00000') {
            this.setState({
                searchList: response.data,
            });
        }
    };

    handleChangeBrand = async (brand) => {
        const franchise = await this.props.commonStore.skuDictOverviewRequest({
            brand: brand.brand,
        });
        if (franchise.code === '00000') {
            this.setState({
                franchiseList: franchise.data.sort((a, b) => a.franchise.localeCompare(b.franchise)),
            });
        }
    };

    handleChangeFranchise = async (item) => {
        const sku = await this.props.commonStore.skuDictOverviewRequest({
            brand: item.brand,
            franchise: item.franchise,
        });
        if (sku.code === '00000') {
            this.setState({
                skuList: sku.data.sort((a, b) => a.productCode.localeCompare(b.productCode)),
            });
        }
    };

    handleChangeColor = (color, type) => {
        const c = this.state.colors;
        c[type] = color;

        this.setState({
            colors: c,
        });
	};
	
	handleSliderChange = (sliderArr, type) => {
		if (type === 'demand') {
			this.props.skuStore.changeDateRange1(sliderArr)
		} else if (type === 'ppi') {
			this.props.skuStore.changeDateRange2(sliderArr)
		} else if (type === 'salesVolume') {
			this.props.skuStore.changeDateRange3(sliderArr)
		}
	}

	getDesc = (dateRange, skuOverview) => {
		const lastDate = dateRange[dateRange.length - 1]
		return handleLegend(skuOverview, 'productCode', lastDate);
	}

    render() {
        const { demandTimeTab, selectedList, selectGroupList, startDate, endDate, type, originalDate, brandList, franchiseList, skuList, searchList, colors } = this.state;
        const { isDialogOpen, timeRangeMonth, timeRangeWeek } = this.props.commonStore;
		const { skuOverviewResult, skuOverview } = this.props.skuStore;

        const url = process.env.PUBLIC_URL + '/static/publicResource/img/sku_default_img@2x.png';
        return (
            <div className="flex-1 flex flex-column hidden p20 sku-bg" ref={(_f) => (this.ele = _f)}>
                <div className="flex align-center title-box">
					<div className="sku-overview-title"></div>
				</div>
                <div className="flex-1 flex flex-column">
                    <div className="flex-1 flex mb20" style={{ maxHeight: '50%' }}>
                        <div className="flex-1 mr18 flex flex-column">
                            <div className="flex align-center border-box mb14">
                                <div
                                    className={cx('flex-1 select-item-box flex align-center px20 border-box', {
                                        mr18: type === 'MONTH',
                                    })}
                                >
                                    <div className="icon-calendar brand-calendar-item mr40"></div>
                                    <DatePicker isCenter={true} onConfirm={this.handleConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                                </div>
                                {type === 'MONTH' && (
                                    <div className="select-item-box flex align-center p8 border-box">
                                        {timeTabList.map((tab, index) => (
                                            <div
                                                key={index}
                                                className={cx('brand-analysis-time border-box hover flex align-center justify-center', {
                                                    'brand-analysis-time-active': demandTimeTab === tab.alias,
                                                })}
                                                onClick={() => this.handleDemandTimeTab(tab.alias)}
                                            >
                                                {tab.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 card-box flex flex-column">
                                <div className="sku-item-box p8 flex border-box">
                                    <div className="sku-select-box border-box flex align-center justify-center">
                                        <SkuDropBox title="Select SKU" searchList={searchList} brandList={brandList} onChangeBrand={this.handleChangeBrand} franchiseList={franchiseList} onChangeFranchise={this.handleChangeFranchise} skuList={skuList} onConfirm={this.handleSelectedConfirm} onSearch={this.handleSearch} currentList={selectedList}></SkuDropBox>
                                    </div>
                                </div>
                                <div className="px14 flex-1 overflow-y">
                                    {selectGroupList.map((group, index) => (
                                        <div
                                            key={index}
                                            className={cx('flex', {
                                                mb10: index !== selectGroupList.length - 1,
                                            })}
                                        >
                                            {group.map((item, i) => (
                                                <div
                                                    className={cx('flex-1 p10 relative', {
                                                        mr10: i !== group.length - 1,
                                                        'selected-item-wrapper': item,
                                                    })}
                                                    key={`${index}-${i}`}
                                                >
                                                    {item && (
                                                        <React.Fragment>
                                                            <div className="flex align-center justify-between">
                                                                <div className="sku-name mb6 flex align-center">
                                                                    <div className="sku-dot mr4" style={{ background: colors.demand[item.productCode] || '#fff' }}></div>
                                                                    <div className="flex-1">{item.productCode || ''}</div>
                                                                </div>
                                                                <div className="mr4" style={{ marginTop: '-6px' }}>
                                                                    <StyledCheckBox checked={true} indeterminate={true} onChange={() => this.handleRemoveSelected(item)} />
                                                                </div>
                                                            </div>
                                                            <div className="sku-description">{item.productName}</div>
                                                            <div className="sku-img-wrapper relative"></div>
                                                            <div className="absolute sku-img-wrapper" style={{ right: '10px', bottom: '10px' }}>
                                                                <img
                                                                    src={item.skuImageUrl ? item.skuImageUrl : url}
                                                                    alt="sku-img"
                                                                    style={{
                                                                        height: '100%',
                                                                    }}
                                                                />
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {skuOverviewResult.demand.map((data, index) => {
                            const VisvalComponent = Visualization.get('line_chart');
                            return (
                                <div
                                    className={cx('flex-1 card-box flex flex-column', {
                                        mr18: index !== skuOverviewResult.demand.length - 1,
                                    })}
                                    key={index}
                                >
                                    <div className="full-width full-height flex flex-column border-box">
                                        <div className="flex align-center brand-chart-title-box">
                                            <div>{data.title}</div>
                                        </div>
                                        <div className="visualization flex-1 p10 flex flex-column">
											<div className="flex-1">
												{VisvalComponent && <VisvalComponent type={2} data={data.data} changeColor={(color) => this.handleChangeColor(color, data.value)} colors={colors[data.value]} />}
											</div>
											<Slider range={data.dateRange} onChange={(value, sliderArr) => this.handleSliderChange(sliderArr, data.value)} />
										</div>
                                        <div className="info-box flex flex-column justify-between">
                                            {this.getDesc(data.currentDateRange, skuOverview).map((group, m) => (
                                                <div key={m} className="flex-1 flex align-center">
                                                    {group.map((desc, k) => {
                                                        return desc.key ? (
                                                            <div
																style={{ width: '33%' }}
                                                                className={cx('flex-1 flex full-height px5', {
                                                                    'info-item-border': k !== group.length - 1,
                                                                })}
                                                                key={`${index}-${m}-${k}`}
                                                            >
                                                                <StyledTooltip
                                                                    placement="top"
                                                                    title={
                                                                        <div style={{ width: '186px' }}>
                                                                            <div className="sku-tooltip-text mb4">{desc.list[0].brand}</div>
                                                                            <div className="sku-tooltip-text mb10">{desc.list[0].productName}</div>
                                                                            <div className="flex align-center">
                                                                                <div
                                                                                    className="dot"
                                                                                    style={{
                                                                                        background: colors[data.value][desc.key],
                                                                                    }}
                                                                                />
                                                                                <div className="ml4 info-item-name-tooltip">{desc.list[0].productCode}</div>
                                                                            </div>
                                                                            <div className="sku-img-wrapper relative">
                                                                                <img
                                                                                    src={desc.list[0].skuImageUrl || url}
                                                                                    className="absolute"
                                                                                    alt="sku-img"
                                                                                    style={{
                                                                                        // width: '100%',
                                                                                        height: '72px',
                                                                                        right: 0,
                                                                                        bottom: 0,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className={cx('border-box flex align-center hover flex-1 hidden')}>
                                                                        <div
                                                                            className="dot"
                                                                            style={{
                                                                                background: colors[data.value][desc.key],
                                                                            }}
                                                                        />
                                                                        <div className="ml4 info-item-name">{desc.key}</div>
                                                                    </div>
                                                                </StyledTooltip>
                                                                <div className="flex align-center">
                                                                    <div className="mr6 info-item-value">{formatM(desc.list[0][data.value], 'M')}</div>
                                                                    {(desc.list[0][data.yoy] && (
                                                                        <div className="flex align-center mt4">
                                                                            {desc.list[0][data.yoy].indexOf('-') !== -1 ? (
                                                                                <ArrowDownward
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        color: '#FF5F60',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <ArrowUpward
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        color: '#4D9B4D',
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            <div
                                                                                className={cx('brand-percent', {
                                                                                    'up-percent': desc.list[0][data.yoy].indexOf('-') === -1,
                                                                                    'down-percent': desc.list[0][data.yoy].indexOf('-') !== -1,
                                                                                })}
                                                                            >
                                                                                {formatPercent(desc.list[0][data.yoy].replace('-', '')) || 0}
                                                                            </div>
                                                                        </div>
                                                                    )) ||
                                                                        null}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={cx('flex-1 flex full-height justify-between px5')}
                                                                key={`${index}-${m}-${k}`}
                                                            ></div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex-1 flex">
                        {skuOverviewResult.data.map((data, index) => {
                            const VisvalComponent = Visualization.get('line_chart');
                            return (
                                <div
                                    className={cx('flex-1 card-box flex flex-column', {
                                        mr18: index !== skuOverviewResult.data.length - 1,
                                    })}
                                    key={index}
                                >
                                    <div className="full-width full-height flex flex-column border-box">
                                        <div className="flex align-center brand-chart-title-box">
                                            <div>{data.title}</div>
                                        </div>
                                        <div className="visualization flex-1 p10 flex flex-column">
											<div className="flex-1">
												{VisvalComponent && <VisvalComponent type={2} data={data.data} changeColor={(color) => this.handleChangeColor(color, data.value)} colors={colors[data.value]} />}
											</div>
											<Slider range={data.dateRange} onChange={(value, sliderArr) => this.handleSliderChange(sliderArr, data.value)} />
										</div>
										<div className="info-box flex flex-column justify-between">
                                            {this.getDesc(data.currentDateRange, skuOverview).map((group, m) => (
                                                <div key={m} className="flex-1 flex align-center">
                                                    {group.map((desc, k) => {
                                                        return desc.key ? (
                                                            <div
																style={{ width: '33%' }}
                                                                className={cx('flex-1 flex full-height px5', {
                                                                    'info-item-border': k !== group.length - 1,
                                                                })}
                                                                key={`${index}-${m}-${k}`}
                                                            >
                                                                <StyledTooltip
                                                                    placement="top"
                                                                    title={
                                                                        <div style={{ width: '186px' }}>
                                                                            <div className="sku-tooltip-text mb4">{desc.list[0].brand}</div>
                                                                            <div className="sku-tooltip-text mb10">{desc.list[0].productName}</div>
                                                                            <div className="flex align-center">
                                                                                <div
                                                                                    className="dot"
                                                                                    style={{
                                                                                        background: colors[data.value][desc.key],
                                                                                    }}
                                                                                />
                                                                                <div className="ml4 info-item-name-tooltip">{desc.list[0].productCode}</div>
                                                                            </div>
                                                                            <div className="sku-img-wrapper relative">
                                                                                <img
                                                                                    src={desc.list[0].skuImageUrl || url}
                                                                                    className="absolute"
                                                                                    alt="sku-img"
                                                                                    style={{
                                                                                        // width: '100%',
                                                                                        height: '72px',
                                                                                        right: 0,
                                                                                        bottom: 0,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className={cx('border-box flex align-center hover flex-1 hidden')}>
                                                                        <div
                                                                            className="dot"
                                                                            style={{
                                                                                background: colors[data.value][desc.key],
                                                                            }}
                                                                        />
                                                                        <div className="ml4 info-item-name">{desc.key}</div>
                                                                    </div>
                                                                </StyledTooltip>
                                                                <div className="flex align-center">
                                                                    <div className="mr6 info-item-value">{formatM(desc.list[0][data.value])}</div>
                                                                    {(desc.list[0][data.yoy] && (
                                                                        <div className="flex align-center mt4">
                                                                            {desc.list[0][data.yoy].indexOf('-') !== -1 ? (
                                                                                <ArrowDownward
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        color: '#FF5F60',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <ArrowUpward
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        color: '#4D9B4D',
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            <div
                                                                                className={cx('brand-percent', {
                                                                                    'up-percent': desc.list[0][data.yoy].indexOf('-') === -1,
                                                                                    'down-percent': desc.list[0][data.yoy].indexOf('-') !== -1,
                                                                                })}
                                                                            >
                                                                                {formatPercent(desc.list[0][data.yoy].replace('-', '')) || 0}
                                                                            </div>
                                                                        </div>
                                                                    )) ||
                                                                        null}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={cx('flex-1 flex full-height justify-between px5')}
                                                                key={`${index}-${m}-${k}`}
                                                            ></div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
            </div>
        );
    }
}
