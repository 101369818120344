import {
    observable,
    action,
    computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';
import _ from 'underscore';
import { skuAnalysisOverviewRequest, skuAnalysisTopRequest, skuAnalysisLaunchRequest, skuAnalysisAttributeIndexRequest, skuAnalysisAttributeDemandRequest } from '../api/sku';
import { groupedByNumber } from '../utils/index';
import { completeMissingData } from './utils';

export class SkuStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
    }

    @observable
    skuOverview = [];

    @observable
    totalRankList = [];

    @observable
    femaleRankList = [];

    @observable
    maleRankList = [];

    @observable
    kidRankList = [];

    @observable
    skuLaunch = [];

    @observable
	dateArr = [];
	
	@observable
	dateRangeArr1 = [];

	@observable
	dateRangeArr2 = [];

	@observable
	dateRangeArr3 = [];

	@observable
	attributeIndexData = []

	@observable
	attributeDemandData = []

	@observable
	attributeIndexDateRange = []

	@observable
	attributeIndexDateRange1 = []

	@observable
	attributeIndexDateRange2 = []

	@observable
	attributeIndexDateRange3 = []

	@observable
	ppiInfoAttribute = {}

	@observable
	attributeDemandDateRange = []

	@observable
	attributeDemandDateRange1 = []

	@observable
	genderTab = ''

	@action
	changeDateRange1(dateRange) {
		this.dateRangeArr1 = dateRange
	}

	@action
	changeDateRange2(dateRange) {
		this.dateRangeArr2 = dateRange
	}

	@action
	changeDateRange3(dateRange) {
		this.dateRangeArr3 = dateRange
	}

    @computed
    get skuOverviewResult() {
        const demandTemp = [];
        const rviTemp = [];
        const svTemp = [];
        this.skuOverview.forEach((item) => {
            demandTemp.push({
                name: item.productCode,
                date: item.date,
                value: Number(item.demand),
                yoy: item.demandYoy,
                skuId: item.diaSkuId,
                productName: item.productName,
                skuImageUrl: item.skuImageUrl,
                origin: item,
            });
            rviTemp.push({
                name: item.productCode,
                date: item.date,
                value: Number(item.ppi),
                yoy: item.ppiYoy,
                skuId: item.diaSkuId,
                productName: item.productName,
                skuImageUrl: item.skuImageUrl,
                origin: item,
            });
            svTemp.push({
                name: item.productCode,
                date: item.date,
                value: Number(item.salesVolume),
                yoy: item.salesVolumeYoy,
                skuId: item.diaSkuId,
                productName: item.productName,
                skuImageUrl: item.skuImageUrl,
                origin: item,
            });
        });
        const skuDemand = Object.entries(_.groupBy(demandTemp, (d) => d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.dateRangeArr1, value),
        }));
        const skuRvi = Object.entries(_.groupBy(rviTemp, (d) => d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.dateRangeArr2, value),
        }));
        const skuSv = Object.entries(_.groupBy(svTemp, (d) => d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.dateRangeArr3, value),
        }));
        return {
            demand: [
                {
                    title: 'DEMAND',
                    data: skuDemand,
                    value: 'demand',
					yoy: 'demandYoy',
					dateRange: this.dateArr,
					currentDateRange: this.dateRangeArr1
                },
            ],
            data: [
                {
                    title: 'PREMIUM PRICE INDEX',
                    data: skuRvi,
                    value: 'ppi',
					yoy: 'ppiYoy',
					dateRange: this.dateArr,
					currentDateRange: this.dateRangeArr2
                },
                {
                    title: 'SALES VOLUME',
                    data: skuSv,
                    value: 'salesVolume',
					yoy: 'salesVolumeYoy',
					dateRange: this.dateArr,
					currentDateRange: this.dateRangeArr3
                },
            ],
        };
    }

    @action
    async skuAnalysisOverviewRequest({ dateType, startDate, endDate, dataType, diaSkuIdList }) {
        const response = await skuAnalysisOverviewRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            diaSkuIdList,
        });
        if (response.code === '00000') {
            this.skuOverview = response.data;
			this.dateArr = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr1 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr2 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr3 = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async skuAnalysisTopRequest({ dateType, startDate, endDate }) {
        const response = await skuAnalysisTopRequest({
            dateType,
            startDate,
            endDate,
        });
        const { code, data } = response;
        if (code === '00000') {
            this.totalRankList = groupedByNumber(4, data.totalRankList, '');
            this.femaleRankList = groupedByNumber(4, data.genderRankData.FEMALE || [], '') || [];
            this.maleRankList = groupedByNumber(4, data.genderRankData.MALE || [], '') || [];
            this.kidRankList = groupedByNumber(4, data.genderRankData.KIDS || [], '') || [];
        }
    }

    @action
    async skuAnalysisLaunchRequest({ productCodeList }) {
        const response = await skuAnalysisLaunchRequest({
            productCodeList,
        });
        const { code, data } = response;
        if (code === '00000') {
            const arr = data.map((item) => {
                return {
                    ...item,
                    date: item.launchDate,
                    // value: [Number(item.ppi), Number(item.unitSoldIndex)],
                    value: [Number(item.ppi) - 1, Number(item.unitSoldIndex) - Number(item.unitSoldIndexAvg)],
                };
            });
            const temp = _.groupBy(arr, (d) => d.productCode);
            this.skuLaunch = Object.entries(temp).map(([key, value]) => ({
                name: key,
                data: value,
            }));
        }
	}

	@action
	changeDemandDateRange(dateRange) {
		this.attributeDemandDateRange1 = dateRange
	}

	@action
	changeIndexDateRange1(dateRange) {
		this.attributeIndexDateRange1 = dateRange
	}

	@action
	changeIndexDateRange2(dateRange) {
		this.attributeIndexDateRange2 = dateRange
	}

	@action
	changeIndexDateRange3(dateRange) {
		this.attributeIndexDateRange3 = dateRange
	}
	
	@action
	async skuAnalysisAttributeDemandRequest({
		dateType,
		startDate,
		endDate,
		dataType,
		gender,
		ppiList,
		attributeList,
		ppiInfo,
		genderTab,
		subGenderList
	}) {
		const response = await skuAnalysisAttributeDemandRequest({
			dateType,
			startDate,
			endDate,
			dataType,
			gender,
			ppiList,
			attributeList,
			subGenderList
		})
		const { code, data } = response;
		if (code === '00000') {
			this.ppiInfoAttribute = ppiInfo;
			this.genderTab = genderTab;
			const allDate = []
			const arr = []
			data.forEach(item => {
				item.overviewSkuList.forEach(sku => {
					allDate.push(sku.date);
					arr.push({
						...sku,
						name: `Attribute ${item.attributeNo}`,
						num: item.attributeNo
					})
				})
			})
			this.attributeDemandData = arr;
			this.attributeDemandDateRange = Array.from(new Set(allDate)).sort()
			this.attributeDemandDateRange1 = Array.from(new Set(allDate)).sort()
		}
	}

	@action
	async skuAnalysisAttributeIndexRequest({
		dateType,
		startDate,
		endDate,
		dataType,
		gender,
		attributeList,
		genderTab
	}) {
		const response = await skuAnalysisAttributeIndexRequest({
			dateType,
			startDate,
			endDate,
			dataType,
			gender,
			attributeList
		})
		const { code, data } = response
		if (code === '00000') {
			this.genderTab = genderTab;
			const arr = [];
			const allDate = []
			data.forEach(item => {
				item.overviewSkuList.forEach(sku => {
					allDate.push(sku.date);
					arr.push({
						...sku,
						name: `Attribute ${item.attributeNo}`,
						num: item.attributeNo
					})
				})
			})
			this.attributeIndexData = arr;
			this.attributeIndexDateRange = Array.from(new Set(allDate)).sort()
			this.attributeIndexDateRange1 = Array.from(new Set(allDate)).sort()
			this.attributeIndexDateRange2 = Array.from(new Set(allDate)).sort()
			this.attributeIndexDateRange3 = Array.from(new Set(allDate)).sort()
		}
	}

	@computed
	get attributeResult() {
		const demandTemp = [];
		const ppiTemp = [];
        const rviTemp = [];
		const diTemp = [];
		this.attributeDemandData.forEach(item => {
			demandTemp.push({
				name: item.name,
				date: item.date,
				value: Number(item.demand),
				yoy: item.demandYoy,
				ppiTier: item.ppiTier,
				ppiTierDisplay: 'PPI' + item.ppiTier,
				num: item.num,
				gender: item.gender,
				origin: item,
			})
		})
		this.attributeIndexData.forEach(item => {
			ppiTemp.push({
				name: item.name,
				date: item.date,
				value: Number(item.ppi),
				yoy: item.ppiYoy,
				num: item.num,
				gender: item.gender,
				origin: item,
			});
			rviTemp.push({
				name: item.name,
				date: item.date,
				value: Number(item.resaleVolumeIndex),
				yoy: item.resaleVolumeIndexYoy,
				num: item.num,
				gender: item.gender,
				origin: item,
			});
			diTemp.push({
				name: item.name,
				date: item.date,
				value: Number(item.diversity),
				yoy: item.diversityYoy,
				num: item.num,
				gender: item.gender,
				origin: item,
			});
		})

		const typeMap = {
            attribute: 'name',
            ppi: 'ppiTier',
		};
		
		const genderMap = {
            attribute: 'gender',
            ppi: 'ppiTier',
        };
        const attributeDemand = Object.entries(_.groupBy(demandTemp, (d) => this.genderTab === 'ALL' ? d[genderMap[this.ppiInfoAttribute.type]]: d[typeMap[this.ppiInfoAttribute.type]]) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.attributeDemandDateRange1, value),
		}));
		const attributePPI = Object.entries(_.groupBy(ppiTemp, (d) => this.genderTab === 'ALL' ? d.gender: d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.attributeIndexDateRange1, value),
		}));
        const attributeRVI = Object.entries(_.groupBy(rviTemp, (d) => this.genderTab === 'ALL' ? d.gender: d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.attributeIndexDateRange2, value),
        }));
        const attributeDI = Object.entries(_.groupBy(diTemp, (d) => this.genderTab === 'ALL' ? d.gender: d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.attributeIndexDateRange3, value),
		}));

		return [
			[
                {
                    title: 'DEMAND BY PPI',
                    data: attributeDemand,
                    value: 'demand',
					yoy: 'demandYoy',
					dateRange: this.attributeDemandDateRange,
					currentDateRange: this.attributeDemandDateRange1
                },
                {
                    title: 'PREMIUM PRICE INDEX',
                    data: attributePPI,
                    value: 'ppi',
					yoy: 'ppiYoy',
					dateRange: this.attributeIndexDateRange,
					currentDateRange: this.attributeIndexDateRange1
                },
            ],
            [
                {
                    title: 'RESALE VOLUME INDEX',
                    data: attributeRVI,
                    value: 'resaleVolumeIndex',
					yoy: 'resaleVolumeIndexYoy',
					dateRange: this.attributeIndexDateRange,
					currentDateRange: this.attributeIndexDateRange2
                },
                {
                    title: 'DIVERSITY INDEX',
                    data: attributeDI,
                    value: 'diversity',
					yoy: 'diversityYoy',
					dateRange: this.attributeIndexDateRange,
					currentDateRange: this.attributeIndexDateRange3
                },
            ],
		]
	}
}
