import * as React from 'react';
import { Menu, TextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { withStyles, createStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import './select.css'

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => {
    return <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />;
});

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '3.5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

const CustomSelect = ({ placeholder, data, onConfirm = () => {}, currentList, currentModelList, disabled, error }) => {
	const [anchorEle, setAnchorEle] = React.useState(null);
	const [select, setSelect] = React.useState([])
	const [checked, setChecked] = React.useState([])
	const [list, setList] = React.useState([])
	const [value, setValue] = React.useState('')
	const [modelList, setModelList] = React.useState([])
	const [currentFranchise, setCurrentFranchise] = React.useState({})
	const [modelSelect, setModelSelect] = React.useState([])

	React.useEffect(() => {
		setList(data || [])
		setSelect([])
		setChecked([])
		setModelList([])
		setModelSelect([])
		setCurrentFranchise({})
	},[data])

	React.useEffect(() => {
		if (currentList && currentList.constructor === Array) {
			setChecked(currentList)
			setSelect(currentList)
		}
	},[currentList])

	React.useEffect(() => {
		if (currentModelList && currentModelList.constructor === Array) {
			// setChecked(currentList)
			setModelSelect(currentModelList)
		}
	},[currentModelList])

	

	const notInExtra = (item, extraData) => {
		const arr = extraData.filter(f => f.value !== item)
		return arr.length === 0
	}

	const handleSelect = (item) => {
		setCurrentFranchise(item)
		setModelList(item.extraData)
		if (select.indexOf(item.value) !== -1) {
			const newSelect = select.filter(f => f !== item.value)
			setSelect(newSelect)
			const newModelSelect = modelSelect.filter(f => notInExtra(f, item.extraData) && f)
			setModelSelect(newModelSelect)
		} else {
			const newSelect = [...select, item.value]
			setSelect(newSelect)
			const newModelSelect = [...modelSelect, ...item.extraData.map(item => item.value)]
			setModelSelect(newModelSelect)
		}
	}

	const handleModelSelect = (item) => {
		if (modelSelect.indexOf(item.value) !== -1) {
			const newModelSelect = modelSelect.filter(f => f !== item.value)
			setModelSelect(newModelSelect)
			const isInArr = currentFranchise.extraData.filter(f => newModelSelect.includes(f.value) && f)
			if (isInArr.length === 0) {
				const newSelect = select.filter(f => f !== currentFranchise.value)
				setSelect(newSelect)
			}
		} else {
			const newModelSelect = [...modelSelect, item.value]
			setModelSelect(newModelSelect)
			const isInArr = select.filter(f => f === currentFranchise.value)
			if (isInArr.length === 0) {
				const newSelect = [...select, currentFranchise.value]
				setSelect(newSelect)
			}
		}
	}

	const handleConfirm = () => {
		setChecked(select)
		onConfirm(select, modelSelect)
		setValue('')
		setList(data || [])
		setAnchorEle(null)
	}

	const handleSearch = (searchValue) => {
		setValue(searchValue)
		if (searchValue) {
			const newList = data.filter(f => f.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
			setList(newList)
		} else {
			setList(data || [])
		}
	}

	const handleCancel = () => {
		setAnchorEle(null)
		setValue('')
		setList(data || [])
	}

	const isSelectAll = (list, modelSelect) => {
		const arr = list.filter(f => modelSelect.includes(f.value) && f)
		return list.length !== 0 && arr.length === list.length
	}

	const handleSelectAll = (modelSelect, list) => {
		const arr = list.filter(f => modelSelect.includes(f.value) && f)
		const noIn = (item) => {
			const t = list.filter(k => k.value === item)
			return t.length === 0
		}
		if (arr.length === list.length) {
			const newModelSelect = modelSelect.filter(f => noIn(f) && f)
			setModelSelect(newModelSelect)
			if (select.includes(currentFranchise.value)) {
				const newSelect = select.filter(f => f !== currentFranchise.value)
				setSelect(newSelect)
			}
		} else {
			const temp = modelSelect.filter(f => noIn(f) && f)
			const newModelSelect = [...temp, ...list.map(f => f.value)]
			setModelSelect(newModelSelect)
			if (!select.includes(currentFranchise.value)) {
				const newSelect = [...select, currentFranchise.value]
				setSelect(newSelect)
			}
		}
	}

	return (
		<>
			<div className={cx("custom-select-box flex align-center full-width", {
				hover: !disabled,
				'pointer-disable': disabled,
				'custom-select-error': error
			})} onClick={(e) => !disabled && setAnchorEle(e.currentTarget)}>
				<div className="flex-1 px10 hidden">
					{
						checked.length ? (
							<div className="custom-select-value text-overflow">
								{
									checked.join(',')
								}
							</div>
						) : (
							<div className="custom-select-placeholder">
								{placeholder || ''}
							</div>
						)
					}
				</div>
				<div className="custom-select-icon"></div>
			</div>
			<StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={() => setAnchorEle(null)}>
				{
					list.length || value ? (
						<div className="custom-select-content p10">
							<StyledTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth={true}
                                value={value}
                                onChange={(e) => handleSearch(e.target.value)}
                                type="search"
                                variant="outlined"
                                autoComplete="off"
                            />
							<div className='flex'>
								<div className="py10 hidden mb10 custom-select-list flex-1" style={{minWidth: '200px'}}>
									<div style={{fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '2px'}}>Nike Franchise</div>
									<div className="custom-select-list-box">
										{
											list.map((item, index) => (
												<div key={index} className="pb10 flex align-center">
													<div className="flex-1">{item.name}</div>
													<div className={cx("icon-check-box hover", {
														'icon-uncheck': select.indexOf(item.value) === -1,
														'icon-check': select.indexOf(item.value) !== -1,
													})}
													onClick={() => handleSelect(item)}
													></div>
												</div>
											))
										}
									</div>
								</div>
								{
									modelList.length ? (
										<div className="my10 hidden mb10 custom-select-list flex-1" style={{borderLeft: '1px solid rgba(154, 157, 167, 0.5)', paddingLeft: '10px', minWidth: '200px'}}>
											<div style={{fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '2px'}}>Model</div>
											<div className="flex align-center mb10 justify-end">
												<div className="mr10">Select all</div>
												<div className={cx("icon-check-box hover", {
														'icon-uncheck': !isSelectAll(modelList, modelSelect),
														'icon-check': isSelectAll(modelList, modelSelect),
													})}
													onClick={() => handleSelectAll(modelSelect, modelList)}
												></div>
											</div>
											<div className="custom-select-list-box">
												{
													modelList.map((item, index) => (
														<div key={index} className="pb10 flex align-center">
															<div className="flex-1">{item.display}</div>
															<div className={cx("icon-check-box hover", {
																'icon-uncheck': modelSelect.indexOf(item.value) === -1,
																'icon-check': modelSelect.indexOf(item.value) !== -1,
															})}
															onClick={() => handleModelSelect(item)}
															></div>
														</div>
													))
												}
											</div>
										</div>
									): null
								}
							</div>
							<div className="flex align-center justify-end">
								<div className="flex align-center">
									<div className="custom-select-button select-button-width flex align-center justify-center hover mr10" onClick={() => handleCancel()}>Cancel</div>
									<div className={cx("custom-select-button select-button-width flex align-center justify-center hover")} onClick={() => handleConfirm()}>Comfirm</div>
								</div>
							</div>
						</div>
					): (
						<div className="custom-select-content p10 flex align-center justify-center">No Data</div>
					)
				}
			</StyledMenu>
		</>
	)
}

export default CustomSelect