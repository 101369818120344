import {
    observable,
    action,
    computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';
import _ from 'underscore';
import { overviewDemandRequest, overviewBrandShareRequest, overviewPPIRequest, overviewPriceTierSummaryRequest, overviewPriceTierTreemapRequest, overviewPriceTierLineRequest } from '../api/overview';
export class OverviewStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
    }

    @observable
    overviewDemand = [];

    @observable
    overviewBrandShare = [];

    @observable
    overviewPPI = [];

    @observable
    overviewPriceTierSummary = {};

    @observable
    overviewPriceTierTreemap = [];

    @observable
    overviewPriceTierLine = [];

    @observable
	ppiDateArr = [];
	
	@observable
	ppiDateArrTemp = [];

    @observable
	treemapLineDateArr = [];
	
	@observable
	treemapLineDateArrTemp = [];

    @observable
	demandDateArr = [];
	
	@observable
    demandDateArrTemp = [];

    @observable
	brandShareDateArr = [];

	@observable
	brandShareDateArrTemp = [];

	@action
	changeDemandCurrentDateRange(dateRange) {
		this.demandDateArrTemp = dateRange;
	}

	@action
	changeBrandShareCurrentDateRange(dateRange) {
		this.brandShareDateArrTemp = dateRange;
	}

	@action
	changePPICurrentDateRange(dateRange) {
		this.ppiDateArrTemp = dateRange;
	}

	@action
	changeTreemapLineDateRange(dateRange) {
		this.treemapLineDateArrTemp = dateRange;
	}
	
	@computed
	get overviewDemandResult() {
		const data = this.overviewDemand.filter(f => this.demandDateArrTemp.indexOf(f.date) !== -1 && f)
		const groupByData = _.groupBy(data, (d) => d.category);
        return groupByData
	}

    @action
    async overviewDemandRequest({ dateType, startDate, endDate, dataType }) {
        const response = await overviewDemandRequest({
            dateType,
            startDate,
            endDate,
            dataType,
        });
        const { code, data } = response;
        if (code === '00000') {
            this.demandDateArr = Array.from(new Set(data.map((d) => d.date))).sort();
			this.demandDateArrTemp = Array.from(new Set(data.map((d) => d.date))).sort();
			this.overviewDemand = data;
        }
	}
	
	@computed
	get overviewBrandShareResult() {
		const data = this.overviewBrandShare.filter(f => this.brandShareDateArrTemp.indexOf(f.date) !== -1 && f)
		const brandGroupData = _.groupBy(data, (d) => d.brandMerge);
		const arr = Object.entries(brandGroupData)
			.map(([key, value]) => ({
				name: key,
				data: value,
			}))
		const targetArr = arr.filter(f => ['NIKE', 'JORDAN', 'CONVERSE'].indexOf(f.name) !== -1);
		const leftArr = arr.filter(f => ['NIKE', 'JORDAN', 'CONVERSE'].indexOf(f.name) === -1).sort((a, b) => a.name.localeCompare(b.name));
		return [...targetArr, ...leftArr];
	}

    @action
    async overviewBrandShareRequest({ dateType, startDate, endDate, dataType, category }) {
        const response = await overviewBrandShareRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            category,
        });
        const { code, data } = response;
        if (code === '00000') {
            this.brandShareDateArr = Array.from(new Set(data.map((d) => d.date))).sort();
			this.brandShareDateArrTemp = Array.from(new Set(data.map((d) => d.date))).sort();
			this.overviewBrandShare = data;
        }
	}
	
	@action
	get overviewPPIResult() {
		const data = this.overviewPPI.filter(f => this.ppiDateArrTemp.indexOf(f.date) !== -1 && f)
		const groupByData = _.groupBy(data, (d) => d.category);
		return groupByData;
	}

    @action
    async overviewPPIRequest({ dateType, startDate, endDate, dataType }) {
        const response = await overviewPPIRequest({
            dateType,
            startDate,
            endDate,
            dataType,
        });
        const { code, data } = response;
        if (code === '00000') {
            this.ppiDateArr = Array.from(new Set(data.map((d) => d.date))).sort();
			this.ppiDateArrTemp = Array.from(new Set(data.map((d) => d.date))).sort();
			this.overviewPPI = data;
        }
    }

    @action
    async overviewPriceTierSummaryRequest({ dateType, startDate, endDate }) {
        const response = await overviewPriceTierSummaryRequest({
            dateType,
            startDate,
            endDate,
        });
        const { code, data } = response;
        if (code === '00000') {
            this.overviewPriceTierSummary = data;
        }
    }

    @action
    async overviewPriceTierTreemapRequest({ dateType, startDate, endDate, priceType }) {
        const response = await overviewPriceTierTreemapRequest({
            dateType,
            startDate,
            endDate,
            priceType,
        });
        const { code, data } = response;
        if (code === '00000') {
            this.overviewPriceTierTreemap = data.sort((a, b) => a.priceTierCode - b.priceTierCode);
        }
	}
	
	@computed
	get overviewPriceTierLineResult() {
		const data = this.overviewPriceTierLine.filter(f => this.treemapLineDateArrTemp.indexOf(f.date) !== -1 && f);
		const groupByData = _.groupBy(data, (d) => d.brandClean);
        return groupByData;
	}

    @action
    async overviewPriceTierLineRequest({ dateType, startDate, endDate, priceType, priceTierCode, brandList }) {
        const response = await overviewPriceTierLineRequest({
            dateType,
            startDate,
            endDate,
            priceType,
            priceTierCode,
            brandList,
        });
        const { code, data } = response;
        if (code === '00000') {
			this.treemapLineDateArr = Array.from(new Set(data.map((d) => d.date))).sort();
			this.treemapLineDateArrTemp = Array.from(new Set(data.map((d) => d.date))).sort();
			this.overviewPriceTierLine = data;
        }
    }
}
