import * as React from 'react';
import { Menu } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import './select.css'

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => {
    return <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />;
});

const CategorySelect = ({ placeholder, data, onConfirm = () => {}, currentList, currentSubCategory, error }) => {
	const [anchorEle, setAnchorEle] = React.useState(null);
	const [select, setSelect] = React.useState([])
	const [checked, setChecked] = React.useState([])
	const [list, setList] = React.useState([])
	const [subList, setSubList] = React.useState([])
	const [subSelect, setSubSelect] = React.useState([])

	React.useEffect(() => {
		setList(data || [])
	},[data])

	React.useEffect(() => {
		if (currentList && currentList.constructor === Array) {
			setChecked(currentList)
			setSelect(currentList)
		}
	},[currentList])

	React.useEffect(() => {
		if (currentSubCategory && currentSubCategory.constructor === Array) {
			// setChecked(currentList)
			setSubSelect(currentSubCategory)
		}
	},[currentSubCategory])

	const handleSelect = (item) => {
		if (item.children) {
			setSubList(item.children)
		}
		if (select.indexOf(item.value) !== -1) {
			const newSelect = select.filter(f => f !== item.value)
			setSelect(newSelect)
			if (newSelect.includes('FTW')) {
				setSubSelect(list[0].children.map(item => item.value))
			} else {
				setSubSelect([])
			}
		} else {
			const newSelect = [...select, item.value]
			setSelect(newSelect)
			if (newSelect.includes('FTW')) {
				setSubSelect(list[0].children.map(item => item.value))
			} else {
				setSubSelect([])
			}
		}
	}

	const handleSubSelect = (item) => {
		if (subSelect.indexOf(item.value) !== -1) {
			const newSelect = subSelect.filter(f => f !== item.value)
			setSubSelect(newSelect)
			if (newSelect.length === 0) {
				const selectNew = select.filter(f => f !== 'FTW')
				setSelect(selectNew)
			} else {
				const selectNew = Array.from(new Set([...select, 'FTW']))
				setSelect(selectNew)
			}
		} else {
			const newSelect = [...subSelect, item.value]
			setSubSelect(newSelect)
			if (newSelect.length === 0) {
				const selectNew = select.filter(f => f !== 'FTW')
				setSelect(selectNew)
			} else {
				const selectNew = Array.from(new Set([...select, 'FTW']))
				setSelect(selectNew)
			}
		}
	}

	const handleConfirm = () => {
		if (select.length) {
			setChecked(select)
			onConfirm(select, subSelect)
			setAnchorEle(null)
		}
	}

	return (
		<>
			<div className={cx("custom-select-box flex align-center full-width hover", {
				'custom-select-error': error
			})} onClick={(e) => setAnchorEle(e.currentTarget)}>
				<div className="flex-1 px10 hidden">
					{
						checked.length ? (
							<div className="custom-select-value text-overflow">
								{
									checked.join(',')
								}
							</div>
						) : (
							<div className="custom-select-placeholder">
								{placeholder || ''}
							</div>
						)
					}
				</div>
				<div className="custom-select-icon"></div>
			</div>
			<StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={() => setAnchorEle(null)}>
				<div className="custom-select-content p10">
					<div className="custom-select-list flex mb10 pb10">
						<div style={{minWidth: '180px'}}>
							{
								list.map((item, index) => (
									<div className="pb10 flex align-center" key={index}>
										<div className="flex-1">{item.name}</div>
										<div className={cx("icon-check-box hover", {
											'icon-uncheck': select.indexOf(item.value) === -1,
											'icon-check': select.indexOf(item.value) !== -1,
										})}
										onClick={() => handleSelect(item)}
										></div>
									</div>
								))
							}
						</div>
						{
							subList.length ? (
								<div style={{minWidth: '180px', borderLeft: '1px solid rgba(154, 157, 167, 0.5)', marginLeft: '10px', paddingLeft: '10px'}}>
									{
										subList.map((item, index) => (
											<div className="pb10 flex align-center" key={index}>
												<div className="flex-1">{item.name}</div>
												<div className={cx("icon-check-box hover", {
													'icon-uncheck': subSelect.indexOf(item.value) === -1,
													'icon-check': subSelect.indexOf(item.value) !== -1,
												})}
												onClick={() => handleSubSelect(item)}
												></div>
											</div>
										))
									}
								</div>
							): null
						}
					</div>
					<div className={cx("custom-select-button flex align-center justify-center", {
						hover: select.length,
						'pointer-disable': !select.length
					})} onClick={() => handleConfirm()}>Comfirm</div>
				</div>
			</StyledMenu>
		</>
	)
}

export default CategorySelect