import request from './request';
import { store } from '../index';

export const $request = (url, data, options, params) => {
    const method = options.method;
    const queryName = options.queryName;
    // const isOperator = options.isOperator || false;
    const responseType = options.responseType || 'json';
    const headers = options.headers;

    return new Promise((resolve, reject) => {
        request({
            url,
            method,
            data,
            queryName,
            params,
            responseType,
            headers,
        })
            .then((response) => {
                const data = response.data;
                resolve(data);
            })
            .catch((error) => {
                if (error.status === 401) {
                    store.history.replace('/login');
                    window.location.reload();
                }
                console.log(error, '===error');
                reject(error);
            });
    });
};

export const $post = (url, data, options) => {
    options.method = 'post';
    return $request(url, data, options);
};

export const $get = (url, data, options) => {
    options.method = 'get';
    return $request(url, {}, options, data);
};
