import { $post, $get } from './index';

export const franchiseOverviewRequest = (data) => {
    return $post('/data/franchise/analysis/overview', data, {
        queryName: 'franchiseOverviewRequest',
    });
};

export const franchiseNikeLcaRequest = (data) => {
    return $post('/data/franchise/analysis/nike/lca', data, {
        queryName: 'franchiseNikeLcaRequest',
    });
};

export const franchiseModelPopupRequest = (data) => {
    return $get('/data/franchise/analysis/model/popup', data, {
        queryName: 'franchiseModelPopupRequest',
    });
};
