import * as echarts from 'echarts';

const formatterByUnit = (value, unit) => {
    if (unit === 'M') {
        return `${Math.round(value / 1000000)
            .toString()
            .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}M`;
    }
};

export const rowRender = (element, props) => {
    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    chart.setOption({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: (params) => {
                let content = `<div>${params && params[0] && params[0].axisValueLabel}</div>`;
                params.forEach((p) => {
                    const name = p.data.name;
                    const v = formatterByUnit(p.data.value, 'M');
                    content += `<div class="flex align-center"><div class="dot" style="background: ${p.color.type === 'linear' ? p.color.colorStops[0].color : p.color}"></div>${name}：${v}</div>`;
                });
                return content;
            },
        },
        grid: {
            top: '2%',
            left: '2%',
            right: '5%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#979797',
                    opacity: 0.5,
                },
            },
            scale: true,
            axisLabel: {
                fontFamily: 'Helvetica',
                fontSize: 12,
                formatter: (value) => {
                    if (typeof value === 'number') {
                        if (Math.abs(value) >= 1000 && Math.abs(value) < 1000000) {
                            const formattedValue = Math.round(value / 1000);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} K`;
                        } else if (Math.abs(value) >= 1000000) {
                            const formattedValue = Math.round(value / 1000000);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} M`;
                        } else {
                            const formattedValue = Math.round(value);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}`;
                        }
                    }
                    return value;
                },
            },
        },
        yAxis: {
            type: 'category',
            data: props.xAxisData,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: '#979797',
                    opacity: 0.8,
                },
            },
            axisLabel: {
                color: 'rgba(255,255,255,0.8)',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
                fontSize: 12,
            },
        },
        series: props.data.map((item, index) => ({
            name: item.name,
            type: 'bar',
            data: item.data,
            itemStyle:
                index === 0
                    ? {
                          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                              { offset: 0, color: '#4B6ACF' },
                              { offset: 1, color: '#FF72C5' },
                          ]),
                      }
                    : {
                          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                              { offset: 0, color: '#FF7334' },
                              { offset: 1, color: '#FFAB64' },
                          ]),
                      },
            label: {
                show: true,
                position: 'right',
                fontSize: 12,
                fontFamily: 'Helvetica-Bold, Helvetica',
                fontWeight: 'bold',
                color: '#FFFFFF',
                formatter: ({ value }) => {
                    if (typeof value === 'number') {
                        if (Math.abs(value) >= 1000 && Math.abs(value) < 1000000) {
                            const formattedValue = Math.round(value / 1000);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} K`;
                        } else if (Math.abs(value) >= 1000000) {
                            const formattedValue = Math.round(value / 1000000);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} M`;
                        } else {
                            const formattedValue = Math.round(value);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}`;
                        }
                    }
                    return value;
                },
            },
        })),
    });
};
