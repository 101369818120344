import * as React from 'react';
import { Slider, Tooltip } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const StyledSlider = withStyles({
	root: {
		color: '#000'
	},
	thumb: {
		width: 18,
		height: 10,
		marginTop: -4,
		'&.Mui-focusVisible': {
			boxShadow: 'none'
		},
		'&:hover': {
			boxShadow: 'none'
		}
	},
	active: {},
	track: {
		color: 'rgba(255,255,255,0.6)',
	  	height: 4,
	},
	rail: {
	 	height: 4,
		color: 'rgba(255,255,255,0.4)',
	},
  })(Slider);

const CustomSlider = ({range, onChange}) => {
	const [value, setValue] = React.useState([])
	React.useEffect(() => {
		if (range.length) {
			setValue([0,range.length - 1])
		}
	}, [range])

	const LabelComponent = (props) => {
		const { children, open, value } = props;
		return (
			value ? <Tooltip open={open} enterTouchDelay={0} placement="top" title={range[value]}>
				<div>{children}</div>
			</Tooltip> : <div>{children}</div>
		);
	}

	const ThumbComponent = (props) => {
		return <span {...props}>
			<div className="slider-thumb"></div>
		</span>
	}

	const handleChange = (e, newValue) => {
		setValue(newValue)
		const arr = range.slice(newValue[0], newValue[1] + 1)
		onChange(newValue, arr)
	}

    return (
        <div style={{padding: '0 8% 0 14%'}}>
			<StyledSlider
				value={value}
				step={1}
				min={0}
				max={range.length ? range.length - 1: 0}
				ValueLabelComponent={LabelComponent}
				ThumbComponent={ThumbComponent}
				onChange={handleChange}
				valueLabelDisplay="auto"
        		aria-labelledby="range-slider"
			/>
		</div>
    );
};

export default CustomSlider;
