import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Icon from '../components/Icon'

import { Visualization } from '../visualization/index';
import CascadeDrop from '../components/CascadeDrop';
import StyledCheckBox from '../components/StyledCheckBox';
import DatePicker from '../components/DatePicker';
import CommonDialog from '../components/CommonDownloadDialog';
import CommonTootip from '../components/StyledTooltip';
import Slider from '../components/Slider'
import Evaluation from './components/Evaluation'
import BasicInformation from './components/BasicInformation'

import { downloadPDF, transformDate, formatM, formatPercent, handleDateStartEnd, handleLegend, downloadXlsx, getColsWpx } from '../../utils/index';
import {
	demandColumns,
	ppiColumns,
	rviColumns,
	diColumns,
	handleDemandData,
	handlePPIData,
	handleRVIData,
	handleDIData,
} from '../../utils/franchiseAnalysisDownload';
import '../../assets/css/franchise.css';

const timeTabList = [
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: '12M',
        alias: 'M12',
    },
];

@inject('commonStore', 'franchiseStore', 'userStore')
@observer
export default class FranchiseOverview extends React.Component {
    state = {
        demandTimeTab: 'MONTH',
        selectedList: [],
        selectGroupList: [],
        startDate: null,
        endDate: null,
        originalDate: {},
        type: 'MONTH',
        colors: {
            DEMAND: {},
            'PREMIUM PRICE INDEX': {},
            'RESALE VOLUME INDEX': {},
            'DIVERSITY INDEX': {},
        },
        open: false,
        language: 'enInfo',
        tooltipOpen: {}
    };
    async componentWillMount() {
        await this.props.commonStore.franchiseDictRequest();
        const { timeRangeMonth, franchiseDefault } = await this.props.commonStore;
        const groupByList = this.groupedByNumber(2, franchiseDefault, '');
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 12);
        this.setState({
            startDate,
            endDate,
            selectedList: franchiseDefault,
            selectGroupList: groupByList,
            originalDate,
        });
        const { type, demandTimeTab } = this.state;
        const { brandList, franchiseList, modelList } = this.handleBrandFranchise(franchiseDefault);
        await this.props.franchiseStore.franchiseOverviewRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: demandTimeTab,
            brandList,
            franchiseList,
            modelList
        });
    }

    handleBrandFranchise = (selectedList) => {
        const brandArr = [];
        const franchiseArr = [];
        const modelArr = [];
        selectedList.forEach((item) => {
            brandArr.push(item[0].value);
            franchiseArr.push(item[1].value);
            modelArr.push(item[2].value);
        });

        return {
            brandList: Array.from(new Set(brandArr)) || [],
            franchiseList: Array.from(new Set(franchiseArr)) || [],
            modelList: Array.from(new Set(modelArr)) || []
        };
    };

    groupedByNumber = (num = 2, list, emptyObj = {}) => {
        const group = [];
        for (let i = 0; i < list.length; i += num) {
            group.push(list.slice(i, i + num));
        }
        const last = (group.length && group[group.length - 1]) || [];
        const empty = [];
        if (last.length) {
            for (let i = 0; i < num - last.length; i++) {
                empty.push(emptyObj);
            }
            empty.forEach((e) => {
                group[group.length - 1].push(e);
            });
        }
        return group;
    };

    handleRemoveSelected = async (item) => {
        const { selectedList } = this.state;
        const newList = selectedList.filter((f) => f[f.length - 1].name !== item.name);
        const newGroup = this.groupedByNumber(2, newList, '');
        this.setState({
            selectedList: newList,
            selectGroupList: newGroup,
        });

        const { startDate, endDate, type, demandTimeTab } = this.state;
        const { brandList, franchiseList, modelList } = this.handleBrandFranchise(newList);
        await this.props.franchiseStore.franchiseOverviewRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: demandTimeTab,
            brandList,
            franchiseList,
            modelList
        });
    };

    handleSelectedConfirm = async (checkedList) => {
        const groupByList = this.groupedByNumber(2, checkedList, '');
        this.setState({
            selectedList: checkedList,
            selectGroupList: groupByList,
        });

        const { startDate, endDate, type, demandTimeTab } = this.state;
        const { brandList, franchiseList, modelList } = this.handleBrandFranchise(checkedList);
        await this.props.franchiseStore.franchiseOverviewRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: demandTimeTab,
            brandList,
            franchiseList,
            modelList
        });
    };

    handleConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        const { demandTimeTab, selectedList } = this.state;
        if (start && end) {
            this.setState({
                startDate: start,
                endDate: end,
                type,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            const { brandList, franchiseList, modelList } = this.handleBrandFranchise(selectedList);
            await this.props.franchiseStore.franchiseOverviewRequest({
                startDate: start,
                endDate: end,
                dateType: type,
                dataType: demandTimeTab,
                brandList,
                franchiseList,
                modelList
            });
        }
    };

    handleDemandTimeTab = async (tab) => {
        this.setState({
            demandTimeTab: tab,
        });
        const { startDate, endDate, type, selectedList } = this.state;
        const { brandList, franchiseList, modelList } = this.handleBrandFranchise(selectedList);
        await this.props.franchiseStore.franchiseOverviewRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: tab,
            brandList,
            franchiseList,
            modelList
        });
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'pdf') {
            this.ele &&
                downloadPDF(
                    this.ele,
                    () => {
                        console.log('download success');
						this.props.commonStore.setDownloadDialog(false);
						this.props.commonStore.setMenuName('');
                    },
                    () => {
                        console.log('download fail');
					},
					name
                );
        } else if (type === 'xlsx') {
			const { franchiseOverviewResult } = this.props.franchiseStore;
			const { demandTimeTab, type } = this.state;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(5)
			}
			let rowsNum = 0
			let sheetData = []

			const demandColumn = demandColumns(type);
			const { data, colsNumber, rowsNumber } = handleDemandData(demandColumn, type, demandTimeTab, franchiseOverviewResult);
			sheetData = [...sheetData, ...data];
			options['!merges'].push({
				s: { r: 0, c: 0 },
				e: { r: 0, c: colsNumber - 1 }
			})
			rowsNum = rowsNumber + rowsNum;

			const ppiColumn = ppiColumns(type);
			const { data: ppiLineData, colsNumber: ppiColsNumber, rowsNumber: ppiRowsNumber } = handlePPIData(ppiColumn, type, demandTimeTab, franchiseOverviewResult);
			sheetData = [...sheetData, ...ppiLineData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: ppiColsNumber - 1 }
			})
			rowsNum = ppiRowsNumber + rowsNum;

			const rviColumn = rviColumns(type);
			const { data: rviData, colsNumber: rviColsNumber, rowsNumber: rviRowsNumber } = handleRVIData(rviColumn, type, demandTimeTab, franchiseOverviewResult);
			sheetData = [...sheetData, ...rviData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: rviColsNumber - 1 }
			})
			rowsNum = rviRowsNumber + rowsNum;

			const diColumn = diColumns(type);
			const { data: diData, colsNumber: diColsNumber, rowsNumber: diRowsNumber } = handleDIData(diColumn, type, demandTimeTab, franchiseOverviewResult);
			sheetData = [...sheetData, ...diData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: diColsNumber - 1 }
			})
			rowsNum = diRowsNumber + rowsNum;

			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    handleChangeColor = (color, type) => {
        const c = this.state.colors;
        c[type] = color;

        this.setState({
            colors: c,
        });
	};

    handleStar = (index, score) => {
        if (index * 2 <= score) {
            return 'score-active'
        } else if (index * 2 > score && (index - 1) * 2 < score) {
            return 'score-half'
        }
        return ''
    }
	
	handleSliderChange = (sliderArr, type) => {
		if (type === 'demand') {
			this.props.franchiseStore.changeDateRange1(sliderArr)
		} else if (type === 'ppi') {
			this.props.franchiseStore.changeDateRange2(sliderArr)
		} else if (type === 'resaleVolumeIndex') {
			this.props.franchiseStore.changeDateRange3(sliderArr)
		} else if (type === 'diversity') {
			this.props.franchiseStore.changeDateRange4(sliderArr)
		}
	}

    handleModelClick = async (data, brand) => {
        try {
            await this.props.franchiseStore.franchiseModelPopupRequest({
                model: brand.key
            })
            const info = this.props.franchiseStore.popInfo
            if (info.type) {
                this.setState({
                    open: true
                })
            } else {
                const tip = {
                    [`${data.title}_${brand.key}`]: true
                }
                this.setState({
                    tooltipOpen: tip
                })
            }
        } catch (error) {
            const tip = {
                [`${data.title}_${brand.key}`]: true
            }
            this.setState({
                tooltipOpen: tip
            })
        }
    }

    handleTooltipClose = (data, brand) => {
        const key = `${data.title}_${brand.key}`
        const statusKeyArr = Object.keys(this.state.tooltipOpen)
        if (statusKeyArr.includes(key)) {
            this.setState({
                tooltipOpen: {}
            })
        }
    }

	getDesc = (dateRange, franchiseOverviewData) => {
		const lastDate = dateRange[dateRange.length - 1]
		return handleLegend(franchiseOverviewData, 'model', lastDate);
	}

    render() {
        const { demandTimeTab, selectedList, selectGroupList, colors, startDate, endDate, type, originalDate, open, language, tooltipOpen } = this.state;
        const { isDialogOpen, franchiseDict, timeRangeMonth, timeRangeWeek } = this.props.commonStore;
		const { franchiseOverviewResult, franchiseOverviewData, popInfo } = this.props.franchiseStore;

        const url = process.env.PUBLIC_URL + '/static/publicResource/img/franchise_default_img.png';
        const RadarChart = Visualization.get('radar_chart');
		
        const data = {
            default: franchiseDict,
		};

        return (
            <div className="flex-1 flex flex-column hidden p20 franchise-bg" ref={(_f) => (this.ele = _f)}>
                <div className="flex align-center title-box">
					<div className="franchise-overview-title"></div>
				</div>
                <div className="flex mb14">
                    <div className="flex-1 mr18 hidden">
                        <div className="select-item-box p8 flex border-box full-width">
                            <div className="franchise-select-box border-box flex align-center justify-center mr20">
                                <CascadeDrop title="Select Franchise" data={data} onConfirm={this.handleSelectedConfirm} currentList={selectedList}></CascadeDrop>
                            </div>
                            <div className="flex-1 flex hidden">
                                {selectGroupList.map((group, index) => (
                                    <div key={index} className="flex flex-column mr30 flex-1 hidden">
                                        {group.map((item, i) => (
                                            <CommonTootip key={`${index}-${i}`} placement="top" title={<div>{(item && item[item.length - 1]['name']) || ''}</div>}>
                                                <div className="flex-1 flex align-center select-brand-item" key={`${index}-${i}`}>
                                                    {(item && (
                                                        <div className="mr4">
                                                            <StyledCheckBox checked={true} indeterminate={true} onChange={() => this.handleRemoveSelected(item[item.length - 1])} />
                                                        </div>
                                                    )) ||
                                                        null}
                                                    <div className="text-overflow hover">{(item && item[item.length - 1]['name']) || ''}</div>
                                                </div>
                                            </CommonTootip>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex align-center border-box hidden">
                        <div
                            className={cx('flex-1 select-item-box flex align-center px20 border-box', {
                                mr18: type === 'MONTH',
                            })}
                        >
                            <div className="icon-calendar brand-calendar-item mr40"></div>
                            <DatePicker isCenter={true} onConfirm={this.handleConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                        </div>
                        {(type === 'MONTH' && (
                            <div className="select-item-box flex align-center p8 border-box">
                                {timeTabList.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={cx('brand-analysis-time border-box hover flex align-center justify-center', {
                                            'brand-analysis-time-active': demandTimeTab === tab.alias,
                                        })}
                                        onClick={() => this.handleDemandTimeTab(tab.alias)}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </div>
                        )) ||
                            null}
                    </div>
                </div>
                {franchiseOverviewResult.map((group, k) => (
                    <div
                        key={k}
                        className={cx('flex-1 flex', {
                            mb20: k !== franchiseOverviewResult.length - 1,
                        })}
                    >
                        {group.map((data, index) => {
                            const VisvalComponent = Visualization.get('line_chart');
                            return (
                                <div
                                    className={cx('flex-1 card-box flex flex-column', {
                                        mr18: index !== group.length - 1,
                                    })}
                                    key={`${k}-${index}`}
                                >
                                    <div className="flex align-center brand-chart-title-box">
                                        <div>{data.title}</div>
                                    </div>
                                    <div className="visualization flex-1 p10 flex flex-column">
										<div className="flex-1">
											{VisvalComponent && <VisvalComponent type={2} data={data.data} changeColor={(color) => this.handleChangeColor(color, data.title)} colors={colors[data.title]} />}
										</div>
										<Slider range={data.dateRange} onChange={(value, sliderArr) => this.handleSliderChange(sliderArr, data.value)} />
									</div>
                                    <div className="info-box flex flex-column justify-between">
                                        {this.getDesc(data.currentDateRange, franchiseOverviewData).map((group, m) => (
                                            <div className="flex-1 flex align-center" key={m}>
                                                {group.map((brand, i) => {
                                                    return brand.key ? (
                                                        
                                                        <div
															style={{ width: '33%' }}
                                                            className={cx('flex full-height px5', {
                                                                'info-item-border': i !== group.length - 1,
                                                            })}
                                                            key={`${index}-${m}-${i}`}
                                                        >
                                                            <ClickAwayListener onClickAway={() => this.handleTooltipClose(data, brand)}>
                                                                <CommonTootip
                                                                    placement="top"
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    open={tooltipOpen[`${data.title}_${brand.key}`] || false}
                                                                    // onClose={this.handleTooltipClose}
                                                                    title={
                                                                        <div className="flex align-center">
                                                                            <div
                                                                                className="dot"
                                                                                style={{
                                                                                    background: colors[data.title][brand.key],
                                                                                }}
                                                                            />
                                                                            <div className="ml4 info-item-name-tooltip">{brand.key}</div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className={cx('border-box flex align-center hover flex-1 hidden')} onClick={() => this.handleModelClick(data, brand)}>
                                                                        <div
                                                                            className="dot"
                                                                            style={{
                                                                                background: colors[data.title][brand.key],
                                                                            }}
                                                                        />
                                                                        <div className="ml4 info-item-name flex-1">{brand.key}</div>
                                                                    </div>
                                                                </CommonTootip>
                                                            </ClickAwayListener>
                                                            <div className="flex align-center">
                                                                <div className="mr6 info-item-value">{formatM(brand.list[0][data.value])}</div>
                                                                {(brand.list[0][data.yoy] && (
                                                                    <div className="flex align-center mt4">
                                                                        {brand.list[0][data.yoy].indexOf('-') !== -1 ? (
                                                                            <ArrowDownward
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: '#FF5F60',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <ArrowUpward
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: '#4D9B4D',
                                                                                }}
                                                                            />
                                                                        )}
                                                                        <div
                                                                            className={cx('brand-percent', {
                                                                                'up-percent': brand.list[0][data.yoy].indexOf('-') === -1,
                                                                                'down-percent': brand.list[0][data.yoy].indexOf('-') !== -1,
                                                                            })}
                                                                        >
                                                                            {formatPercent(brand.list[0][data.yoy].replace('-', '')) || 0}
                                                                        </div>
                                                                    </div>
                                                                )) ||
                                                                    null}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={cx('flex-1 flex full-height justify-between px5')}
                                                            key={`${index}-${m}-${i}`}
                                                        ></div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
                <Dialog
                    className='pop-dialog'
                    open={open}
                    maxWidth="lg"
                >
                    <div className={cx('pop-box flex flex-column', {
                        'pop-box-lg': popInfo.type === 1
                    })}>
                        <div className='pop-head flex align-center'>
                            <div className='flex align-center head-left'><Icon style={{width: '21px', height: '16px'}} name='dialog-title'></Icon><span style={{minWidth: '200px', paddingLeft: '10px'}}>{ popInfo.model }</span></div>
                            <div className='flex-1'></div>
                            <div className='pop-button-groups flex align-center'>
                                <div className={cx('flex-1 pop-button-item', { 'active': language === 'enInfo' })} onClick={() => this.setState({language: 'enInfo'})}>English</div>
                                <div className={cx('flex-1 pop-button-item', { 'active': language === 'cnInfo' })} onClick={() => this.setState({language: 'cnInfo'})}>Chinese</div>
                            </div>
                            <div className='pop-head-close' onClick={() => this.setState({ open: false })}>Close</div>
                        </div>
                        {
                            popInfo[language] && (
                                <div className='pop-content flex flex-1'>
                                    <div className='flex-1 flex flex-column pop-content-left-box'>
                                        <div className='flex-1 pop-content-left-top-box flex flex-column'>
                                            <div className='flex align-center'>
                                                <div className='pop-content-logo'>DEWU</div>
                                                <div className='pop-content-subtitle'>{ popInfo[language].title }</div>
                                            </div>
                                            <div className='flex-1 flex'>
                                                <div className='flex-1 flex flex-column align-center'>
                                                    <div className='pop-content-img flex-1 flex align-center justify-center'>
                                                        <img style={{ height: '170px' }} src={ popInfo.imgUrl || url } alt="" />
                                                    </div>
                                                    {
                                                        popInfo[language].score && (
                                                            <div className='pop-content-score-box flex align-center'>
                                                                <div className='pop-content-score-total'>{ popInfo[language].score }</div>
                                                                {
                                                                    [1,2,3,4,5].map((item) => (
                                                                        <div className='pop-content-star-box flex align-center justify-center' key={item}>
                                                                            <div className={cx('pop-content-star', this.handleStar(item, popInfo[language].score))}></div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                {
                                                    popInfo[language].radarData.length > 0 && (
                                                        <div className='flex-1'>
                                                            <RadarChart total={popInfo[language].score} data={popInfo[language].radarData}></RadarChart>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {
                                            popInfo.type !== 1 && (
                                                <div className='pop-content-left-down-box'>
                                                    <div className='pop-content-left-down-title'>· { popInfo[language].commentsTitle }</div>
                                                    <div className='flex'>
                                                        <div className='advantage-box flex-1' style={{ marginRight: '24px' }}>
                                                            <Evaluation
                                                                name='advantage'
                                                                data={popInfo[language].advantage}
                                                            ></Evaluation>
                                                        </div>
                                                        <div className='defect-box flex-1'>
                                                            <Evaluation
                                                                name='defect'
                                                                data={popInfo[language].defect}
                                                            ></Evaluation>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            popInfo.type === 1 && (
                                                <div className='pop-content-left-down-box'>
                                                    <div className='pop-content-left-down-title'>· { popInfo[language].basicTitle }</div>
                                                    <div className='flex'>
                                                        <div className='flex-1'>
                                                            <BasicInformation name='basic' data={popInfo[language].basicInformation}></BasicInformation>
                                                        </div>
                                                        <div className='flex-1'>
                                                            <BasicInformation name='conf' data={popInfo[language].configuration}></BasicInformation>
                                                        </div>
                                                        <div className='flex-1'>
                                                            <BasicInformation name='attr' data={popInfo[language].attributes}></BasicInformation>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        popInfo.type === 1 && (
                                            <div className='pop-content-right-box'>
                                                <div className='pop-content-right-title'>· { popInfo[language].commentsTitle }</div>
                                                <div className='advantage-box'>
                                                    <Evaluation
                                                        name='advantage'
                                                        data={popInfo[language].advantage}
                                                    ></Evaluation>
                                                </div>
                                                <div className='defect-box'>
                                                    <Evaluation
                                                        name='defect'
                                                        data={popInfo[language].defect}
                                                    ></Evaluation>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </Dialog>
            </div>
        );
    }
}
