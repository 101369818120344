import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: '#fff',
        },
        '&$checked + $track': {
            backgroundColor: '#22C3AF',
        },
    },
    checked: {},
    track: {},
})(Switch);

export default StyledSwitch;
