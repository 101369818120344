import { $post, $get } from './index';

export const userInfoRequest = () => {
    return $get(
        '/authentication/user/current',
        {},
        {
            queryName: 'userInfoRequest',
        }
    );
};

export const userListRequest = (data) => {
    return $post('/user/list', data, {
        queryName: 'userListRequest',
    });
};

export const roleDictRequest = () => {
    return $get(
        '/role/dict/list',
        {},
        {
            queryName: 'roleDictRequest',
        }
    );
};

export const userResetPasswordRequest = (data) => {
    return $post('/authentication/password/reset/internal', data, {
        queryName: 'userResetPasswordRequest',
    });
};

export const userSaveRequest = (data) => {
    return $post('/user/save', data, {
        queryName: 'userSaveRequest',
    });
};

export const userEnableRequest = (data) => {
    return $post('/user/enable', data, {
        queryName: 'userEnableRequest',
    });
};

export const userRemoveRequest = (data) => {
    return $post('/user/remove', data, {
        queryName: 'userRemoveRequest',
    });
};

export const roleListRequest = (data) => {
    return $post('/role/list', data, {
        queryName: 'roleListRequest',
    });
};

export const permissionDictRequest = () => {
    return $get(
        '/permission/dict/list',
        {},
        {
            queryName: 'permissionDictRequest',
        }
    );
};

export const roleSaveRequest = (data) => {
    return $post('/role/save', data, {
        queryName: 'roleSaveRequest',
    });
};

export const roleInfoRequest = (data) => {
    return $get('/role/info', data, {
        queryName: 'roleInfoRequest',
    });
};

export const roleRemoveRequest = (data) => {
    return $post('/role/remove', data, {
        queryName: 'roleRemoveRequest',
    });
};
