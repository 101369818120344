import { $post, $get } from './index';

export const explorGroupRequest = (data) => {
    return $get('/data/attribute_exploration/definition/list', data, {
        queryName: 'explorGroupRequest',
    });
};

export const explorResultRequest = (data) => {
    return $post('/data/attribute_exploration/result', data, {
        queryName: 'explorResultRequest',
    });
};

export const explorGroupDownload = (data) => {
    return $post('/data/attribute_exploration/definition/download', data, {
        queryName: 'explorGroupDownload',
    });
};

export const explorGroupSearch = (data) => {
    return $post('/data/attribute_exploration/product_code/list', data, {
        queryName: 'explorGroupSearch'
    })
}

export const removeGroupRequest = (data) => {
    return $post('/data/attribute_exploration/definition/remove', data, {
        queryName: 'removeGroupRequest'
    })
}

export const updateSkuSelectRequest = (data) => {
    return $post('/data/attribute_exploration/product_code/select/save', data, {
        queryName: 'updateSkuSelectRequest'
    })
}

export const saveGroupDefineRequest = (data) => {
    return $post('/data/attribute_exploration/definition/save', data, {
        queryName: 'saveGroupDefineRequest'
    })
}

export const getFranchiseByCategory = (data) => {
    return $get('/data/dict/brand/franchise/model', data, {
        queryName: 'getFranchiseByCategory',
    });
}
