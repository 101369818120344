import * as React from 'react';
import { TableContainer, Table, TableHead, TableBody, Paper } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { withLoading } from '../../hoc/WithLoading';

import './paginationTable.css';

const useStyles = makeStyles({
    table: {
        minWidth: 500,
    },
    container: {
        height: '100%',
    },
});

const TableWrapper = ({ headerCellRender, bodyCellRender, total, changePage, children, current }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className="pagination-table-box full-width full-height">
                <TableContainer className={classes.container} component={Paper}>
                    <Table stickyHeader={true} className={classes.table}>
                        <TableHead>{headerCellRender()}</TableHead>
                        <TableBody>{(bodyCellRender && bodyCellRender()) || children}</TableBody>
                    </Table>
                </TableContainer>
            </div>

            {changePage && (
                <div className="flex align-center justify-center mt10 pagination-box">
                    <Pagination count={total} variant="outlined" shape="rounded" onChange={changePage} page={current} />
                </div>
            )}
        </React.Fragment>
    );
};

export default withLoading(TableWrapper);
