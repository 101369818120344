import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

import { Visualization } from './visualization/index';
// import DropBox from './components/DropBox';
// import CascadeDrop from './components/CascadeDrop';
import GenderCascadeDrop from './components/GenderCascadeDrop'
import StyledCheckBox from './components/StyledCheckBox';
import DatePicker from './components/DatePicker';
import PPIComponent from './components/PPIComponent';
import CommonDialog from './components/CommonDownloadDialog';
import CommonTootip from './components/StyledTooltip';
import Slider from './components/Slider';

import { downloadPDF, transformDate, groupedByNumber, handlePPI, formatM, formatPercent, handleDateStartEnd, handleLegend, downloadXlsx, getColsWpx } from '../utils/index';
import {
	demandColumns,
	ppiColumns,
	rviColumns,
	diColumns,
	handleDemandData,
	handlePPILineData,
	handleRVIData,
	handleDIData,
} from '../utils/genderAnalysisDownload';
import '../assets/css/brandAndGender.css';

const timeTabList = [
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: '12M',
        alias: 'M12',
    },
];

// const typeTabList = [
//     {
//         name: 'Brand',
//         alias: 'BRAND',
//     },
//     {
//         name: 'Franchise',
//         alias: 'FRANCHISE',
//     },
// ];

// const dropTabList = [
//     {
//         name: 'ALL',
//         alias: 'ALL',
//     },
//     {
//         name: 'MALE',
//         alias: 'MALE',
//     },
//     {
//         name: 'FEMALE',
//         alias: 'FEMALE',
//     },
//     {
//         name: 'KIDS',
//         alias: 'KIDS',
//     },
// ];

@inject('brandAndGenderStore', 'commonStore', 'userStore')
@observer
export default class GenderAnalysis extends React.Component {
    state = {
        demandTimeTab: 'MONTH',
        typeTab: 'BRAND',
        selectedList: [],
        selectGroupList: [],
        currentTab: 'MALE',
        type: 'MONTH',
        startDate: null,
        endDate: null,
        originalDate: {},
        data: {},
        ppiInfo: {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: [],
            data: [],
            ppi: ['TOTAL']
        },
        ppiData: {},
        colors: {
            resaleVolumeIndex: {},
            diversity: {},
            ppi: {},
            demand: {},
        },
    };
    async componentWillMount() {
        const { timeRangeMonth } = await this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 12);
        const { typeTab, type, demandTimeTab, currentTab } = this.state;
        this.setState({
            startDate,
            endDate,
            originalDate,
        });
        await this.props.commonStore.brandDictRequest();
        await this.props.commonStore.franchiseDictRequest();

        const { brandDefault, brandDict } = this.props.commonStore;
        const brandList = brandDict.map((item) => ({
            ...item,
            name: item.display,
		}));
        const groupByList = groupedByNumber(2, brandDefault, '');
        // const ppiData = handlePPIData(brandDefault, currentTab);
        const ppiData = handlePPI(brandDefault, currentTab);
        const ppiInfo = {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: brandDefault,
            data: brandDefault,
            ppi: ['TOTAL'],
        };
        this.setState({
            data: {
                ALL: [{
					defaultFlag: 2,
					display: 'TOTAL',
					extraData: null,
					name: 'TOTAL',
					value: 'TOTAL'
				}, ...brandList],
                MALE: brandList,
                FEMALE: brandList,
                KIDS: brandList,
            },
            selectedList: brandDefault,
            selectGroupList: groupByList,
            ppiInfo,
            ppiData,
        });

        await this.getRequest(typeTab, startDate, endDate, type, demandTimeTab, currentTab, ppiInfo, brandDefault, []);
    }

    handleRemoveSelected = async (item) => {
        const { typeTab, startDate, endDate, type, currentTab, selectedList, demandTimeTab } = this.state;
        const newList = selectedList.filter((f) => f !== item);
        const newGroup = groupedByNumber(2, newList, '');
        const ppiData = handlePPI(newList, currentTab);
        const ppiInfo = {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: newList,
            data: newList,
            ppi: ['TOTAL']
        };
        this.setState({
            selectedList: newList,
            selectGroupList: newGroup,
            ppiData,
            ppiInfo,
        });

        const select = (typeTab === 'BRAND' || typeTab === 'CATEGORY') ? newList : newList.map((item) => item.length && item[1] && item[1].value);
        const modelList = typeTab === 'FRANCHISE' ? newList.map((item) => item.length && item[item.length - 1] && item[item.length - 1].value) : []
        await this.getRequest(typeTab, startDate, endDate, type, demandTimeTab, currentTab, ppiInfo, select, modelList);
    };

    handlePPIConfirm = async (selected) => {
        const genderList = Array.from(new Set(selected.value.map((item) => item.gender).filter((f) => f)));
        const singleGender = selected.originKey.gender;
        const ppiInfo = {
            type: selected.type,
            key: selected.key,
            value: Array.from(new Set(selected.value.map((item) => item.value))),
            gender: genderList,
            origin: selected.originKey,
            name: selected.originKey && selected.originKey.name,
            data: selected.data,
            ppi: selected.ppi
        };
        this.setState({
            ppiInfo,
        });
        const { type, startDate, endDate, demandTimeTab, currentTab, typeTab } = this.state;
        if (typeTab === 'BRAND') {
            await this.props.brandAndGenderStore.genderAnalysisBrandDemandRequest({
                dateType: type,
                startDate,
                endDate,
                dataType: demandTimeTab,
                gender: (singleGender && [singleGender]) || (genderList.length && genderList) || [currentTab],
                level: typeTab,
                brandList: ppiInfo.data,
                ppiList: ppiInfo.ppi,
                ppiInfo,
                genderTab: currentTab,
            });
        } else {
            await this.props.brandAndGenderStore.genderAnalysisCategoryDemandRequest({
                dateType: type,
                startDate,
                endDate,
                dataType: demandTimeTab,
                gender: (genderList.length && genderList) || [currentTab],
                level: typeTab === 'CATEGORY' ? 'INNER_SUB_CATEGORY' : typeTab,
                subCategoryList: ppiInfo.data.map(item => item.toUpperCase()),
                ppiList: ppiInfo.ppi,
                ppiInfo,
                genderTab: currentTab,
            });
        }
    };

    handleConfirm = async (checkedList, gender) => {
        const groupByList = groupedByNumber(2, checkedList, '');
        const ppiData = handlePPI(checkedList, gender);
        const ppiInfo = {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: checkedList,
            data: checkedList,
            ppi: ['TOTAL']
        };
        this.setState({
            selectedList: checkedList,
            selectGroupList: groupByList,
            currentTab: gender,
            ppiData,
            ppiInfo,
        });

        const { startDate, endDate, type, demandTimeTab } = this.state;
        await this.getRequest('BRAND', startDate, endDate, type, demandTimeTab, gender, ppiInfo, checkedList, []);
    };

    handleDemandTimeTab = async (tab) => {
        this.setState({
            demandTimeTab: tab,
        });
        const { typeTab, startDate, endDate, type, currentTab, ppiInfo, selectedList } = this.state;
        const select = (typeTab === 'BRAND' || typeTab === 'CATEGORY') ? selectedList : selectedList.map((item) => item.length && item[1] && item[1].value);
        const modelList = typeTab === 'FRANCHISE' ? selectedList.map((item) => item.length && item[item.length - 1] && item[item.length - 1].value) : []
        await this.getRequest(typeTab, startDate, endDate, type, tab, currentTab, ppiInfo, select, modelList);
    };

    // handleTypeTab = (tab) => {
    //     // todo
    //     const { brandDefault, brandDict, franchiseDict, franchiseDefault } = this.props.commonStore;
    //     const fDeault = tab === 'BRAND' ? brandDefault : franchiseDefault.map((item) => item.length && item[1] && item[1].value);

    //     const dictDefault = tab === 'BRAND' ? brandDefault : franchiseDefault;
    //     const list =
    //         tab === 'BRAND'
    //             ? brandDict.map((item) => ({
    //                   ...item,
    //                   name: item.display,
    //               }))
    //             : franchiseDict;
    //     const groupByList = groupedByNumber(2, dictDefault, '');

    //     const ppiData = tab === 'BRAND' ? handlePPI(brandDefault, 'MALE') : {};
    //     const ppiInfo =
    //         tab === 'BRAND'
    //             ? {
    //                   type: 'brand',
    //                   key: 'Demand',
    //                   value: brandDefault,
    //                   name: 'Demand',
    //                   ppi: ['TOTAL'],
    //                   data: brandDefault
    //               }
    //             : {
    //                   type: 'brand',
    //                   key: 'Demand',
    //                   value: [],
    //                   name: 'Demand',
    //                   ppi: ['TOTAL'],
    //                   data: []
    //               };

    //     this.setState({
    //         data: {
    //             ALL: tab === 'BRAND' ? [{
	// 				defaultFlag: 2,
	// 				display: 'TOTAL',
	// 				extraData: null,
	// 				name: 'TOTAL',
	// 				value: 'TOTAL'
	// 			}, ...list] :list,
    //             MALE: list,
    //             FEMALE: list,
    //             KIDS: list,
    //         },
    //         selectedList: dictDefault,
    //         selectGroupList: groupByList,
    //         typeTab: tab,
    //         currentTab: 'MALE',
    //         ppiData,
    //         ppiInfo,
    //     });
    //     const { startDate, endDate, type, demandTimeTab } = this.state;
    //     this.getRequest(tab, startDate, endDate, type, demandTimeTab, 'MALE', ppiInfo, fDeault);
    // };

    getRequest = async (tab, startDate, endDate, type, demandTimeTab, currentTab, ppiInfo, selected, modelList) => {
        if (tab === 'BRAND') {
            await this.props.brandAndGenderStore.genderAnalysisBrandDemandRequest({
                dateType: type,
                startDate,
                endDate,
                dataType: demandTimeTab,
                gender: [currentTab],
                level: tab,
                brandList: ppiInfo.data,
                ppiList: ppiInfo.ppi,
                ppiInfo,
                genderTab: currentTab,
            });

            await this.props.brandAndGenderStore.genderAnalysisBrandIndexRequest({
                dateType: type,
                startDate,
                endDate,
                dataType: demandTimeTab,
                gender: [currentTab],
                level: tab,
                brandList: selected,
                genderTab: currentTab,
            });
        } else if (tab === 'FRANCHISE') {
            await this.props.brandAndGenderStore.genderAnalysisFranchiseDemandRequest({
                dateType: type,
                startDate,
                endDate,
                dataType: demandTimeTab,
                gender: [currentTab],
                level: tab,
                franchiseList: selected,
                ppiInfo,
                genderTab: currentTab,
                modelList
            });

            await this.props.brandAndGenderStore.genderAnalysisFranchiseIndexRequest({
                dateType: type,
                startDate,
                endDate,
                dataType: demandTimeTab,
                gender: [currentTab],
                level: tab,
                franchiseList: selected,
                genderTab: currentTab,
                modelList
            });
        } else {
            await this.props.brandAndGenderStore.genderAnalysisCategoryDemandRequest({
                dateType: type,
                startDate,
                endDate,
                dataType: demandTimeTab,
                gender: [currentTab],
                level: tab === 'CATEGORY' ? 'INNER_SUB_CATEGORY': tab,
                subCategoryList: ppiInfo.data.map(item => item.toUpperCase()),
                ppiList: ppiInfo.ppi,
                ppiInfo,
                genderTab: currentTab,
            });

            await this.props.brandAndGenderStore.genderAnalysisCategoryIndexRequest({
                dateType: type,
                startDate,
                endDate,
                dataType: demandTimeTab,
                gender: [currentTab],
                level: tab === 'CATEGORY' ? 'INNER_SUB_CATEGORY': tab,
                subCategoryList: selected.map(item => item.toUpperCase()),
                genderTab: currentTab,
            });
        }
    };

    onConfirm = (checkedList, gender, type) => {
        this.setState({
            currentTab: gender,
            typeTab: type
        })
        if (type === 'FRANCHISE') {
            this.handleSelectedConfirm(checkedList, gender)
        } else if (type === 'BRAND') {
            this.handleConfirm(checkedList, gender)
        } else {
            this.handleCategoryConfirm(checkedList, gender)
        }
    }

    handleCategoryConfirm = async (checkedList, gender) => {
        const groupByList = groupedByNumber(2, checkedList, '');
        const ppiData = handlePPI(checkedList, gender);
        const ppiInfo = {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: checkedList,
            data: checkedList,
            ppi: ['TOTAL']
        };
        this.setState({
            selectedList: checkedList,
            selectGroupList: groupByList,
            currentTab: gender,
            ppiData,
            ppiInfo,
        });

        const { startDate, endDate, type, demandTimeTab } = this.state;
        await this.getRequest('CATEGORY', startDate, endDate, type, demandTimeTab, gender, ppiInfo, checkedList, []);
    }

    handleSelectedConfirm = async (checkedList, gender) => {
        const groupByList = groupedByNumber(2, checkedList, '');
        const ppiData = handlePPI(checkedList, gender);
        const ppiInfo = {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: checkedList,
            data: checkedList,
            ppi: ['TOTAL']
        };
        this.setState({
            selectedList: checkedList,
            selectGroupList: groupByList,
            currentTab: gender,
            ppiData,
            ppiInfo,
        });

        const { startDate, endDate, type, demandTimeTab } = this.state;
        const franchiseList = checkedList.map((item) => item.length && item[1] && item[1].value);
        const modelList = checkedList.map((item) => item.length && item[item.length - 1] && item[item.length - 1].value);
        await this.getRequest('FRANCHISE', startDate, endDate, type, demandTimeTab, gender, ppiInfo, franchiseList, modelList);
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'pdf') {
            this.ele &&
                downloadPDF(
                    this.ele,
                    () => {
                        console.log('download success');
						this.props.commonStore.setDownloadDialog(false);
						this.props.commonStore.setMenuName('');
                    },
                    () => {
                        console.log('download fail');
					},
					name
                );
        } else if (type === 'xlsx') {
			const { genderAnalysisResult } = this.props.brandAndGenderStore;
			const { demandTimeTab, type, typeTab } = this.state;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(8)
			}
			let rowsNum = 0
			let sheetData = []

			const demandColumn = demandColumns(type);
			const { data, colsNumber, rowsNumber } = handleDemandData(demandColumn, type, demandTimeTab, genderAnalysisResult, typeTab);
			sheetData = [...sheetData, ...data];
			options['!merges'].push({
				s: { r: 0, c: 0 },
				e: { r: 0, c: colsNumber - 1 }
			})
			rowsNum = rowsNumber + rowsNum;

			const ppiColumn = ppiColumns(type);
			const { data: ppiLineData, colsNumber: ppiColsNumber, rowsNumber: ppiRowsNumber } = handlePPILineData(ppiColumn, type, demandTimeTab, genderAnalysisResult, typeTab);
			sheetData = [...sheetData, ...ppiLineData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: ppiColsNumber - 1 }
			})
			rowsNum = ppiRowsNumber + rowsNum;

			const rviColumn = rviColumns(type);
			const { data: rviData, colsNumber: rviColsNumber, rowsNumber: rviRowsNumber } = handleRVIData(rviColumn, type, demandTimeTab, genderAnalysisResult, typeTab);
			sheetData = [...sheetData, ...rviData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: rviColsNumber - 1 }
			})
			rowsNum = rviRowsNumber + rowsNum;

			const diColumn = diColumns(type);
			const { data: diData, colsNumber: diColsNumber, rowsNumber: diRowsNumber } = handleDIData(diColumn, type, demandTimeTab, genderAnalysisResult, typeTab);
			sheetData = [...sheetData, ...diData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: diColsNumber - 1 }
			})
			rowsNum = diRowsNumber + rowsNum;

			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    handleChangeColor = (color, type) => {
        const c = this.state.colors;
        c[type] = color;

        this.setState({
            colors: c,
        });
    };

    handleDateConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        const { demandTimeTab, ppiInfo, selectedList, typeTab, currentTab } = this.state;
        if (start && end) {
            this.setState({
                startDate: start,
                endDate: end,
                type,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            const select = (typeTab === 'BRAND' || typeTab === 'CATEGORY') ? selectedList : selectedList.map((item) => item.length && item[1] && item[1].value);
            const modelList = typeTab === 'FRANCHISE' ? selectedList.map((item) => item.length && item[item.length - 1] && item[item.length - 1].value) : []
            await this.getRequest(typeTab, start, end, type, demandTimeTab, currentTab, ppiInfo, select, modelList);
        }
	};
	
	getPPIDesc = (dateRange, genderAnalysisPPIData, ppiInfo, currentTab, typeTab) => {
		const lastDate = dateRange[dateRange.length - 1];
		const descMap = {
            brand: typeTab === 'BRAND' ? 'brandClean' : typeTab === 'CATEGORY' ? 'subCategory' : 'model',
            ppi: 'ppiTier',
        };

        const genderDescMap = {
            brand: 'gender',
            ppi: 'ppiTier',
		};
		
		const genderTypeTabMap = {
            BRAND: 'brandClean',
            FRANCHISE: 'model',
            CATEGORY: 'subCategory'
        };
		return handleLegend(genderAnalysisPPIData, currentTab === 'ALL' ? genderDescMap[ppiInfo.type] : descMap[ppiInfo.type], lastDate, (typeTab === 'BRAND' || typeTab === 'CATEGORY') ? ppiInfo : '', currentTab, genderTypeTabMap[typeTab]);
	}

	getDesc = (dateRange, genderAnalysisData, currentTab, typeTab) => {
		const lastDate = dateRange[dateRange.length - 1];
		const genderTypeTabMap = {
            BRAND: 'brandClean',
            FRANCHISE: 'model',
            CATEGORY: 'subCategory'
        };
		return handleLegend(genderAnalysisData, currentTab === 'ALL' ? 'gender' : typeTab === 'BRAND' ? 'brandClean' : typeTab === 'CATEGORY' ? 'subCategory' : 'model', lastDate, '', currentTab, genderTypeTabMap[typeTab]);
	}

	handleSliderChange = (sliderArr, type) => {
		if (type === 'demand') {
			this.props.brandAndGenderStore.changeDemandDateRange(sliderArr)
		} else if (type === 'ppi') {
			this.props.brandAndGenderStore.changeIndexDateRange1(sliderArr)
		} else if (type === 'resaleVolumeIndex') {
			this.props.brandAndGenderStore.changeIndexDateRange2(sliderArr)
		} else if (type === 'diversity') {
			this.props.brandAndGenderStore.changeIndexDateRange3(sliderArr)
		}
	}

    render() {
        const { genderAnalysisResult, genderAnalysisPPIData, genderAnalysisData } = this.props.brandAndGenderStore;
        const { demandTimeTab, selectedList, selectGroupList, typeTab, currentTab, startDate, endDate, type, originalDate, ppiData, ppiInfo, colors } = this.state;
		const { isDialogOpen, timeRangeMonth, timeRangeWeek, brandDict, franchiseDict } = this.props.commonStore;

        return (
            <div className="flex-1 flex flex-column hidden p20 gender-analysis-bg" ref={(_f) => (this.ele = _f)}>
                <div className="flex align-center title-box">
					<div className="gender-title"></div>
				</div>
                <div className="flex mb14">
                    <div className="select-item-box flex align-center p8 border-box mr18">
                        <GenderCascadeDrop brandDict={brandDict} franchiseDict={franchiseDict} onConfirm={this.onConfirm} currentList={selectedList} gender={currentTab} type={typeTab}></GenderCascadeDrop>

                        {/* {typeTabList.map((tab, index) => (
                            <div
                                key={index}
                                className={cx('brand-analysis-time border-box hover flex align-center justify-center', {
                                    'brand-analysis-time-active': typeTab === tab.alias,
                                })}
                                onClick={() => this.handleTypeTab(tab.alias)}
                            >
                                {tab.name}
                            </div>
                        ))} */}
                    </div>
                    <div className="flex-1 mr18 hidden">
                        <div className="select-item-box p8 flex border-box full-width">
                            {/* <div
                                className={cx('border-box flex align-center justify-center', {
                                    'brand-select-box': typeTab === 'BRAND',
                                    'gender-select-box': typeTab === 'FRANCHISE',
                                })}
                            >
                                {typeTab === 'BRAND' ? <DropBox title="Select Brand" data={data} tabList={dropTabList} onConfirm={this.handleConfirm} currentList={selectedList} currentTab={currentTab}></DropBox> : <CascadeDrop title="Select Franchise" data={data} tabList={dropTabList} onConfirm={this.handleSelectedConfirm} currentList={selectedList} currentTab={currentTab}></CascadeDrop>}
                            </div> */}
                            <div className="flex flex-1 hidden">
                                {typeTab !== 'FRANCHISE'
                                    ? selectGroupList.map((group, index) => (
                                          <div key={index} className="flex flex-column ml30 flex-1 hidden">
                                              {group.map((item, i) => (
                                                  item && <CommonTootip key={`${index}-${i}`} placement="top" title={<div>{(item && (currentTab !== 'ALL' ? `${item}-${currentTab}` : item)) || ''}</div>}>
                                                      <div className="flex-1 flex align-center select-brand-item" key={`${index}-${i}`}>
                                                          {(item && (
                                                              <div className="mr4">
                                                                  <StyledCheckBox checked={true} indeterminate={true} onChange={() => this.handleRemoveSelected(item)} />
                                                              </div>
                                                          )) ||
                                                              null}
                                                          <div className="text-overflow hover">{(item && (currentTab !== 'ALL' ? `${item}-${currentTab}` : item)) || ''}</div>
                                                      </div>
                                                  </CommonTootip>
                                              ))}
                                          </div>
                                      ))
                                    : selectGroupList.map((group, index) => (
                                          <div key={index} className="flex flex-column ml30 flex-1 hidden">
                                              {group.map((item, i) => (
                                                  item && item.length > 0 && <CommonTootip key={`${index}-${i}`} placement="top" title={<div>{(item && (currentTab !== 'ALL' ? `${item[item.length - 1]['name']}-${currentTab}` : item[item.length - 1]['name'])) || ''}</div>}>
                                                      <div className="flex-1 flex align-center select-brand-item" key={`${index}-${i}`}>
                                                          {(item && (
                                                              <div className="mr4">
                                                                  <StyledCheckBox checked={true} indeterminate={true} onChange={() => this.handleRemoveSelected(item[item.length - 1])} />
                                                              </div>
                                                          )) ||
                                                              null}
                                                          <div className="text-overflow hover">{(item && (currentTab !== 'ALL' ? `${item[item.length - 1]['name']}-${currentTab}` : item[item.length - 1]['name'])) || ''}</div>
                                                      </div>
                                                  </CommonTootip>
                                              ))}
                                          </div>
                                      ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className={cx('select-item-box flex align-center px20 border-box', {
                            mr18: type === 'MONTH',
                        })}
                    >
                        <div className="icon-calendar brand-calendar-item mr20"></div>
                        <DatePicker isCenter={true} onConfirm={this.handleDateConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                    </div>
                    {type === 'MONTH' && (
                        <div className="select-item-box flex align-center p8 border-box">
                            {timeTabList.map((tab, index) => (
                                <div
                                    key={index}
                                    className={cx('brand-analysis-time border-box hover flex align-center justify-center', {
                                        'brand-analysis-time-active': demandTimeTab === tab.alias,
                                    })}
                                    onClick={() => this.handleDemandTimeTab(tab.alias)}
                                >
                                    {tab.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {genderAnalysisResult.map((brand, index) => (
                    <div
                        key={index}
                        className={cx('flex-1 flex', {
                            mb20: index !== genderAnalysisResult.length - 1,
                        })}
                    >
                        {brand.map((item, i) => {
                            const LineComponent = Visualization.get('line_chart');
                            return (
                                <div
                                    key={`${index}-${i}`}
                                    className={cx('flex-1 card-box flex flex-column', {
                                        mr18: i !== brand.length - 1,
                                    })}
                                >
                                    <div className="flex align-center brand-chart-title-box">
                                        <div>{typeTab === 'BRAND' ? item.title : index === 0 && i === 0 ? 'DEMAND' : item.title}</div>
                                        {(index === 0 && i === 0 && typeTab !== 'FRANCHISE' && (
                                            <div className="brand-chart-ppi-box flex align-center justify-center ml10">
                                                <PPIComponent data={ppiData} onConfirm={this.handlePPIConfirm}></PPIComponent>
                                            </div>
                                        )) ||
                                            null}
                                    </div>
                                    <div className="visualization flex-1 p10 flex flex-column">
										<div className="flex-1">
											{LineComponent && <LineComponent type={2} typeTab={typeTab} gender={currentTab} ppiInfo={(index === 0 && i === 0 && (typeTab === 'BRAND' || typeTab === 'CATEGORY') && ppiInfo) || null} data={item.data} changeColor={(color) => this.handleChangeColor(color, item.value)} colors={colors[item.value]} />}
										</div>
										<Slider range={item.dateRange} onChange={(value, sliderArr) => this.handleSliderChange(sliderArr, item.value)} />
									</div>
                                    <div className="info-box flex flex-column justify-between">
                                        {((index === 0 && i === 0 && this.getPPIDesc(item.currentDateRange, genderAnalysisPPIData, ppiInfo, currentTab, typeTab)) || this.getDesc(item.currentDateRange, genderAnalysisData, currentTab, typeTab)).map((group, m) => (
                                            <div key={m} className="flex-1 flex align-center">
                                                {group.map((desc, k) => {
                                                    return desc.key ? (
                                                        <div
                                                            style={{ width: '33%' }}
                                                            className={cx('flex full-height px5', {
                                                                'info-item-border': k !== group.length - 1,
                                                            })}
                                                            key={`${index}-${m}-${k}`}
                                                        >
                                                            {desc && <CommonTootip
                                                                placement="top"
                                                                title={
                                                                    <div className="flex align-center">
                                                                        <div
                                                                            className="dot"
                                                                            style={{
                                                                                background: colors[item.value][desc.key],
                                                                            }}
                                                                        />
                                                                        <div className="ml4 info-item-name-tooltip">{desc.key}</div>
                                                                    </div>
                                                                }
                                                            >
                                                                <div className={cx('border-box flex align-center hover flex-1 hidden')}>
                                                                    <div
                                                                        className="dot"
                                                                        style={{
                                                                            background: colors[item.value][desc.key],
                                                                        }}
                                                                    />
                                                                    <div className="ml4 info-item-name flex-1">{desc.key}</div>
                                                                </div>
                                                            </CommonTootip>}
                                                            <div className="flex align-center">
                                                                <div className="mr6 info-item-value">{formatM(desc.list[0][item.value])}</div>
                                                                {(desc.list[0][item.yoy] && (
                                                                    <div className="flex align-center mt4">
                                                                        {desc.list[0][item.yoy].indexOf('-') !== -1 ? (
                                                                            <ArrowDownward
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: '#FF5F60',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <ArrowUpward
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: '#4D9B4D',
                                                                                }}
                                                                            />
                                                                        )}
                                                                        <div
                                                                            className={cx('brand-percent', {
                                                                                'up-percent': desc.list[0][item.yoy].indexOf('-') === -1,
                                                                                'down-percent': desc.list[0][item.yoy].indexOf('-') !== -1,
                                                                            })}
                                                                        >
                                                                            {formatPercent(desc.list[0][item.yoy].replace('-', '')) || 0}
                                                                        </div>
                                                                    </div>
                                                                )) ||
                                                                    null}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex-1 flex full-height justify-between px5" key={`${index}-${m}-${k}`}></div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
            </div>
        );
    }
}
