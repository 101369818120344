import { withStyles, createStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '3.5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

export default StyledTextField;
