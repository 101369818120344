import * as React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { InputAdornment, LinearProgress } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Search, PlayArrow, Pause } from '@material-ui/icons';

import StyledTextField from '../components/StyledTextField';
import FranchiseLCADatePicker from '../components/FranchiseLCADatePicker';
import CommonDialog from '../components/CommonDownloadDialog';
import { Visualization } from '../visualization/index';
import MenuList from '../components/MenuList';

import { transformDate, handleDateStartEnd, downloadXlsx, getColsWpx } from '../../utils/index';
import {
	lcaColumns,
	handleLcaData
} from '../../utils/franchiseAnalysisDownload';
import '../../assets/css/franchise.css';

const StyleLinearProgress = withStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: 'rgba(255,255,255,0.12)',
            '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: 'rgba(255, 119, 58, 0.53)',
            },
        },
    })
)(LinearProgress);

const timeTab = [
    {
        name: 'SEASON',
        alias: 'QUARTER',
    },
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: 'WEEK',
        alias: 'WEEK',
    },
];

@inject('franchiseStore', 'commonStore', 'userStore')
@observer
export default class NikeFranchiseLCA extends React.Component {
    state = {
        searchValue: '',
        currentTab: 'MONTH',
        autoStatus: 'play',
        progressValueObj: {},
        timer: null,
        selectedList: [],
        startDate: null,
        endDate: null,
        originalDate: {},
        type: 'MONTH',
        colors: {},
        currentDate: null,
        originalDict: [],
        showDict: [],
    };

    async componentWillMount() {
        await this.props.commonStore.franchiseNikeDictRequest();
        const { franchiseNikeDefault, franchiseNikeDict } = this.props.commonStore;

        const { timeRangeMonth } = await this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 6);
        this.setState({
            selectedList: franchiseNikeDefault,
            originalDict: franchiseNikeDict,
            showDict: franchiseNikeDict,
            startDate,
            endDate,
            originalDate,
        });

        const { brandList, franchiseList } = this.handleBrandFranchise(franchiseNikeDefault);
        const { type } = this.state;
        await this.props.franchiseStore.franchiseNikeLcaRequest({
            dateType: type,
            startDate,
            endDate,
            brandList,
            franchiseList,
        });
        this.resetTimelineProgress();
    }

    resetTimelineProgress = () => {
        const { franchiseTimeline } = this.props.franchiseStore;
        if (franchiseTimeline.length) {
            this.setState({
                currentDate: franchiseTimeline[0].name,
            });
        }

        const obj = {};
        franchiseTimeline.forEach((time) => {
            obj[time.name] = 0;
        });
        this.setState({
            progressValueObj: obj,
        });
    };

    handleBrandFranchise = (selectedList) => {
        const brand = [];
        const franchise = [];
        selectedList.forEach((item) => {
            if (item.length) {
                brand.push(item[0].value);
                franchise.push(item[1].value);
            }
        });

        return {
            brandList: Array.from(new Set(brand)),
            franchiseList: Array.from(new Set(franchise)),
        };
    };

    changeColor = (color) => {
        this.setState({
            colors: color,
        });
    };

    handleCheckboxChange = async (franchise, product) => {
        const { selectedList } = this.state;
        let index = null;
        selectedList.forEach((item, i) => {
            if (item.length) {
                if (item[0].value === franchise.value && item[1].value === product.value) {
                    index = i;
                }
            }
        });
        let newSelectedList = [];
        if (index !== null) {
            newSelectedList = [...selectedList.slice(0, index), ...selectedList.slice(index + 1)];
        } else {
            newSelectedList = [...selectedList, [franchise, product]];
        }
        const color = {};
        newSelectedList.forEach((item) => {
            if (item.length) {
                color[item[0].value] = {};
            }
        });
        this.setState({
            selectedList: newSelectedList,
        });

        const { brandList, franchiseList } = this.handleBrandFranchise(newSelectedList);
        const { type, startDate, endDate } = this.state;
        await this.props.franchiseStore.franchiseNikeLcaRequest({
            dateType: type,
            startDate,
            endDate,
            brandList,
            franchiseList,
        });
        this.resetTimelineProgress();
    };

    handleSearch = (value) => {
        const { franchiseNikeDict } = this.props.commonStore;
        const arr = [];
        franchiseNikeDict.forEach((franchise) => {
            if (franchise.extraData) {
                const extraData = (franchise.extraData || []).filter((item) => item.value.toLowerCase().indexOf(value.toLowerCase()) !== -1);
                if (extraData.length) {
                    arr.push({
                        ...franchise,
                        extraData,
                    });
                }
            }
        });
        this.setState({
            searchValue: value,
            showDict: arr,
        });
    };

    handleDateConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        if (start && end) {
            this.setState({
                startDate: start,
                endDate: end,
                type,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            const { selectedList } = this.state;
            const { brandList, franchiseList } = this.handleBrandFranchise(selectedList);
            await this.props.franchiseStore.franchiseNikeLcaRequest({
                dateType: type,
                startDate: start,
                endDate: end,
                brandList,
                franchiseList,
            });
            this.resetTimelineProgress();
        }
    };

    handleTimeLine = () => {
        let value = 0;
        let isLast = false;
        this.timer = setInterval(() => {
            let key = '';
            let flag = true;
            if (value >= 100) {
                setTimeout(() => {
                    value = 0;
                }, 200);
            } else {
                Object.entries(this.state.progressValueObj).forEach(([k, v], i) => {
                    if (v < 100 && flag) {
                        key = k;
                        flag = false;
                        if (i === Object.entries(this.state.progressValueObj).length - 1) {
                            isLast = true;
                        }
                    }
                });
                if (isLast) {
                    clearInterval(this.timer);
                    this.timer = null;
                    this.setState({
                        autoStatus: 'play',
                    });
                } else {
                    value += (100 * 100) / 3000;
                    if (value > 100) {
                        value = 100;
                    }
                    this.setState({
                        progressValueObj: {
                            ...this.state.progressValueObj,
                            [key]: value,
                        },
                    });
                }
            }
            const { currentDate } = this.state;
            if (key && String(key) !== String(currentDate)) {
                this.setState({
                    currentDate: String(key),
                });
            }
        }, 100);
        this.setState({
            autoStatus: 'pause',
        });
    };

    handleDotClick = (index) => {
        const { franchiseTimeline } = this.props.franchiseStore;
        const obj = {};
        franchiseTimeline.forEach((time, i) => {
            if (i < index) {
                obj[time.name] = 100;
            } else {
                obj[time.name] = 0;
            }
        });
        this.setState({
            progressValueObj: obj,
        });
        clearInterval(this.timer);
        this.timer = null;
        this.setState({
            autoStatus: 'play',
            currentDate: franchiseTimeline[index].name,
        });
    };

    handleChangeStatus = (status) => {
        const { franchiseTimeline } = this.props.franchiseStore;
        if (franchiseTimeline.length > 1) {
            if (status === 'play') {
                const lastValue = this.state.progressValueObj[franchiseTimeline[franchiseTimeline.length - 2]['name']];
                if (lastValue === 100) {
                    const obj = {};
                    franchiseTimeline.forEach((time) => {
                        obj[time.name] = 0;
                    });
                    this.setState({
                        progressValueObj: obj,
                    });
                    setTimeout(() => {
                        this.handleTimeLine();
                    }, 500);
                } else {
                    this.handleTimeLine();
                }
            } else {
                clearInterval(this.timer);
                this.timer = null;
                this.setState({
                    autoStatus: 'play',
                });
            }
        }
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'xlsx') {
			const { franchiseLca } = this.props.franchiseStore;
			const { type } = this.state;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(6)
			}
			let sheetData = []

			const lcaColumn = lcaColumns(type);
			const { data, colsNumber } = handleLcaData(lcaColumn, franchiseLca);
			sheetData = [...sheetData, ...data];
			options['!merges'].push({
				s: { r: 0, c: 0 },
				e: { r: 0, c: colsNumber - 1 }
			})

			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
        this.props.commonStore.setDownloadDialog(false);
        this.props.commonStore.setMenuName('');
    };

    render() {
        const { searchValue, currentTab, autoStatus, progressValueObj, colors, currentDate, showDict, startDate, endDate, type, originalDate, selectedList } = this.state;
        const VisualizationComponentBubble = Visualization.get('bubble_chart');
        const { isDialogOpen, timeRangeMonth, timeRangeWeek } = this.props.commonStore;
		const { franchiseLca, franchiseTimeline } = this.props.franchiseStore;
		
        return (
            <div className="flex-1 flex flex-column hidden p20 franchise-bg relative" ref={(_f) => (this.ele = _f)}>
                <div className="flex-1 flex flex-column z-index-2 full-height">
					<div className="flex align-center title-box">
						<div className="franchise-lca-title"></div>
					</div>
                    <div className="flex-1 hidden card-box border-box flex flex-column">
                        <div className="card-content full-height flex p20 border-box flex-1 hidden">
                            <div className="mr10 flex flex-column border-box">
                                <div className="flex align-center" style={{ width: '180px' }}>
                                    <StyledTextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search fontSize="small" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth={true}
                                        value={searchValue}
                                        onChange={(e) => this.handleSearch(e.target.value)}
                                        id="search"
                                        type="search"
                                        variant="outlined"
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="flex-1 pt5 overflow-y">
                                    <MenuList data={showDict} selectedList={selectedList} colorsData={colors} onChange={this.handleCheckboxChange}></MenuList>
                                </div>
                            </div>
                            <div className="flex-1 nike-sku-visualization p10">
                                <VisualizationComponentBubble data={franchiseLca} currentDate={currentDate} changeColor={this.changeColor} from="franchise" xName={'Premium Price Index'} yName={'Sales Volume'} />
                            </div>
                        </div>
                        <div className="flex align-center mb20 timeline-box">
                            <div className="time-tab-box flex align-center mr10">
                                {timeTab.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={cx('flex-1 flex align-center justify-center lca-tab hover', {
                                            'lca-tab-active': currentTab === tab.alias,
                                        })}
                                        onClick={() => this.setState({ currentTab: tab.alias })}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </div>
                            <div className="time-picker mr10">
                                <FranchiseLCADatePicker monthRange={timeRangeMonth} weekRange={timeRangeWeek} onConfirm={this.handleDateConfirm} currentTab={currentTab} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></FranchiseLCADatePicker>
                            </div>
                            <div className="mr20 start-pause-button flex align-center justify-center hover" onClick={() => this.handleChangeStatus(autoStatus)}>
                                {autoStatus === 'play' ? <PlayArrow style={{ color: '#FF773A' }} /> : <Pause style={{ color: '#FF773A' }} />}
                            </div>
                            <div className="flex-1 flex">
                                {franchiseTimeline.map((line, index) => (
                                    <div
                                        className={cx({
                                            'flex-1': index !== franchiseTimeline.length - 1,
                                        })}
                                        key={index}
                                    >
                                        <div className="flex align-center mb6">
                                            <div className="timeline-dot hover" onClick={() => this.handleDotClick(index)}></div>
                                            {(index !== franchiseTimeline.length - 1 && (
                                                <div className="flex-1">
                                                    <StyleLinearProgress variant="determinate" value={progressValueObj[line.name] || 0} />
                                                </div>
                                            )) ||
                                                null}
                                        </div>
                                        <div
                                            className={cx('timeline-text', {
                                                'timeline-text-pos': index !== 0,
                                                'timeline-text-pos-last': index === franchiseTimeline.length - 1,
                                            })}
                                        >
                                            {line.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel} disable='pdf'></CommonDialog>
            </div>
        );
    }
}
