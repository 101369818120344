import { Checkbox } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const StyledCheckBox = withStyles((theme) =>
    createStyles({
        root: {
            color: '#fff',
            opacity: 0.5,
            background: '#000',
            padding: '0px',
            fontSize: '12px',
            borderRadius: '100%',
            overflow: 'hidden',
            width: '12px',
            height: '12px',
            '&.MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#fff',
            },
            '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
                opacity: 1,
            },
        },
    })
)(Checkbox);

export default StyledCheckBox;
