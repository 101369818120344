import * as React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

const withLoading = (WrappedComponent) =>
    class WithLoading extends React.Component {
        render() {
            return (
                <div className="relative full-width full-height">
                    <WrappedComponent {...this.props} />
                    {this.props.isLoading ? (
                        <div className="absolute left top full-width full-height flex align-center justify-center bgwhiteOpacity" style={{ minHeight: '200px' }}>
                            <CircularProgress size="20px" style={{ color: '#fff' }} />
                            <Typography style={{ color: '#fff' }} className="pl10" variant="h5">
                                loading...
                            </Typography>
                        </div>
                    ) : null}
                </div>
            );
        }
    };

export { withLoading };
