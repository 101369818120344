export const demandColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'group_name',
			value: 'name'
		},
		{
			name: 'demand',
			value: 'value'
		}
	]
	return arr
}

export const ppiColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'group_name',
			value: 'name'
		},
		{
			name: 'ppi',
			value: 'value'
		}
	]
	return arr
}

export const rviColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'group_name',
			value: 'name'
		},
		{
			name: 'resale_volume_index',
			value: 'value'
		}
	]
	return arr
}

export const diColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'group_name',
			value: 'name'
		},
		{
			name: 'diversity',
			value: 'value'
		}
	]
	return arr
}

export const handleTreemapTimeData = (columns, type, startDate, endDate, data) => {
	const title = []
	const headerData = columns.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'SUB-CATEGORY BRAND SHARE BY PRICE TIER'
	const arr = [];
	arr.push(title)
	arr.push(headerData)
	arr.push([type.toLowerCase(), startDate, endDate, data.totalDemand])
	
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleTreemapBrandData = (columns, data) => {
	const headerData = columns.map(h => {
		return h.name
	});
	const arr = [];
	arr.push(headerData)

    const lifestyleDemand = Math.round(Number(data.lifestyleDemand) * 100) + '%';
    const runningDemand = Math.round(Number(data.runningDemand) * 100) + '%';
    const basketballDemand = Math.round(Number(data.basketballDemand) * 100) + '%';
    const othersDemand = Math.round(Number(data.othersDemand) * 100) + '%';

	arr.push(['brand share', lifestyleDemand, runningDemand, basketballDemand, othersDemand])
	
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleTreemapData = (columns, treemapTab, data) => {
	const headerData = columns.map(h => {
		return h.name
	});
	const arr = [];
	arr.push(headerData)

	data.forEach(item => {
		item.itemList.forEach(list => {
			const itemArr = []
			columns.forEach(column => {
				if (column.name === 'price_type') {
					itemArr.push(treemapTab)
				} else if (column.name === 'price_tier') {
					itemArr.push(item.priceTierName)
				} else {
					itemArr.push(list[column.value] || '')
				}
			})
			arr.push(itemArr)
		})
	})
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleLineData = (columns, treemapTab, data, priceTierName) => {
	const title = []
	const headerData = columns.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = `BRAND DEMAND OF ${treemapTab === 'RESALE_PRICE' ? 'RESALE': treemapTab} PRICE RANGE ${priceTierName}`
	const arr = [];
	arr.push(title)
	arr.push(headerData)

	Object.entries(data).forEach(([key, value]) => {
		value.forEach(item => {
			const itemArr = [];
			columns.forEach(column => {
				if (column.name === 'price_type') {
					itemArr.push(treemapTab)
				} else {
					itemArr.push(item[column.value] || '')
				}
			})
			arr.push(itemArr)
		})
	})
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}