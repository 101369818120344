import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Menu } from '@material-ui/core';
import cx from 'classnames';
import moment from 'moment';

import OverviewDrop from './components/OverviewDrop';
import DatePicker from './components/DatePicker';
import CommonDialog from './components/CommonDownloadDialog';
import CommonTootip from './components/StyledTooltip';
import Slider from './components/Slider'
import { Visualization } from './visualization/index';

import { getMonth, downloadPDF, formatM, formatPercent, brandColor, handleDateStartEnd, getWeekStartEnd, transformDate, downloadXlsx, getColsWpx } from '../utils/index';
import {
	demandColumns,
	brandShareColumns,
	ppiColumns,
	treemapTimeColumns,
	treemapBrandColumns,
	treemapColumns,
	lineColumns,
	handleDemandData,
	handleBrandShareData,
	handlePPIData,
	handleTreemapTimeData,
	handleTreemapBrandData,
	handleTreemapData,
	handleLineData
} from '../utils/overviewDownload';
import { withStyles } from '@material-ui/core/styles';
import '../assets/css/overview.css';

const StyledMenu = withStyles({
    paper: {
        background: 'rgba(0, 0, 0, 0.84)',
        border: '1px solid rgba(100, 100, 100, 0.49)',
        color: '#fff',
        borderRadius: '4px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} {...props} />);

const brandShareTabList = [
    {
        name: 'FTW',
        alias: 'FTW',
    },
    {
        name: 'APP',
        alias: 'APP',
    },
];

const timeTabList = [
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: '12M',
        alias: 'M12',
    },
];

const tabList = [
    {
        name: 'DEMAND',
        alias: 'demand',
    },
    {
        name: 'Brand Share',
        alias: 'brand',
    },
];

const treemapTabList = [
    {
        name: 'MSRP',
        alias: 'MSRP',
    },
    {
        name: 'Resale Price',
        alias: 'RESALE_PRICE',
    },
];

@inject('overviewStore', 'commonStore', 'userStore')
@observer
export default class Overview extends React.Component {
    state = {
        active: 0,
        demandTab: 'demand',
        demandTimeTab: 'MONTH',
        brandShareTimeTab: 'MONTH',
        brandShareTab: 'FTW',
        ppiTimeTab: 'MONTH',
        treemapTab: 'MSRP',
        type: 'MONTH',
        anchorEle: null,
        startDate: null,
        endDate: null,
        originalDate: {},
        currentLineInfo: {},
        lineLegendInfo: [],
        treemapLineColor: {},
        treemapStartDate: null,
        treemapEndDate: null,
        treemapType: 'MONTH',
        treemapOriginalDate: {},
		colors: {},
    };

    async componentDidMount() {
        const { timeRangeMonth } = await this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 12);
        const { type } = this.state;
        this.setState({
            startDate,
            endDate,
            originalDate,
            treemapStartDate: startDate,
            treemapEndDate: endDate,
            treemapOriginalDate: originalDate,
        });
        await this.getRequest(startDate, endDate, type);
    }

    getRequest = async (startDate, endDate, type) => {
        const { demandTimeTab, brandShareTimeTab, brandShareTab, ppiTimeTab, treemapTab } = this.state;
        await this.props.overviewStore.overviewDemandRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: demandTimeTab,
        });
        await this.props.overviewStore.overviewBrandShareRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: brandShareTimeTab,
            category: brandShareTab,
        });
        await this.props.overviewStore.overviewPPIRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: ppiTimeTab,
        });
        await this.props.overviewStore.overviewPriceTierSummaryRequest({
            startDate,
            endDate,
            dateType: type,
        });
        await this.props.overviewStore.overviewPriceTierTreemapRequest({
            startDate,
            endDate,
            dateType: type,
            priceType: treemapTab,
        });

        const { overviewPriceTierTreemap } = this.props.overviewStore;
        if (overviewPriceTierTreemap.length) {
            this.setState({
                currentLineInfo: overviewPriceTierTreemap[0],
                lineLegendInfo: overviewPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
            });
            await this.props.overviewStore.overviewPriceTierLineRequest({
                startDate,
                endDate,
                dateType: type,
                priceType: treemapTab,
                priceTierCode: overviewPriceTierTreemap[0].priceTierCode,
                brandList: overviewPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
            });
        }
    };

    handleChange = async (index, e, data) => {
        const { treemapTab, treemapStartDate, treemapEndDate, treemapType } = this.state;
        this.setState({
            active: index,
            // anchorEle: e.currentTarget,
            currentLineInfo: data,
			lineLegendInfo: data.brandDictList.slice(0, 3) || [],
        });
        await this.props.overviewStore.overviewPriceTierLineRequest({
            startDate: treemapStartDate,
            endDate: treemapEndDate,
            dateType: treemapType,
            priceType: treemapTab,
            priceTierCode: data.priceTierCode,
            brandList: data.brandDictList.slice(0, 3) || [],
        });
    };

    handleConfirm = async (selected, type, original) => {
        if (type === 'MONTH') {
            const start = Number(selected[0].replace('-', ''));
            const end = Number(selected[1].replace('-', ''));
            this.setState({
                type,
                treemapStartDate: start,
                treemapEndDate: end,
                treemapType: type,
                treemapOriginalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
                startDate: start,
                endDate: end,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            await this.getRequest(start, end, type);
        } else if (type === 'WEEK') {
            const { start, end } = getWeekStartEnd(selected);
            this.setState({
                type,
                treemapStartDate: start,
                treemapEndDate: end,
                treemapType: type,
                treemapOriginalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
                startDate: start,
                endDate: end,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            await this.getRequest(start, end, type);
        }
    };

    handleTabChange = (tab) => {
		const { demandDateArr, brandShareDateArr } = this.props.overviewStore
		this.props.overviewStore.changeDemandCurrentDateRange(demandDateArr)
		this.props.overviewStore.changeBrandShareCurrentDateRange(brandShareDateArr)
        this.setState({
			demandTab: tab,
        });
    };

    handleDemandTimeTab = async (tab) => {
        this.setState({
			demandTimeTab: tab,
        });
        const { type, startDate, endDate } = this.state;
        await this.props.overviewStore.overviewDemandRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: tab,
        });
    };

    handleBrandShareTab = async (tab) => {
        const { startDate, endDate, type, brandShareTimeTab } = this.state;
        this.setState({
            brandShareTab: tab,
        });
        await this.props.overviewStore.overviewBrandShareRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: brandShareTimeTab,
            category: tab,
        });
    };

    handleBrandTimeTab = async (tab) => {
        const { startDate, endDate, type, brandShareTab } = this.state;
        this.setState({
			brandShareTimeTab: tab,
        });
        await this.props.overviewStore.overviewBrandShareRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: tab,
            category: brandShareTab,
        });
    };

    handlePPITimeTab = async (tab) => {
        const { type, startDate, endDate } = this.state;
        this.setState({
			ppiTimeTab: tab,
        });
        await this.props.overviewStore.overviewPPIRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: tab,
        });
    };

    handleTreemapTab = async (tab) => {
        const { treemapStartDate, treemapEndDate, treemapType } = this.state;
        this.setState({
			treemapTab: tab,
        });
        await this.props.overviewStore.overviewPriceTierSummaryRequest({
            startDate: treemapStartDate,
            endDate: treemapEndDate,
            dateType: treemapType,
        });
        await this.props.overviewStore.overviewPriceTierTreemapRequest({
            startDate: treemapStartDate,
            endDate: treemapEndDate,
            dateType: treemapType,
            priceType: tab,
        });
        const { overviewPriceTierTreemap } = this.props.overviewStore;
        if (overviewPriceTierTreemap.length) {
            this.setState({
                currentLineInfo: overviewPriceTierTreemap[0],
                lineLegendInfo: overviewPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
            });
            await this.props.overviewStore.overviewPriceTierLineRequest({
                startDate: treemapStartDate,
                endDate: treemapEndDate,
                dateType: treemapType,
                priceType: tab,
                priceTierCode: overviewPriceTierTreemap[0].priceTierCode,
                brandList: overviewPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
            });
        }
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'pdf') {
            this.ele &&
                downloadPDF(
                    this.ele,
                    () => {
                        console.log('download success');
						this.props.commonStore.setDownloadDialog(false);
						this.props.commonStore.setMenuName('');
                    },
                    () => {
                        console.log('download fail');
					},
					name
                );
        } else if (type === 'xlsx') {
			const { type, demandTimeTab, demandTab, brandShareTab, treemapType, treemapStartDate, treemapEndDate, treemapTab, currentLineInfo } = this.state;
			const { overviewDemandResult, overviewBrandShareResult, overviewPPIResult, overviewPriceTierSummary, overviewPriceTierTreemap, overviewPriceTierLineResult } = this.props.overviewStore;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(5)
			}
			let rowsNum = 0
			let sheetData = []

			if (demandTab === 'demand') {
				const demandColumn = demandColumns(type);
				const { data, colsNumber, rowsNumber } = handleDemandData(demandColumn, type, demandTimeTab, overviewDemandResult);
				sheetData = [...sheetData, ...data];
				options['!merges'].push({
					s: { r: 0, c: 0 },
					e: { r: 0, c: colsNumber - 1 }
				})
				rowsNum = rowsNumber;
			} else {
				const column = brandShareColumns(type);
				const {data, colsNumber, rowsNumber} = handleBrandShareData(column, type, demandTimeTab, overviewBrandShareResult, brandShareTab);
				sheetData = [...sheetData, ...data];
				options['!merges'].push({
					s: { r: 0, c: 0 },
					e: { r: 0, c: colsNumber - 1 }
				})
				rowsNum = rowsNumber;
			}

			const ppiColumn = ppiColumns(type)
			const { data: ppiData, colsNumber: ppiColsNumber, rowsNumber: ppiRowsNumber } = handlePPIData(ppiColumn, type, demandTimeTab, overviewPPIResult);
			sheetData = [...sheetData, ...ppiData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: ppiColsNumber - 1 }
			})
			rowsNum = ppiRowsNumber + rowsNum;

			const treemapTimeColumn = treemapTimeColumns;
			const { data: treemapTimeData, colsNumber: treemapTimeColsNumber, rowsNumber: treemapTimeRowsNumber } = handleTreemapTimeData(treemapTimeColumn, treemapType, treemapStartDate, treemapEndDate, overviewPriceTierSummary);
			sheetData = [...sheetData, ...treemapTimeData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: treemapTimeColsNumber - 1 }
			})
			rowsNum = treemapTimeRowsNumber + rowsNum;

			const treemapBrandColumn = treemapBrandColumns;
			const { data: treemapBrandData, rowsNumber: treemapBrandRowsNumber } = handleTreemapBrandData(treemapBrandColumn, overviewPriceTierSummary);
			sheetData = [...sheetData, ...treemapBrandData];
			rowsNum = treemapBrandRowsNumber + rowsNum;
			
			const treemapColumn = treemapColumns;
			const { data: treemapData, rowsNumber: treemapRowsNumber } = handleTreemapData(treemapColumn, treemapTab, overviewPriceTierTreemap);
			sheetData = [...sheetData, ...treemapData];
			rowsNum = treemapRowsNumber + rowsNum;

			const lineColumn = lineColumns(treemapType);
			const { data: lineData, colsNumber: lineColsNumber, rowsNumber: lineRowsNumber } = handleLineData(lineColumn, treemapTab, overviewPriceTierLineResult, currentLineInfo.priceTierName);
			sheetData = [...sheetData, ...lineData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: lineColsNumber - 1 }
			})
			rowsNum = lineRowsNumber + rowsNum;
			
			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
	};

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    getYoy = (data, category, name, yoyName, type) => {
        const arr = data[category];
        if (arr && arr.length) {
            const obj = arr[arr.length - 1];
            const date = obj.date;
            const year = String(date).substring(0, 4);
            const month = String(date).substring(4);
            const dateFormat = type === 'MONTH' ? getMonth(Number(month)) : `W${Number(month)}`;
            return {
                value: formatM(obj[name]),
                valueYoy: formatPercent(obj[yoyName]),
                year,
                date: dateFormat,
            };
        }
        return {
            value: '',
            valueYoy: '',
            year: '',
            date: '',
        };
    };

    getSummary = (data) => {
        const nike = (Number(data.nikeDemand) / Number(data.totalDemand)) * 100;
        const jordan = (Number(data.jordanDemand) / Number(data.totalDemand)) * 100;
        const converse = (Number(data.converseDemand) / Number(data.totalDemand)) * 100;

        return {
            nikeDemand: Math.round(nike) || 0,
            jordanDemand: Math.round(jordan) || 0,
            converseDemand: Math.round(converse) || 0,
            total: formatM(data.totalDemand),
        };
    };

    treemapLineChangeColor = (color) => {
        this.setState({
            treemapLineColor: color,
        });
    };

    handleLineBrandConfirm = async (data) => {
        const { treemapTab, currentLineInfo, treemapStartDate, treemapEndDate, treemapType } = this.state;
        await this.props.overviewStore.overviewPriceTierLineRequest({
            startDate: treemapStartDate,
            endDate: treemapEndDate,
            dateType: treemapType,
            priceType: treemapTab,
            priceTierCode: currentLineInfo.priceTierCode,
            brandList: data || [],
        });
        this.setState({
            lineLegendInfo: data,
        });
    };

    handleTreemapConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        if (start && end) {
            this.setState({
                treemapStartDate: start,
                treemapEndDate: end,
                treemapType: type,
                treemapOriginalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            const { treemapTab } = this.state;
            await this.props.overviewStore.overviewPriceTierSummaryRequest({
                startDate: start,
                endDate: end,
                dateType: type,
            });
            await this.props.overviewStore.overviewPriceTierTreemapRequest({
                startDate: start,
                endDate: end,
                dateType: type,
                priceType: treemapTab,
            });
            const { overviewPriceTierTreemap } = this.props.overviewStore;

            if (overviewPriceTierTreemap.length) {
                this.setState({
                    currentLineInfo: overviewPriceTierTreemap[0],
                    lineLegendInfo: overviewPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
                });
                await this.props.overviewStore.overviewPriceTierLineRequest({
                    startDate: start,
                    endDate: end,
                    dateType: type,
                    priceType: treemapTab,
                    priceTierCode: overviewPriceTierTreemap[0].priceTierCode || 1,
                    brandList: overviewPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
                });
            }
        }
    };

    changeBrandShareColor = (colors) => {
        this.setState({ colors });
	};
	
	handleSliderChangeDemand = (value, sliderArr) => {
		this.props.overviewStore.changeDemandCurrentDateRange(sliderArr)
	}

	handleSliderChangeBrandShare = (value, sliderArr) => {
		this.props.overviewStore.changeBrandShareCurrentDateRange(sliderArr)
	}

	handleSliderChangePPI = (value, sliderArr) => {
		this.props.overviewStore.changePPICurrentDateRange(sliderArr)
	}

	handleSliderChangeTreemapLine = (value, sliderArr) => {
		this.props.overviewStore.changeTreemapLineDateRange(sliderArr)
	}

    render() {
        const VisualizationComponentBar = Visualization.get('bar_chart');
        const VisualizationComponentLine = Visualization.get('line_chart');
        const VisualizationComponentBrandLine = Visualization.get('line_chart');
        const { overviewDemandResult, demandDateArrTemp, demandDateArr, overviewBrandShareResult, brandShareDateArrTemp, brandShareDateArr, overviewPPIResult, ppiDateArrTemp, ppiDateArr, overviewPriceTierSummary, overviewPriceTierTreemap, overviewPriceTierLineResult, treemapLineDateArr, treemapLineDateArrTemp } = this.props.overviewStore;
        const { isDialogOpen, timeRangeMonth, timeRangeWeek } = this.props.commonStore;
        const { active, demandTab, demandTimeTab, brandShareTab, brandShareTimeTab, ppiTimeTab, treemapTab, type, anchorEle, currentLineInfo, lineLegendInfo, treemapLineColor, startDate, endDate, treemapStartDate, treemapEndDate, treemapType, treemapOriginalDate, originalDate, colors } = this.state;
        const demandObj = this.getYoy(overviewDemandResult, 'TOTAL', 'demand', 'demandYoy', type);
        const ppiObj = this.getYoy(overviewPPIResult, 'TOTAL', 'ppi', 'ppiYoy', type);
        const { nikeDemand, jordanDemand, converseDemand, total } = this.getSummary(overviewPriceTierSummary);
        const list = (currentLineInfo.itemList || []).map((d) => ({
            name: d.brandClean,
		}));

        return (
            <div className="flex-1 flex flex-column hidden p20 overview-bg" ref={(_f) => (this.ele = _f)}>
                <div className="flex-1 flex flex-column">
                    <div className="flex align-center justify-between">
						<div className="flex align-center title-box">
							<div className="market-performance"></div>
						</div>
                        <div className="flex align-center pl10 pr10 calendar-box">
                            <div className="icon-calendar calendar-item"></div>
                            <DatePicker onConfirm={this.handleConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                        </div>
                    </div>
                    <div className="flex-1 flex">
                        <div className="flex-1 mr20 relative card-box performance-box">
                            <div className="full-width full-height flex flex-column p10 border-box">
                                <div className="demand-title-box flex align-center">
                                    {tabList.map((tab, index) => (
                                        <div
                                            key={index}
                                            className={cx('px20 demand-tab hover', {
                                                'demand-tab-active': demandTab === tab.alias,
                                            })}
                                            onClick={() => this.handleTabChange(tab.alias)}
                                        >
                                            {tab.name}
                                            <div
                                                className={cx('tab-line mt2', {
                                                    'tab-line-active': demandTab === tab.alias,
                                                })}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {demandTab === 'demand' ? (
                                    <div className="flex-1 demand-content-box flex flex-column">
                                        <div className="demand-chart-title-box flex align-center justify-between mb6">
                                            <div className="flex align-center">
                                                <div className="time-box flex flex-column align-center">
                                                    <div className="time-month flex align-center justify-center">{demandObj.date}</div>
                                                    <div className="time-year">{demandObj.year}</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="demand-chart-symbol">￥</div>
                                                    <div className="demand-chart-title-number">{demandObj.value || ''}</div>
                                                </div>
                                                {(type === 'MONTH' && demandObj.valueYoy && (
                                                    <div className="flex align-center">
                                                        <div className="demand-chart-icon flex align-center">
                                                            {demandObj.valueYoy && demandObj.valueYoy.indexOf('-') !== -1 ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" style={{ color: '#4D9B4D' }} />}
                                                            <div style={{ color: demandObj.valueYoy && demandObj.valueYoy.indexOf('-') !== -1 ? '#FF5F60' : '#4D9B4D' }}>{demandObj.valueYoy && demandObj.valueYoy.replace('-', '')}</div>
                                                        </div>
                                                        <div className="demand-chart-icon-text flex align-center">VS. LY</div>
                                                    </div>
                                                )) ||
                                                    null}
                                            </div>
                                            <div className="flex flex-column justify-between align-end full-height">
                                                <div className="flex align-center">
                                                    {(type === 'MONTH' &&
                                                        timeTabList.map((tab, index) => (
                                                            <div
                                                                key={index}
                                                                className={cx('legend-item hover flex align-center justify-center', {
                                                                    'selected-legend': demandTimeTab === tab.alias,
                                                                })}
                                                                onClick={() => this.handleDemandTimeTab(tab.alias)}
                                                            >
                                                                {tab.name}
                                                            </div>
                                                        ))) ||
                                                        null}
                                                </div>
                                                <div className="flex align-center">
                                                    <div className="flex align-center mr34 legend-text">
                                                        <div className="bar-legend ftw-color" />
                                                        FTW
                                                    </div>
                                                    <div className="flex align-center legend-text">
                                                        <div className="bar-legend app-color" />
                                                        APP
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1">{VisualizationComponentBar && <VisualizationComponentBar allDate={demandDateArrTemp} data={overviewDemandResult} type={demandTab} />}</div>
										<Slider range={demandDateArr} onChange={this.handleSliderChangeDemand} />
									</div>
                                ) : (
                                    <div className="flex-1 demand-content-box flex flex-column">
                                        <div className="demand-chart-title-box flex align-center justify-between mb10">
                                            <div className="flex align-center">
                                                {brandShareTabList.map((tab, index) => (
                                                    <div
                                                        key={index}
                                                        className={cx('flex align-center justify-center hover brand-share-tab', {
                                                            'brand-share-tab-active': brandShareTab === tab.alias,
                                                        })}
                                                        onClick={() => this.handleBrandShareTab(tab.alias)}
                                                    >
                                                        {tab.name}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="flex align-center">
                                                {(type === 'MONTH' &&
                                                    timeTabList.map((tab, index) => (
                                                        <div
                                                            key={index}
                                                            className={cx('legend-item hover flex align-center justify-center', {
                                                                'selected-legend': brandShareTimeTab === tab.alias,
                                                            })}
                                                            onClick={() => this.handleBrandTimeTab(tab.alias)}
                                                        >
                                                            {tab.name}
                                                        </div>
                                                    ))) ||
                                                    null}
                                            </div>
                                        </div>
                                        <div className="flex align-center justify-center flex-wrap">
                                            {overviewBrandShareResult.map((item, index) => (
                                                <div className="flex align-center mr20 mb10" key={index}>
                                                    <div className="brand-share-legend" style={{ backgroundColor: colors[item.name] }} />
                                                    <div className="barnd-share-legend-text">{item.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex-1">{VisualizationComponentBar && <VisualizationComponentBar barType={2} data={overviewBrandShareResult} allDate={brandShareDateArrTemp} type={demandTab} changeColor={this.changeBrandShareColor} />}</div>
										<Slider range={brandShareDateArr} onChange={this.handleSliderChangeBrandShare} />
									</div>
                                )}
                            </div>
                        </div>
                        <div className="flex-1 relative card-box">
                            <div className="full-width full-height flex align-center justify-center absolute overview-ppi-chart">
                                <div className="full-width full-height flex flex-column p10 border-box">
                                    <div className="demand-title-box flex align-center">
                                        <div className={cx('px20 demand-tab demand-tab-active')}>
                                            PREMIUM PRICE INDEX
                                            <div className={cx('tab-line mt2 tab-line-active')} />
                                        </div>
                                    </div>
                                    <div className="demand-chart-title-box flex align-center justify-between mb6">
                                        <div className="flex align-center">
                                            <div className="time-box flex flex-column align-center">
                                                <div className="time-month flex align-center justify-center">{ppiObj.date}</div>
                                                <div className="time-year">{ppiObj.year}</div>
                                            </div>
                                            <div className="flex">
                                                {/* <div className="demand-chart-symbol">￥</div> */}
                                                <div className="demand-chart-title-number ml6">{ppiObj.value || ''}</div>
                                            </div>
                                            {(type === 'MONTH' && ppiObj.valueYoy && (
                                                <div className="flex align-center">
                                                    <div className="demand-chart-icon flex align-center">
                                                        {ppiObj.valueYoy && ppiObj.valueYoy.indexOf('-') !== -1 ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" style={{ color: '#4D9B4D' }} />}
                                                        <div style={{ color: ppiObj.valueYoy && ppiObj.valueYoy.indexOf('-') !== -1 ? '#FF5F60' : '#4D9B4D' }}>{ppiObj.valueYoy && ppiObj.valueYoy.replace('-', '')}</div>
                                                    </div>
                                                    <div className="demand-chart-icon-text flex align-center">VS. LY</div>
                                                </div>
                                            )) ||
                                                null}
                                        </div>
                                        <div className="flex flex-column justify-between align-end full-height">
                                            <div className="flex align-center">
                                                {(type === 'MONTH' &&
                                                    timeTabList.map((tab, index) => (
                                                        <div
                                                            key={index}
                                                            className={cx('legend-item hover flex align-center justify-center', {
                                                                'selected-legend': ppiTimeTab === tab.alias,
                                                            })}
                                                            onClick={() => this.handlePPITimeTab(tab.alias)}
                                                        >
                                                            {tab.name}
                                                        </div>
                                                    ))) ||
                                                    null}
                                            </div>
                                            <div className="flex align-center">
                                                <div className="flex align-center mr34 legend-text">
                                                    <div className="line-dot-legend-box relative ftw-color flex align-center justify-center">
                                                        <div className="line-dot-legend dot-ftw-color"></div>
                                                    </div>
                                                    FTW
                                                </div>
                                                <div className="flex align-center legend-text">
                                                    <div className="line-dot-legend-box relative app-color flex align-center justify-center">
                                                        <div className="line-dot-legend dot-app-color"></div>
                                                    </div>
                                                    APP
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="visualization flex-1">{VisualizationComponentLine && <VisualizationComponentLine data={overviewPPIResult} allDate={ppiDateArrTemp} />}</div>
									<Slider range={ppiDateArr} onChange={this.handleSliderChangePPI} />
								</div>
                            </div>
                            <div className="absolute overview-ppi-bg full-height full-width" />
                        </div>
                    </div>
                </div>
                <div className="flex-1 flex flex-column mt10">
                    <div className="flex align-center justify-between">
						<div className="flex align-center title-box">
							<div className="brand-price-tier"></div>
						</div>
						<div className="just-do-it-bg"></div>
					</div>
                    <div className="flex-1 card-box flex flex-column">
                        <div className="treemap-title-box border-box flex align-center px20">
                            <div className="flex flex-column mr60 treemap-tab-box">
                                {treemapTabList.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={cx('demand-tab hover', {
                                            'demand-tab-active': treemapTab === tab.alias,
                                            mb10: index === 0,
                                        })}
                                        onClick={() => this.handleTreemapTab(tab.alias)}
                                    >
                                        {tab.name}
                                        <div
                                            className={cx('tab-line mt2', {
                                                'tab-line-active': treemapTab === tab.alias,
                                            })}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="flex-1 flex align-center treemap-brand-box border-box p10 ml10">
                                <div className="brand-icon-box brand-total mr10 flex align-center justify-center">
                                    <div className="treemap-brand-icon treemap-total"></div>
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="date-picker">
                                        <DatePicker onConfirm={this.handleTreemapConfirm} currentStartDate={treemapStartDate} currentEndDate={treemapEndDate} currentType={treemapType} currentOrigin={treemapOriginalDate} parentOrigin={originalDate}></DatePicker>
                                    </div>
                                    <div className="treemap-brand-text">￥{total}</div>
                                </div>
                            </div>
                            <div className="flex-1 flex align-center treemap-brand-box border-box p10 ml10">
                                <div className="brand-icon-box brand-nike mr10 flex align-center justify-center">
                                    <div className="treemap-brand-icon treemap-nike"></div>
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="treemap-brand-title mb10">NIKE SHARE</div>
                                    <div className="treemap-brand-content">{nikeDemand}%</div>
                                </div>
                            </div>
                            <div className="flex-1 flex align-center treemap-brand-box border-box p10 ml10">
                                <div className="brand-icon-box brand-jordan mr10 flex align-center justify-center">
                                    <div className="treemap-brand-icon treemap-jordan"></div>
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="treemap-brand-title mb10">JORDAN SHARE</div>
                                    <div className="treemap-brand-content">{jordanDemand}%</div>
                                </div>
                            </div>
                            <div className="flex-1 flex align-center treemap-brand-box border-box p10 ml10">
                                <div className="brand-icon-box brand-converse mr10 flex align-center justify-center">
                                    <div className="treemap-brand-icon treemap-converse"></div>
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="treemap-brand-title mb10">CONVERSE SHARE</div>
                                    <div className="treemap-brand-content">{converseDemand}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex full-height">
                            <div className="flex ml20 my10 treemap-chart-box">
                                <div className="flex-1 flex flex-column bg-img">
                                    {overviewPriceTierTreemap.map((tree, index) => {
                                        const Treemap = Visualization.get('treemap_chart');
                                        return (
                                            <React.Fragment key={index}>
                                                <CommonTootip
                                                    placement="top"
                                                    title={
                                                        <div className="p16">
                                                            <div className="price-tier-name mb16">
                                                                {tree.priceTierName}({formatPercent(tree.priceTierShare)})
                                                            </div>
                                                            {(tree.rankItemList || []).map((item, index) => (
                                                                <div key={index} className="flex align-center mb16">
                                                                    <div className="brand-color" style={{ backgroundColor: brandColor(item.brandClean) || '#657179' }}></div>
                                                                    <div className="price-tier-brandclean mr10">{item.brandClean}</div>
                                                                    <div className="price-tier-demand mr10">￥{formatM(item.demand)}</div>
                                                                    <div className="price-tier-brandshare">{formatPercent(item.brandShare)}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                >
                                                    <div style={{ border: active === index && '2px solid #fff', height: formatPercent(tree.priceTierShare), borderRadius: (index === 0 && '4px 0 0 0') || (index === overviewPriceTierTreemap.length - 1 && '0 0 0 4px') || '0 0 0 0', overflow: 'hidden', opacity: 0.7 }} onClick={(e) => this.handleChange(index, e, tree)}>
                                                        <Treemap key={index} data={tree} />
                                                    </div>
                                                </CommonTootip>
                                                {index !== overviewPriceTierTreemap.length - 1 ? <div className="divid divid-left"></div> : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="flex flex-column py10">
                                {overviewPriceTierTreemap.map((tree, index) => (
                                    <React.Fragment key={index}>
                                        <div style={{ height: formatPercent(tree.priceTierShare) }} className="flex">
                                            <div
                                                className={cx('treemap-right-button pl10 flex flex-column justify-center', {
                                                    'treemap-right-button-selected': index === active,
                                                })}
                                            >
                                                <div className="button-number">{tree.priceTierName}</div>
                                                <div className="button-percent">{formatPercent(tree.priceTierShare)}</div>
                                            </div>
                                        </div>
                                        {index !== overviewPriceTierTreemap.length - 1 ? <div className="divid"></div> : null}
                                    </React.Fragment>
                                ))}
                            </div>
                            <div
                                className={cx('flex-1 pl4 my10 mr20 border-box flex flex-column treemap-line-chart-box', {
                                    'treemap-line-chart-first': active === 0,
                                    'treemap-line-chart-last': active === overviewPriceTierTreemap.length - 1,
                                })}
                            >
                                <div className="treemap-line-chart-title">BRAND DEMAND OF { treemapTab === 'RESALE_PRICE' ? 'RESALE': treemapTab } PRICE RANGE {currentLineInfo.priceTierName || ''}</div>
                                <div className="right-legend flex visualization-legend">
                                    <div className="mr20 flex align-center select-box">
                                        <div className="brand-icon"></div>
                                        <div className="flex-1 flex align-center">
                                            <OverviewDrop data={list} currentData={lineLegendInfo} onConfirm={this.handleLineBrandConfirm}></OverviewDrop>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">
                                        {lineLegendInfo.map((legend, index) => (
                                            <div
                                                key={index}
                                                className={cx('flex align-center', {
                                                    mr30: index !== lineLegendInfo.length - 1,
                                                })}
                                            >
                                                <div style={{ backgroundColor: treemapLineColor[legend] }} className="line-dot-legend-box relative flex align-center justify-center">
                                                    <div style={{ backgroundColor: treemapLineColor[legend] }} className="line-dot-legend"></div>
                                                </div>
                                                {legend}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex-1">{VisualizationComponentBrandLine && <VisualizationComponentBrandLine type={3} data={overviewPriceTierLineResult} allDate={treemapLineDateArrTemp} treemapLineChangeColor={this.treemapLineChangeColor} treemapLineColor={treemapLineColor} />}</div>
								<Slider range={treemapLineDateArr} onChange={this.handleSliderChangeTreemapLine} />
							</div>
                        </div>
                    </div>
                </div>
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
                <StyledMenu
                    keepMounted
                    anchorEl={anchorEle}
                    open={Boolean(anchorEle)}
                    onClose={() =>
                        this.setState({
                            anchorEle: null,
                        })
                    }
                >
                    <div className="p16">
                        <div className="price-tier-name mb16">
                            {currentLineInfo.priceTierName}({formatPercent(currentLineInfo.priceTierShare)})
                        </div>
                        {(currentLineInfo.rankItemList || []).map((item, index) => (
                            <div key={index} className="flex align-center mb16">
                                <div className="brand-color" style={{ backgroundColor: brandColor(item.brandClean) || '#657179' }}></div>
                                <div className="price-tier-brandclean mr10">{item.brandClean}</div>
                                <div className="price-tier-demand mr10">￥{formatM(item.demand)}</div>
                                <div className="price-tier-brandshare">{formatPercent(item.brandShare)}</div>
                            </div>
                        ))}
                    </div>
                </StyledMenu>
            </div>
        );
    }
}
