import * as React from 'react';
import { Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import cx from 'classnames';
import '../../assets/css/home.css';
import './datePicker.css';
import moment from 'moment';
import { transformSingleDate } from '../../utils/index';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} {...props} />);

const FranchiseLCADatePicker = ({ onConfirm = () => {}, currentTab, currentType, currentStart, currentEnd, currentOrigin, monthRange, weekRange }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [dateType, setDateType] = React.useState('MONTH');
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [original, setOriginal] = React.useState([]);
    const [showDate, setShowDate] = React.useState([]);
    const [dates, setDates] = React.useState([]);

    React.useEffect(() => {
        setDateType(currentTab || 'MONTH');
    }, [currentTab]);

    const handleCancel = () => {
        setAnchorEle(null);
        setOpen(false);
    };

    const handleConfirm = () => {
        if (selected.length === 2) {
            setShowDate(selected);
            setAnchorEle(null);
            setOpen(false);
            onConfirm(selected, dateType, original);
        }
    };

    const handleChange = (data, formattedData) => {
        setSelected(formattedData);
        setOriginal(data);
    };

    const disabledDate = (current) => {
		const isFuture = current && currentType && current > moment().endOf(dateType);
		if (isFuture) {
			return isFuture
		}
        if (!dates || dates.length === 0) {
            if (dateType === 'MONTH' && monthRange) {
                const end = monthRange.max ? transformSingleDate(monthRange.max, 'month') : null;
                const start = monthRange.min ? transformSingleDate(monthRange.min, 'month') : null;
                const isLater = current && currentType && (end ? current > moment(end).endOf(dateType) : current > moment().endOf(dateType));
                const isEarlier = current && currentType && (start ? current < moment(start).startOf(dateType) : false);
                return isEarlier || isLater;
            } else if (dateType === 'WEEK' && weekRange) {
                const end = weekRange.max ? transformSingleDate(weekRange.max, 'week') : null;
                const start = weekRange.min ? transformSingleDate(weekRange.min, 'week') : null;
                let isLater = false;
                let isEarlier = false;
                const currentYear = current.year();
                const currentWeek = current.week();
                if (end && current) {
                    const { year, week } = end;
                    if (currentYear < year) {
                        isLater = false;
                    } else if (currentYear === year && currentWeek <= week) {
                        isLater = false;
                    } else {
                        isLater = true;
                    }
                } else if (current) {
                    isLater = current > moment().endOf(dateType);
                }

                if (start && current) {
                    const { year, week } = start;
                    if (currentYear < year) {
                        isEarlier = true;
                    } else if (currentYear === year && currentWeek < week) {
                        isEarlier = true;
                    } else {
                        isEarlier = false;
                    }
                }
                return isEarlier || isLater;
            }
        }

        const disableMap = {
            MONTH: 'months',
            QUARTER: 'quarters',
            WEEK: 'weeks',
        };
        const tooLate = dates[0] && current.diff(dates[0], disableMap[dateType]) > 4;
        const tooEarlier = dates[1] && dates[1].diff(current, disableMap[dateType]) > 4;

        if (dateType === 'MONTH' && monthRange) {
            const end = monthRange.max ? transformSingleDate(monthRange.max, 'month') : null;
            const start = monthRange.min ? transformSingleDate(monthRange.min, 'month') : null;
            const isLater = current && currentType && (end ? current > moment(end).endOf(dateType) : current > moment().endOf(dateType));
            const isEarlier = current && currentType && (start ? current < moment(start).startOf(dateType) : false);
            return isEarlier || isLater || tooLate || tooEarlier;
        } else if (dateType === 'WEEK' && weekRange) {
            const end = weekRange.max ? transformSingleDate(weekRange.max, 'week') : null;
            const start = weekRange.min ? transformSingleDate(weekRange.min, 'week') : null;
            let isLater = false;
            let isEarlier = false;
            const currentYear = current.year();
            const currentWeek = current.week();
            if (end && current) {
                const { year, week } = end;
                if (currentYear < year) {
                    isLater = false;
                } else if (currentYear === year && currentWeek <= week) {
                    isLater = false;
                } else {
                    isLater = true;
                }
            } else if (current) {
                isLater = current > moment().endOf(dateType);
            }

            if (start && current) {
                const { year, week } = start;
                if (currentYear < year) {
                    isEarlier = true;
                } else if (currentYear === year && currentWeek < week) {
                    isEarlier = true;
                } else {
                    isEarlier = false;
                }
            }
            return isEarlier || isLater || tooLate || tooEarlier;
        }

        return tooLate || tooEarlier;
    };

    return (
        <div className="picker-box">
            <div
                className="flex align-center hover"
                onClick={(e) => {
                    setAnchorEle(e.currentTarget);
                    setOpen(true);
                    setDates([]);
                    setSelected([]);
                    setOriginal([]);
                }}
            >
                <div className="time-item-box flex align-center px10">
                    <div className="icon-calendar picker-item"></div>
                    <div>{(showDate.length === 2 && showDate[0]) || (currentOrigin && currentOrigin.startDate) || ''}</div>
                </div>
                <div className="px8">-</div>
                <div className="time-item-box flex align-center px10">
                    <div className="icon-calendar picker-item"></div>
                    <div>{(showDate.length === 2 && showDate[1]) || (currentOrigin && currentOrigin.endDate) || ''}</div>
                </div>
            </div>
            <StyledMenu
                keepMounted
                anchorEl={anchorEle}
                open={Boolean(anchorEle)}
                onClose={() => {
                    setAnchorEle(null);
                    setOpen(false);
                }}
            >
                <div className="px10 picker-content flex flex-column">
                    <div style={{ width: '560px', height: '340px', position: 'relative', top: '20px' }}>{<RangePicker value={original} picker={dateType === 'QUARTER' ? 'quarter' : dateType.toLowerCase()} open={open} disabledDate={disabledDate} popupStyle={{ zIndex: 9999 }} onChange={handleChange} onCalendarChange={(value) => setDates(value)} />}</div>
                    <div className="flex align-center mt30">
                        <div className="flex-1 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                            Cancel
                        </div>
                        <div className="flex-1 ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default FranchiseLCADatePicker;
