import * as echarts from 'echarts';

const formatterTooltip = (value, num) => {
    if (num === 0) {
        return `${Math.round(value / 1000000)
            .toString()
            .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}M`;
    }
    return `￥${(value / 1000000).toFixed(2)}M`;
};
const formatter = (value) => {
    return Math.round(value)
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
};
export const bubbleRender = (element, props) => {
    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    const color = {};
    chart.setOption({
        grid: {
            left: '7%',
            top: '10%',
            right: '15%',
            bottom: '7%',
        },
        xAxis: {
            splitLine: {
                show: false,
            },
            scale: true,
            axisTick: {
                show: false,
                // onZero: false,
            },
            name: props.xName,
            nameTextStyle: {
                color: '#fff',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,0.38)',
                    width: 2,
                },
            },
            // nameLocation: 'center',
            nameGap: 30,
            axisLabel: {
                show: false,
            },
        },
        yAxis: {
            splitLine: {
                show: false,
                // onZero: false,
            },
            scale: true,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,0.38)',
                    width: 2,
                },
            },
            name: props.yName,
            nameTextStyle: {
                color: '#fff',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
            axisLabel: {
                show: false,
            },
        },
        tooltip: {
            // position: 'bottom',
            formatter: function (params) {
                const title = props.from === 'franchise' ? params.data.brand : params.data.franchise;
                const subTitle = props.from === 'franchise' ? params.data.franchise : params.data.productCode;
                const yName = props.from === 'franchise' ? 'Sales Volume' : 'Unit Sold Index';
                const value = props.from === 'franchise' ? formatter(Number(params.data.salesVolume), 0) : Number(params.data.unitSoldIndex).toFixed(2);
                return "<div class='tooltip-box'><div class='tooltip-title'>" + title + "</div><div class='flex align-center' style='margin-bottom: 25px'><div class='legend-dot' style='background: " + params.color + "'></div><div>" + subTitle + '</div></div><div>' + yName + '：' + value + '</div><div>Premium Price Index：' + Number(params.data.ppi).toFixed(2) + '</div><div>Demand：' + formatterTooltip(Number(params.data.demand)) + '</div></div>';
            },
            backgroundColor: '#000',
            borderWidth: 0,
            borderColor: '#000',
            textStyle: {
                color: '#fff',
                fontSize: 12,
                fontFamily: 'Helvetica',
            },
        },
        series: props.data.map((s) => ({
            id: s.name,
            name: s.name,
            data: s.data,
            type: 'scatter',
            symbolSize: function (data, params) {
                if (params.data.salesVolume) {
                    if (!color[`${params.data.brand}&${params.data.franchise}`] || color[`${params.data.brand}&${params.data.franchise}`] !== params.color) {
                        color[`${params.data.brand}&${params.data.franchise}`] = params.color;
                    }
                    if (params.data.date !== props.currentDate) {
                        return 0;
                    }
                    return Math.sqrt(Number(params.data.demand)) / 3e2;
                }
                if (!color[`${params.data.franchise}&${params.data.productCode}`] || color[`${params.data.franchise}&${params.data.productCode}`] !== params.color) {
                    color[`${params.data.franchise}&${params.data.productCode}`] = params.color;
                }

                return Math.sqrt(Number(params.data.demand)) / 0.3e2;
            },
            emphasis: {
                focus: 'series',
            },
            itemStyle: {
                shadowBlur: 10,
                shadowColor: '#000',
                shadowOffsetY: 5,
            },
        })),
    });
    props.changeColor(color);
};
