import * as React from 'react';
import ChartRender from './ChartRender';
import { rowRender } from './chartRender/rowRender';

export default class BarChart extends React.Component {
    static indentify = 'row_chart';
    render() {
        return (
            <div className="full-height full-width">
                <ChartRender chartRender={rowRender} {...this.props} />
            </div>
        );
    }
}
