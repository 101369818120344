import * as React from 'react';
import StyledDialog from './StyledDialog';
import Icon from './Icon';

const downloadTypes = [
    {
        name: 'pdf',
    },
    {
        name: 'xlsx',
    },
];

const CommonDialog = ({ commonOpen = false, onCancel = () => {}, onConfirm = () => {}, disable }) => {
    const [type, setType] = React.useState(disable === 'pdf' ? 'xlsx': 'pdf');
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(Boolean(commonOpen));
    }, [commonOpen]);

    const handleConfirm = () => {
        onConfirm(type);
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <StyledDialog open={open}>
            <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '480px' }}>
                <div className="dialog-content-title mb30">DOWNLOAD DATA</div>

                <div className="flex justify-center mb30">
                    {downloadTypes.map((item, index) => {
						if (item.name === disable) {
							return null
						}
						return (
							<div className="flex flex-column align-center" key={index}>
								<div className="icon-wrap mb30 hover" onClick={() => setType(item.name)}>
									<Icon name={item.name} style={{ fill: type === item.name ? '#000' : 'rgba(0,0,0,0.5)' }}></Icon>
								</div>
								<div style={{ width: '200px', height: type === item.name ? '4px' : '1px', backgroundColor: '#000', marginTop: type === item.name ? '-3px' : 0 }}></div>
							</div>
						)
					})}
                </div>
                <div className="flex align-center justify-between full-width">
                    <div className="flex-1 dialog-operator-item flex align-center justify-center hover mr10" onClick={() => handleCancel()}>
                        Cancel
                    </div>
                    <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => handleConfirm()}>
                        Confirm
                    </div>
                </div>
            </div>
        </StyledDialog>
    );
};

export default CommonDialog;
