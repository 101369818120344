import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment';

import DatePicker from '../components/DatePicker';
import CommonDialog from '../components/CommonDownloadDialog';

import { downloadPDF, handleDateStartEnd, transformDate, downloadXlsx, getColsWpx } from '../../utils/index';
import {
	topSkuTimeColumns,
	columns,
	handleTopSkuTimeData,
	handleTotalData,
	handleMaleData,
	handleFemaleData,
	handleKidData
} from '../../utils/skuAnalysisDownload';
import '../../assets/css/sku.css';

@inject('skuStore', 'commonStore', 'userStore')
@observer
export default class TopSku extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        type: 'MONTH',
        originalDate: {},
    };

    async componentWillMount() {
        const { timeRangeMonth } = await this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 1);
        this.setState({
            startDate,
            endDate,
            originalDate,
        });

        const { type } = this.state;
        await this.props.skuStore.skuAnalysisTopRequest({
            dateType: type,
            startDate,
            endDate,
        });
    }

    formatter = (data, type) => {
        if (type === 'int') {
            const value = Math.round(data)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            return value;
        } else if (type === 'M') {
            const value = Math.round(data / 1000000)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            return `${value}M`;
        } else if (type === 'K') {
            const value = Math.round(data / 1000)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            return `${value}K`;
        }
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'pdf') {
            this.ele &&
                downloadPDF(
                    this.ele,
                    () => {
                        console.log('download success');
						this.props.commonStore.setDownloadDialog(false);
						this.props.commonStore.setMenuName('');
                    },
                    () => {
                        console.log('download fail');
					},
					name
                );
        } else if (type === 'xlsx') {
			const { totalRankList, femaleRankList, maleRankList, kidRankList } = this.props.skuStore;
			const { type, startDate, endDate } = this.state;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(9)
			}
			let rowsNum = 0
			let sheetData = []

			const timeColumn = topSkuTimeColumns;
			const { data: timeData, rowsNumber: timeRowsNumber } = handleTopSkuTimeData(timeColumn, type, startDate, endDate);
			sheetData = [...sheetData, ...timeData];
			rowsNum = timeRowsNumber + rowsNum;

			const column = columns;
			const { data: totalData, colsNumber: totalColsNumber, rowsNumber: totalRowsNumber } = handleTotalData(column, totalRankList);
			sheetData = [...sheetData, ...totalData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: totalColsNumber - 1 }
			})
			rowsNum = totalRowsNumber + rowsNum;

			const { data: maleData, colsNumber: maleColsNumber, rowsNumber: maleRowsNumber } = handleMaleData(column, maleRankList);
			sheetData = [...sheetData, ...maleData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: maleColsNumber - 1 }
			})
			rowsNum = maleRowsNumber + rowsNum;

			const { data: femaleData, colsNumber: femaleColsNumber, rowsNumber: femaleRowsNumber } = handleFemaleData(column, femaleRankList);
			sheetData = [...sheetData, ...femaleData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: femaleColsNumber - 1 }
			})
			rowsNum = femaleRowsNumber + rowsNum;

			const { data: kidData, colsNumber: kidColsNumber, rowsNumber: kidRowsNumber } = handleKidData(column, kidRankList);
			sheetData = [...sheetData, ...kidData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: kidColsNumber - 1 }
			})
			rowsNum = kidRowsNumber + rowsNum;

			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    handleConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        if (start && end) {
            this.setState({
                startDate: start,
                endDate: end,
                type,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            await this.props.skuStore.skuAnalysisTopRequest({
                dateType: type,
                startDate: start,
                endDate: end,
            });
        }
    };

    render() {
        const { startDate, endDate, type, originalDate } = this.state;
        const { totalRankList, femaleRankList, maleRankList, kidRankList } = this.props.skuStore;
        const { isDialogOpen, timeRangeMonth, timeRangeWeek } = this.props.commonStore;
		const defaultUrl = process.env.PUBLIC_URL + '/static/publicResource/img/sku_default_img@2x.png';
		
        return (
            <div className="flex-1 flex flex-column p20 hidden sku-bg relative" ref={(_f) => (this.ele = _f)}>
                <div className="absolute top-sku-bg-filter"></div>
                <div className="flex-1 overflow-y z-index-2">
                    <div className="flex-1 flex flex-column mb20">
                        <div className="flex align-center justify-between">
							<div className="flex align-center title-box">
								<div className="sku-top-total-title"></div>
							</div>
                            <div className="flex align-center pl10 pr10 calendar-box">
                                <div className="icon-calendar calendar-item"></div>
                                <DatePicker onConfirm={this.handleConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                            </div>
                        </div>
                        <div className="flex-1 card-box">
                            <div className="card-content full-height flex flex-column p12 border-box">
								{
									totalRankList.length === 0 && <div style={{minHeight: '200px'}}></div>
								}
                                {totalRankList.map((total, index) => (
                                    <div
                                        className={cx('flex align-center full-width', {
                                            mb10: index !== totalRankList.length - 1,
                                        })}
                                        key={index}
                                    >
                                        {total.map((data, i) => {
                                            return (
                                                (data && (
                                                    <div
                                                        className={cx('flex-1 sku-card flex flex-column full-height hidden pointer relative', {
                                                            mr20: i !== total.length - 1,
                                                        })}
                                                        key={`${index}-${i}`}
                                                    >
                                                        <div className="sku-hover-bg"></div>
                                                        <div className="flex-1 border-box px20 py8 flex flex-column">
                                                            <div className="mb15 flex align-center justify-between flex-1 relative">
                                                                <div className="sku-title-number absolute">{data.rank < 10 ? `0${data.rank}` : data.rank}</div>
                                                                <div className="sku-title pt30">{data.productName}</div>
                                                                <div
                                                                    style={{
                                                                        minWidth: '100px',
                                                                    }}
                                                                    className="relative"
                                                                >
                                                                    <img
                                                                        src={data.skuImageUrl || defaultUrl}
                                                                        className="absolute img-pos"
                                                                        alt="top-sku-img"
                                                                        style={{
                                                                            // right: '-40px',
                                                                            bottom: '-50px',
                                                                            width: '134px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex align-end justify-between sku-price">
                                                                <div>
                                                                    <div>
                                                                        OMD：
                                                                        {data.releaseDate}
                                                                    </div>
                                                                    <div>UNITS SOLD： {this.formatter(Math.abs(Number(data.unitsSold)), 'K')}</div>
                                                                </div>
                                                                <div className="compare-value flex">
                                                                    <div className="flex align-center">￥{this.formatter(Math.abs(Number(data.demand)), 'M')}</div>
                                                                </div>
                                                            </div>
                                                            <div className="card-bottom-box-nobg border-box pt8 flex justify-between">
                                                                <div className="flex align-center">
                                                                    <div className="flex align-center mr4">
                                                                        MSRP：
                                                                        {data.msrp} RMB
                                                                    </div>
                                                                </div>
                                                                <div className="flex align-center">
                                                                    <div className="mr4">AUR：{this.formatter(Number(data.demandByUnitsSold), 'int')} RMB</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) || (
                                                    <div
                                                        className={cx('flex-1 sku-card flex flex-column full-height hidden pointer relative', {
                                                            mr20: i !== total.length - 1,
                                                        })}
                                                        key={`${index}-${i}`}
                                                    ></div>
                                                )
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-column mb20">
                        <div className="flex align-center justify-between">
							<div className="flex align-center title-box">
								<div className="sku-top-male-title"></div>
							</div>
                        </div>
                        <div className="flex-1 card-box">
                            <div className="card-content full-height flex flex-column p12 border-box">
								{
									maleRankList.length === 0 && <div style={{minHeight: '200px'}}></div>
								}
                                {maleRankList.map((male, index) => (
                                    <div
                                        className={cx('flex align-center full-width', {
                                            mb10: index !== maleRankList.length - 1,
                                        })}
                                        key={index}
                                    >
                                        {male.map((data, i) => (
                                            <div
                                                className={cx('flex-1 sku-card flex flex-column full-height hidden pointer relative', {
                                                    mr20: i !== male.length - 1,
                                                })}
                                                key={`${index}-${i}`}
                                            >
                                                <div className="sku-hover-bg"></div>
                                                <div className="flex-1 border-box px20 py8 flex flex-column">
                                                    <div className="mb15 flex align-center justify-between flex-1 relative">
                                                        <div className="sku-title-number absolute">{data.rank < 10 ? `0${data.rank}` : data.rank}</div>
                                                        <div className="sku-title pt30">{data.productName}</div>
                                                        <div
                                                            style={{
                                                                minWidth: '100px',
                                                            }}
                                                            className="relative"
                                                        >
                                                            <img
                                                                src={data.skuImageUrl || defaultUrl}
                                                                className="absolute img-pos"
                                                                alt="top-sku-img"
                                                                style={{
                                                                    // right: '-40px',
                                                                    bottom: '-50px',
                                                                    width: '134px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex align-end justify-between sku-price">
                                                        <div>
                                                            <div>
                                                                OMD：
                                                                {data.releaseDate}
                                                            </div>
                                                            <div>UNITS SOLD： {this.formatter(Math.abs(Number(data.unitsSold)), 'K')}</div>
                                                        </div>
                                                        <div className="compare-value flex">
                                                            <div className="flex align-center">￥{this.formatter(Math.abs(Number(data.demand)), 'M')}</div>
                                                        </div>
                                                    </div>
                                                    <div className="card-bottom-box-nobg border-box pt8 flex justify-between">
                                                        <div className="flex align-center">
                                                            <div className="flex align-center mr4">
                                                                MSRP：
                                                                {data.msrp} RMB
                                                            </div>
                                                        </div>
                                                        <div className="flex align-center">
                                                            <div className="mr4">AUR：{this.formatter(Number(data.demandByUnitsSold), 'int')} RMB</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-column mb20">
                        <div className="flex align-center justify-between">
							<div className="flex align-center title-box">
								<div className="sku-top-female-title"></div>
							</div>
                        </div>
                        <div className="flex-1 card-box">
                            <div className="card-content full-height flex flex-column p12 border-box">
								{
									femaleRankList.length === 0 && <div style={{minHeight: '200px'}}></div>
								}
                                {femaleRankList.map((female, index) => (
                                    <div
                                        className={cx('flex align-center full-width', {
                                            mb10: index !== femaleRankList.length - 1,
                                        })}
                                        key={index}
                                    >
                                        {female.map((data, i) => (
                                            <div
                                                className={cx('flex-1 sku-card flex flex-column full-height hidden pointer relative', {
                                                    mr20: i !== female.length - 1,
                                                })}
                                                key={`${index}-${i}`}
                                            >
                                                <div className="sku-hover-bg"></div>
                                                <div className="flex-1 border-box px20 py8 flex flex-column">
                                                    <div className="mb15 flex align-center justify-between flex-1 relative">
                                                        <div className="sku-title-number absolute">{data.rank < 10 ? `0${data.rank}` : data.rank}</div>
                                                        <div className="sku-title pt30">{data.productName}</div>
                                                        <div
                                                            style={{
                                                                minWidth: '100px',
                                                            }}
                                                            className="relative"
                                                        >
                                                            <img
                                                                src={data.skuImageUrl || defaultUrl}
                                                                className="absolute img-pos"
                                                                alt="top-sku-img"
                                                                style={{
                                                                    // right: '-40px',
                                                                    bottom: '-50px',
                                                                    width: '134px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex align-end justify-between sku-price">
                                                        <div>
                                                            <div>
                                                                OMD：
                                                                {data.releaseDate}
                                                            </div>
                                                            <div>UNITS SOLD： {this.formatter(Math.abs(Number(data.unitsSold)), 'K')}</div>
                                                        </div>
                                                        <div className="compare-value flex">
                                                            <div className="flex align-center">￥{this.formatter(Math.abs(Number(data.demand)), 'M')}</div>
                                                        </div>
                                                    </div>
                                                    <div className="card-bottom-box-nobg border-box pt8 flex justify-between">
                                                        <div className="flex align-center">
                                                            <div className="flex align-center mr4">
                                                                MSRP：
                                                                {data.msrp} RMB
                                                            </div>
                                                        </div>
                                                        <div className="flex align-center">
                                                            <div className="mr4">AUR：{this.formatter(Number(data.demandByUnitsSold), 'int')} RMB</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-column">
                        <div className="flex align-center justify-between">
							<div className="flex align-center title-box">
								<div className="sku-top-kid-title"></div>
							</div>
                        </div>
                        <div className="flex-1 card-box">
                            <div className="card-content full-height flex flex-column p12 border-box">
								{
									kidRankList.length === 0 && <div style={{minHeight: '200px'}}></div>
								}
                                {kidRankList.map((kid, index) => (
                                    <div
                                        className={cx('flex align-center full-width', {
                                            mb10: index !== kidRankList.length - 1,
                                        })}
                                        key={index}
                                    >
                                        {kid.map((data, i) => (
                                            <div
                                                className={cx('flex-1 sku-card flex flex-column full-height hidden pointer relative', {
                                                    mr20: i !== kid.length - 1,
                                                })}
                                                key={`${index}-${i}`}
                                            >
                                                <div className="sku-hover-bg"></div>
                                                <div className="flex-1 border-box px20 py8 flex flex-column">
                                                    <div className="mb15 flex align-center justify-between flex-1 relative">
                                                        <div className="sku-title-number absolute">{data.rank < 10 ? `0${data.rank}` : data.rank}</div>
                                                        <div className="sku-title pt30">{data.productName}</div>
                                                        <div
                                                            style={{
                                                                minWidth: '100px',
                                                            }}
                                                            className="relative"
                                                        >
                                                            <img
                                                                src={data.skuImageUrl || defaultUrl}
                                                                className="absolute img-pos"
                                                                alt="top-sku-img"
                                                                style={{
                                                                    // right: '-40px',
                                                                    bottom: '-50px',
                                                                    width: '134px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex align-end justify-between sku-price">
                                                        <div>
                                                            <div>
                                                                OMD：
                                                                {data.releaseDate}
                                                            </div>
                                                            <div>UNITS SOLD： {this.formatter(Math.abs(Number(data.unitsSold)), 'K')}</div>
                                                        </div>
                                                        <div className="compare-value flex">
                                                            <div className="flex align-center">￥{this.formatter(Math.abs(Number(data.demand)), 'M')}</div>
                                                        </div>
                                                    </div>
                                                    <div className="card-bottom-box-nobg border-box pt8 flex justify-between">
                                                        <div className="flex align-center">
                                                            <div className="flex align-center mr4">
                                                                MSRP：
                                                                {data.msrp} RMB
                                                            </div>
                                                        </div>
                                                        <div className="flex align-center">
                                                            <div className="mr4">AUR：{this.formatter(Number(data.demandByUnitsSold), 'int')} RMB</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
            </div>
        );
    }
}
