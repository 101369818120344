import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import RoleTable from './RoleTable';
import CommonTextField from '../components/TextField';
import DialogTextField from '../login/DialogTextField';
import { Formik } from 'formik';
import * as Yup from 'yup';

const StyledDialog = withStyles((theme) => ({
    root: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.76)',
        },
    },
}))(Dialog);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const columns = [
    {
        name: 'roleName',
        label: 'Role Name',
        minWidth: '140px',
    },
    {
        name: 'userCount',
        label: 'User Count',
        minWidth: '140px',
    },
    {
        name: 'createUser',
        label: 'Create User',
        width: '160px',
    },
    {
        name: 'createTime',
        label: 'Create Time',
        width: '140px',
    },
    {
        name: 'operator',
        label: 'Operator',
        width: '160px',
    },
];

@inject('userStore')
@observer
export default class Role extends React.Component {
    state = {
        roleName: '',
        addOrEdit: 'add',
        userOpen: false,
        deleteOpen: false,
        dialogInfo: {
            roleName: '',
            roles: [],
            roleId: '',
        },
        deleteRoleId: '',
    };
    async componentWillMount() {
        await this.props.userStore.roleListRequest({});
        await this.props.userStore.permissionDictRequest();
    }

    handleSearch = async () => {
        const { roleName } = this.state;
        await this.props.userStore.roleListRequest({
            roleName,
        });
    };

    handleCreate = () => {
        this.setState({
            addOrEdit: 'add',
            userOpen: true,
        });
    };

    handleEdit = async (data) => {
        const { code, data: responseData } = await this.props.userStore.roleInfoRequest({
            id: data.roleId,
        });
        if (code === '00000') {
            this.setState({
                addOrEdit: 'edit',
                userOpen: true,
                dialogInfo: {
                    roleName: responseData.roleName,
                    roleId: responseData.roleId,
                    roles: responseData.permissionIdList,
                },
            });
        }
    };

    handleOperator = ({ type, data, options }, callback) => {
        if (type === 'edit') {
            this.handleEdit(data);
        } else if (type === 'delete') {
            this.setState({
                deleteRoleId: data.roleId,
                deleteOpen: true,
            });
        }
    };

    handleCancel = () => {
        this.setState({
            userOpen: false,
            dialogInfo: {
                roleName: '',
                roles: [],
                roleId: '',
            },
        });
    };

    handleSubmit = async (data) => {
        const response = await this.props.userStore.roleSaveRequest({
            roleId: this.state.addOrEdit === 'add' ? null : this.state.dialogInfo.roleId,
            roleName: data.roleName,
            permissionIds: data.roles,
        });
        if (response.code === '00000') {
            this.setState({ userOpen: false });
            const { roleName } = this.state;
            await this.props.userStore.roleListRequest({
                roleName,
            });
        }
    };

    handleDeleteCancel = () => {
        this.setState({
            deleteOpen: false,
            deleteRoleId: '',
        });
    };

    handleDeleteConfirm = async () => {
        const { code } = await this.props.userStore.roleRemoveRequest({
            id: this.state.deleteRoleId,
        });
        if (code === '00000') {
            this.setState({ deleteOpen: false });
            const { roleName } = this.state;
            await this.props.userStore.roleListRequest({
                roleName,
            });
        }
    };

    render() {
        const { permissionDict, roleList } = this.props.userStore;
        const { roleName, addOrEdit, userOpen, dialogInfo, deleteOpen } = this.state;
        const validationSchema = Yup.object({
            roleName: Yup.string().required('roleName is required'),
            roles: Yup.array().test('', 'roles is required', (value) => {
                if (value.length) {
                    return true;
                }
                return false;
            }),
        });
        return (
            <div className="flex-1 flex hidden p20 align-center">
                <div className="flex-1 flex flex-column card-box full-height full-width p20 border-box">
                    <div className="mb10 flex align-center">
                        <div className="flex align-center mr20">
                            <div className="mr20" style={{ whiteSpace: 'nowrap' }}>
                                Role Name:
                            </div>
                            <CommonTextField
                                size="small"
                                fullWidth={true}
                                name="roleName"
                                type="text"
                                margin="normal"
                                value={roleName}
                                variant="outlined"
                                onChange={(e) =>
                                    this.setState({
                                        roleName: e.target.value,
                                    })
                                }
                            ></CommonTextField>
                        </div>

                        <div className="mr10">
                            <Button variant="outlined" style={{ color: '#fff', border: '1px solid rgba(255,255,255,0.5)' }} size="small" onClick={() => this.handleSearch()}>
                                search
                            </Button>
                        </div>
                        <div>
                            <Button variant="outlined" style={{ color: '#fff', border: '1px solid rgba(255,255,255,0.5)' }} size="small" onClick={() => this.handleCreate()}>
                                create
                            </Button>
                        </div>
                    </div>
                    <div className="flex-1 overflow-y">
                        <RoleTable columns={columns} data={roleList} onOperator={this.handleOperator}></RoleTable>
                    </div>
                </div>
                <StyledDialog open={userOpen} maxWidth="xs" fullWidth={true}>
                    <DialogTitle>{addOrEdit === 'edit' ? 'edit role' : 'new role'}</DialogTitle>
                    <Formik
                        initialValues={{
                            roleName: dialogInfo.roleName,
                            roles: dialogInfo.roles,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={this.handleSubmit}
                        render={(formProps) => (
                            <React.Fragment>
                                <DialogContent>
                                    <div className="flex align-center">
                                        <div className="mr10 flex align-center" style={{ width: '150px' }}>
                                            <div style={{ color: 'red', paddingTop: '10px', marginRight: '4px' }}>*</div>
                                            <div className="flex-1">Role Name</div>
                                        </div>
                                        <DialogTextField size="small" fullWidth={true} name="roleName" type="text" margin="normal" value={formProps.getFieldProps('roleName').value} variant="outlined" onChange={formProps.handleChange} helperText={(formProps.touched.roleName && formProps.errors.roleName) || ''} error={Boolean(formProps.touched.roleName && formProps.errors.roleName)} onBlur={formProps.handleBlur}></DialogTextField>
                                    </div>

                                    <div className="flex align-center">
                                        <div className="mr10 flex align-center" style={{ width: '150px' }}>
                                            <div style={{ color: 'red', paddingTop: '10px', marginRight: '4px' }}>*</div>
                                            <div className="flex-1">Roles</div>
                                        </div>
                                        <DialogTextField
                                            size="small"
                                            fullWidth={true}
                                            select={true}
                                            name="roles"
                                            margin="normal"
                                            value={formProps.getFieldProps('roles').value}
                                            variant="outlined"
                                            onChange={formProps.handleChange}
                                            helperText={(formProps.touched.roles && formProps.errors.roles) || ''}
                                            error={Boolean(formProps.touched.roles && formProps.errors.roles)}
                                            onBlur={formProps.handleBlur}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                            minWidth: 200,
                                                            color: '#516F90',
                                                        },
                                                    },
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    },
                                                    transformOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    },
                                                    getContentAnchorEl: null,
                                                },
                                                multiple: true,
                                            }}
                                        >
                                            {permissionDict.map((dict) => (
                                                <MenuItem key={dict.display} value={dict.value}>
                                                    {dict.display}
                                                </MenuItem>
                                            ))}
                                        </DialogTextField>
                                    </div>
                                </DialogContent>

                                <DialogActions>
                                    <Button onClick={this.handleCancel} color="primary" variant="outlined">
                                        Cancel
                                    </Button>
                                    <Button onClick={() => formProps.handleSubmit()} color="primary" variant="contained">
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </React.Fragment>
                        )}
                    />
                </StyledDialog>

                <StyledDialog open={deleteOpen} maxWidth="xs" fullWidth={true}>
                    <DialogTitle>Delete</DialogTitle>
                    <DialogContent>Do you want to delete it?</DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteCancel} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDeleteConfirm} color="primary" variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                </StyledDialog>
            </div>
        );
    }
}
