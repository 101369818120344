import * as React from 'react';
import { Menu, TextField, Checkbox, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import cx from 'classnames';
import './dropBox.css';
import DatePicker from './DatePicker';
import _ from 'underscore';

import { withStyles, createStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => {
    return <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: props.position === 'right' ? 'top' : 'bottom', horizontal: props.position === 'right' ? 'right' : 'left' }} transformOrigin={{ vertical: 'top', horizontal: props.position === 'right' ? 'left' : 'left' }} {...props} />;
});

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '3.5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

const StyledCheckBox = withStyles((theme) =>
    createStyles({
        root: {
            color: '#fff',
            opacity: 0.5,
            background: '#000',
            padding: '0px',
            fontSize: '12px',
            borderRadius: '100%',
            overflow: 'hidden',
            width: '16px',
            height: '16px',
            '&.MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#fff',
            },
            '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
                opacity: 1,
            },
        },
    })
)(Checkbox);

const SkuDrop = ({
	onConfirm = () => {},
	title = 'Brands',
	searchList,
	currentList,
	position,
	onSearch,
	brandList,
	onChangeBrand,
	franchiseList,
	onChangeFranchise,
	skuList,
	currentStartDate,
	currentEndDate,
	currentType,
	currentOrigin,
	onDateConfirm,
	onRefresh,
	dayRange,
	onSelect,
	onCancel
}) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('');
    const [checkedList, setCheckedList] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [subMenus, setSubMenus] = React.useState([]);
    const [productList, setProductList] = React.useState([]);
    const [total, setTotal] = React.useState(6);
    const [tempCheck, setTempCheck] = React.useState({});

    React.useEffect(() => {
        setList(brandList || []);
        setSearchValue('');
        setSubMenus([]);

        setCheckedList(currentList || []);
        if (position) {
            setTotal(10000);
        }
    }, [currentList, position, brandList]);

    React.useEffect(() => {
        setSubMenus(franchiseList || []);
    }, [franchiseList]);

    React.useEffect(() => {
        setProductList(skuList || []);
    }, [skuList]);

    const handleCancel = () => {
        setAnchorEle(null);
        setCheckedList(currentList || []);
        setSearchValue('');
        setSubMenus([]);
        setTempCheck({});
		setProductList([]);
		onCancel && onCancel()
    };

    const handleConfirm = () => {
        onConfirm(checkedList);
        setAnchorEle(null);
        setSearchValue('');
        setTempCheck({});
    };

    const handleRemoveSelected = (item) => {
        let index = null;
        checkedList.forEach((list, i) => {
            if (list.diaSkuId === item.diaSkuId) {
                index = i;
            }
        });
        if (index !== null) {
            const newList = remove(checkedList, index);
            setCheckedList(newList);
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        onSearch(value);
    };

    const add = (list, item) => {
        const arr = [...list, item];
        return arr;
    };

    const remove = (list, index) => {
        const arr = [...list.slice(0, index), ...list.slice(index + 1)];
        return arr;
    };

    const checkedObj = {};
    checkedList.forEach((list) => {
        checkedObj[list.diaSkuId] = true;
        checkedObj[list.franchise] = true;
        checkedObj[list.brand] = true;
    });

    const groupedByNumber = (num = 2, list, emptyObj = {}) => {
        const group = [];
        for (let i = 0; i < list.length; i += num) {
            group.push(list.slice(i, i + num));
        }
        const last = (group.length && group[group.length - 1]) || [];
        const empty = [];
        if (last.length) {
            for (let i = 0; i < num - last.length; i++) {
                empty.push(emptyObj);
            }
            empty.forEach((e) => {
                group[group.length - 1].push(e);
            });
        }
        return group;
    };

    const groupedCheckedList = groupedByNumber(2, checkedList, '');

    const handleDateConfirm = (selected, type, original) => {
        onDateConfirm && onDateConfirm(selected, type, original, searchValue);
    };

    const handleRefresh = () => {
        onRefresh && onRefresh(searchValue);
    };

    const handleBrand = (item, index) => {
        const obj = {
            [item.brand]: true,
        };
        setTempCheck(obj);
        onChangeBrand(item);
    };

    const handleFranchise = (item, index) => {
        const obj = {
            [item.brand]: true,
            [item.franchise]: true,
        };
        setTempCheck(obj);
        onChangeFranchise(item);
    };

    const handleChoose = (item) => {
        let index = null;
        checkedList.forEach((list, i) => {
            if (list.diaSkuId === item.diaSkuId) {
                index = i;
            }
        });
        if (index !== null) {
			const newList = remove(checkedList, index);
			onSelect && onSelect(newList)
            setCheckedList(newList);
        } else if (checkedList.length < total) {
			const newList = add(checkedList, item);
			onSelect && onSelect(newList)
            setCheckedList(newList);
        }
    };

    const handleChange = (item) => {
        let index = null;
        checkedList.forEach((list, i) => {
            if (list.diaSkuId === item.diaSkuId) {
                index = i;
            }
        });
        if (index !== null) {
            const newList = remove(checkedList, index);
            setCheckedList(newList);
        } else if (checkedList.length < total) {
            const newList = add(checkedList, item);
            setCheckedList(newList);
        }
    };

    const isSelectAll = (list) => {
        const selected = list.filter((item) => _.findWhere(checkedList, { diaSkuId: item.diaSkuId }) && item);
        if (selected.length === list.length) {
            return true;
        }
        return false;
    };

    const handleSelectAll = (list) => {
        const isCheckAll = isSelectAll(list);
        const arr = _.filter(checkedList, (item) => {
            const obj = _.findWhere(list, { diaSkuId: item.diaSkuId });
            return !obj && item;
        });
        let newList = arr;
        if (!isCheckAll) {
            newList = [...arr, ...list];
        }
        setCheckedList(newList);
    };

    return (
        <div
            className={cx('drop-box', {
                'full-width full-height flex align-center justify-center': position,
            })}
        >
            <div
                className={cx('hover flex align-center', {
                    'justify-center full-width full-height': position,
                })}
                onClick={(e) => setAnchorEle(e.currentTarget)}
            >
                <div className="select-icon"></div>
                <div>{title}</div>
            </div>
            <StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} position={position} onClose={handleCancel}>
                <div className="py10 px20 drop-content flex flex-column">
                    <div className="py10 flex align-center">
                        <div className="flex-1">
                            <StyledTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth={true}
                                value={searchValue}
                                onChange={(e) => handleSearch(e.target.value)}
                                id="search"
                                type="search"
                                variant="outlined"
                                autoComplete="off"
                            />
                        </div>
                        {(position && (
                            <div className="flex align-center date-wrapper px10 ml10">
                                <div className="icon-calendar brand-calendar-item mr10"></div>
                                <DatePicker onRefresh={handleRefresh} dayRange={dayRange} onConfirm={handleDateConfirm} position={position} currentStartDate={currentStartDate} currentEndDate={currentEndDate} currentType={currentType} currentOrigin={currentOrigin} showDay={position}></DatePicker>
                            </div>
                        )) ||
                            null}
                    </div>
                    <div className="flex-1 py10 flex hidden" style={{ maxHeight: '300px', minHeight: '100px' }}>
                        {searchValue === '' && !currentOrigin ? (
                            <React.Fragment>
                                <div className="flex-1 flex flex-column overflow-y drop-item-box">
                                    {list.map((item, index) => (
                                        <div
                                            className={cx('flex align-center justify-between drop-items pb15 hidden hover', {
                                                'drop-active': checkedObj[item.brand] || tempCheck[item.brand],
                                            })}
                                            key={index}
                                            onClick={() => handleBrand(item, index)}
                                        >
                                            <div className="flex align-center">{item.brand}</div>
                                        </div>
                                    ))}
                                </div>
								<div className="flex-1 flex flex-column overflow-y drop-sub-menu drop-item-box">
									{subMenus.map((subMenu, index) => (
										<div
											className={cx('flex align-center justify-between drop-items pb15 hover', {
												'drop-active': (checkedObj[subMenu.franchise] && checkedObj[subMenu.brand]) || (tempCheck[subMenu.franchise] && tempCheck[subMenu.brand]),
											})}
											key={`${index}`}
											onClick={() => handleFranchise(subMenu, index)}
										>
											<div className="flex align-center">{subMenu.franchise}</div>
										</div>
									))}
								</div>
								<div className="flex-1 flex flex-column drop-sub-menu drop-item-box">
									<div className="flex-1 flex flex-column overflow-y">
										{productList.map((product, index) => (
											<div
												className={cx('flex align-center justify-between drop-items pb15 hover', {
													'drop-active': checkedObj[`${product.diaSkuId}`],
												})}
												key={`${index}`}
												onClick={() => handleChoose(product, index)}
											>
												<div className="flex align-center">{product.productCode}</div>

												<div
													className={cx('icon-check-box hover', {
														'icon-check': checkedObj[product.diaSkuId],
														'icon-uncheck': !checkedObj[product.diaSkuId],
													})}
												></div>
											</div>
										))}
									</div>
									{(position && productList.length && (
										<div className="py10 px10 flex align-center justify-end hover" onClick={() => handleSelectAll(productList)}>
											Selected all
											<div
												className={cx('icon-check-box ml10', {
													'icon-check': isSelectAll(productList),
													'icon-uncheck': !isSelectAll(productList),
												})}
											></div>
										</div>
									)) ||
										null}
								</div>
                            </React.Fragment>
                        ) : (
                            <div className="flex flex flex-column">
                                <div className="flex-1 flex flex-column overflow-y drop-item-box">
                                    {searchList.map((search, index) => {
                                        return (
                                            <div
                                                className={cx('flex align-center justify-between hidden', {
                                                    'drop-active': checkedObj[search.diaSkuId],
                                                })}
                                                key={index}
                                                style={{ minHeight: '30px' }}
                                            >
                                                <div className="flex align-center py10">
                                                    <div className="pr40 search-content-item hidden" style={{ width: '300px', textOverflow: 'ellipsis' }}>
                                                        {search.productCode}
                                                    </div>
                                                    <div className="pr40 search-content-item" style={{ width: '160px', textOverflow: 'ellipsis' }}>
                                                        {search.brand}
                                                    </div>
                                                    <div className="pr40 search-content-item" style={{ width: '160px', textOverflow: 'ellipsis' }}>
                                                        {search.franchise}
                                                    </div>
                                                    <div className="search-content-description mr20 py10">{search.productName}</div>
                                                </div>
                                                <div
                                                    className={cx('icon-check-box hover', {
                                                        'icon-check': checkedObj[search.diaSkuId],
                                                        'icon-uncheck': !checkedObj[search.diaSkuId],
                                                    })}
                                                    onClick={() => handleChange(search)}
                                                ></div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {(position && searchList.length && (
                                    <div className="py10 px10 flex align-center justify-end hover" onClick={() => handleSelectAll(searchList)}>
                                        Selected all
                                        <div
                                            className={cx('icon-check-box ml10', {
                                                'icon-check': isSelectAll(searchList),
                                                'icon-uncheck': !isSelectAll(searchList),
                                            })}
                                        ></div>
                                    </div>
                                )) ||
                                    null}
                            </div>
                        )}
                    </div>
                    {!position && checkedList.length ? (
                        <div className="flex flex-column py10 select-content">
                            {groupedCheckedList.map((group, index) => (
                                <div className="flex align-center mb10" key={index}>
                                    {group.map((list, i) => {
                                        if (list) {
                                            return (
                                                <div className="flex-1 flex align-center" key={`${index}_${i}`} style={{ minWidth: '300px' }}>
                                                    <StyledCheckBox checked={true} indeterminate={true} onChange={() => handleRemoveSelected(list)} />
                                                    <div className="pl4 drop-sub-item flex align-center flex-1">
                                                        <div className="mr10" style={{ minWidth: '80px' }}>
                                                            {list.productCode}
                                                        </div>
                                                        <div className="mr10">{`[ ${list.brand} - ${list.franchise} ]`}</div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return <div className="flex-1 flex align-center" key={`${index}_${i}`} style={{ minWidth: '300px' }} />;
                                    })}
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div className="flex align-center justify-between footer-box">
                        <div className="flex align-center select-text">
                            {(!position && (
                                <React.Fragment>
                                    <div>Selected</div>
                                    <div className="select-value">
                                        {checkedList.length}/{total}
                                    </div>
                                </React.Fragment>
                            )) ||
                                null}
                        </div>
                        <div className="flex align-center">
                            <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                                Cancel
                            </div>
                            <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default SkuDrop;
