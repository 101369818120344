import { $post } from './index';

export const subCategoryIndexRequest = (data) => {
    return $post('/data/sub_category/overview/analysis', data, {
        queryName: 'subCategoryIndexRequest',
    });
};

export const subCategorySummaryRequest = (data) => {
    return $post('/data/sub_category/overview/brand/share/by/price/tier/summary', data, {
        queryName: 'subCategorySummaryRequest',
    });
};

export const subCategoryBlockRequest = (data) => {
    return $post('/data/sub_category/overview/brand/share/by/price/tier/data/block', data, {
        queryName: 'subCategoryBlockRequest',
    });
};

export const subCategoryLineRequest = (data) => {
    return $post('/data/sub_category/overview/brand/share/by/price/tier/data/line', data, {
        queryName: 'subCategoryLineRequest',
    });
};
