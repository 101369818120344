import {
    observable,
    action,
    computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';
import _ from 'underscore';
import { brandAnalysisRequest, brandAnalysisPPIRequest, genderAnalysisBrandDemandRequest, genderAnalysisBrandIndexRequest, genderAnalysisFranchiseDemandRequest, genderAnalysisFranchiseIndexRequest, genderAnalysisCategoryDemandRequest, genderAnalysisCategoryIndexRequest } from '../api/brandAndGender';
import { completeMissingData } from './utils';

export class BrandAndGenderStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
    }

    @observable
    brandAnalysisData = [];

    @observable
    brandAnalysisPPIData = [];

    @observable
    ppiInfo = {};

    @observable
    ppiInfoGender = {};

    @observable
    genderTab = '';

    @observable
    level = '';

    @observable
    genderAnalysisPPIData = [];

    @observable
    genderAnalysisData = [];

    @observable
	demandDateArr = [];
	
	@observable
	demandDateArrOrigin = []

	@observable
	indexDateArr1 = []
	
	@observable
	indexDateArr2 = []

	@observable
	indexDateArr3 = []
	
	@observable
	indexDateArrOrigin = []

    @computed
    get brandAnalysisResult() {
        const temp = this.brandAnalysisPPIData.map((item) => ({
            name: item.brandClean,
            date: item.date,
            value: Number(item.demand),
            yoy: item.demandYoy,
            ppiTier: item.ppiTier,
            ppiTierDisplay: `PPI${item.ppiTier}`
        }));
        const typeMap = {
            brand: 'name',
            ppi: 'ppiTier',
        };
        const brandAnalysisDemand = Object.entries(_.groupBy(temp, (d) => d[typeMap[this.ppiInfo.type]]) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.demandDateArr, value),
        }));

        const ppiTemp = [];
        const rviTemp = [];
        const diTemp = [];
        this.brandAnalysisData.forEach((item) => {
            ppiTemp.push({
                name: item.brandClean,
                date: item.date,
                value: Number(item.ppi),
                yoy: item.ppiYoy,
                origin: item,
            });
            rviTemp.push({
                name: item.brandClean,
                date: item.date,
                value: Number(item.resaleVolumeIndex),
                yoy: item.resaleVolumeIndexYoy,
                origin: item,
            });
            diTemp.push({
                name: item.brandClean,
                date: item.date,
                value: Number(item.diversity),
                yoy: item.diversityYoy,
                origin: item,
            });
        });
        const brandAnalysisPPI = Object.entries(_.groupBy(ppiTemp, (d) => d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.indexDateArr1, value),
        }));
        const brandAnalysisRVI = Object.entries(_.groupBy(rviTemp, (d) => d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.indexDateArr2, value),
        }));
        const brandAnalysisDI = Object.entries(_.groupBy(diTemp, (d) => d.name) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.indexDateArr3, value),
        }));
        return [
            [
                {
                    title: 'DEMAND BY PPI',
                    data: brandAnalysisDemand,
                    value: 'demand',
					yoy: 'demandYoy',
					dateRange: this.demandDateArrOrigin,
					currentDateRange: this.demandDateArr
                },
                {
                    title: 'PREMIUM PRICE INDEX',
                    data: brandAnalysisPPI,
                    value: 'ppi',
					yoy: 'ppiYoy',
					dateRange: this.indexDateArrOrigin,
					currentDateRange: this.indexDateArr1
                },
            ],
            [
                {
                    title: 'RESALE VOLUME INDEX',
                    data: brandAnalysisRVI,
                    value: 'resaleVolumeIndex',
					yoy: 'resaleVolumeIndexYoy',
					dateRange: this.indexDateArrOrigin,
					currentDateRange: this.indexDateArr2
                },
                {
                    title: 'DIVERSITY INDEX',
                    data: brandAnalysisDI,
                    value: 'diversity',
					yoy: 'diversityYoy',
					dateRange: this.indexDateArrOrigin,
					currentDateRange: this.indexDateArr3
                },
            ],
        ];
	}
	
	@action
	changeIndexDateRange1(dateRange) {
		this.indexDateArr1 = dateRange
	}

	@action
	changeIndexDateRange2(dateRange) {
		this.indexDateArr2 = dateRange
	}
	@action
	changeIndexDateRange3(dateRange) {
		this.indexDateArr3 = dateRange
	}

	@action
	changeDemandDateRange(dateRange) {
		this.demandDateArr = dateRange
	}

    @action
    async brandAnalysisPPIRequest({ dateType, startDate, endDate, dataType, brandList, ppiList, ppiInfo }) {
        const response = await brandAnalysisPPIRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            brandList,
            ppiList,
        });
        if (response.code === '00000') {
            this.ppiInfo = ppiInfo;
            this.brandAnalysisPPIData = response.data;
			this.demandDateArr = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.demandDateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async brandAnalysisRequest({ dateType, startDate, endDate, dataType, brandList }) {
        const response = await brandAnalysisRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            brandList,
        });
        if (response.code === '00000') {
            this.brandAnalysisData = response.data;
			this.indexDateArr1 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArr2 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArr3 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @computed
    get genderAnalysisResult() {
        const temp = this.genderAnalysisPPIData.map((item) => ({
            name: item.brandClean,
            date: item.date,
            value: Number(item.demand),
            yoy: item.demandYoy,
            ppiTier: item.ppiTier,
            ppiTierDisplay: `PPI${item.ppiTier}`,
            franchise: item.franchise,
            gender: item.gender,
            category: item.subCategory,
            model: item.model
        }));
        const typeMap = {
            brand: this.level === 'BRAND' ? 'name' : this.level === 'INNER_SUB_CATEGORY' ? 'category' :'model',
            ppi: 'ppiTier',
        };
        const genderMap = {
            brand: 'gender',
            ppi: 'ppiTier',
        };
        const genderAnalysisDemand = Object.entries(_.groupBy(temp, (d) => (this.genderTab === 'ALL' ? d[genderMap[this.ppiInfoGender.type]] : d[typeMap[this.ppiInfoGender.type]])) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.demandDateArr, value),
        }));

        const ppiTemp = [];
        const rviTemp = [];
        const diTemp = [];
        this.genderAnalysisData.forEach((item) => {
            ppiTemp.push({
                name: item.brandClean,
                date: item.date,
                value: Number(item.ppi),
                yoy: item.ppiYoy,
                origin: item,
                franchise: item.franchise,
                gender: item.gender,
                category: item.subCategory,
                model: item.model
            });
            rviTemp.push({
                name: item.brandClean,
                date: item.date,
                value: Number(item.resaleVolumeIndex),
                yoy: item.resaleVolumeIndexYoy,
                origin: item,
                franchise: item.franchise,
                gender: item.gender,
                category: item.subCategory,
                model: item.model
            });
            diTemp.push({
                name: item.brandClean,
                date: item.date,
                value: Number(item.diversity),
                yoy: item.diversityYoy,
                origin: item,
                franchise: item.franchise,
                gender: item.gender,
                category: item.subCategory,
                model: item.model
            });
        });
        const genderAnalysisPPI = Object.entries(_.groupBy(ppiTemp, (d) => (this.genderTab === 'ALL' ? d.gender : this.level === 'BRAND' ? d.name : this.level === 'INNER_SUB_CATEGORY' ? d.category : d.model)) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.indexDateArr1, value),
        }));
        const genderAnalysisRVI = Object.entries(_.groupBy(rviTemp, (d) => (this.genderTab === 'ALL' ? d.gender : this.level === 'BRAND' ? d.name : this.level === 'INNER_SUB_CATEGORY' ? d.category : d.model)) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.indexDateArr2, value),
        }));
        const genderAnalysisDI = Object.entries(_.groupBy(diTemp, (d) => (this.genderTab === 'ALL' ? d.gender : this.level === 'BRAND' ? d.name : this.level === 'INNER_SUB_CATEGORY' ? d.category : d.model)) || {}).map(([key, value]) => ({
            name: key,
            data: completeMissingData(this.indexDateArr3, value),
        }));
        return [
            [
                {
                    title: 'DEMAND BY PPI',
                    data: genderAnalysisDemand,
                    value: 'demand',
					yoy: 'demandYoy',
					dateRange: this.demandDateArrOrigin,
					currentDateRange: this.demandDateArr
                },
                {
                    title: 'PREMIUM PRICE INDEX',
                    data: genderAnalysisPPI,
                    value: 'ppi',
					yoy: 'ppiYoy',
					dateRange: this.indexDateArrOrigin,
					currentDateRange: this.indexDateArr1
                },
            ],
            [
                {
                    title: 'RESALE VOLUME INDEX',
                    data: genderAnalysisRVI,
                    value: 'resaleVolumeIndex',
					yoy: 'resaleVolumeIndexYoy',
					dateRange: this.indexDateArrOrigin,
					currentDateRange: this.indexDateArr2
                },
                {
                    title: 'DIVERSITY INDEX',
                    data: genderAnalysisDI,
                    value: 'diversity',
					yoy: 'diversityYoy',
					dateRange: this.indexDateArrOrigin,
					currentDateRange: this.indexDateArr3
                },
            ],
        ];
    }

    @action
    async genderAnalysisBrandDemandRequest({ dateType, startDate, endDate, dataType, gender, level, brandList, ppiList, ppiInfo, genderTab }) {
        const response = await genderAnalysisBrandDemandRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            gender,
            level: brandList.length === 1 && brandList[0] === 'TOTAL' ? 'MARKET': level,
            brandList,
            ppiList,
        });
        if (response.code === '00000') {
            this.ppiInfoGender = ppiInfo;
            this.genderTab = genderTab;
            this.level = level;
            this.genderAnalysisPPIData = response.data;
			this.demandDateArr = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.demandDateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async genderAnalysisBrandIndexRequest({ dateType, startDate, endDate, dataType, gender, level, brandList, genderTab }) {
        const response = await genderAnalysisBrandIndexRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            gender,
            level: brandList.length === 1 && brandList[0] === 'TOTAL' ? 'MARKET': level,
            brandList,
        });
        if (response.code === '00000') {
            this.genderTab = genderTab;
            this.level = level;
            this.genderAnalysisData = response.data;
			this.indexDateArr1 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArr2 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArr3 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async genderAnalysisFranchiseDemandRequest({ dateType, startDate, endDate, dataType, gender, level, franchiseList, ppiInfo, genderTab, modelList }) {
        const response = await genderAnalysisFranchiseDemandRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            gender,
            level,
            franchiseList,
            modelList
        });
        if (response.code === '00000') {
            this.ppiInfoGender = ppiInfo;
            this.genderTab = genderTab;
            this.level = level;
            this.genderAnalysisPPIData = response.data;
			this.demandDateArr = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.demandDateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async genderAnalysisFranchiseIndexRequest({ dateType, startDate, endDate, dataType, gender, level, franchiseList, genderTab, modelList }) {
        const response = await genderAnalysisFranchiseIndexRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            gender,
            level,
            franchiseList,
            modelList
        });
        if (response.code === '00000') {
            this.genderTab = genderTab;
            this.level = level;
            this.genderAnalysisData = response.data;
			this.indexDateArr1 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArr2 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArr3 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async genderAnalysisCategoryDemandRequest({ dateType, startDate, endDate, dataType, gender, level, subCategoryList, ppiList, ppiInfo, genderTab }) {
        const response = await genderAnalysisCategoryDemandRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            gender,
            level,
            subCategoryList,
            ppiList,
        });
        if (response.code === '00000') {
            this.ppiInfoGender = ppiInfo;
            this.genderTab = genderTab;
            this.level = level;
            this.genderAnalysisPPIData = response.data;
			this.demandDateArr = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.demandDateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }

    @action
    async genderAnalysisCategoryIndexRequest({ dateType, startDate, endDate, dataType, gender, level, subCategoryList, genderTab }) {
        const response = await genderAnalysisCategoryIndexRequest({
            dateType,
            startDate,
            endDate,
            dataType,
            gender,
            level,
            subCategoryList,
        });
        if (response.code === '00000') {
            this.genderTab = genderTab;
            this.level = level;
            this.genderAnalysisData = response.data;
			this.indexDateArr1 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArr2 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArr3 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.indexDateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
    }
}
