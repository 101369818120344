import { $post, $get } from './index';

export const loginRequest = (data) => {
    return $post('/authentication/login', data, {
        queryName: 'loginRequest',
    });
};

export const logoutRequest = () => {
    return $post(
        '/authentication/logout',
        {},
        {
            queryName: 'logoutRequest',
        }
    );
};

export const resetUrlValidateRequest = (data) => {
    return $post('/authentication/password/reset/url/validate', data, {
        queryName: 'resetUrlValidateRequest',
    });
};

export const resetPasswordRequest = (data) => {
    return $post('/authentication/password/reset/action', data, {
        queryName: 'resetPasswordRequest',
    });
};

export const forgetPasswordImgRequest = () => {
    return $get(
        '/authentication/verification/code/password/reset',
        {},
        {
            queryName: 'forgetPasswordImgRequest',
        }
    );
};

export const forgetPasswordResetRequest = (data) => {
    return $post('/authentication/password/reset/request', data, {
        queryName: 'forgetPasswordResetRequest',
    });
};

export const updatePasswordRequest = (data) => {
    return $post('/authentication/password/update', data, {
        queryName: 'updatePasswordRequest',
    });
};
