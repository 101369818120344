import React, { useState, useContext } from 'react'
import '../../assets/css/explor.css'
import Icon from '../components/Icon'
import cx from 'classnames'
import CommonTextField from '../components/TextField'
import { withStyles, createStyles } from '@material-ui/core/styles';
import { TextField, Checkbox, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { add, remove, groupedByNumber } from '../../utils/index';
import { MobXProviderContext } from 'mobx-react';
import CommonTootip from '../components/StyledTooltip';
import CommonDialog from '../components/StyledDialog';

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

const StyledCheckBox = withStyles((theme) =>
    createStyles({
        root: {
            color: '#fff',
            opacity: 0.5,
            background: '#000',
            padding: '0px',
            fontSize: '12px',
            borderRadius: '100%',
            overflow: 'hidden',
            width: '16px',
            height: '16px',
            '&.MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#fff',
            },
            '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
                opacity: 1,
            },
        },
    })
)(Checkbox);

const stepList = [
    {
        name: 'Step 1',
        value: 1
    },
    {
        name: 'Step 2',
        value: 2
    },
    {
        name: 'Step 3',
        value: 3
    }
]

const typeList = [
    {
        name: 'Sub-Category',
        value: 'category'
    },
    {
        name: 'Brand',
        value: 'brand'
    },
    {
        name: 'Franchise-Model',
        value: 'franchise'
    },
    {
        name: 'Gender',
        value: 'gender'
    }
]

const categoryList = [
    {
        display: 'LIFESTYLE',
        value: 'LIFESTYLE'
    },
    {
        display: 'RUNNING',
        value: 'RUNNING'
    },
    {
        display: 'BASKETBALL',
        value: 'BASKETBALL'
    },
    {
        display: 'NON-SPORTSWEAR',
        value: 'NON-SPORTSWEAR'
    },
    {
        display: 'OTHERS',
        value: 'OTHERS'
    }
]

const genderList = [
    {
        display: 'MALE',
        value: 'MALE'
    },
    {
        display: 'FEMALE',
        value: 'FEMALE'
    },
    {
        display: 'KIDS',
        value: 'KIDS'
    },
]

const ExplorAttribute = ({ cancel, confirm }) => {
    const store = useContext(MobXProviderContext)
    const [step, setStep] = useState(1)
    const [showMenu, setShowMenu] = useState(false)
    const [list, setList] = useState([])
    const [textFieldValue, setTextFieldValue] = useState('')
    const [select, setSelect] = useState({
        category: '',
        brand: '',
        franchise: '',
        gender: ''
    })
    const [currentMenu, setCurrentMenu] = useState({})
    const [searchValue, setSearchValue] = useState('')
    const [checkedList, setCheckedList] = useState([])
    const [checkObj, setCheckObj] = useState({})
    const [checkData, setCheckData] = useState({
        category: [],
        brand: [],
        franchise: [],
        gender: []
    })
    const [open, setOpen] = useState({
        category: false,
        brand: false,
        franchise: false,
        gender: false
    })
    const [openAll, setOpenAll] = useState(false)
    const [brandDict, setBrandDict] = useState([])
    const [franchiseDict, setFranchiseDict] = useState([])
    const [data, setData] = useState({
        category: categoryList,
        gender: genderList,
        brand: [],
        franchise: []
    })
    const [franchiseMenu, setFranchiseMenu] = useState([])
    const [modelMenu, setModelMenu] = useState([])
    const [tempCheck, setTempCheck] = useState([])
    const [key, setKey] = useState([1])
    // containObj = {
    //     1: {  key 1,2,3,4,5,6,7,8,9 就是 下面循环中的item
    //         tags: [],
    //         groupTags: [],
    //         currentGroupNum: 1
    //     }
    // }
    const [containObj, setContainObj] = useState({})
    const [notContainObj, setNotContainObj] = useState({})
    const [cautionOpen, setCautionOpen] = useState(false)
    const [cautionMessage, setCautionMessage] = useState('')
    const [isDisable, setIsDisable] = useState(false)
    const [searchList, setSearchList] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const loadingUrl = process.env.PUBLIC_URL + '/static/publicResource/img/loading.gif';

    // useEffect(() => {
    //     (async () => {
    //         await store.commonStore.brandDictRequest()
    //         // await store.commonStore.franchiseDictRequest()
    //         const brand = store.commonStore.brandDict
    //         // const franchise = store.commonStore.franchiseDict
    //         setBrandDict(brand)
    //         // setFranchiseDict(franchise)
    //         setData({
    //             ...data,
    //             brand,
    //             // franchise
    //         })
    //     })()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const handleStepChoose = (stepInfo) => {
        if (stepInfo.value !== step && step + 2 !== stepInfo.value && step - 2 !== stepInfo.value) {
            if (validate(stepInfo.value)) {
                setStep(stepInfo.value)
                setSearchValue('')
            } else {
                const message = stepInfo.value === 2 ? 'Sub-Category and Gender is required.' : 'Please complete keywords.'
                setCautionOpen(true)
                setCautionMessage(message)
            }
        }
        
    }

    const validate = (step) => {
        if (step === 2) {
            return checkData.category.length > 0 && checkData.gender.length > 0
        } else if (step === 3) {
            const lastKey = key[key.length - 1]
            const lastContainObj = containObj[lastKey]
            return lastContainObj && lastContainObj.tags && lastContainObj.tags.length > 0
        }
        return true
    }

    const handleCancel = () => {
        cancel()
    }

    const handleParams = () => {
        const groupName = textFieldValue
        const subCategoryList = checkData.category
        const brandList = checkData.brand
        const genderList = checkData.gender
        const franchiseList = checkData.franchise.map(item => item && item[item.length - 2].value)
        const modelList = checkData.franchise.map(item => item && item[item.length - 1].value)
        const definitionKeywordList = key.map(keyItem => ({
            containedKeywords: containObj[keyItem].tags,
            notContainedKeywords: (notContainObj[keyItem] && notContainObj[keyItem].tags) || []
        }))

        return {
            groupName,
			subCategoryList,
			brandList,
			franchiseList,
			modelList,
			genderList,
			definitionKeywordList
        }
    }

    const handleConfirm = async () => {
        if (!isDisable) {
            if (textFieldValue.length > 0) {
                const params = handleParams()
                try {
                    setIsDisable(true)
                    setShowLoading(true)
                    const response = await store.explorStore.saveGroupDefineRequest(params)
                    if (response.code === '00000') {
                        confirm()
                    } else if (response.code === 'AEG_1002') {
                        setCautionMessage(response.message)
                        setCautionOpen(true)
                    }
                    setShowLoading(false)
                    setIsDisable(false)
                } catch (error) {
                    setShowLoading(false)
                    setIsDisable(false)
                }
            } else {
                setCautionOpen(true)
                setCautionMessage('Please complete group name.')
            }
        }
    }

    const handleMenuClick = (menu) => {
        if (currentMenu.value === menu.value) {
            setShowMenu(false)
            setCurrentMenu({})
        } else {
            if (menu.value === 'franchise' && checkData['brand'].length === 0) {
                setList([])
                setFranchiseMenu([])
                setModelMenu([])
                setCheckedList([])
                setShowMenu(true)
                setCurrentMenu(menu)
            } else if (menu.value === 'franchise' && checkData['brand'].length > 0) {
                setList(data[menu.value].filter(item => checkData['brand'].includes(item.value) && item))
                setCheckedList(checkData[menu.value])
                setShowMenu(true)
                setCurrentMenu(menu)
            } else {
                setList(data[menu.value])
                setCheckedList(checkData[menu.value])
                setShowMenu(true)
                setCurrentMenu(menu)
            }
        }
        setSearchValue('')
    }

    const recursionTreeData = (item, searchObj, value, pos) => {
        if (item.isLeaf && item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            searchObj[pos] = item;
        } else if (!item.isLeaf) {
            searchObj[pos] = item;
            item.children.forEach((d) => {
                recursionTreeData(d, searchObj, value, `${pos}#%%#${d.name}_${d.position}`);
            });
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        if (currentMenu.value !== 'franchise') {
            if (value) {
                const temp = data[currentMenu.value]
                const arr = temp.filter(f => f.value.toUpperCase().indexOf(value.toUpperCase()) !== -1 && f)
                setList(arr)
            } else {
                const temp = data[currentMenu.value]
                setList(temp)
            }
        } else {

            const result = [];
            if (value) {
                const searchObj = {};
                const brand = checkData['brand']
                JSON.parse(JSON.stringify(franchiseDict)).filter(f => brand.includes(f.value) && f).forEach((l) => {
                    recursionTreeData(l, searchObj, value, `${l.name}_${l.position}`);
                });

                const obj = JSON.parse(JSON.stringify(searchObj));

                Object.entries(obj).forEach(([key, values]) => {
                    if (values.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 && values.isLeaf) {
                        const arr = [];
                        Object.entries(obj).forEach(([k, v]) => {
                            if (key.indexOf(k) === 0) {
                                arr.push(v);
                            }
                        });
                        result.push(arr);
                    }
                });
            }
            setSearchList(result);
        }
    };

    const handleRemoveSelected = (name) => {
        if (currentMenu.value !== 'franchise') {
            const list = remove(checkedList, name);
            const checked = { ...checkObj, [name]: false };
            setCheckedList(list);
            setSelect({
                ...select,
                [currentMenu.value]: list.toString()
            })
            setCheckData({
                ...checkData,
                [currentMenu.value]: list
            })
            setCheckObj(checked);
        } else {
            const item = name[name.length - 1]
            handleModel(item)
        }
    };

    const handleCheckBox = async (item) => {
        const checked = checkObj[item.value];
        if (!checked) {
                setCheckObj({
                    ...checkObj,
                    [item.value]: !checked,
                });
                const list = add(checkedList, item.value);
                setCheckedList(list);
                setSelect({
                    ...select,
                    [currentMenu.value]: list.toString()
                })
                setCheckData({
                    ...checkData,
                    [currentMenu.value]: list
                })
                if (currentMenu.value === 'category') {
                    await store.explorStore.getFranchiseByCategory({
                        sub_category: list
                    })
                    const franchise = store.explorStore.dict
                    const brand = store.explorStore.brandDict
                    setFranchiseDict(franchise)
                    setBrandDict(brand)
                    setData({
                        ...data,
                        brand,
                        franchise
                    })
                    setSelect({
                        ...select,
                        [currentMenu.value]: list.toString(),
                        franchise: '',
                        brand: ''
                    })
                    setCheckData({
                        ...checkData,
                        [currentMenu.value]: list,
                        franchise: [],
                        brand: []
                    })
                }
        } else {
            // brand 勾选去掉 franchise 相应去掉勾选
            if (currentMenu.value === 'brand') {
                setCheckObj({
                    ...checkObj,
                    [item.value]: !checked,
                });
                const list = remove(checkedList, item.value);
                setCheckedList(list);
                
                const franchiseCheck = checkData['franchise']
                const franchiseCheckFinal = franchiseCheck.map(item => (item && list.includes(item[0].value)) && item).filter(f => f)
                setSelect({
                    ...select,
                    [currentMenu.value]: list.toString(),
                    franchise: franchiseCheckFinal.map(item => item[item.length - 1].value).toString()
                })
                setCheckData({
                    ...checkData,
                    [currentMenu.value]: list,
                    franchise: franchiseCheckFinal
                })
            } else {
                setCheckObj({
                    ...checkObj,
                    [item.value]: !checked,
                });
                const list = remove(checkedList, item.value);
                setCheckedList(list);
                setSelect({
                    ...select,
                    [currentMenu.value]: list.toString()
                })
                setCheckData({
                    ...checkData,
                    [currentMenu.value]: list
                })
                if (currentMenu.value === 'category') {
                    await store.explorStore.getFranchiseByCategory({
                        sub_category: list
                    })
                    const franchise = store.explorStore.dict
                    const brand = store.explorStore.brandDict
                    setFranchiseDict(franchise)
                    setBrandDict(brand)
                    setData({
                        ...data,
                        brand,
                        franchise
                    })
                    setSelect({
                        ...select,
                        [currentMenu.value]: list.toString(),
                        franchise: '',
                        brand: ''
                    })
                    setCheckData({
                        ...checkData,
                        [currentMenu.value]: list,
                        franchise: [],
                        brand: []
                    })
                }
            }
        }
    };

    const isSelectAll = () => {
        if (currentMenu.value === 'brand') {
            const brands = data['brand'] || []
            return checkData['brand'].length > 0 && checkData['brand'].length === brands.length
        } else if (currentMenu.value === 'franchise') {
            const model = modelMenu
            const franchiseCheckData = checkData['franchise']

            const inChildArr = []
            model.forEach(item => {
                franchiseCheckData.forEach(f => {
                    if (f.length > 0 && f[f.length - 1] && f[f.length - 1].value === item.value) {
                        inChildArr.push(item)
                    }
                })
            })

            return inChildArr.length > 0 && inChildArr.length === model.length
        }
    }

    const handleSelectAll = () => {
        if (currentMenu.value === 'brand') {
            if (isSelectAll()) {
                setCheckData({
                    ...checkData,
                    brand: []
                })
                setCheckedList([])
                const obj = {}
                brandDict.forEach(item => {
                    obj[item.value] = false
                })
                setCheckObj({
                    ...checkObj,
                    ...obj
                })
                setSelect({
                    ...select,
                    [currentMenu.value]: ''
                })
            } else {
                const obj = {}
                const temp = brandDict.map(item => {
                    obj[item.value] = true
                    return item.value
                })
                setCheckData({
                    ...checkData,
                    brand: temp
                })
                setCheckedList(temp)
                setCheckObj({
                    ...checkObj,
                    ...obj
                })
                setSelect({
                    ...select,
                    [currentMenu.value]: temp.toString()
                })
            }
        } else {
            if (isSelectAll()) {
                const franchiseCheck = checkData['franchise']
                const models = modelMenu.map(f => f.value)
                const arr = franchiseCheck.filter(item => !models.includes(item[item.length - 1].value) && item)
                setCheckData({
                    ...checkData,
                    franchise: arr
                })
                setCheckedList([])
                setSelect({
                    ...select,
                    franchise: arr.map(item => item[item.length - 1].value).toString()
                })
            } else {
                const franchiseCheck = checkData['franchise']
                const models = modelMenu.map(f => f.value)
                const arr = franchiseCheck.filter(item => !models.includes(item[item.length - 1].value) && item)
                const final = modelMenu.map(item => ([
                    ...tempCheck,
                    item
                ]))
                const franchiseArr = [...arr, ...final]
                setCheckData({
                    ...checkData,
                    franchise: franchiseArr
                })
                setCheckedList(franchiseArr)
                setSelect({
                    ...select,
                    franchise: franchiseArr.map(item => item[item.length - 1].value).toString()
                })
            }
        }
    }

    const handleBrand = (item) => {
        setFranchiseMenu(item.children);
        setModelMenu([])
        setTempCheck([
            {
                ...item,
                checked: true,
            },
        ]);
    }

    const handleFranchise = (item) => {
        setModelMenu(item.children)
        const temp = update(tempCheck, { ...item, checked: true }, item.position - 1);
        setTempCheck(temp)
    }

    const update = (list, item, index) => {
        const arr = [...list.slice(0, index), item, ...list.slice(index + 1)];
        return arr;
    };

    const removeByIndex = (list, index) => {
        const arr = [...list.slice(0, index), ...list.slice(index + 1)];
        return arr;
    };

    const handleModel = (item) => {
        let removeIndex = null;
        checkData['franchise'].forEach((l, index) => {
            const lastCheck = l[l.length - 1];
            if (`${lastCheck.parentValue}_${lastCheck.name}_${lastCheck.position}` === `${item.parentValue}_${item.name}_${item.position}`) {
                removeIndex = index;
            }
        });

        const temp = update(tempCheck, { ...item, checked: removeIndex !== null ? false : true }, item.position - 1);
        setTempCheck(temp);

        let checkList = checkedList;
        if (removeIndex !== null) {
            checkList = removeByIndex(checkedList, removeIndex);
        } else {
            checkList = add(checkedList, temp);
        }
        setCheckedList(checkList);
        setCheckData({
            ...checkData,
            franchise: checkList
        })
        setSelect({
            ...select,
            [currentMenu.value]: checkList.map(item => item[item.length - 1].value).toString()
        })
    }

    const checkedObj = {}
    if (currentMenu.value === 'franchise') {
        // leaf is checked and parent is checked
        checkedList.forEach((item) => {
            item && item.forEach((check) => {
                checkedObj[`${check.parentValue}-${check.name}_${check.position}`] = true;
            });
        });

        // contains leaf is not checked but parent is checked
        tempCheck.forEach((check) => {
            checkedObj[`${check.parentValue}-${check.name}_${check.position}`] = check.checked;
        });
    }

    const handleTagRemove = (item, tag, index, type) => {
        if (type === 'contain') {
            const currentGroupNum = containObj[item].currentGroupNum
            const currentNum = currentGroupNum * 2 + index
            const list = containObj[item].tags
            const newList = [
                ...list.slice(0, currentNum),
                ...list.slice(currentNum + 1)
            ]
            const newGroup = groupedByNumber(2, newList, '')
            const obj = {
                ...containObj,
                [item]: {
                    ...(containObj[item] || {}),
                    tags: newList,
                    groupTags: newGroup,
                    currentGroupNum: newGroup.length - 1 < currentGroupNum ? currentGroupNum - 1 : currentGroupNum,
                    value: ''
                }
            }
            setContainObj(obj)
        } else {
            const currentGroupNum = notContainObj[item].currentGroupNum
            const currentNum = currentGroupNum * 2 + index
            const list = notContainObj[item].tags
            const newList = [
                ...list.slice(0, currentNum),
                ...list.slice(currentNum + 1)
            ]
            const newGroup = groupedByNumber(2, newList, '')
            const obj = {
                ...notContainObj,
                [item]: {
                    ...(notContainObj[item] || {}),
                    tags: newList,
                    groupTags: newGroup,
                    currentGroupNum: newGroup.length - 1 < currentGroupNum ? currentGroupNum - 1 : currentGroupNum,
                    value: ''
                }
            }
            setNotContainObj(obj)
        }
    }

    const handleChange = (e, item, type) => {
        if (type === 'contain') {
            const obj = {
                ...containObj,
                [item]: {
                    ...(containObj[item] || {}),
                    value: e.target.value
                }
            }
            setContainObj(obj)
        } else {
            const obj = {
                ...notContainObj,
                [item]: {
                    ...(notContainObj[item] || {}),
                    value: e.target.value
                }
            }
            setNotContainObj(obj)
        }
    }

    const handleEnter = (e, item, type) => {
        if (e.code === 'Enter') {
            if (type === 'contain') {
                const tags = [...((containObj[item] && containObj[item].tags) || []), e.target.value]
                const groupTags = groupedByNumber(2, tags, '')
                const obj = {
                    ...containObj,
                    [item]: {
                        ...(containObj[item] || {}),
                        tags,
                        groupTags,
                        currentGroupNum: groupTags.length - 1,
                        value: ''
                    }
                }
                setContainObj(obj)
            } else {
                const tags = [...((notContainObj[item] && notContainObj[item].tags) || []), e.target.value]
                const groupTags = groupedByNumber(2, tags, '')
                const obj = {
                    ...notContainObj,
                    [item]: {
                        ...(notContainObj[item] || {}),
                        tags,
                        groupTags,
                        currentGroupNum: groupTags.length - 1,
                        value: ''
                    }
                }
                setNotContainObj(obj)
            }
        }
    }

    const handleAddKey = (item) => {
        const newKey = [
            ...key,
            key[key.length - 1] + 1
        ]
        setKey(newKey)
    }

    const handleRemoveKey = (index) => {
        const arr = [
            ...key.slice(0, index),
            ...key.slice(index + 1)
        ]
        setKey(arr)
    }

    const canAdd = (item) => {
        return containObj[item] && containObj[item].tags && containObj[item].tags.length > 0
    }

    const handleArrowActive = (item, arrow, type) => {
        if (type === 'contain') {
            if (arrow === 'up') {
                return containObj[item] && containObj[item].groupTags && containObj[item].groupTags.length > 1 && containObj[item].currentGroupNum > 0
            } else {
                return containObj[item] && containObj[item].groupTags && containObj[item].groupTags.length > 1 && containObj[item].currentGroupNum < containObj[item].groupTags.length - 1
            }
        } else {
            if (arrow === 'up') {
                return notContainObj[item] && notContainObj[item].groupTags && notContainObj[item].groupTags.length > 1 && notContainObj[item].currentGroupNum > 0
            } else {
                return notContainObj[item] && notContainObj[item].groupTags && notContainObj[item].groupTags.length > 1 && notContainObj[item].currentGroupNum < notContainObj[item].groupTags.length - 1
            }
        }
    }

    const handleArrowClick = (item, arrow, type) => {
        if (handleArrowActive(item, arrow, type)) {
            if (type === 'contain') {
                const obj = {
                    ...containObj,
                    [item]: {
                        ...(containObj[item] || {}),
                        currentGroupNum: arrow === 'up' ? containObj[item].currentGroupNum - 1 : containObj[item].currentGroupNum + 1,
                    }
                }
                setContainObj(obj)
            } else {
                const obj = {
                    ...notContainObj,
                    [item]: {
                        ...(notContainObj[item] || {}),
                        currentGroupNum: arrow === 'up' ? notContainObj[item].currentGroupNum - 1 : notContainObj[item].currentGroupNum + 1,
                    }
                }
                setNotContainObj(obj)
            }
        }
    }

    const handleOpenAll = () => {
        setOpenAll(!openAll)
        setOpen({
            category: !openAll,
            brand: !openAll,
            franchise: !openAll,
            gender: !openAll
        })
    }

    const handleOpen = (type) => {
        setOpen({
            ...open,
            [type]: !open[type]
        })
    }

    const handleNextStep = (item) => {
        if (validate(item)) {
            setStep(item)
        } else {
            const message = item === 2 ? 'Sub-Category and Gender is required.' : 'Please complete keywords'
            setCautionOpen(true)
            setCautionMessage(message)
        }
    }

    const handleFranchiseSearch = (list) => {
        const currentLast = list[list.length - 1];

        let removeIndex = null;
        checkedList.forEach((l, index) => {
            const lastCheck = l[l.length - 1];
            if (`${lastCheck.name}_${lastCheck.position}` === `${currentLast.name}_${currentLast.position}`) {
                removeIndex = index;
            }
        });

        let checkList = checkedList;
        if (removeIndex !== null) {
            checkList = removeByIndex(checkedList, removeIndex);
        } else {
            checkList = add(checkedList, list);
        }
        setCheckedList(checkList);
        setCheckData({
            ...checkData,
            franchise: checkList
        })
        setSelect({
            ...select,
            [currentMenu.value]: checkList.map(item => item[item.length - 1].value).toString()
        })
    };

    return (
        <div className='explor-attribute-box flex flex-column relative'>
            {
                showLoading && (
                    (
                        <div className='absolute flex align-center justify-center' style={{width: '100%', height: '100%', zIndex: 999}}>
                            <div style={{width: '110px', height: '110px', borderRadius: '8px', overflow: 'hidden'}}>
                                <img src={loadingUrl} alt="" />
                            </div>
                        </div>
                    )
                )
            }
            <div className='explor-attribute-head flex align-center'>
                <div className='flex align-center'><Icon className="flex-1" style={{width: '21px', height: '16px', marginRight: '6px'}} name='dialog-title'></Icon>Define SKU Group</div>
                <div className='flex-1'></div>
                <div className='explor-attribute-close hover' onClick={handleCancel}></div>
            </div>
            <div className='explor-attribute-step-box flex align-center'>
                {
                    stepList.map((item, index) => (
                        <React.Fragment key={index}>
                            <div className={cx('flex-1 explor-attribute-step', {
                                'explor-attribute-step-active': step === item.value,
                                hover: item.value !== step && step + 2 !== item.value && step - 2 !== item.value
                            })} onClick={() => handleStepChoose(item)}>{item.name}</div>
                            {
                                index !== stepList.length - 1 && (
                                    <div className='flex-1 flex align-center justify-center'>
                                        <div className={cx('explor-attribute-line', {
                                            'explor-attribute-line-active': step === item.value
                                        })}></div>
                                    </div>
                                )
                            }
                        </React.Fragment>
                    ))
                }
            </div>
            <div className='flex-1 explor-attribute-content-box flex flex-column hidden'>
                {
                    step === 1 && (
                        <>
                            <div className='flex explor-attribute-condition-box'>
                                {
                                    typeList.map((item, index) => (
                                        <div className={cx('flex-1 hidden', {
                                            mr8: index !== typeList.length - 1
                                        })} key={index}>
                                            <div className='mb6'>{item.name}</div>
                                            <div className={cx('explor-attribute-select-box flex align-center px10 hover', {
                                                'explor-attribute-select-box-active': currentMenu.value === item.value
                                            })} onClick={() => handleMenuClick(item)}>
                                                <div className='flex-1 explor-attribute-select-content'>{select[item.value]}</div>
                                                <div className={cx('step1-arrow', {
                                                    'step1-arrow-active': currentMenu.value === item.value
                                                })}></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                showMenu ? (
                                    <div className='flex-1 hidden flex flex-column'>
                                        <div className="">
                                            <StyledTextField
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth={true}
                                                value={searchValue}
                                                onChange={(e) => handleSearch(e.target.value)}
                                                id="search"
                                                type="search"
                                                variant="outlined"
                                                autoComplete="off"
                                            />
                                        </div>
                                        {
                                            currentMenu.value === 'franchise' && searchValue ? (
                                                <div className='flex-1 mb20 mt14 flex hidden flex-column overflow-y'>
                                                    {searchList.map((search, index) => {
                                                        const searchItems = JSON.parse(JSON.stringify(search)).reverse();
                                                        const checkLabel = searchItems[0];
                                                        return (
                                                            <div
                                                                className={cx('flex align-center justify-between hidden', {
                                                                    'drop-active': checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                                                })}
                                                                key={index}
                                                                style={{ minHeight: '30px' }}
                                                            >
                                                                <div className="flex align-center flex-1 py10">
                                                                    {searchItems.map((item, i) => (
                                                                        <div className="pr40 flex-1 search-content-item" style={{whiteSpace: 'normal'}} key={`${index}_${i}`}>
                                                                            {item.name}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {checkLabel.description ? <div className="flex align-center flex-1 search-content-description mr20 py10">{checkLabel.description}</div> : null}
                                                                <div
                                                                    className={cx('icon-check-box hover', {
                                                                        'icon-check': checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                                                        'icon-uncheck': !checkedObj[`${checkLabel.parentValue}-${checkLabel.name}_${checkLabel.position}`],
                                                                    })}
                                                                    onClick={() => handleFranchiseSearch(search)}
                                                                ></div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ): (
                                                <div className='flex-1 pb20 pt14 flex hidden'>
                                                    <div className='explor-attribute-list-box'>
                                                        {currentMenu.value !== 'franchise' && list.map((item, index) => (
                                                            <div
                                                                className={cx('flex align-center justify-between explor-list-items py4 my4 pl10 mr4 hidden', {
                                                                    'explor-drop-active': checkObj[item.value],
                                                                })}
                                                                key={index}
                                                            >
                                                                <div className="flex align-center no-wrap flex-1">{item.display}</div>
                                                                <div
                                                                    className={cx('icon-check-box hover', {
                                                                        'icon-check': checkObj[item.value],
                                                                        'icon-uncheck': !checkObj[item.value],
                                                                    })}
                                                                    onClick={() => handleCheckBox(item)}
                                                                ></div>
                                                            </div>
                                                        ))}
                                                        {currentMenu.value === 'franchise' && list.map((item, index) => (
                                                            <div
                                                                className={cx('flex align-center justify-between explor-list-items py4 my4 pl10 mr4 hidden hover', {
                                                                    'explor-drop-active': checkedObj[`${item.parentValue}-${item.name}_${item.position}`]
                                                                })}
                                                                key={index}
                                                                onClick={() => handleBrand(item)}
                                                            >
                                                                <div className="flex align-center flex-1">{item.name}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {
                                                        currentMenu.value === 'franchise' && franchiseMenu.length > 0 && (
                                                            <div className='explor-attribute-list-box explor-checked-list'>
                                                                <div style={{ fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '4px', paddingLeft: '10px' }}>Nike Franchise</div>
                                                                {
                                                                    currentMenu.value === 'franchise' && franchiseMenu.map((item, index) => (
                                                                        <div
                                                                            className={cx('flex align-center justify-between explor-list-items py4 my4 pl10 mr4 hidden hover', {
                                                                                'explor-drop-active': checkedObj[`${item.parentValue}-${item.name}_${item.position}`]
                                                                            })}
                                                                            key={index}
                                                                            onClick={() => handleFranchise(item)}
                                                                        >
                                                                            <div className="flex align-center flex-1">{item.name}</div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        currentMenu.value === 'franchise' && modelMenu.length > 0 && (
                                                            <div className='explor-attribute-list-box explor-checked-list'>
                                                                <div style={{ fontSize: '12px', color: 'rgba(255,255,255,0.5)', paddingBottom: '4px', paddingLeft: '10px' }}>Model</div>
                                                                <div
                                                                    className={cx('flex align-center justify-between explor-list-items my4 pl10 mr4 hidden')}
                                                                >
                                                                    <div className="flex align-center flex-1">Select all</div>
                                                                    <div
                                                                        className={cx('icon-check-box hover', {
                                                                            'icon-uncheck': !isSelectAll(),
                                                                            'icon-check': isSelectAll(),
                                                                        })}
                                                                        onClick={() => handleSelectAll()}
                                                                    ></div>
                                                                </div>
                                                                {
                                                                    currentMenu.value === 'franchise' && modelMenu.map((item, index) => (
                                                                        <div
                                                                            className={cx('flex align-center justify-between explor-list-items py4 my4 pl10 mr4 hidden', {
                                                                                'explor-drop-active': checkedObj[`${item.parentValue}-${item.name}_${item.position}`]
                                                                            })}
                                                                            key={index}
                                                                        >
                                                                            <div className="flex align-center flex-1">{item.name}</div>
                                                                            <div
                                                                                className={cx('icon-check-box hover', {
                                                                                    'icon-check': checkedObj[`${item.parentValue}-${item.name}_${item.position}`],
                                                                                    'icon-uncheck': !checkedObj[`${item.parentValue}-${item.name}_${item.position}`],
                                                                                })}
                                                                                onClick={() => handleModel(item)}
                                                                            ></div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    <div className='explor-attribute-list-box explor-checked-list'>
                                                        <div className='mb10 explor-select-title pt10'>Selected {currentMenu.name}</div>
                                                        {checkedList.map((item, index) => (
                                                            <div className="flex align-center pb10" key={index}>
                                                                <StyledCheckBox checked={true} indeterminate={true} onChange={() => handleRemoveSelected(item)} />
                                                                <div className="pl8 drop-sub-item no-wrap">{currentMenu.value !== 'franchise' ? item : item[item.length - 1].name}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ): (
                                    <div className='flex-1 hidden-x'>
                                        <div className='flex align-center explor-attribute-condition-view'>
                                            <div className='explor-attribute-condition-title'>View filtered conditions</div>
                                            <div className={cx('explor-item-icon explor-item-icon-add hover', {
                                                'explor-item-icon-remove': openAll
                                            })} onClick={handleOpenAll}></div>
                                        </div>
                                        <div>
                                            <div className='flex align-center explor-attribute-condition-item'>
                                                <div className='explor-dot'></div>
                                                <div className='explor-item-name'>Sub-Category</div>
                                                <div className={cx('explor-item-icon explor-item-icon-add', {
                                                    'explor-item-icon-remove': open.category
                                                })} onClick={() => handleOpen('category')}></div>
                                            </div>
                                            {
                                                checkData.category.length > 0 && open.category && <div className='explor-select-item mb15'>{checkData.category.join(';')}</div>
                                            }
                                        </div>
                                        <div>
                                            <div className='flex align-center explor-attribute-condition-item'>
                                                <div className='explor-dot'></div>
                                                <div className='explor-item-name'>Brand</div>
                                                <div className={cx('explor-item-icon explor-item-icon-add', {
                                                    'explor-item-icon-remove': open.brand
                                                })} onClick={() => handleOpen('brand')}></div>
                                            </div>
                                            {
                                                checkData.brand.length > 0 && open.brand && <div className='explor-select-item mb15'>{checkData.brand.join(';')}</div>
                                            }
                                        </div>
                                        <div>
                                            <div className='flex align-center explor-attribute-condition-item'>
                                                <div className='explor-dot'></div>
                                                <div className='explor-item-name'>Franchise-Model</div>
                                                <div className={cx('explor-item-icon explor-item-icon-add', {
                                                    'explor-item-icon-remove': open.franchise
                                                })} onClick={() => handleOpen('franchise')}></div>
                                            </div>
                                            {
                                                checkData.franchise.length > 0 && open.franchise && <div className='explor-select-item mb15'>{checkData.franchise.map(item => item && item[item.length - 1].value).join(';')}</div>
                                            }
                                        </div>
                                        <div>
                                            <div className='flex align-center explor-attribute-condition-item'>
                                                <div className='explor-dot'></div>
                                                <div className='explor-item-name'>Gender</div>
                                                <div className={cx('explor-item-icon explor-item-icon-add', {
                                                    'explor-item-icon-remove': open.gender
                                                })} onClick={() => handleOpen('gender')}></div>
                                            </div>
                                            {
                                                checkData.gender.length > 0 && open.gender && <div className='explor-select-item mb15'>{checkData.gender.join(';')}</div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )
                }
                {
                    step === 2 && (
                        <div className='explor-attribute-step2 flex flex-column flex-1'>
                            <div className='step2-title'>Edit Keyword</div>
                            <div className='flex-1'>
                                {
                                    key.map((item, index) => (
                                        <div key={index}>
                                            {
                                                index !== 0 && <div className='step2-or my14'>· or</div>
                                            }
                                            <div className='flex align-center'>
                                                <div className='explor-label mr6'>condition 1 contain</div>
                                                <div className='explor-step2-input mr8'>
                                                    <CommonTextField
                                                        style={{margin: 0, border: 'none', width: '72px'}}
                                                        fullWidth={true}
                                                        size='small'
                                                        name="con1"
                                                        type="text"
                                                        variant="outlined"
                                                        value={(containObj[item] && containObj[item].value) || ''}
                                                        onChange={(e) => handleChange(e, item, 'contain')}
                                                        onKeyDown={(e) => handleEnter(e, item, 'contain')}
                                                        autoComplete="off"
                                                    ></CommonTextField>
                                                </div>
                                                <div className='explor-step2-tags mr14 p4 flex align-center'>
                                                    <div className='flex-1 flex'>
                                                        {
                                                            ((containObj[item] && containObj[item].groupTags && containObj[item].groupTags[containObj[item].currentGroupNum]) || []).map((tag, i) => (
                                                                <div className='flex-1 flex align-ceter' key={i}>
                                                                    {
                                                                        tag && (
                                                                            <div className='flex-1 step2-tag-item flex align-center'>
                                                                                <CommonTootip
                                                                                    placement="top"
                                                                                    title={tag}
                                                                                >
                                                                                    <div className='flex-1 step2-tag-name'>
                                                                                            {tag}
                                                                                    </div>
                                                                                </CommonTootip>
                                                                                <div className='step2-del hover' onClick={() => handleTagRemove(item, tag, i, 'contain')}></div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='step2-tag-icon-box flex flex-column'>
                                                        <div className={cx('step2-tag-icon mb6 step2-tag-up', {
                                                            'step2-tag-up-active hover': handleArrowActive(item, 'up', 'contain')
                                                        })} onClick={() => handleArrowClick(item, 'up', 'contain')}></div>
                                                        <div className={cx('step2-tag-icon step2-tag-down', {
                                                            'step2-tag-down-active hover': handleArrowActive(item, 'down', 'contain')
                                                        })} onClick={() => handleArrowClick(item, 'down', 'contain')}></div>
                                                    </div>
                                                </div>
                                                <div className='explor-label mr6'><span style={{color: '#FF773A'}}>and</span> not contain</div>
                                                <div className='explor-step2-input mr8'>
                                                    <CommonTextField
                                                        style={{margin: 0, border: 'none', width: '72px'}}
                                                        fullWidth={true}
                                                        size='small'
                                                        name="con1"
                                                        type="text"
                                                        variant="outlined"
                                                        value={(notContainObj[item] && notContainObj[item].value) || ''}
                                                        onChange={(e) => handleChange(e, item, 'not')}
                                                        onKeyDown={(e) => handleEnter(e, item, 'not')}
                                                        autoComplete="off"
                                                    ></CommonTextField>
                                                </div>
                                                <div className='explor-step2-tags mr14 p4 flex align-center'>
                                                    <div className='flex-1 flex'>
                                                        {
                                                            ((notContainObj[item] && notContainObj[item].groupTags && notContainObj[item].groupTags[notContainObj[item].currentGroupNum]) || []).map((tag, i) => (
                                                                <div className='flex-1 flex align-ceter' key={i}>
                                                                    {
                                                                        tag && (
                                                                            <div className='flex-1 step2-tag-item flex align-center'>
                                                                                <div className='flex-1 step2-tag-name'>{tag}</div>
                                                                                <div className='step2-del hover' onClick={() => handleTagRemove(item, tag, i, 'not')}></div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='step2-tag-icon-box flex flex-column'>
                                                        <div className={cx('step2-tag-icon mb6 step2-tag-up', {
                                                            'step2-tag-up-active hover': handleArrowActive(item, 'up', 'not')
                                                        })} onClick={() => handleArrowClick(item, 'up', 'not')}></div>
                                                        <div className={cx('step2-tag-icon step2-tag-down', {
                                                            'step2-tag-down-active hover': handleArrowActive(item, 'down', 'not')
                                                        })} onClick={() => handleArrowClick(item, 'down', 'not')}></div>
                                                    </div>
                                                </div>
                                                <div className='flex align-center'>
                                                    {
                                                        key.length !== 1 && <div className='step2-icon step2-icon-remove hover' onClick={() => handleRemoveKey(index)}></div>
                                                    }
                                                    {
                                                        index === key.length - 1 && key.length < 3 && canAdd(item) && <div className='step2-icon step2-icon-add ml4 hover' onClick={() => handleAddKey(item)}></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='step-2-desc flex item-center'><div className='flex' style={{ marginTop: '-4px' }}><Icon className='flex-1' style={{width: '14px', height: '14px', marginRight: '8px'}} name='desc'></Icon></div>Please edit keywords in the text box. Seperate multiple keywords with semicolons. Keywords in one row will be matched simultaneously.</div>
                        </div>
                    )
                }
                {
                    step === 3 && (
                        <div className='explor-attribute-step3'>
                            <div className='step3-title'>Please name the group</div>
                            <div className='step3-input flex align-center'>
                                <span className='step3-input-name'>Name</span>
                                <CommonTextField
                                    style={{margin: '0 0 10px 20px'}}
                                    fullWidth={true}
                                    name="step3"
                                    type="text"
                                    margin="normal"
                                    value={textFieldValue}
                                    variant="outlined"
                                    onChange={(e) =>
                                        setTextFieldValue(e.target.value.substring(0,20))
                                    }
                                    placeholder='Please enter'
                                ></CommonTextField>
                            </div>
                            <div className='text-right'><span style={{color: '#FF773A'}}>{textFieldValue.length} /</span> 20</div>
                        </div>
                    )
                }
            </div>
            <div className='explor-attribute-footer flex'>
                {
                    step === 2 && <div><span style={{color: '#FF773A'}}>{key.length} /</span> 3</div>
                }
                {
                    step === 1 && currentMenu.value === 'brand' && (
                        <div className='flex align-center'>
                            <div className="mr10">Select all</div>
                            <div className={cx("icon-check-box hover", {
                                    'icon-uncheck': !isSelectAll(),
                                    'icon-check': isSelectAll(),
                                })}
                                onClick={() => handleSelectAll()}
                            ></div>
                        </div>
                    )
                }
                <div className='flex-1'></div>
                <div className='flex align-center'>
                    {
                        step === 1 && <div className='explor-attribute-button hover' onClick={handleCancel}>Cancel</div>
                    }
                    {
                        step !== 1 && <div className='explor-attribute-button hover' onClick={() => setStep(step - 1)}>Previous step</div>
                    }
                    {
                        step !== 3 && <div className='explor-attribute-button hover' onClick={() => handleNextStep(step + 1)}>Next step</div>
                    }
                    {
                        step === 3 && <div className={cx('explor-attribute-button', {
                            hover: !isDisable
                        })} onClick={handleConfirm}>Comfirm</div>
                    }
                </div>
            </div>
            <CommonDialog open={cautionOpen}>
                <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                    <div className="download-caution-icon mb10"></div>
                    <div className="dialog-content-title mb6">CAUTION</div>
                    <div className="dialog-content-desc text-center mb16">{cautionMessage}</div>
                    <div className="flex align-center justify-between full-width">
                        <div className="flex-1 upload-button-item flex align-center justify-center hover mr10" onClick={() => setCautionOpen(false)}>
                            Confirm
                        </div>
                    </div>
                </div>
            </CommonDialog>
        </div>
    )
}

export default ExplorAttribute