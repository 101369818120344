import * as echarts from 'echarts';
import { completeMissingData } from '../../../store/utils';

const formatterByUnit = (value, unit) => {
    if (unit === 'M') {
        return `${Math.round(value / 1000000)
            .toString()
            .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}M`;
    }
};

export const lineRender = (element, props) => {
    const ftwDataTemp = (props.data['FTW'] || []).map((item) => {
        return {
            name: item.category,
            value: Number(item.ppi),
            yoy: item.ppiYoy,
            date: item.date,
        };
    });
    const appDataTemp = (props.data['APP'] || []).map((item) => {
        return {
            name: item.category,
            value: Number(item.ppi),
            yoy: item.ppiYoy,
            date: item.date,
        };
    });

    const ftwData = completeMissingData(props.allDate, ftwDataTemp);
    const appData = completeMissingData(props.allDate, appDataTemp);

    const xAxisData = props.allDate;

    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    chart.setOption({
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                let content = `<div>${params && params[0] && params[0].axisValueLabel}</div>`;
                params.forEach((p) => {
                    if (p.data.value) {
                        const name = p.data.name;
                        const v = p.data.value.toFixed(2);
                        content += `<div class="flex align-center"><div class="dot" style="background: ${p.color.type === 'linear' ? p.color.colorStops[0].color : p.color}"></div>${name}：${v}</div>`;
                    }
                });
                return content;
            },
        },
        grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: 10,
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: '#979797',
                },
            },
            axisLabel: {
                color: '#fff',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
        },
        yAxis: {
            type: 'value',
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#979797',
                    opacity: 0.3,
                },
            },
            scale: true,
            axisLabel: {
                color: 'rgba(255,255,255,0.7)',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
                formatter: (value) => {
                    if (typeof value === 'number') {
                        return value.toFixed(1);
                    }
                    return value;
                },
            },
        },
        series: [
            {
                name: 'APP',
                type: 'line',
                data: appData,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        { offset: 0, color: '#4B6ACF' },
                        { offset: 1, color: '#8D72FF' },
                    ]),
                    borderWidth: 3,
                    borderColor: '#000',
                },
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    width: 4,
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#88A4EB' },
                        { offset: 1, color: 'rgba(136, 164, 235, 0)' },
                    ]),
                    opacity: 0.3,
                },
            },
            {
                name: 'FTW',
                type: 'line',
                data: ftwData,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        { offset: 0, color: '#FF7334' },
                        { offset: 1, color: '#FFAB64' },
                    ]),
                    borderWidth: 3,
                    borderColor: '#000',
                },
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    width: 4,
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        { offset: 0, color: 'rgba(255, 175, 108, 0)' },
                        { offset: 1, color: '#FF773A' },
                    ]),
                    opacity: 0.3,
                },
            },
        ],
        // dataZoom: [
        //     {
        //         start: 0,
        //         end: 100,
        //         height: 4,
        //         backgroundColor: '#000',
        //         opacity: 0.3,
        //         moveHandleSize: 0,
        //         brushSelect: false,
        //         fillerColor: 'rgba(255,255,255,0.8)',
        //         handleIcon:
        //             'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAUCAYAAADlep81AAABYWlDQ1BrQ0dDb2xvclNwYWNlRGlzcGxheVAzAAAokWNgYFJJLCjIYWFgYMjNKykKcndSiIiMUmB/yMAOhLwMYgwKicnFBY4BAT5AJQwwGhV8u8bACKIv64LMOiU1tUm1XsDXYqbw1YuvRJsw1aMArpTU4mQg/QeIU5MLikoYGBhTgGzl8pICELsDyBYpAjoKyJ4DYqdD2BtA7CQI+whYTUiQM5B9A8hWSM5IBJrB+API1klCEk9HYkPtBQFul8zigpzESoUAYwKuJQOUpFaUgGjn/ILKosz0jBIFR2AopSp45iXr6SgYGRiaMzCAwhyi+nMgOCwZxc4gxJrvMzDY7v////9uhJjXfgaGjUCdXDsRYhoWDAyC3AwMJ3YWJBYlgoWYgZgpLY2B4dNyBgbeSAYG4QtAPdHFacZGYHlGHicGBtZ7//9/VmNgYJ/MwPB3wv//vxf9//93MVDzHQaGA3kAFSFl7jXH0fsAAAA4ZVhJZk1NACoAAAAIAAGHaQAEAAAAAQAAABoAAAAAAAKgAgAEAAAAAQAAACSgAwAEAAAAAQAAABQAAAAAqOd5QAAAAeRJREFUSA3NVr1qAkEQntvLgR4Hoo2QNp1gZ5fO0sqkyQsELCxEwZBHSCIolr5EYmEjeQAtBKuUVsHUggGFwzPzXbLLetyBB8Fz4NjZb372273ZH4NCZDAYWJvNpup5XpXNJcMwLvf7vRPiejTEOb45xxcHzIQQw3Q6PazVam4wgREE+v3+DRPpcPBV0PaffSa4YGLtRqPxpudVhJiA6PV6T2xs6w4n0DvNZvORCXoYy5QDZjKZZ9ZPTQbDX0+nU3s8Hr+j468QftNut3sFECX5fJ6KxSKlUimaz+e0XC5917h4VH7TNG/x+wQKGDUT5SjxQqFAjuNQLpejcrksYYqLq8CAAg7gcoHddEwBY2W4CMmyLLJtW6WLi6vAgAIO4CL+tnbAHN7lwiP56R4SQ6tLFK776Dq4CAZKOpiwXhI8i8uESajhwQUrdFYiuJhwnJ+FgAtWaHYWbH5J4J4Tw2MJ8QxIfnqMxNDqEoXrProOLgK3LhfTQjeE6dvtlnhbkuu6tFqtlEtcXAUGFHAAF3M0GnmVSuWTZ3MX8Dno8qFF2WzWJzSZTGi9Xvv2uPhBUq3DV8d9vV7/UCdZt9t9YXsSlytodVqt1gMUte3xBIAB4InFf37IMdUKSeBsHmiSENokn7A/rJQSNHKxQ8YAAAAASUVORK5CYII=',
        //         handleSize: '15',
        //         showDetail: false,
        //         bottom: 10,
        //         right: '10%',
        //         left: '12%',
        //     },
        // ],
    });
};

export const lineRender3 = (element, props) => {
    const color = { ...props.treemapLineColor };
    const data = Object.entries(props.data).map(([key, value]) => {
        const d = value.map((item) => {
            return {
                name: item.brandClean,
                value: Number(item.demand),
                date: item.date,
                code: item.priceTierCode,
                range: item.priceTierName,
            };
        });
        return {
            brand: key,
            data: completeMissingData(props.allDate, d),
        };
    });
    const xAxisData = props.allDate;

    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    chart.setOption({
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                let content = `<div>${params && params[0] && params[0].axisValueLabel}</div>`;
                params.forEach((p) => {
                    if (p.data.value) {
                        const name = p.data.name;
                        const v = formatterByUnit(p.data.value, 'M');
                        content += `<div class="flex align-center"><div class="dot" style="background: ${p.color.type === 'linear' ? p.color.colorStops[0].color : p.color}"></div>${name}：${v}</div>`;
                    }
                });
                return content;
            },
        },
        grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: 10,
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: '#979797',
                },
            },
            axisLabel: {
                color: 'rgba(255,255,255,1)',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
        },
        yAxis: {
            type: 'value',
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#979797',
                    opacity: 0.3,
                },
            },
            scale: true,
            axisLabel: {
                color: 'rgba(255,255,255,0.7)',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
                formatter: (value) => {
                    if (typeof value === 'number') {
                        if (Math.abs(value) >= 1000000) {
                            const formattedValue = Math.round(value / 1000000);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} M`;
                        } else {
                            const formattedValue = Math.round(value);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}`;
                        }
                    }
                    return value;
                },
            },
        },
        series: data.map((item) => {
            return {
                name: item.brand,
                type: 'line',
                data: item.data,
                itemStyle: {
                    color: (params) => {
                        if (!color[params.data.name] || color[params.data.name] !== params.color) {
                            color[params.data.name] = params.color;
                        }
                        return params.color;
                    },
                    borderWidth: 2,
                    borderColor: '#202020',
                    // shadowBlur: 10,
                },
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    width: 4,
                    shadowColor: 'rgba(0,0,0,1)',
                    shadowBlur: 4,
                },
            };
        }),
        // dataZoom: [
        //     {
        //         start: 0,
        //         end: 100,
        //         height: 4,
        //         backgroundColor: '#000',
        //         opacity: 0.3,
        //         moveHandleSize: 0,
        //         brushSelect: false,
        //         fillerColor: 'rgba(255,255,255,0.8)',
        //         handleIcon:
        //             'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAUCAYAAADlep81AAABYWlDQ1BrQ0dDb2xvclNwYWNlRGlzcGxheVAzAAAokWNgYFJJLCjIYWFgYMjNKykKcndSiIiMUmB/yMAOhLwMYgwKicnFBY4BAT5AJQwwGhV8u8bACKIv64LMOiU1tUm1XsDXYqbw1YuvRJsw1aMArpTU4mQg/QeIU5MLikoYGBhTgGzl8pICELsDyBYpAjoKyJ4DYqdD2BtA7CQI+whYTUiQM5B9A8hWSM5IBJrB+API1klCEk9HYkPtBQFul8zigpzESoUAYwKuJQOUpFaUgGjn/ILKosz0jBIFR2AopSp45iXr6SgYGRiaMzCAwhyi+nMgOCwZxc4gxJrvMzDY7v////9uhJjXfgaGjUCdXDsRYhoWDAyC3AwMJ3YWJBYlgoWYgZgpLY2B4dNyBgbeSAYG4QtAPdHFacZGYHlGHicGBtZ7//9/VmNgYJ/MwPB3wv//vxf9//93MVDzHQaGA3kAFSFl7jXH0fsAAAA4ZVhJZk1NACoAAAAIAAGHaQAEAAAAAQAAABoAAAAAAAKgAgAEAAAAAQAAACSgAwAEAAAAAQAAABQAAAAAqOd5QAAAAeRJREFUSA3NVr1qAkEQntvLgR4Hoo2QNp1gZ5fO0sqkyQsELCxEwZBHSCIolr5EYmEjeQAtBKuUVsHUggGFwzPzXbLLetyBB8Fz4NjZb372273ZH4NCZDAYWJvNpup5XpXNJcMwLvf7vRPiejTEOb45xxcHzIQQw3Q6PazVam4wgREE+v3+DRPpcPBV0PaffSa4YGLtRqPxpudVhJiA6PV6T2xs6w4n0DvNZvORCXoYy5QDZjKZZ9ZPTQbDX0+nU3s8Hr+j468QftNut3sFECX5fJ6KxSKlUimaz+e0XC5917h4VH7TNG/x+wQKGDUT5SjxQqFAjuNQLpejcrksYYqLq8CAAg7gcoHddEwBY2W4CMmyLLJtW6WLi6vAgAIO4CL+tnbAHN7lwiP56R4SQ6tLFK776Dq4CAZKOpiwXhI8i8uESajhwQUrdFYiuJhwnJ+FgAtWaHYWbH5J4J4Tw2MJ8QxIfnqMxNDqEoXrProOLgK3LhfTQjeE6dvtlnhbkuu6tFqtlEtcXAUGFHAAF3M0GnmVSuWTZ3MX8Dno8qFF2WzWJzSZTGi9Xvv2uPhBUq3DV8d9vV7/UCdZt9t9YXsSlytodVqt1gMUte3xBIAB4InFf37IMdUKSeBsHmiSENokn7A/rJQSNHKxQ8YAAAAASUVORK5CYII=',
        //         handleSize: '15',
        //         showDetail: false,
        //         bottom: 10,
        //         right: '8%',
        //         left: '16%',
        //     },
        // ],
    });
    props.treemapLineChangeColor(color);
};

const handleColors = (props, params, colors) => {
    const columnFromTab = props.typeTab === 'FRANCHISE' ? params.data.model : props.typeTab === 'CATEGORY' ? params.data.category : params.data.name;
    const attributeGengerTitle = props.gender === 'ATTRIBUTE' && props.ppiInfo ? (params.data.ppiTier ? (props.ppiInfo.key === 'Filter premium range' || props.ppiInfo.type === 'ppi' ? `${columnFromTab}-${props.gender}-${params.data.ppiTierDisplay}` : `${columnFromTab}-${props.gender}`) : `${columnFromTab}-${props.gender}`) : `${columnFromTab}-${props.gender}`
    const genderTitle = props.gender !== 'ATTRIBUTE' && props.ppiInfo ? (params.data.ppiTier ? (props.from === 'attribute' ? ((props.ppiInfo.key === 'Filter premium range' || props.ppiInfo.type === 'ppi') ? `${columnFromTab}-${props.gender}-${params.data.ppiTierDisplay}` : `${columnFromTab}-${props.gender}`) : `${columnFromTab}-${params.data.gender}-${params.data.ppiTierDisplay}`) : `${columnFromTab}-${params.data.gender}`) : `${columnFromTab}-${params.data.gender}`;
    // const genderTitle = props.ppiInfo ? (params.data.ppiTier ? `${columnFromTab}-${props.gender !== 'ATTRIBUTE' ? params.data.gender : props.gender}-${params.data.ppiTierDisplay}` : `${columnFromTab}-${props.gender !== 'ATTRIBUTE' ? params.data.gender : props.gender}`) : `${columnFromTab}-${props.gender !== 'ATTRIBUTE' ? params.data.gender : props.gender}`;
    const title = props.ppiInfo ? (params.data.ppiTier ? `${params.data.name}-${params.data.ppiTierDisplay}` : params.data.name) : params.data.name;
    // const name = props.gender ? genderTitle : title;
    const name = props.gender ? (props.gender !== 'ATTRIBUTE' ? genderTitle : attributeGengerTitle) : title;

    if (!colors[name] || colors[name] !== params.color) {
        colors[name] = params.color;
    }
};

export const lineRender2 = (element, props) => {
    const allTime = [];
    const colors = { ...props.colors };
    const data = props.data.map((item) => ({
        name: item.name,
        type: 'line',
        data: item.data.map((item) => {
            allTime.push(item.date);
            return item;
        }),
        itemStyle: {
            borderWidth: 2,
            borderColor: '#000',
            color: (params) => {
                handleColors(props, params, colors);
                return params.color;
            },
        },
        symbol: 'circle',
        symbolSize: 8,
        lineStyle: {
            width: 2,
        },
        connectNulls: true,
    }));
    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    const xAxisData = Array.from(new Set(allTime)).sort();
    chart.setOption({
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                let content = `<div>${params && params[0] && params[0].axisValueLabel}</div>`;
                params.forEach((p) => {
                    if (p.data.value) {
                        const columnFromTab = props.typeTab === 'FRANCHISE' ? p.data.model : props.typeTab === 'CATEGORY' ? p.data.category: p.data.name;
                        const attributeGengerTitle = props.gender === 'ATTRIBUTE' && props.ppiInfo ? (p.data.ppiTier ? ((props.ppiInfo.key === 'Filter premium range' || props.ppiInfo.type === 'ppi') ? `${columnFromTab}-${props.gender}-${p.data.ppiTierDisplay}` : `${columnFromTab}-${props.gender}`) : `${columnFromTab}-${props.gender}`) : `${columnFromTab}-${p.data.gender}`
                        const genderTitle = props.gender !== 'ATTRIBUTE' && props.ppiInfo ? (p.data.ppiTier ? (props.from === 'attribute' ? ((props.ppiInfo.key === 'Filter premium range' || props.ppiInfo.type === 'ppi') ? `${columnFromTab}-${props.gender}-${p.data.ppiTierDisplay}` : `${columnFromTab}-${props.gender}`) : `${columnFromTab}-${p.data.gender}-${p.data.ppiTierDisplay}`) : `${columnFromTab}-${p.data.gender}`) : `${columnFromTab}-${p.data.gender}`;
                        const title = props.ppiInfo ? (p.data.ppiTier ? `${p.data.name}-${p.data.ppiTierDisplay}` : p.data.name) : p.data.name;
                        const name = props.gender ? (props.gender !== 'ATTRIBUTE' ? genderTitle : attributeGengerTitle) : title;

                        let v;
                        const value = p.data.value;
                        if (typeof value === 'number') {
                            if (Math.abs(value) >= 1000 && Math.abs(value) < 1000000) {
                                const formattedValue = Math.round(value);
                                v = `${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}`;
                            } else if (Math.abs(value) >= 1000000) {
                                const formattedValue = Math.round(value / 1000000);
                                v = `${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} M`;
                            } else {
                                const formattedValue = value.toFixed(2);
                                v = `${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}`;
                            }
                        }

                        content += `<div class="flex align-center"><div class="dot" style="background: ${p.color.type === 'linear' ? p.color.colorStops[0].color : p.color}"></div>${name}：${v}</div>`;
                    }
                });
                return content;
            },
        },
        grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: 10,
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: '#979797',
                },
            },
            axisLabel: {
                color: 'rgba(255,255,255,1)',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
            },
        },
        yAxis: {
            type: 'value',
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#979797',
                    opacity: 0.3,
                },
            },
            scale: true,
            axisLabel: {
                color: 'rgba(255,255,255,0.7)',
                fontWeight: 'bold',
                fontFamily: 'Helvetica-Bold, Helvetica',
                formatter: (value) => {
                    if (typeof value === 'number') {
                        if (Math.abs(value) >= 1000 && Math.abs(value) < 1000000) {
                            const formattedValue = Math.round(value);
                            return `${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}`;
                        } else if (Math.abs(value) >= 1000000) {
                            const formattedValue = Math.round(value / 1000000);
                            return `￥${formattedValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')} M`;
                        }
                    }
                    return value;
                },
            },
        },
        series: data,

        // dataZoom: [
        //     {
        //         type: 'slider',
        //         start: 0,
        //         end: 100,
        //         height: 4,
        //         backgroundColor: '#000',
        //         opacity: 0.3,
        //         moveHandleSize: 0,
        //         brushSelect: false,
        //         fillerColor: 'rgba(255,255,255,0.8)',
        //         handleIcon:
        //             'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAUCAYAAADlep81AAABYWlDQ1BrQ0dDb2xvclNwYWNlRGlzcGxheVAzAAAokWNgYFJJLCjIYWFgYMjNKykKcndSiIiMUmB/yMAOhLwMYgwKicnFBY4BAT5AJQwwGhV8u8bACKIv64LMOiU1tUm1XsDXYqbw1YuvRJsw1aMArpTU4mQg/QeIU5MLikoYGBhTgGzl8pICELsDyBYpAjoKyJ4DYqdD2BtA7CQI+whYTUiQM5B9A8hWSM5IBJrB+API1klCEk9HYkPtBQFul8zigpzESoUAYwKuJQOUpFaUgGjn/ILKosz0jBIFR2AopSp45iXr6SgYGRiaMzCAwhyi+nMgOCwZxc4gxJrvMzDY7v////9uhJjXfgaGjUCdXDsRYhoWDAyC3AwMJ3YWJBYlgoWYgZgpLY2B4dNyBgbeSAYG4QtAPdHFacZGYHlGHicGBtZ7//9/VmNgYJ/MwPB3wv//vxf9//93MVDzHQaGA3kAFSFl7jXH0fsAAAA4ZVhJZk1NACoAAAAIAAGHaQAEAAAAAQAAABoAAAAAAAKgAgAEAAAAAQAAACSgAwAEAAAAAQAAABQAAAAAqOd5QAAAAeRJREFUSA3NVr1qAkEQntvLgR4Hoo2QNp1gZ5fO0sqkyQsELCxEwZBHSCIolr5EYmEjeQAtBKuUVsHUggGFwzPzXbLLetyBB8Fz4NjZb372273ZH4NCZDAYWJvNpup5XpXNJcMwLvf7vRPiejTEOb45xxcHzIQQw3Q6PazVam4wgREE+v3+DRPpcPBV0PaffSa4YGLtRqPxpudVhJiA6PV6T2xs6w4n0DvNZvORCXoYy5QDZjKZZ9ZPTQbDX0+nU3s8Hr+j468QftNut3sFECX5fJ6KxSKlUimaz+e0XC5917h4VH7TNG/x+wQKGDUT5SjxQqFAjuNQLpejcrksYYqLq8CAAg7gcoHddEwBY2W4CMmyLLJtW6WLi6vAgAIO4CL+tnbAHN7lwiP56R4SQ6tLFK776Dq4CAZKOpiwXhI8i8uESajhwQUrdFYiuJhwnJ+FgAtWaHYWbH5J4J4Tw2MJ8QxIfnqMxNDqEoXrProOLgK3LhfTQjeE6dvtlnhbkuu6tFqtlEtcXAUGFHAAF3M0GnmVSuWTZ3MX8Dno8qFF2WzWJzSZTGi9Xvv2uPhBUq3DV8d9vV7/UCdZt9t9YXsSlytodVqt1gMUte3xBIAB4InFf37IMdUKSeBsHmiSENokn7A/rJQSNHKxQ8YAAAAASUVORK5CYII=',
        //         handleSize: '15',
        //         showDetail: false,
        //         bottom: 10,
        //         right: '10%',
        //         left: '12%',
        //     },
        // ],
    });
    props.changeColor && props.changeColor(colors);
};
