import * as echarts from 'echarts';

export const radarRender = (element, props) => {
    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    const options = {
        radar: {
            indicator: props.data,
            axisName: {
                formatter: (name, indicator) => {
                    if (props.total < indicator.value) {
                        return `{c|${indicator.value}}\n{d|${name}}`
                    }
                    return `{a|${indicator.value}}\n{b|${name}}`
                },
                rich: {
                    a: {
                        fontSize: '14px',
                        // fontFamily: 'fz',
                        fontWeight: 500,
                        color: '#B6B6B6',
                        lineHeight: 16
                    },
                    b: {
                        fontSize: '12px',
                        // fontFamily: 'fz-bold',
                        fontWeight: 500,
                        color: '#B6B6B6',
                        lineHeight: 15
                    },
                    c: {
                        fontSize: '14px',
                        // fontFamily: 'fz',
                        fontWeight: 500,
                        color: '#FF773A',
                        lineHeight: 16
                    },
                    d: {
                        fontSize: '12px',
                        // fontFamily: 'fz-bold',
                        fontWeight: 500,
                        color: '#FF773A',
                        lineHeight: 15
                    }
                }
            },
            radius: '60%',
            center: ['52%', '52%'],
            splitNumber: 1,
            axisLine: {
                lineStyle: {
                    color: 'rgba(173, 124, 103, 0.50)'
                }
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(173, 124, 103, 0.50)'
                }
            },
            splitArea: {
                areaStyle: {
                    color: 'transparent'
                }
            },
            nameGap: 8
        },
        series: [
            {
                name: 'talentData',
                type: 'radar',
                data: [
                    {
                        value: props.data.map(item => 10),
                        name: 'max',
                        symbol: 'none',
                        lineStyle: {
                            opacity: 0,
                        },
                    },
                    {
                        value: props.data.map(item => item.value),
                        name: 'act',
                        lineStyle: {
                            color: 'rgba(255, 119, 58, 1)'
                        },
                        areaStyle: {
                            color: 'rgba(255, 119, 58, 0.41)'
                        },
                        // symbol: 'none',
                        symbolSize: 6,
                        itemStyle: {
                            color: 'rgba(255, 119, 58, 1)'
                        }
                    }
                ]
            }
        ]
    }
    chart.setOption(options);
};
