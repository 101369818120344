import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import { clearAllQuery } from '../api/request';

export default class RouterGuard extends React.Component {
    componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            clearAllQuery();
        }
    }

    render() {
        const { location, routers } = this.props;
        const pathname = location.pathname;

        if (routers.length) {
            let parentRouter = {
                path: '',
                exact: false,
                name: '',
                component: null,
            };
            let firstRouter = {
                path: '',
                exact: false,
                name: '',
                component: null,
            };
            let secondRouter = {
                path: '',
                exact: false,
                name: '',
                component: null,
            };

            const mainRouter = _.findWhere(routers, { path: '/' }) || {
                path: '',
                exact: false,
                name: '',
                component: null,
            };

            if (mainRouter.path === '') {
                return <div></div>;
            }

            const pathArr = pathname.split('/').filter((f) => f);

            pathArr.forEach((path, index) => {
                if (index === 0) {
                    mainRouter.children?.forEach((r) => {
                        if (r.path === `/${path}`) {
                            firstRouter = r;
                            parentRouter = mainRouter;
                        }
                    });
                } else if (index === 1) {
                    firstRouter.children?.forEach((c) => {
                        if (c.path === `${firstRouter.path}/${path}`) {
                            secondRouter = c;
                        }
                    });
                }
            });

            if (!parentRouter.path) {
                pathArr.forEach((path, index) => {
                    if (index === 0) {
                        routers.forEach((r) => {
                            if (r.path === `/${path}`) {
                                parentRouter = r;
                            }
                        });
                    } else if (index === 1) {
                        parentRouter.children?.forEach((c) => {
                            if (c.path === `${parentRouter.path}/${path}`) {
                                firstRouter = c;
                            }
                        });
                    } else if (index === 2) {
                        firstRouter.children?.forEach((s) => {
                            if (s.path === `${parentRouter.path}${firstRouter.path}/${path}`) {
                                secondRouter = s;
                            }
                        });
                    }
                });
            }

            if (pathArr.length === 0) {
                parentRouter = mainRouter;
            }
            if (pathname === '/') {
                parentRouter = _.findWhere(routers, { path: '/' }) || {
                    path: '',
                };
                firstRouter = _.findWhere(mainRouter.children, { path: '/overview' }) || {
                    path: '',
                };
                return (
                    <Redirect
                        to={{
                            pathname: '/overview',
                        }}
                    />
                );
            }

            if (secondRouter.path) {
                document.title = `DEWU-MARKET - ${secondRouter.name}`;
            } else if (firstRouter.path) {
                document.title = `DEWU-MARKET - ${firstRouter.name}`;
            } else {
                document.title = `DEWU-MARKET - ${parentRouter.name}`;
            }

            // clearAllQuery();
            if (parentRouter.path) {
                return (
                    <Route
                        path={parentRouter.path}
                        exact={parentRouter.exact}
                        render={(props) => (
                            <div className="flex full-height">
                                <parentRouter.component {...props} />
                                {firstRouter.path && (
                                    <Switch>
                                        <Route
                                            path={firstRouter.path}
                                            exact={firstRouter.exact}
                                            render={(childProps) => (
                                                <div className="first-router flex-1 flex flex-column hidden">
                                                    <firstRouter.component {...childProps} />
                                                    {secondRouter.path && (
                                                        <Switch>
                                                            <Route path={secondRouter.path} exact={secondRouter.exact} component={secondRouter.component} />
                                                            <Redirect to="/404" />;
                                                        </Switch>
                                                    )}
                                                </div>
                                            )}
                                        />
                                        <Redirect to="/404" />;
                                    </Switch>
                                )}
                            </div>
                        )}
                    />
                );
            }
            return <Redirect to="/404" />;
        }
        return <div></div>;
    }
}
