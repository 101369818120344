import * as React from 'react';
import cx from 'classnames';

const Icon = ({ name = 'demo', className, style, fill }) => {
    return (
        <div className={cx('flex align-center justify-center full-height full-width', className)}>
            <svg className="full-width full-height" aria-hidden={true} style={fill ? { fill } : style}>
                <use xlinkHref={`#icon-${name}`}></use>
            </svg>
        </div>
    );
};

export default Icon;
