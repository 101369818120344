import * as React from 'react';
import { Menu, TextField, Checkbox, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import cx from 'classnames';
import { add, remove } from '../../utils/index';
import './dropBox.css';

import { withStyles, createStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />);

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

const StyledCheckBox = withStyles((theme) =>
    createStyles({
        root: {
            color: '#fff',
            opacity: 0.5,
            background: '#000',
            padding: '0px',
            fontSize: '12px',
            borderRadius: '100%',
            overflow: 'hidden',
            width: '16px',
            height: '16px',
            '&.MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#fff',
            },
            '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
                opacity: 1,
            },
        },
    })
)(Checkbox);

const DropBox = ({ onConfirm = () => {}, title = 'Brands', data, currentList, currentTab, tabList }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('');
    const [checkObj, setCheckObj] = React.useState({});
    const [checkedList, setCheckedList] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [tab, setTab] = React.useState('');
    const [total, setTotal] = React.useState(6);

    React.useEffect(() => {
        if (tabList) {
            const firstTab = tabList[0]['alias'];
            setTab(currentTab || firstTab);
            setList((currentTab && data[currentTab]) || data[firstTab] || []);
            if ((currentTab || firstTab) === 'ALL') {
                setTotal(1);
            } else {
                setTotal(6);
            }
        } else {
            setList(data['default'] || []);
        }
        if (currentList) {
            setCheckedList(currentList);
            const obj = {};
            currentList.forEach((l) => {
                obj[l] = true;
            });
            setCheckObj(obj);
        }
    }, [data, tabList, currentList, currentTab]);

    const handleCheckBox = (name) => {
        const checked = checkObj[name];
        if (!checked) {
            if (checkedList.length < total) {
                setCheckObj({
                    ...checkObj,
                    [name]: !checked,
                });
                const list = add(checkedList, name);
                setCheckedList(list);
            }
        } else {
            setCheckObj({
                ...checkObj,
                [name]: !checked,
            });
            const list = remove(checkedList, name);
            setCheckedList(list);
        }
    };

    const handleRemoveSelected = (name) => {
        const list = remove(checkedList, name);
        const checked = { ...checkObj, [name]: false };
        setCheckedList(list);
        setCheckObj(checked);
    };

    const handleCancel = () => {
        setAnchorEle(null);
        setCheckedList(currentList || []);
        const obj = {};
        currentList.forEach((l) => {
            obj[l] = true;
        });
        setCheckObj(obj);
        setTab(currentTab);
        if (currentTab === 'ALL') {
            setTotal(1);
        } else {
            setTotal(6);
        }
    };

    const handleConfirm = () => {
        onConfirm(checkedList, tab);
        setAnchorEle(null);
        setSearchValue('');
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        const list =
            data[currentTab || 'default'].filter((f) => {
                if (f.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    return f;
                }
                return false;
            }) || [];
        setList(list);
    };

    const handleTabChange = (tabItem) => {
        setTab(tabItem);
        setList(data[tabItem]);
        setCheckedList([]);
        setCheckObj({});
        if (tabItem === 'ALL') {
            setTotal(1);
        } else {
            setTotal(6);
        }
    };

    return (
        <div className="drop-box">
            <div className="hover flex align-center" onClick={(e) => setAnchorEle(e.currentTarget)}>
                <div className="select-icon"></div>
                <div>{title}</div>
            </div>
            <StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={() => setAnchorEle(null)}>
                <div className="py10 px20 drop-content flex flex-column">
                    {(tabList && (
                        <div className="flex align-center">
                            {tabList.map((tabItem, index) => (
                                <div
                                    key={index}
                                    className={cx('flex-1 tab-item border-box flex align-center justify-center hover', {
                                        'tab-item-active': tabItem.alias === tab,
                                    })}
                                    onClick={() => handleTabChange(tabItem.alias)}
                                >
                                    {tabItem.name}
                                </div>
                            ))}
                        </div>
                    )) ||
                        null}
                    <div className="pt10">
                        <StyledTextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth={true}
                            value={searchValue}
                            onChange={(e) => handleSearch(e.target.value)}
                            id="search"
                            type="search"
                            variant="outlined"
                            autoComplete="off"
                        />
                    </div>
                    <div className="flex-1 py10 flex hidden" style={{ maxHeight: '300px' }}>
                        <div className="flex-1 flex flex-column overflow-y drop-item-box">
                            {list.map((item, index) => (
                                <div
                                    className={cx('flex align-center justify-between drop-items hidden', {
                                        'drop-active': checkObj[item.name],
                                    })}
                                    key={index}
                                >
                                    <div className="flex align-center py10 no-wrap flex-1">{item.name}</div>
                                    <div
                                        className={cx('icon-check-box hover', {
                                            'icon-check': checkObj[item.name],
                                            'icon-uncheck': !checkObj[item.name],
                                        })}
                                        onClick={() => handleCheckBox(item.name)}
                                    ></div>
                                </div>
                            ))}
                        </div>
						<div className="flex-1 flex flex-column overflow-y drop-sub-menu drop-item-box">
							<div className="flex align-center justify-between mb20">
								<div>{title}</div>
								<div className="flex align-center select-text">
									<div>Selected</div>
									<div className="select-value">
										{checkedList.length}/{total}
									</div>
								</div>
							</div>
							{checkedList.map((item, index) => (
								<div className="flex align-center pb15" key={index}>
									<StyledCheckBox checked={true} indeterminate={true} onChange={() => handleRemoveSelected(item)} />
									<div className="pl4 drop-sub-item no-wrap">{item}</div>
								</div>
							))}
						</div>
                    </div>
                    <div className="flex align-center justify-between footer-box">
                        <div className="flex align-center select-text">
                            <div>Selected</div>
                            <div className="select-value">
                                {checkedList.length}/{total}
                            </div>
                        </div>
                        <div className="flex align-center">
                            <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                                Cancel
                            </div>
                            <div className="ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                                Confirm
                            </div>
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default DropBox;
