import { $post } from './index';

export const brandAnalysisRequest = (data) => {
    return $post('/data/brand/analysis/index', data, {
        queryName: 'brandAnalysisRequest',
    });
};

export const brandAnalysisPPIRequest = (data) => {
    return $post('/data/brand/analysis/demand/ppi', data, {
        queryName: 'brandAnalysisPPIRequest',
    });
};

export const genderAnalysisBrandDemandRequest = (data) => {
    return $post('/data/gender/analysis/demand/brand', data, {
        queryName: 'genderAnalysisBrandDemandRequest',
    });
};

export const genderAnalysisBrandIndexRequest = (data) => {
    return $post('/data/gender/analysis/index/brand', data, {
        queryName: 'genderAnalysisBrandIndexRequest',
    });
};

export const genderAnalysisFranchiseDemandRequest = (data) => {
    return $post('/data/gender/analysis/demand/franchise', data, {
        queryName: 'genderAnalysisFranchiseDemandRequest',
    });
};

export const genderAnalysisFranchiseIndexRequest = (data) => {
    return $post('/data/gender/analysis/index/franchise', data, {
        queryName: 'genderAnalysisFranchiseIndexRequest',
    });
};

export const genderAnalysisCategoryDemandRequest = (data) => {
    return $post('/data/gender/analysis/demand/sub_category', data, {
        queryName: 'genderAnalysisCategoryDemandRequest',
    });
};

export const genderAnalysisCategoryIndexRequest = (data) => {
    return $post('/data/gender/analysis/index/sub_category', data, {
        queryName: 'genderAnalysisCategoryIndexRequest',
    });
};
