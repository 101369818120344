import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from '../views/login/Login';
import ResetPassword from '../views/login/ResetPassword';
import RouterGuard from './RouterGuard';
// import routers from './routers';

export default class PrivateRouter extends React.Component {
    // getChildrenRouters = (children, key) => {
    //     if (children && children.length) {
    //         return (
    //             <Switch>
    //                 {children.map((child, index) => {
    //                     if (child.children) {
    //                         return (
    //                             <Route
    //                                 key={`${key}-${index}`}
    //                                 path={child.path}
    //                                 exact={child.exact}
    //                                 render={(props) => (
    //                                     <div>
    //                                         <child.component {...props} />
    //                                         {child.children && this.getChildrenRouters(child.children, `${key}-${index}`)}
    //                                     </div>
    //                                 )}
    //                             />
    //                         );
    //                     } else {
    //                         return <Route key={`${key}-${index}`} path={child.path} exact={child.exact} component={child.component} />;
    //                     }
    //                 })}
    //             </Switch>
    //         );
    //     }
    // };
    render() {
        return (
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/reset" component={ResetPassword} />
                <RouterGuard routers={this.props.routers} />
                {/* {this.props.routers.map((router, index) => (
                    <Route
                        key={index}
                        path={router.path}
                        exact={router.exact}
                        render={(props) => (
                            <div>
                                <router.component {...props} />
                                {router.children && this.getChildrenRouters(router.children, `${index}`)}
                            </div>
                        )}
                    />
                ))} */}
            </Switch>
        );
    }
}
