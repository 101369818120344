import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Visualization } from './visualization/index';
import DropBox from './components/DropBox';
import StyledCheckBox from './components/StyledCheckBox';
import DatePicker from './components/DatePicker';
import PPIComponent from './components/PPIComponent';
import CommonDialog from './components/CommonDownloadDialog';
import CommonTootip from './components/StyledTooltip';
import Slider from './components/Slider';

import { downloadPDF, transformDate, formatM, formatPercent, handleDateStartEnd, groupedByNumber, handlePPI, handleLegend, downloadXlsx, getColsWpx } from '../utils/index';
import {
	demandColumns,
	ppiColumns,
	rviColumns,
	diColumns,
	handleDemandData,
	handlePPILineData,
	handleRVIData,
	handleDIData,
} from '../utils/brandAnalysisDownload';
import '../assets/css/brandAndGender.css';

const timeTabList = [
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: '12M',
        alias: 'M12',
    },
];

@inject('brandAndGenderStore', 'commonStore', 'userStore')
@observer
export default class BrandAnalysis extends React.Component {
    state = {
        demandTimeTab: 'MONTH',
        selectedList: [],
        selectGroupList: [],
        type: 'MONTH',
        startDate: null,
        endDate: null,
        originalDate: {},
        colors: {
            resaleVolumeIndex: {},
            diversity: {},
            ppi: {},
            demand: {},
        },
        ppiData: {},
        ppiInfo: {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: [],
            data: [],
            ppi: ['TOTAL']
        },
        data: {
            default: [],
        },
    };
    async componentWillMount() {
        const { timeRangeMonth } = await this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 12);
        this.setState({
            startDate,
            endDate,
            originalDate,
        });
        const { demandTimeTab, type } = this.state;
        await this.props.commonStore.brandDictRequest();
        const { brandDefault, brandDict } = this.props.commonStore;
        const data = {
            default: brandDict.map((item) => ({
                ...item,
                name: item.display,
            })),
        };
        const groupByList = groupedByNumber(2, brandDefault, '');
        // const ppiData = handlePPIData(brandDefault);
        const ppiData = handlePPI(brandDefault)
        const ppiInfo = {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: brandDefault,
            data: brandDefault,
            ppi: ['TOTAL']
        };
        this.setState({
            selectedList: brandDefault,
            selectGroupList: groupByList,
            ppiInfo,
            ppiData,
            data,
        });

        await this.getRequest(startDate, endDate, type, demandTimeTab, ppiInfo, brandDefault);
    }

    getRequest = async (startDate, endDate, type, demandTimeTab, ppiInfo, selected) => {
        await this.props.brandAndGenderStore.brandAnalysisPPIRequest({
            dateType: type,
            startDate,
            endDate,
            dataType: demandTimeTab,
            brandList: ppiInfo.data,
            ppiList: ppiInfo.ppi,
            ppiInfo,
        });
        await this.props.brandAndGenderStore.brandAnalysisRequest({
            dateType: type,
            startDate,
            endDate,
            dataType: demandTimeTab,
            brandList: selected,
        });
    };

    handleRemoveSelected = async (item) => {
        const { selectedList } = this.state;
        const newList = selectedList.filter((f) => f !== item);
        const newGroup = groupedByNumber(2, newList, '');

        const ppiData = handlePPI(newList);
        const ppiInfo = {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: newList,
            data: newList,
            ppi: ['TOTAL']
        };
        this.setState({
            selectedList: newList,
            selectGroupList: newGroup,
            ppiData,
            ppiInfo,
        });

        const { startDate, endDate, type, demandTimeTab } = this.state;
        await this.getRequest(startDate, endDate, type, demandTimeTab, ppiInfo, newList);
    };

    handlePPIConfirm = async (selected) => {
        const ppiInfo = {
            type: selected.type,
            key: selected.key,
            value: Array.from(new Set((selected.value.map((item) => item.value) || []))),
            name: selected.originKey && selected.originKey.name,
            data: selected.data,
            ppi: selected.ppi
        };
        this.setState({
            ppiInfo,
        });
        const { type, startDate, endDate, demandTimeTab } = this.state;
        await this.props.brandAndGenderStore.brandAnalysisPPIRequest({
            dateType: type,
            startDate,
            endDate,
            dataType: demandTimeTab,
            brandList: ppiInfo.data,
            ppiList: ppiInfo.ppi,
            ppiInfo,
        });
    };

    handleConfirm = async (checkedList) => {
        const groupByList = groupedByNumber(2, checkedList, '');
        const ppiData = handlePPI(checkedList);
        const ppiInfo = {
            type: 'brand',
            key: 'Demand',
            name: 'Demand',
            value: checkedList,
            data: checkedList,
            ppi: ['TOTAL']
        };

        this.setState({
            selectedList: checkedList,
            selectGroupList: groupByList,
            ppiData,
            ppiInfo,
        });
        const { startDate, endDate, type, demandTimeTab } = this.state;
        await this.getRequest(startDate, endDate, type, demandTimeTab, ppiInfo, checkedList);
    };

    handleDemandTimeTab = async (tab) => {
        this.setState({
            demandTimeTab: tab,
        });
        const { startDate, endDate, type, ppiInfo, selectedList } = this.state;
        await this.getRequest(startDate, endDate, type, tab, ppiInfo, selectedList);
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'pdf') {
            this.ele &&
                downloadPDF(
                    this.ele,
                    () => {
                        console.log('download success');
						this.props.commonStore.setDownloadDialog(false);
						this.props.commonStore.setMenuName('');
                    },
                    () => {
                        console.log('download fail');
					},
					name
                );
        } else if (type === 'xlsx') {
			const { brandAnalysisResult } = this.props.brandAndGenderStore;
			const { demandTimeTab, type } = this.state;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(6)
			}
			let rowsNum = 0
			let sheetData = []

			const demandColumn = demandColumns(type);
			const { data, colsNumber, rowsNumber } = handleDemandData(demandColumn, type, demandTimeTab, brandAnalysisResult);
			sheetData = [...sheetData, ...data];
			options['!merges'].push({
				s: { r: 0, c: 0 },
				e: { r: 0, c: colsNumber - 1 }
			})
			rowsNum = rowsNumber + rowsNum;

			const ppiColumn = ppiColumns(type);
			const { data: ppiLineData, colsNumber: ppiColsNumber, rowsNumber: ppiRowsNumber } = handlePPILineData(ppiColumn, type, demandTimeTab, brandAnalysisResult);
			sheetData = [...sheetData, ...ppiLineData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: ppiColsNumber - 1 }
			})
			rowsNum = ppiRowsNumber + rowsNum;

			const rviColumn = rviColumns(type);
			const { data: rviData, colsNumber: rviColsNumber, rowsNumber: rviRowsNumber } = handleRVIData(rviColumn, type, demandTimeTab, brandAnalysisResult);
			sheetData = [...sheetData, ...rviData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: rviColsNumber - 1 }
			})
			rowsNum = rviRowsNumber + rowsNum;

			const diColumn = diColumns(type);
			const { data: diData, colsNumber: diColsNumber, rowsNumber: diRowsNumber } = handleDIData(diColumn, type, demandTimeTab, brandAnalysisResult);
			sheetData = [...sheetData, ...diData];
			options['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: diColsNumber - 1 }
			})
			rowsNum = diRowsNumber + rowsNum;

			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    handleChangeColor = (color, type) => {
        const c = this.state.colors;
        c[type] = color;

        this.setState({
            colors: c,
        });
    };

    handleDateConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        const { demandTimeTab, ppiInfo, selectedList } = this.state;
        if (start && end) {
            this.setState({
                startDate: start,
                endDate: end,
                type,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            await this.getRequest(start, end, type, demandTimeTab, ppiInfo, selectedList);
        }
	};
	
	handleSliderChange = (sliderArr, type) => {
		if (type === 'demand') {
			this.props.brandAndGenderStore.changeDemandDateRange(sliderArr)
		} else if (type === 'ppi') {
			this.props.brandAndGenderStore.changeIndexDateRange1(sliderArr)
		} else if (type === 'resaleVolumeIndex') {
			this.props.brandAndGenderStore.changeIndexDateRange2(sliderArr)
		} else if (type === 'diversity') {
			this.props.brandAndGenderStore.changeIndexDateRange3(sliderArr)
		}
	}

	getPPIDesc = (dateRange, brandAnalysisPPIData, ppiInfo) => {
		const descMap = {
            brand: 'brandClean',
            ppi: 'ppiTier',
        };
		const lastDate = dateRange[dateRange.length - 1]
		return handleLegend(brandAnalysisPPIData, descMap[ppiInfo.type], lastDate, ppiInfo);
	}

	getDesc = (dateRange, brandAnalysisData) => {
		const lastDate = dateRange[dateRange.length - 1]
		return handleLegend(brandAnalysisData, 'brandClean', lastDate);
	}

    render() {
        const { brandAnalysisData, brandAnalysisPPIData, brandAnalysisResult } = this.props.brandAndGenderStore;
        const { demandTimeTab, selectedList, selectGroupList, colors, ppiData, data, ppiInfo, startDate, endDate, type, originalDate } = this.state;
		const { isDialogOpen, timeRangeMonth, timeRangeWeek } = this.props.commonStore;
		
        return (
            <div className="flex-1 flex flex-column hidden p20 brand-analysis-bg" ref={(_f) => (this.ele = _f)}>
                <div className="flex align-center title-box">
					<div className="brand-title"></div>
				</div>
                <div className="flex mb14">
                    <div className="flex-1 mr18 hidden">
                        <div className="select-item-box p8 flex border-box flex full-width">
                            <div className="brand-select-box border-box flex align-center justify-center">
                                <DropBox title="Select Brand" data={data} onConfirm={this.handleConfirm} currentList={selectedList}></DropBox>
                            </div>
                            <div className="flex-1 flex hidden">
                                {selectGroupList.map((group, index) => (
                                    <div key={index} className="flex flex-column ml30 flex-1 hidden">
                                        {group.map((item, i) => (
                                            <CommonTootip key={`${index}-${i}`} placement="top" title={<div>{item}</div>}>
                                                <div className="flex-1 flex align-center select-brand-item" key={`${index}-${i}`}>
                                                    {(item && (
                                                        <div className="mr4">
                                                            <StyledCheckBox checked={true} indeterminate={true} onChange={() => this.handleRemoveSelected(item)} />
                                                        </div>
                                                    )) ||
                                                        null}
                                                    <div className="text-overflow hover">{item}</div>
                                                </div>
                                            </CommonTootip>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex align-center border-box hidden">
                        <div
                            className={cx('flex-1 select-item-box flex align-center px20 border-box', {
                                mr18: type === 'MONTH',
                            })}
                        >
                            <div className="icon-calendar brand-calendar-item mr40"></div>
                            <DatePicker isCenter={true} onConfirm={this.handleDateConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                        </div>
                        {(type === 'MONTH' && (
                            <div className="select-item-box flex align-center p8 border-box">
                                {timeTabList.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={cx('brand-analysis-time border-box hover flex align-center justify-center', {
                                            'brand-analysis-time-active': demandTimeTab === tab.alias,
                                        })}
                                        onClick={() => this.handleDemandTimeTab(tab.alias)}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </div>
                        )) ||
                            null}
                    </div>
                </div>
                {brandAnalysisResult.map((brand, index) => (
                    <div
                        key={index}
                        className={cx('flex-1 flex', {
                            mb20: index !== brandAnalysisResult.length - 1,
                        })}
                    >
                        {brand.map((item, i) => {
                            const LineComponent = Visualization.get('line_chart');
                            return (
                                <div
                                    key={`${index}-${i}`}
                                    className={cx('flex-1 card-box flex flex-column', {
                                        mr18: i !== brand.length - 1,
                                    })}
                                >
                                    <div className="flex align-center brand-chart-title-box">
                                        <div>{item.title}</div>
                                        {(index === 0 && i === 0 && (
                                            <div className="brand-chart-ppi-box flex align-center justify-center ml10">
                                                <PPIComponent data={ppiData} onConfirm={this.handlePPIConfirm}></PPIComponent>
                                            </div>
                                        )) ||
                                            null}
                                    </div>
                                    <div className="visualization flex-1 p10 flex flex-column">
										<div className="flex-1">
											{LineComponent && <LineComponent type={2} ppiInfo={(index === 0 && i === 0 && ppiInfo) || null} data={item.data} changeColor={(color) => this.handleChangeColor(color, item.value)} colors={colors[item.value]} />}
										</div>
										<Slider range={item.dateRange} onChange={(value, sliderArr) => this.handleSliderChange(sliderArr, item.value)} />
									</div>
                                    <div className="info-box flex flex-column justify-between">
                                        {((index === 0 && i === 0 && this.getPPIDesc(item.currentDateRange, brandAnalysisPPIData, ppiInfo)) || this.getDesc(item.currentDateRange, brandAnalysisData)).map((group, m) => (
                                            <div key={m} className="flex-1 flex align-center">
                                                {group.map((desc, k) => {
                                                    return desc.key ? (
                                                        <div
                                                            style={{ width: '33%' }}
                                                            className={cx('flex full-height px5', {
                                                                'info-item-border': k !== group.length - 1,
                                                            })}
                                                            key={`${index}-${m}-${k}`}
                                                        >
                                                            <CommonTootip
                                                                placement="top"
                                                                title={
                                                                    <div className="flex align-center">
                                                                        <div
                                                                            className="dot"
                                                                            style={{
                                                                                background: colors[item.value][desc.key],
                                                                            }}
                                                                        />
                                                                        <div className="ml4 info-item-name-tooltip">{desc.key}</div>
                                                                    </div>
                                                                }
                                                            >
                                                                <div className={cx('border-box flex align-center hover flex-1 hidden')}>
                                                                    <div
                                                                        className="dot"
                                                                        style={{
                                                                            background: colors[item.value][desc.key],
                                                                        }}
                                                                    />
                                                                    <div className="ml4 info-item-name flex-1">{desc.key}</div>
                                                                </div>
                                                            </CommonTootip>
                                                            <div className="flex align-center">
                                                                <div className="mr6 info-item-value">{formatM(desc.list[0][item.value])}</div>
                                                                {(desc.list[0][item.yoy] && (
                                                                    <div className="flex align-center mt4">
                                                                        {desc.list[0][item.yoy].indexOf('-') !== -1 ? (
                                                                            <ArrowDownward
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: '#FF5F60',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <ArrowUpward
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: '#4D9B4D',
                                                                                }}
                                                                            />
                                                                        )}
                                                                        <div
                                                                            className={cx('brand-percent', {
                                                                                'up-percent': desc.list[0][item.yoy].indexOf('-') === -1,
                                                                                'down-percent': desc.list[0][item.yoy].indexOf('-') !== -1,
                                                                            })}
                                                                        >
                                                                            {formatPercent(desc.list[0][item.yoy].replace('-', '')) || 0}
                                                                        </div>
                                                                    </div>
                                                                )) ||
                                                                    null}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex-1 flex full-height justify-between px5" key={`${index}-${m}-${k}`}></div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}

                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
            </div>
        );
    }
}
