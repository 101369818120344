import * as React from 'react';
import { Menu, TextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { withStyles, createStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import _ from 'underscore';
import './select.css'

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => {
    return <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />;
});

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            background: 'rgba(255, 255, 255, 0.16)',
            borderRadius: '14px',
            '& .MuiOutlinedInput-input': {
                padding: '3.5px 10px',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: '#fff',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    })
)(TextField);

const CustomSelect = ({ placeholder, data, onConfirm = () => {}, currentList, disabled, error }) => {
	const [anchorEle, setAnchorEle] = React.useState(null);
	const [select, setSelect] = React.useState([])
	const [checked, setChecked] = React.useState([])
	const [list, setList] = React.useState([])
	const [value, setValue] = React.useState('')

	React.useEffect(() => {
		setList(data || [])
		setSelect([])
		setChecked([])
	},[data])

	React.useEffect(() => {
		if (currentList && currentList.constructor === Array) {
			setChecked(currentList)
			setSelect(currentList)
		}
	},[currentList])

	const handleSelect = (item) => {
		if (select.indexOf(item.value) !== -1) {
			const newSelect = select.filter(f => f !== item.value)
			setSelect(newSelect)
		} else {
			const newSelect = [...select, item.value]
			setSelect(newSelect)
		}
	}

	const handleConfirm = () => {
		setChecked(select)
		onConfirm(select)
		setValue('')
		setList(data || [])
		setAnchorEle(null)
	}

	const handleSearch = (searchValue) => {
		setValue(searchValue)
		if (searchValue) {
			const newList = data.filter(f => f.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
			setList(newList)
		} else {
			setList(data || [])
		}
	}

	const handleCancel = () => {
		setAnchorEle(null)
		setValue('')
		setList(data || [])
	}

	const isSelectAll = (list) => {
		let flag = true
		list.forEach(item => {
			if (select.indexOf(item.value) === -1) {
				flag = false
			}
		})

		return flag;
	}

	const handleSelectAll = (select, list) => {
		if (list.length && select.length === list.length) {
			const arr = select.filter(f => !_.findWhere(list, { value: f }) && f)
			setSelect(arr)
		} else {
			const arr = select.filter(f => !_.findWhere(list, { value: f }) && f)
			const listValues = list.map(f => f.value)
			const newSelect = [ ...arr, ...listValues ]
			setSelect(newSelect)
		}
	}

	return (
		<>
			<div className={cx("custom-select-box flex align-center full-width", {
				hover: !disabled,
				'pointer-disable': disabled,
				'custom-select-error': error
			})} onClick={(e) => !disabled && setAnchorEle(e.currentTarget)}>
				<div className="flex-1 px10 hidden">
					{
						checked.length ? (
							<div className="custom-select-value text-overflow">
								{
									checked.join(',')
								}
							</div>
						) : (
							<div className="custom-select-placeholder">
								{placeholder || ''}
							</div>
						)
					}
				</div>
				<div className="custom-select-icon"></div>
			</div>
			<StyledMenu keepMounted anchorEl={anchorEle} open={Boolean(anchorEle)} onClose={() => setAnchorEle(null)}>
				{
					list.length || value ? (
						<div className="custom-select-content p10">
							<StyledTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth={true}
                                value={value}
                                onChange={(e) => handleSearch(e.target.value)}
                                type="search"
                                variant="outlined"
                                autoComplete="off"
                            />
							<div className="py10 hidden mb10 custom-select-list">
								<div className="custom-select-list-box">
									{
										list.map((item, index) => (
											<div key={index} className="pb10 flex align-center">
												<div className="flex-1">{item.name}</div>
												<div className={cx("icon-check-box hover", {
													'icon-uncheck': select.indexOf(item.value) === -1,
													'icon-check': select.indexOf(item.value) !== -1,
												})}
												onClick={() => handleSelect(item)}
												></div>
											</div>
										))
									}
								</div>
							</div>
							<div className="flex align-center justify-between">
								<div className="flex align-center mr10">
									<div className="mr10">Select all</div>
									<div className={cx("icon-check-box hover", {
											'icon-uncheck': !isSelectAll(list),
											'icon-check': isSelectAll(list),
										})}
										onClick={() => handleSelectAll(select, list)}
									></div>
								</div>
								<div className="flex align-center">
									<div className="custom-select-button select-button-width flex align-center justify-center hover mr10" onClick={() => handleCancel()}>Cancel</div>
									<div className={cx("custom-select-button select-button-width flex align-center justify-center hover")} onClick={() => handleConfirm()}>Comfirm</div>
								</div>
							</div>
						</div>
					): (
						<div className="custom-select-content p10 flex align-center justify-center">No Data</div>
					)
				}
			</StyledMenu>
		</>
	)
}

export default CustomSelect