import { $post } from './index';

export const skuAnalysisOverviewRequest = (data) => {
    return $post('/data/sku/analysis/overview', data, {
        queryName: 'skuAnalysisOverviewRequest',
    });
};

export const skuAnalysisTopRequest = (data) => {
    return $post('/data/sku/analysis/top', data, {
        queryName: 'skuAnalysisTopRequest',
    });
};

export const skuAnalysisLaunchRequest = (data) => {
    return $post('/data/sku/analysis/nike/product/new/launch/sku', data, {
        queryName: 'skuAnalysisLaunchRequest',
    });
};

export const skuAnalysisAttributeIndexRequest = (data) => {
	return $post('/data/sku/analysis/nike/product/attribute/index', data, {
		queryName: 'skuAnalysisAttributeIndexRequest'
	})
}

export const skuAnalysisAttributeDemandRequest = (data) => {
	return $post('/data/sku/analysis/nike/product/attribute/demand/ppi', data, {
		queryName: 'skuAnalysisAttributeDemandRequest'
	})
}