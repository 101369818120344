import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';

import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { RouterStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core';
import rootStore from './store/index';
import { theme } from './theme/index';
import registerVisualization from './views/visualization/index';
import './assets/icons/index';
registerVisualization();

const routerStore = new RouterStore();
const history = createBrowserHistory();
export const store = {
    ...rootStore,
    router: routerStore,
    history,
    rootStore,
};

ReactDOM.render(
    <Provider {...store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
