import BarChart from './Bar';
import LineChart from './Line';
import RowChart from './Row';
import BubbleChart from './Bubble';
import TreemapChart from './Treemap';
import RadarChart from './Radar';
const Visualization = new Map();

const registerVisualization = (visualization) => {
    const type = visualization.indentify;
    if (type) {
        Visualization.set(type, visualization);
    }
};

export default function register() {
    registerVisualization(BarChart);
    registerVisualization(LineChart);
    registerVisualization(RowChart);
    registerVisualization(BubbleChart);
    registerVisualization(TreemapChart);
    registerVisualization(RadarChart);
}

export { Visualization };
