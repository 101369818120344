import * as React from 'react';
import * as Yup from 'yup';
import { observer, MobXProviderContext } from 'mobx-react';
import { useFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import '../../assets/css/overview.css';
import './login.css';
import { Box, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import UserTextField from '../components/TextField';

const ColorButton = withStyles((theme) => ({
    root: {
        fontSize: '14px',
        fontFamily: 'Helvetica-Bold, Helvetica',
        fontWeight: 'bold',
        color: '#fff',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        '&:hover': {
            backgroundColor: '#FF773A',
            color: '#fff',
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#fff',
        },
    },
}))(Button);

const ModifyPassword = ({ history }) => {
    const store = React.useContext(MobXProviderContext);
    const [showOld, setShowOld] = React.useState(false);
    const [showOne, setShowOne] = React.useState(false);
    const [showTwo, setShowTwo] = React.useState(false);
    const [errorText, setErrorText] = React.useState(false);

    const handleConfirm = async (values) => {
        try {
            const response = await store.userStore.updatePasswordRequest({
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
            });
            const { code, message } = response;
            if (code === '00000') {
                setErrorText('');
                history.replace('/login');
            } else {
                setErrorText(message);
                formik.setSubmitting(false);
            }
        } catch (error) {
            setErrorText('');
            formik.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            passwordAgain: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required('Password is required!'),
            newPassword: Yup.string().test('', 'Must have at least 8 characters, at most 16 characters, and include A-Z, a-z, and 0-9', (value) => {
                if (value) {
                    const isValidate = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}/.test(value);
                    if (isValidate) {
                        return true;
                    }
                    return false;
                }
                return false;
            }),
            passwordAgain: Yup.string().test('', 'Both passwords must match', (value, context) => {
                if (value) {
                    const pre = context.parent.newPassword;
                    if (pre && value === pre) {
                        return true;
                    }
                    return false;
                }
                return false;
            }),
        }),
        onSubmit: (values) => {
            handleConfirm(values);
        },
    });

    return (
        <div className="flex-1 flex flex-column hidden p20 overview-bg relative">
            <div className="absolute modify-bg full-height full-width" />
            <div className="card-box flex-1 z-index-2 flex align-center flex-column relative">
                <div className="mt90 modify-logo"></div>
                <div className="absolute modify-title">Change Password</div>

                <div style={{ width: '320px' }}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modify-label">Old Password</div>
                        <UserTextField
                            size="small"
                            error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
                            fullWidth={true}
                            name="oldPassword"
                            type={showOld ? 'text' : 'password'}
                            id="outlined-password"
                            margin="normal"
                            defaultValue={formik.getFieldProps('oldPassword').value}
                            helperText={(formik.touched.oldPassword && formik.errors.oldPassword) || ''}
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <div className="hover" onClick={() => setShowOld(!showOld)}>
                                            {showOld ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="modify-label">New Password</div>
                        <UserTextField
                            size="small"
                            error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                            fullWidth={true}
                            name="newPassword"
                            type={showOne ? 'text' : 'password'}
                            id="outlined-password"
                            margin="normal"
                            defaultValue={formik.getFieldProps('newPassword').value}
                            helperText={(formik.touched.newPassword && formik.errors.newPassword) || ''}
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <div className="hover" onClick={() => setShowOne(!showOne)}>
                                            {showOne ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="modify-label">New Password Again</div>
                        <UserTextField
                            size="small"
                            error={Boolean(formik.touched.passwordAgain && formik.errors.passwordAgain)}
                            fullWidth={true}
                            name="passwordAgain"
                            type={showTwo ? 'text' : 'password'}
                            id="outlined-password"
                            margin="normal"
                            defaultValue={formik.getFieldProps('passwordAgain').value}
                            helperText={(formik.touched.passwordAgain && formik.errors.passwordAgain) || ''}
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <div className="hover" onClick={() => setShowTwo(!showTwo)}>
                                            {showTwo ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Box py={2}>
                            <ColorButton color="primary" disableRipple={true} disabled={formik.isSubmitting} fullWidth={true} size="small" type="submit" variant="contained">
                                CONFIRM
                            </ColorButton>
                        </Box>
                    </form>
                </div>
                {(errorText && (
                    <div className="flex align-center">
                        <div className="login-error-icon"></div>
                        <div className="login-error-text">{errorText}</div>
                    </div>
                )) ||
                    null}
            </div>
        </div>
    );
};

export default withRouter(observer(ModifyPassword));
