import { headerCol } from './index';

export const demandColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'category',
			value: 'category'
		},
		{
			name: 'demand',
			value: 'demand'
		},
		{
			name: 'demand_yoy',
			value: 'demandYoy'
		}
	]
	return arr
}

export const brandShareColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'category',
			value: 'brand_share_tab'
		},
		{
			name: 'brand',
			value: 'brandMerge'
		},
		{
			name: 'demand_share',
			value: 'demandShare'
		}
	]
	return arr
}

export const ppiColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'period_type',
			value: 'period_type'
		},
		{
			name: 'category',
			value: 'category'
		},
		{
			name: 'ppi',
			value: 'ppi'
		},
		{
			name: 'ppi_yoy',
			value: 'ppiYoy'
		}
	]
	return arr
}

export const treemapTimeColumns = [
	{
		name: null,
		value: 'type'
	},
	{
		name: 'start',
		value: 'startDate'
	},
	{
		name: 'end',
		value: 'endDate'
	},
	{
		name: 'FTW Demand',
		value: 'totalDemand'
	}
]

export const treemapBrandColumns = [
	{
		name: null,
		value: 'brand_share'
	},
	{
		name: 'NIKE',
		value: 'nike'
	},
	{
		name: 'JORDAN',
		value: 'jordan'
	},
	{
		name: 'CONVERSE',
		value: 'converse'
	}
]

export const treemapColumns = [
	{
		name: 'brand',
		value: 'brandClean'
	},
	{
		name: 'price_type',
		value: 'price_type'
	},
	{
		name: 'price_tier',
		value: 'priceTierName'
	},
	{
		name: 'demand',
		value: 'demand'
	},
	{
		name: 'demand_share',
		value: 'brandShare'
	}
]

export const lineColumns = (type) => {
	const arr = [
		{
			name: type.toLowerCase(),
			value: 'date'
		},
		{
			name: 'price_type',
			value: 'price_type'
		},
		{
			name: 'price_tier',
			value: 'priceTierName'
		},
		{
			name: 'brand',
			value: 'brandClean'
		},
		{
			name: 'demand',
			value: 'demand'
		}
	]
	return arr
}

export const handleDemandData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'demand_yoy']
	const header = headerCol(columns, type, filterArr, demandTimeTab);
	const title = []
	const headerData = header.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'DEMAND'
	const arr = [];
	arr.push(title)
	arr.push(headerData)

	Object.entries(data).forEach(([key, value]) => {
		if (key !== 'TOTAL') {
			value.forEach(item => {
				const itemArr = []
				header.forEach(head => {
					if (head.name === 'period_type') {
						itemArr.push(demandTimeTab === 'M12' ? '12M': demandTimeTab)
					} else {
						itemArr.push(item[head.value] || '')
					}
				})
				arr.push(itemArr)
			})
		}
	})
	return {
		data: arr,
		colsNumber: header.length,
		rowsNumber: arr.length
	}
}

export const handleBrandShareData = (columns, type, demandTimeTab, data, brandShareTab) => {
	const filterArr = ['period_type']
	const header = headerCol(columns, type, filterArr, demandTimeTab);
	const title = []
	const headerData = header.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'BRAND SHARE'
	const arr = [];
	arr.push(title)
	arr.push(headerData)
	
	data.forEach(item => {
		item.data.forEach(d => {
			const itemArr = []
			header.forEach(head => {
				if (head.name === 'period_type') {
					itemArr.push(demandTimeTab === 'M12' ? '12M': demandTimeTab)
				} else if (head.name === 'category') {
					itemArr.push(brandShareTab)
				} else {
					itemArr.push(d[head.value] || '')
				}
			})
			arr.push(itemArr)
		})
	})
	return {
		data: arr,
		colsNumber: header.length,
		rowsNumber: arr.length
	}
}

export const handlePPIData = (columns, type, demandTimeTab, data) => {
	const filterArr = ['period_type', 'ppi_yoy']
	const header = headerCol(columns, type, filterArr, demandTimeTab);
	const title = []
	const headerData = header.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'PREMIUM PRICE INDEX'
	const arr = [];
	arr.push(title)
	arr.push(headerData)

	Object.entries(data).forEach(([key, value]) => {
		if (key !== 'TOTAL') {
			value.forEach(item => {
				const itemArr = []
				header.forEach(head => {
					if (head.name === 'period_type') {
						itemArr.push(demandTimeTab === 'M12' ? '12M': demandTimeTab)
					} else {
						itemArr.push(item[head.value] || '')
					}
				})
				arr.push(itemArr)
			})
		}
	})
	return {
		data: arr,
		colsNumber: header.length,
		rowsNumber: arr.length
	}
}

export const handleTreemapTimeData = (columns, type, startDate, endDate, data) => {
	const title = []
	const headerData = columns.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = 'BRAND SHARE BY PRICE TIER'
	const arr = [];
	arr.push(title)
	arr.push(headerData)
	arr.push([type.toLowerCase(), startDate, endDate, data.totalDemand])
	
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleTreemapBrandData = (columns, data) => {
	const headerData = columns.map(h => {
		return h.name
	});
	const arr = [];
	arr.push(headerData)

	const nike = `${Math.round((Number(data.nikeDemand) / Number(data.totalDemand)) * 100)}%`;
	const jordan = `${Math.round((Number(data.jordanDemand) / Number(data.totalDemand)) * 100)}%`;
	const converse = `${Math.round((Number(data.converseDemand) / Number(data.totalDemand)) * 100)}%`;
	arr.push(['brand share', nike, jordan, converse])
	
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleTreemapData = (columns, treemapTab, data) => {
	const headerData = columns.map(h => {
		return h.name
	});
	const arr = [];
	arr.push(headerData)

	data.forEach(item => {
		item.itemList.forEach(list => {
			const itemArr = []
			columns.forEach(column => {
				if (column.name === 'price_type') {
					itemArr.push(treemapTab)
				} else if (column.name === 'price_tier') {
					itemArr.push(item.priceTierName)
				} else {
					itemArr.push(list[column.value] || '')
				}
			})
			arr.push(itemArr)
		})
	})
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}

export const handleLineData = (columns, treemapTab, data, priceTierName) => {
	const title = []
	const headerData = columns.map(h => {
		title.push(null)
		return h.name
	});
	title[0] = `BRAND DEMAND OF ${treemapTab === 'RESALE_PRICE' ? 'RESALE': treemapTab} PRICE RANGE ${priceTierName}`
	const arr = [];
	arr.push(title)
	arr.push(headerData)

	Object.entries(data).forEach(([key, value]) => {
		value.forEach(item => {
			const itemArr = [];
			columns.forEach(column => {
				if (column.name === 'price_type') {
					itemArr.push(treemapTab)
				} else {
					itemArr.push(item[column.value] || '')
				}
			})
			arr.push(itemArr)
		})
	})
	return {
		data: arr,
		colsNumber: columns.length,
		rowsNumber: arr.length
	}
}