import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import UserTable from './UserTable';
import CommonTextField from '../components/TextField';
import DialogTextField from '../login/DialogTextField';
import { Formik } from 'formik';
import * as Yup from 'yup';

const StyledDialog = withStyles((theme) => ({
    root: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.76)',
        },
    },
}))(Dialog);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const columns = [
    {
        name: 'email',
        label: 'Email',
        minWidth: '140px',
    },
    {
        name: 'nickname',
        label: 'Nickname',
        minWidth: '140px',
    },
    {
        name: 'roleNames',
        label: 'Roles',
        minWidth: '140px',
    },
    {
        name: 'createUser',
        label: 'Create User',
        width: '140px',
    },
    {
        name: 'createTime',
        label: 'Create Time',
        width: '130px',
    },
    {
        name: 'lockFlag',
        label: 'Locked',
        width: '80px',
    },
    {
        name: 'firstLoginFlag',
        label: 'First Login',
        width: '100px',
    },
    {
        name: 'enableFlag',
        label: 'Enable',
        width: '100px',
    },
    {
        name: 'reset',
        label: 'Reset Password',
        width: '120px',
    },
    {
        name: 'operator',
        label: 'Operator',
        width: '160px',
    },
];

@inject('userStore')
@observer
export default class User extends React.Component {
    state = {
        emailValue: '',
        roleValue: '',
        addOrEdit: 'add',
        userOpen: false,
        deleteOpen: false,
        dialogInfo: {
            email: '',
            nickName: '',
            password: '',
            passwordAgain: '',
            roles: [],
            userId: '',
            userType: 2,
        },
        deleteUserId: '',
    };
    async componentWillMount() {
        await this.props.userStore.roleDictRequest();
        await this.props.userStore.userListRequest({});
    }

    handleSearch = async () => {
        const { emailValue, roleValue } = this.state;
        await this.props.userStore.userListRequest({
            email: emailValue,
            roleId: roleValue,
        });
    };

    handleCreate = () => {
        this.setState({
            addOrEdit: 'add',
            userOpen: true,
        });
    };

    handleSwitch = async (data, options, callback) => {
        try {
            const response = await this.props.userStore.userEnableRequest({
                userId: data.userId,
                enableFlag: options ? 1 : 2,
            });
            if (response.code === '00000') {
            } else {
                callback && callback();
            }
        } catch (error) {
            callback && callback();
        }
    };

    handleUserReset = async (data) => {
        await this.props.userStore.userResetPasswordRequest({
            userId: data.userId,
        });
    };

    handleOperator = ({ type, data, options }, callback) => {
        if (type === 'switch') {
            this.handleSwitch(data, options, callback);
        } else if (type === 'delete') {
            this.setState({
                deleteOpen: true,
                deleteUserId: data.userId,
            });
        } else if (type === 'reset') {
            this.handleUserReset(data);
        } else if (type === 'edit') {
            this.setState({
                userOpen: true,
                addOrEdit: 'edit',
                dialogInfo: {
                    email: data.email,
                    nickName: data.nickname,
                    password: '',
                    passwordAgain: '',
                    roles: data.roleIdList,
                    userId: data.userId,
                    userType: data.userType,
                },
            });
        }
    };

    handleCancel = () => {
        this.setState({
            userOpen: false,
            dialogInfo: {
                email: '',
                nickName: '',
                password: '',
                passwordAgain: '',
                roles: [],
                userId: '',
                userType: 2,
            },
        });
    };

    handleSubmit = async (data) => {
        const response = await this.props.userStore.userSaveRequest({
            userId: this.state.addOrEdit === 'add' ? null : this.state.dialogInfo.userId,
            nickname: data.nickName,
            password: data.password,
            email: data.email,
            roleIds: data.roles,
        });
        if (response.code === '00000') {
            this.setState({ userOpen: false });
            const { emailValue, roleValue } = this.state;
            await this.props.userStore.userListRequest({
                email: emailValue,
                roleId: roleValue,
            });
        }
    };

    handleDeleteCancel = () => {
        this.setState({
            deleteOpen: false,
            deleteUserId: '',
        });
    };

    handleDeleteConfirm = async () => {
        const { code } = await this.props.userStore.userRemoveRequest({
            id: this.state.deleteUserId,
        });
        if (code === '00000') {
            this.setState({ deleteOpen: false });
            const { emailValue, roleValue } = this.state;
            await this.props.userStore.userListRequest({
                email: emailValue,
                roleId: roleValue,
            });
        }
    };

    render() {
        const { userList, roleDict } = this.props.userStore;
        const { emailValue, roleValue, addOrEdit, userOpen, dialogInfo, deleteOpen } = this.state;
        const validationSchema = Yup.object({
            email: Yup.string().required('email is required'),
            nickName: Yup.string().required('nickName is required'),
            password: Yup.string().test('', 'Must have at least 8 characters, at most 16 characters, and include A-Z, a-z, and 0-9', (value) => {
                if (this.state.addOrEdit === 'edit') {
                    if (value) {
                        const isValidate = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}/.test(value);
                        if (isValidate) {
                            return true;
                        }
                        return false;
                    }
                    return true;
                }
                if (value) {
                    const isValidate = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}/.test(value);
                    if (isValidate) {
                        return true;
                    }
                    return false;
                }
                return false;
            }),
            passwordAgain: Yup.string().test('', 'Both passwords must match', (value, context) => {
                if (this.state.addOrEdit === 'edit') {
                    const pre = context.parent.password;
                    if (value && value !== pre) {
                        return false;
                    }
                    return true;
                }
                if (value) {
                    const pre = context.parent.password;
                    if (pre && value === pre) {
                        return true;
                    }
                    return false;
                }
                return false;
            }),
            roles: Yup.array().test('', 'roles is required', (value) => {
                if (this.state.addOrEdit === 'edit' && this.state.dialogInfo.userType === 1) {
                    return true;
                }
                if (value.length) {
                    return true;
                }
                return false;
            }),
        });
        return (
            <div className="flex-1 flex hidden p20 align-center">
                <div className="flex-1 flex flex-column card-box full-height full-width p20 border-box">
                    <div className="mb10 flex align-center">
                        <div className="flex align-center mr20">
                            <div className="mr20">Email:</div>
                            <CommonTextField
                                size="small"
                                fullWidth={true}
                                name="email"
                                type="text"
                                margin="normal"
                                value={emailValue}
                                variant="outlined"
                                onChange={(e) =>
                                    this.setState({
                                        emailValue: e.target.value,
                                    })
                                }
                            ></CommonTextField>
                        </div>
                        <div className="flex align-center mr20">
                            <div className="mr20">Role:</div>
                            <div style={{ minWidth: '200px' }}>
                                <CommonTextField
                                    size="small"
                                    fullWidth={true}
                                    name="role"
                                    select={true}
                                    margin="normal"
                                    value={roleValue}
                                    variant="outlined"
                                    onChange={(e) =>
                                        this.setState({
                                            roleValue: e.target.value,
                                        })
                                    }
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    minWidth: 200,
                                                    color: '#516F90',
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        },
                                    }}
                                >
                                    {roleDict.map((item) => (
                                        <MenuItem key={item.display} value={item.value}>
                                            {item.display}
                                        </MenuItem>
                                    ))}
                                </CommonTextField>
                            </div>
                        </div>
                        <div className="mr10">
                            <Button variant="outlined" style={{ color: '#fff', border: '1px solid rgba(255,255,255,0.5)' }} size="small" onClick={() => this.handleSearch()}>
                                search
                            </Button>
                        </div>
                        <div>
                            <Button variant="outlined" style={{ color: '#fff', border: '1px solid rgba(255,255,255,0.5)' }} size="small" onClick={() => this.handleCreate()}>
                                create
                            </Button>
                        </div>
                    </div>
                    <div className="flex-1 overflow-y">
                        <UserTable columns={columns} data={userList} onOperator={this.handleOperator}></UserTable>
                    </div>
                </div>
                <StyledDialog open={userOpen} maxWidth="xs" fullWidth={true}>
                    <DialogTitle>{addOrEdit === 'edit' ? 'edit user' : 'new user'}</DialogTitle>
                    <Formik
                        initialValues={{
                            email: dialogInfo.email,
                            nickName: dialogInfo.nickName,
                            password: dialogInfo.password,
                            passwordAgain: dialogInfo.passwordAgain,
                            roles: dialogInfo.roles,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={this.handleSubmit}
                        render={(formProps) => (
                            <React.Fragment>
                                <DialogContent>
                                    <div className="flex align-center">
                                        <div className="mr10 flex align-center" style={{ width: '160px' }}>
                                            <div style={{ color: 'red', paddingTop: '10px', marginRight: '4px' }}>*</div>
                                            <div className="flex-1">Email</div>
                                        </div>
                                        <DialogTextField disabled={addOrEdit === 'edit'} size="small" fullWidth={true} name="email" type="text" margin="normal" value={formProps.getFieldProps('email').value} variant="outlined" onChange={formProps.handleChange} helperText={(formProps.touched.email && formProps.errors.email) || ''} error={Boolean(formProps.touched.email && formProps.errors.email)} onBlur={formProps.handleBlur}></DialogTextField>
                                    </div>
                                    <div className="flex align-center">
                                        <div className="mr10 flex align-center" style={{ width: '160px' }}>
                                            <div style={{ color: 'red', paddingTop: '10px', marginRight: '4px' }}>*</div>
                                            <div className="flex-1">Nickname</div>
                                        </div>
                                        <DialogTextField size="small" fullWidth={true} name="nickName" type="text" margin="normal" value={formProps.getFieldProps('nickName').value} variant="outlined" onChange={formProps.handleChange} helperText={(formProps.touched.nickName && formProps.errors.nickName) || ''} error={Boolean(formProps.touched.nickName && formProps.errors.nickName)} onBlur={formProps.handleBlur}></DialogTextField>
                                    </div>
                                    <div className="flex align-center">
                                        <div className="mr10 flex align-center" style={{ width: '160px' }}>
                                            {(addOrEdit === 'add' && <div style={{ color: 'red', paddingTop: '10px', marginRight: '4px' }}>*</div>) || null}
                                            <div className="flex-1">Password</div>
                                        </div>
                                        <DialogTextField size="small" fullWidth={true} name="password" type="password" margin="normal" value={formProps.getFieldProps('password').value} variant="outlined" onChange={formProps.handleChange} helperText={(formProps.touched.password && formProps.errors.password) || ''} error={Boolean(formProps.touched.password && formProps.errors.password)} onBlur={formProps.handleBlur}></DialogTextField>
                                    </div>
                                    <div className="flex align-center">
                                        <div className="mr10 flex align-center" style={{ width: '160px' }}>
                                            {(addOrEdit === 'add' && <div style={{ color: 'red', paddingTop: '10px', marginRight: '4px' }}>*</div>) || null}
                                            <div className="flex-1">Password Confirm</div>
                                        </div>
                                        <DialogTextField size="small" fullWidth={true} name="passwordAgain" type="password" margin="normal" value={formProps.getFieldProps('passwordAgain').value} variant="outlined" onChange={formProps.handleChange} helperText={(formProps.touched.passwordAgain && formProps.errors.passwordAgain) || ''} error={Boolean(formProps.touched.passwordAgain && formProps.errors.passwordAgain)} onBlur={formProps.handleBlur}></DialogTextField>
                                    </div>
                                    {(dialogInfo.userType === 2 && (
                                        <div className="flex align-center">
                                            <div className="mr10 flex align-center" style={{ width: '160px' }}>
                                                <div style={{ color: 'red', paddingTop: '10px', marginRight: '4px' }}>*</div>
                                                <div className="flex-1">Roles</div>
                                            </div>
                                            <DialogTextField
                                                size="small"
                                                fullWidth={true}
                                                select={true}
                                                name="roles"
                                                margin="normal"
                                                value={formProps.getFieldProps('roles').value}
                                                variant="outlined"
                                                onChange={formProps.handleChange}
                                                helperText={(formProps.touched.roles && formProps.errors.roles) || ''}
                                                error={Boolean(formProps.touched.roles && formProps.errors.roles)}
                                                onBlur={formProps.handleBlur}
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                minWidth: 200,
                                                                color: '#516F90',
                                                            },
                                                        },
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                        getContentAnchorEl: null,
                                                    },
                                                    multiple: true,
                                                }}
                                            >
                                                {roleDict.map((dict) => (
                                                    <MenuItem key={dict.display} value={dict.value}>
                                                        {dict.display}
                                                    </MenuItem>
                                                ))}
                                            </DialogTextField>
                                        </div>
                                    )) ||
                                        null}
                                </DialogContent>

                                <DialogActions>
                                    <Button onClick={this.handleCancel} color="primary" variant="outlined">
                                        Cancel
                                    </Button>
                                    <Button onClick={() => formProps.handleSubmit()} color="primary" variant="contained">
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </React.Fragment>
                        )}
                    />
                </StyledDialog>
                <StyledDialog open={deleteOpen} maxWidth="xs" fullWidth={true}>
                    <DialogTitle>Delete</DialogTitle>
                    <DialogContent>Do you want to delete it?</DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteCancel} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDeleteConfirm} color="primary" variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                </StyledDialog>
            </div>
        );
    }
}
