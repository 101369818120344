import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Menu } from '@material-ui/core';
import cx from 'classnames';
import moment from 'moment';

import OverviewDrop from '../components/OverviewDrop';
import DatePicker from '../components/DatePicker';
import CommonDialog from '../components/CommonDownloadDialog';
import CommonTootip from '../components/StyledTooltip';
import Slider from '../components/Slider'
import { Visualization } from '../visualization/index';

import { downloadPDF, formatM, formatPercent, brandColor, handleDateStartEnd, getWeekStartEnd, transformDate, downloadXlsx2, getColsWpx, handleLegend } from '../../utils/index';
import {
	treemapTimeColumns,
	treemapBrandColumns,
	treemapColumns,
	lineColumns,
	handleTreemapTimeData,
	handleTreemapBrandData,
	handleTreemapData,
	handleLineData
} from '../../utils/subCategoryDownload';

import {
	handleDemandData,
	handlePPIData,
	handleRVIData,
	handleDIData,
} from '../../utils/franchiseAnalysisDownload';
import {
    demandColumns,
	ppiColumns,
	rviColumns,
	diColumns
} from '../../utils/subCategoryDownload'

import { withStyles } from '@material-ui/core/styles';
import '../../assets/css/subCategory.css';

const StyledMenu = withStyles({
    paper: {
        background: 'rgba(0, 0, 0, 0.84)',
        border: '1px solid rgba(100, 100, 100, 0.49)',
        color: '#fff',
        borderRadius: '4px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} {...props} />);

const timeTabList = [
    {
        name: 'MONTH',
        alias: 'MONTH',
    },
    {
        name: '12M',
        alias: 'M12',
    },
];

const treemapTabList = [
    {
        name: 'MSRP',
        alias: 'MSRP',
    },
    {
        name: 'Resale Price',
        alias: 'RESALE_PRICE',
    },
];

@inject('overviewStore', 'commonStore', 'userStore', 'subCategoryStore')
@observer
export default class SubCategory extends React.Component {
    state = {
        active: 0,
        demandTab: 'demand',
        demandTimeTab: 'MONTH',
        brandShareTimeTab: 'MONTH',
        brandShareTab: 'FTW',
        ppiTimeTab: 'MONTH',
        treemapTab: 'MSRP',
        type: 'MONTH',
        anchorEle: null,
        startDate: null,
        endDate: null,
        originalDate: {},
        currentLineInfo: {},
        lineLegendInfo: [],
        treemapLineColor: {},
        treemapStartDate: null,
        treemapEndDate: null,
        treemapType: 'MONTH',
        treemapOriginalDate: {},
        subCategory: 'LIFESTYLE',
        colors: {
            DEMAND: {},
            'PREMIUM PRICE INDEX': {},
            'RESALE VOLUME INDEX': {},
            'DIVERSITY INDEX': {},
        },
    };

    async componentDidMount() {
        const { timeRangeMonth } = await this.props.commonStore;
        const { max } = timeRangeMonth;
        const { startDate, endDate, originalDate } = transformDate(max, 12);
        const { type, treemapTab, subCategory, demandTimeTab } = this.state;
        this.setState({
            startDate,
            endDate,
            originalDate,
            treemapStartDate: startDate,
            treemapEndDate: endDate,
            treemapOriginalDate: originalDate,
        });
        await this.getRequest(startDate, endDate, type, treemapTab, subCategory);
        await this.getIndexRequest(startDate, endDate, type, demandTimeTab)
    }

    getIndexRequest = async (startDate, endDate, type, demandTimeTab) => {
        await this.props.subCategoryStore.subCategoryIndexRequest({
            startDate,
            endDate,
            dateType: type,
            dataType: demandTimeTab,
        });
    }

    getRequest = async (startDate, endDate, type, priceType, subCategory) => {
        await this.props.subCategoryStore.subCategorySummaryRequest({
            startDate,
            endDate,
            dateType: type,
            priceType
        });
        await this.props.subCategoryStore.subCategoryBlockRequest({
            startDate,
            endDate,
            dateType: type,
            priceType,
            subCategory
        });

        const { subCategoryPriceTierTreemap } = this.props.subCategoryStore;
        if (subCategoryPriceTierTreemap.length) {
            this.setState({
                currentLineInfo: subCategoryPriceTierTreemap[0],
                lineLegendInfo: subCategoryPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
            });
            await this.props.subCategoryStore.subCategoryLineRequest({
                startDate,
                endDate,
                dateType: type,
                priceType,
                priceTierCode: subCategoryPriceTierTreemap[0].priceTierCode,
                brandList: subCategoryPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
                subCategory
            });
        }
    };

    handleChange = async (index, e, data) => {
        const { treemapTab, treemapStartDate, treemapEndDate, treemapType, subCategory } = this.state;
        this.setState({
            active: index,
            // anchorEle: e.currentTarget,
            currentLineInfo: data,
			lineLegendInfo: data.brandDictList.slice(0, 3) || [],
        });

        await this.props.subCategoryStore.subCategoryLineRequest({
            startDate: treemapStartDate,
            endDate: treemapEndDate,
            dateType: treemapType,
            priceType: treemapTab,
            priceTierCode: data.priceTierCode,
            brandList: data.brandDictList.slice(0, 3) || [],
            subCategory
        });
    };

    handleConfirm = async (selected, type, original) => {
        const { demandTimeTab } = this.state
        if (type === 'MONTH') {
            const start = Number(selected[0].replace('-', ''));
            const end = Number(selected[1].replace('-', ''));
            this.setState({
                type,
                startDate: start,
                endDate: end,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            await this.getIndexRequest(start, end, type, demandTimeTab);
        } else if (type === 'WEEK') {
            const { start, end } = getWeekStartEnd(selected);
            this.setState({
                type,
                startDate: start,
                endDate: end,
                originalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            await this.getIndexRequest(start, end, type, demandTimeTab);
        }
    };

    handleDemandTimeTab = async (tab) => {
        this.setState({
			demandTimeTab: tab,
        });
        const { type, startDate, endDate } = this.state;
        await this.getIndexRequest(startDate, endDate, type, tab);
    };

    handleTreemapTab = async (tab) => {
        const { treemapStartDate, treemapEndDate, treemapType, subCategory } = this.state;
        this.setState({
			treemapTab: tab,
        });
        await this.getRequest(treemapStartDate, treemapEndDate, treemapType, tab, subCategory)
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'pdf') {
            this.ele &&
                downloadPDF(
                    this.ele,
                    () => {
                        console.log('download success');
						this.props.commonStore.setDownloadDialog(false);
						this.props.commonStore.setMenuName('');
                    },
                    () => {
                        console.log('download fail');
					},
					name
                );
        } else if (type === 'xlsx') {
			const { type, demandTimeTab, treemapType, treemapStartDate, treemapEndDate, treemapTab, currentLineInfo } = this.state;
            const { subCategorySummaryData, subCategoryPriceTierTreemap, subCategoryPriceTierLineResult } = this.props.subCategoryStore	

            const { subCategoryIndexResult } = this.props.subCategoryStore;
            const allOptions = {
                demand: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                },
                ppi: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                },
                rvi: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                },
                di: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                },
                treemap: {
                    '!merges': [],
                    '!cols': getColsWpx(5)
                }
            }

            const allSheet = {
                demand: [],
                ppi: [],
                rvi: [],
                di: [],
                treemap: []
            }

            const allName = {
                demand: '',
                ppi: '',
                rvi: '',
                di: '',
                treemap: ''
            }

            let rowsNum = 0

            const treemapTimeColumn = treemapTimeColumns;
			const { data: treemapTimeData, colsNumber: treemapTimeColsNumber, rowsNumber: treemapTimeRowsNumber } = handleTreemapTimeData(treemapTimeColumn, treemapType, treemapStartDate, treemapEndDate, subCategorySummaryData);
            allSheet.treemap = treemapTimeData
			allOptions.treemap['!merges'].push({
				s: { r: 0, c: 0 },
				e: { r: 0, c: treemapTimeColsNumber - 1 }
			})
			rowsNum = treemapTimeRowsNumber + rowsNum;

			const treemapBrandColumn = treemapBrandColumns;
			const { data: treemapBrandData, rowsNumber: treemapBrandRowsNumber } = handleTreemapBrandData(treemapBrandColumn, subCategorySummaryData);
            allSheet.treemap = [...allSheet.treemap, ...treemapBrandData]
			rowsNum = treemapBrandRowsNumber + rowsNum;
			
			const treemapColumn = treemapColumns;
			const { data: treemapData, rowsNumber: treemapRowsNumber } = handleTreemapData(treemapColumn, treemapTab, subCategoryPriceTierTreemap);
            allSheet.treemap = [...allSheet.treemap, ...treemapData]
			rowsNum = treemapRowsNumber + rowsNum;

			const lineColumn = lineColumns(treemapType);
			const { data: lineData, colsNumber: lineColsNumber } = handleLineData(lineColumn, treemapTab, subCategoryPriceTierLineResult, currentLineInfo.priceTierName);
            allSheet.treemap = [...allSheet.treemap, ...lineData]
			allOptions.treemap['!merges'].push({
				s: { r: rowsNum, c: 0 },
				e: { r: rowsNum, c: lineColsNumber - 1 }
			})
            allName.treemap = 'BRAND SHARE BY PRICE TIER'
			
			const demandColumn = demandColumns(type);
			const { data, colsNumber, sheetName } = handleDemandData(demandColumn, type, demandTimeTab, subCategoryIndexResult, false);
            allSheet.demand = data
			allOptions.demand['!merges'].push({
				s: { r: 0, c: colsNumber - 1 },
				e: { r: 0, c: colsNumber - 1 }
			})
            allName.demand = sheetName

			const ppiColumn = ppiColumns(type);
			const { data: ppiLineData, colsNumber: ppiColsNumber, sheetName: ppiName } = handlePPIData(ppiColumn, type, demandTimeTab, subCategoryIndexResult, false);
            allSheet.ppi = ppiLineData
			allOptions.ppi['!merges'].push({
				s: { r: 0, c: ppiColsNumber - 1 },
				e: { r: 0, c: ppiColsNumber - 1 }
			})
            allName.ppi = ppiName

			const rviColumn = rviColumns(type);
			const { data: rviData, colsNumber: rviColsNumber, sheetName: rviName } = handleRVIData(rviColumn, type, demandTimeTab, subCategoryIndexResult, false);
            allSheet.rvi = rviData
			allOptions.rvi['!merges'].push({
				s: { r: 0, c: rviColsNumber - 1 },
				e: { r: 0, c: rviColsNumber - 1 }
			})
            allName.rvi = rviName

			const diColumn = diColumns(type);
			const { data: diData, colsNumber: diColsNumber, sheetName: diName } = handleDIData(diColumn, type, demandTimeTab, subCategoryIndexResult, false);
            allSheet.di = diData
			allOptions.di['!merges'].push({
				s: { r: 0, c: diColsNumber - 1 },
				e: { r: 0, c: diColsNumber - 1 }
			})
            allName.di = diName

			downloadXlsx2(allSheet, menuName, name, allOptions, allName);
			
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
	};

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    getSummary = (data) => {
        const lifestyleDemand = Number(data.lifestyleDemand) * 100;
        const runningDemand = Number(data.runningDemand) * 100;
        const basketballDemand = Number(data.basketballDemand) * 100;
        const othersDemand = Number(data.othersDemand) * 100;

        return {
            lifestyle: Math.round(lifestyleDemand) || 0,
            running: Math.round(runningDemand) || 0,
            basketball: Math.round(basketballDemand) || 0,
            others: Math.round(othersDemand) || 0,
            total: formatM(data.totalDemand),
        };
    };

    treemapLineChangeColor = (color) => {
        this.setState({
            treemapLineColor: color,
        });
    };

    handleLineBrandConfirm = async (data) => {
        const { treemapTab, currentLineInfo, treemapStartDate, treemapEndDate, treemapType, subCategory } = this.state;
        
        await this.props.subCategoryStore.subCategoryLineRequest({
            startDate: treemapStartDate,
            endDate: treemapEndDate,
            dateType: treemapType,
            priceType: treemapTab,
            priceTierCode: currentLineInfo.priceTierCode,
            brandList: data || [],
            subCategory
        });

        this.setState({
            lineLegendInfo: data,
        });
    };

    handleTreemapConfirm = async (selected, type, original) => {
        const { start, end } = handleDateStartEnd(selected, type);
        if (start && end) {
            this.setState({
                treemapStartDate: start,
                treemapEndDate: end,
                treemapType: type,
                treemapOriginalDate: {
                    startDate: selected[0],
                    endDate: selected[1],
                    origin: original,
                },
            });
            const { treemapTab, subCategory } = this.state;
            await this.getRequest(start, end, type, treemapTab, subCategory)
        }
    };

    changeBrandShareColor = (colors) => {
        this.setState({ colors });
	};

	handleSliderChangeTreemapLine = (value, sliderArr) => {
		this.props.subCategoryStore.changeTreemapLineDateRange(sliderArr)
	}

    handleSliderChange = (sliderArr, type) => {
		if (type === 'demand') {
			this.props.subCategoryStore.changeDateRange1(sliderArr)
		} else if (type === 'ppi') {
			this.props.subCategoryStore.changeDateRange2(sliderArr)
		} else if (type === 'resaleVolumeIndex') {
			this.props.subCategoryStore.changeDateRange3(sliderArr)
		} else if (type === 'diversity') {
			this.props.subCategoryStore.changeDateRange4(sliderArr)
		}
	}

    getDesc = (dateRange, subCategoryIndexData) => {
		const lastDate = dateRange[dateRange.length - 1]
		return handleLegend(subCategoryIndexData, 'subCategory', lastDate);
	}

    handleChangeColor = (color, type) => {
        const c = this.state.colors;
        c[type] = color;

        this.setState({
            colors: c,
        });
	};

    handleSubCategory = async (category) => {
        this.setState({
            subCategory: category
        })
        const { treemapStartDate, treemapEndDate, type, treemapTab } = this.state

        await this.props.subCategoryStore.subCategoryBlockRequest({
            startDate: treemapStartDate,
            endDate: treemapEndDate,
            dateType: type,
            priceType: treemapTab,
            subCategory: category
        });

        const { subCategoryPriceTierTreemap } = this.props.subCategoryStore;
        if (subCategoryPriceTierTreemap.length) {
            this.setState({
                currentLineInfo: subCategoryPriceTierTreemap[0],
                lineLegendInfo: subCategoryPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
            });
            await this.props.subCategoryStore.subCategoryLineRequest({
                startDate: treemapStartDate,
                endDate: treemapEndDate,
                dateType: type,
                priceType: treemapTab,
                priceTierCode: subCategoryPriceTierTreemap[0].priceTierCode,
                brandList: subCategoryPriceTierTreemap[0].brandDictList.slice(0, 3) || [],
                subCategory: category
            });
        }
    }

    render() {
        const { subCategoryIndexResult, subCategoryIndexData, subCategorySummaryData, subCategoryPriceTierTreemap, subCategoryPriceTierLineResult, treemapLineDateArr, treemapLineDateArrTemp } = this.props.subCategoryStore;
        const VisualizationComponentBrandLine = Visualization.get('line_chart');
        const { isDialogOpen, timeRangeMonth, timeRangeWeek } = this.props.commonStore;
        const { active, demandTimeTab, treemapTab, type, anchorEle, currentLineInfo, lineLegendInfo, treemapLineColor, startDate, endDate, treemapStartDate, treemapEndDate, treemapType, treemapOriginalDate, originalDate, colors, subCategory } = this.state;
        const { lifestyle, running, basketball, others, total } = this.getSummary(subCategorySummaryData);
        const list = (currentLineInfo.itemList || []).map((d) => ({
            name: d.brandClean,
		}));

        return (
            <div className="flex flex-column hidden p20 sub-category-bg" ref={(_f) => (this.ele = _f)}>
                <div className="flex flex-column" style={{height: '420px', minHeight: '420px'}}>
                    <div className="flex align-center justify-between">
						<div className="flex align-center title-box">
							<div className="sub-category-price-tier"></div>
						</div>
					</div>
                    <div className="flex-1 card-box flex flex-column">
                        <div className="sub-category-treemap-title-box border-box flex align-center px20">
                            <div className="flex flex-column mr60 treemap-tab-box">
                                {treemapTabList.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={cx('demand-tab hover', {
                                            'demand-tab-active': treemapTab === tab.alias,
                                            mb10: index === 0,
                                        })}
                                        onClick={() => this.handleTreemapTab(tab.alias)}
                                    >
                                        {tab.name}
                                        <div
                                            className={cx('tab-line mt2', {
                                                'tab-line-active': treemapTab === tab.alias,
                                            })}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="flex-1 flex align-center treemap-brand-box border-box p10 ml10">
                                <div className="brand-icon-box brand-total mr10 flex align-center justify-center">
                                    <div className="treemap-brand-icon treemap-total"></div>
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="date-picker">
                                        <DatePicker onConfirm={this.handleTreemapConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={treemapStartDate} currentEndDate={treemapEndDate} currentType={treemapType} currentOrigin={treemapOriginalDate}></DatePicker>
                                    </div>
                                    <div className="treemap-brand-text">￥{total}</div>
                                </div>
                            </div>
                            <div className={cx("flex align-center treemap-brand-box sub-category-summary-item border-box p10 ml10 hover", { 'sub-category-item-active': subCategory ===  'LIFESTYLE'})} onClick={() => this.handleSubCategory('LIFESTYLE')}>
                                <div className="lifestyle-icon mr10 flex align-center justify-center">
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="treemap-brand-title mb6">LIFESTYLE</div>
                                    <div className="treemap-brand-content">{lifestyle}%</div>
                                </div>
                                {
                                    subCategory === 'LIFESTYLE' ? <div className='sub-category-item-arrow'></div> : null
                                }
                            </div>
                            <div className={cx("flex align-center treemap-brand-box sub-category-summary-item border-box p10 ml10 hover", { 'sub-category-item-active': subCategory ===  'RUNNING'})} onClick={() => this.handleSubCategory('RUNNING')}>
                                <div className="running-icon brand-jordan mr10 flex align-center justify-center">
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="treemap-brand-title mb6">RUNNING</div>
                                    <div className="treemap-brand-content">{running}%</div>
                                </div>
                                {
                                    subCategory === 'RUNNING' ? <div className='sub-category-item-arrow'></div> : null
                                }
                            </div>
                            <div className={cx("flex align-center treemap-brand-box sub-category-summary-item border-box p10 ml10 hover", { 'sub-category-item-active': subCategory ===  'BASKETBALL'})} onClick={() => this.handleSubCategory('BASKETBALL')}>
                                <div className="basketball-icon brand-converse mr10 flex align-center justify-center">
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="treemap-brand-title mb6">BASKETBALL</div>
                                    <div className="treemap-brand-content">{basketball}%</div>
                                </div>
                                {
                                    subCategory === 'BASKETBALL' ? <div className='sub-category-item-arrow'></div> : null
                                }
                            </div>
                            <div className={cx("flex align-center treemap-brand-box sub-category-summary-item border-box p10 ml10 hover", { 'sub-category-item-active': subCategory ===  'OTHERS'})} onClick={() => this.handleSubCategory('OTHERS')}>
                                <div className="others-icon brand-converse mr10 flex align-center justify-center">
                                </div>
                                <div className="flex-1 flex flex-column">
                                    <div className="treemap-brand-title mb6">OTHERS</div>
                                    <div className="treemap-brand-content">{others}%</div>
                                </div>
                                {
                                    subCategory === 'OTHERS' ? <div className='sub-category-item-arrow'></div> : null
                                }
                            </div>
                        </div>
                        <div className="flex-1 flex full-height">
                            <div className="flex ml20 my10 treemap-chart-box">
                                <div className="flex-1 flex flex-column bg-img">
                                    {subCategoryPriceTierTreemap.map((tree, index) => {
                                        const Treemap = Visualization.get('treemap_chart');
                                        return (
                                            <React.Fragment key={index}>
                                                <CommonTootip
                                                    placement="top"
                                                    title={
                                                        <div className="p16">
                                                            <div className="price-tier-name mb16">
                                                                {tree.priceTierName}({formatPercent(tree.priceTierShare)})
                                                            </div>
                                                            <div className='flex'>
                                                                <div className='flex-1'>
                                                                    {(tree.rankItemList || []).map((item, index) => (
                                                                        <div key={index} className="flex align-center mb16">
                                                                            <div className="brand-color" style={{ backgroundColor: brandColor(item.brandClean) || '#657179' }}></div>
                                                                            <div className="price-tier-brandclean mr10">{item.brandClean}</div>
                                                                            <div className="price-tier-demand mr10">￥{formatM(item.demand)}</div>
                                                                            <div className="price-tier-brandshare">{formatPercent(item.brandShare)}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className='flex-1' style={{borderLeft: '1px solid rgba(154, 157, 167, 0.5)', marginLeft: '20px', paddingLeft: '15px'}}>
                                                                    {(tree.franchiseRankItemList || []).map((item, index) => (
                                                                        <div key={index} className="flex align-center mb16">
                                                                            <div className="price-tier-franchise mr10">{item.franchise}</div>
                                                                            <div className="price-tier-demand mr10">￥{formatM(item.demand)}</div>
                                                                            <div className="price-tier-brandshare">{formatPercent(item.brandShare)}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <div style={{ border: active === index && '2px solid #fff', height: formatPercent(tree.priceTierShare), borderRadius: (index === 0 && '4px 0 0 0') || (index === subCategoryPriceTierTreemap.length - 1 && '0 0 0 4px') || '0 0 0 0', overflow: 'hidden', opacity: 0.7 }} onClick={(e) => this.handleChange(index, e, tree)}>
                                                        <Treemap key={index} data={tree} />
                                                    </div>
                                                </CommonTootip>
                                                {index !== subCategoryPriceTierTreemap.length - 1 ? <div className="divid divid-left"></div> : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="flex flex-column py10">
                                {subCategoryPriceTierTreemap.map((tree, index) => (
                                    <React.Fragment key={index}>
                                        <div style={{ height: formatPercent(tree.priceTierShare) }} className="flex">
                                            <div
                                                className={cx('treemap-right-button pl10 flex flex-column justify-center', {
                                                    'treemap-right-button-selected': index === active,
                                                })}
                                            >
                                                <div className="button-number">{tree.priceTierName}</div>
                                                <div className="button-percent">{formatPercent(tree.priceTierShare)}</div>
                                            </div>
                                        </div>
                                        {index !== subCategoryPriceTierTreemap.length - 1 ? <div className="divid"></div> : null}
                                    </React.Fragment>
                                ))}
                            </div>
                            <div
                                className={cx('flex-1 pl4 my10 mr20 border-box flex flex-column treemap-line-chart-box', {
                                    'treemap-line-chart-first': active === 0,
                                    'treemap-line-chart-last': active === subCategoryPriceTierTreemap.length - 1,
                                })}
                            >
                                <div className="treemap-line-chart-title">BRAND DEMAND OF { treemapTab === 'RESALE_PRICE' ? 'RESALE': treemapTab } PRICE RANGE {currentLineInfo.priceTierName || ''}</div>
                                <div className="right-legend flex visualization-legend">
                                    <div className="mr20 flex align-center select-box">
                                        <div className="brand-icon"></div>
                                        <div className="flex-1 flex align-center">
                                            <OverviewDrop data={list} currentData={lineLegendInfo} onConfirm={this.handleLineBrandConfirm}></OverviewDrop>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">
                                        {lineLegendInfo.map((legend, index) => (
                                            <div
                                                key={index}
                                                className={cx('flex align-center', {
                                                    mr30: index !== lineLegendInfo.length - 1,
                                                })}
                                            >
                                                <div style={{ backgroundColor: treemapLineColor[legend] }} className="line-dot-legend-box relative flex align-center justify-center">
                                                    <div style={{ backgroundColor: treemapLineColor[legend] }} className="line-dot-legend"></div>
                                                </div>
                                                {legend}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex-1">{VisualizationComponentBrandLine && <VisualizationComponentBrandLine type={3} data={subCategoryPriceTierLineResult} allDate={treemapLineDateArrTemp} treemapLineChangeColor={this.treemapLineChangeColor} treemapLineColor={treemapLineColor} />}</div>
								<Slider range={treemapLineDateArr} onChange={this.handleSliderChangeTreemapLine} />
							</div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 flex flex-column mt10">
                    <div className="flex align-center">
						<div className="flex align-center title-box">
							<div className="sub-category-analysis"></div>
						</div>
                        <div className='flex-1'></div>
                        <div className="flex align-center pl10 pr10 calendar-box">
                            <div className="icon-calendar calendar-item"></div>
                            <DatePicker onConfirm={this.handleConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStartDate={startDate} currentEndDate={endDate} currentType={type} currentOrigin={originalDate}></DatePicker>
                        </div>
                        {(type === 'MONTH' && (
                            <div className="sub-category-select-item-box flex align-center py4 px8 border-box">
                                {timeTabList.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={cx('brand-analysis-time border-box hover flex align-center justify-center', {
                                            'brand-analysis-time-active': demandTimeTab === tab.alias,
                                        })}
                                        onClick={() => this.handleDemandTimeTab(tab.alias)}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </div>
                        )) ||
                            null}
                    </div>
                    {subCategoryIndexResult.map((group, k) => (
                        <div
                            key={k}
                            className={cx('flex', {
                                mb20: k !== subCategoryIndexResult.length - 1,
                            })}
                            style={{height: '420px'}}
                        >
                            {group.map((data, index) => {
                                const VisvalComponent = Visualization.get('line_chart');
                                return (
                                    <div
                                        className={cx('flex-1 card-box flex flex-column', {
                                            mr18: index !== group.length - 1,
                                        })}
                                        key={`${k}-${index}`}
                                    >
                                        <div className="flex align-center brand-chart-title-box">
                                            <div>{data.title}</div>
                                        </div>
                                        <div className="visualization flex-1 p10 flex flex-column">
                                            <div className="flex-1">
                                                {VisvalComponent && <VisvalComponent type={2} data={data.data} changeColor={(color) => this.handleChangeColor(color, data.title)} colors={colors[data.title]} />}
                                            </div>
                                            <Slider range={data.dateRange} onChange={(value, sliderArr) => this.handleSliderChange(sliderArr, data.value)} />
                                        </div>
                                        <div className="info-box flex flex-column justify-between">
                                            {this.getDesc(data.currentDateRange, subCategoryIndexData).map((group, m) => (
                                                <div className="flex-1 flex align-center" key={m}>
                                                    {group.map((brand, i) => {
                                                        return brand.key ? (
                                                            
                                                            <div
                                                                style={{ width: '33%' }}
                                                                className={cx('flex full-height px5', {
                                                                    'info-item-border': i !== group.length - 1,
                                                                })}
                                                                key={`${index}-${m}-${i}`}
                                                            >
                                                                <CommonTootip
                                                                    placement="top"
                                                                    title={
                                                                        <div className="flex align-center">
                                                                            <div
                                                                                className="dot"
                                                                                style={{
                                                                                    background: colors[data.title][brand.key],
                                                                                }}
                                                                            />
                                                                            <div className="ml4 info-item-name-tooltip">{brand.key}</div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className={cx('border-box flex align-center hover flex-1 hidden')}>
                                                                        <div
                                                                            className="dot"
                                                                            style={{
                                                                                background: colors[data.title][brand.key],
                                                                            }}
                                                                        />
                                                                        <div className="ml4 info-item-name flex-1">{brand.key}</div>
                                                                    </div>
                                                                </CommonTootip>
                                                                <div className="flex align-center">
                                                                    <div className="mr6 info-item-value">{formatM(brand.list[0][data.value])}</div>
                                                                    {(brand.list[0][data.yoy] && (
                                                                        <div className="flex align-center mt4">
                                                                            {brand.list[0][data.yoy].indexOf('-') !== -1 ? (
                                                                                <ArrowDownward
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        color: '#FF5F60',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <ArrowUpward
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        color: '#4D9B4D',
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            <div
                                                                                className={cx('brand-percent', {
                                                                                    'up-percent': brand.list[0][data.yoy].indexOf('-') === -1,
                                                                                    'down-percent': brand.list[0][data.yoy].indexOf('-') !== -1,
                                                                                })}
                                                                            >
                                                                                {formatPercent(brand.list[0][data.yoy].replace('-', '')) || 0}
                                                                            </div>
                                                                        </div>
                                                                    )) ||
                                                                        null}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={cx('flex-1 flex full-height justify-between px5')}
                                                                key={`${index}-${m}-${i}`}
                                                            ></div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel}></CommonDialog>
                <StyledMenu
                    keepMounted
                    anchorEl={anchorEle}
                    open={Boolean(anchorEle)}
                    onClose={() =>
                        this.setState({
                            anchorEle: null,
                        })
                    }
                >
                    <div className="p16">
                        <div className="price-tier-name mb16">
                            {currentLineInfo.priceTierName}({formatPercent(currentLineInfo.priceTierShare)})
                        </div>
                        {(currentLineInfo.rankItemList || []).map((item, index) => (
                            <div key={index} className="flex align-center mb16">
                                <div className="brand-color" style={{ backgroundColor: brandColor(item.brandClean) || '#657179' }}></div>
                                <div className="price-tier-brandclean mr10">{item.brandClean}</div>
                                <div className="price-tier-demand mr10">￥{formatM(item.demand)}</div>
                                <div className="price-tier-brandshare">{formatPercent(item.brandShare)}</div>
                            </div>
                        ))}
                    </div>
                </StyledMenu>
            </div>
        );
    }
}
