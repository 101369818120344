import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 1920,
        fontSize: '12px',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '10px',
        padding: '10px',
    },
}))(Tooltip);

export default StyledTooltip;
