import {
    observable,
    action,
    // computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';
import {
	dataManageBrandFranchiseRequest,
	dataManageAttributeRequest,
	dataManageDownloadHistoryListRequest,
	dataManageDownloadUrlRequest,
	dataManageDownloadOverviewRequest,
	dataManageDownloadSubmitRequest,
	dataManageDownloadTemplateRequest,
	dataManageDownloadProductCodeUploadRequest,
	dataManageDownloadProductCodeOverviewRequest,
	dataManageUploadTemplateRequest,
	dataManageUploadRequest,
    dataManageUploadConfirmRequest,
    dataManageUploadCancelRequest
} from '../api/dataManage';
import { groupedByNumber } from '../utils/index';

export class DataManageStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
    }

    @observable
    dataManageBrand = [];

    @observable
    attributeList = [];

    @observable
    downloadHistoryList = [];

    @observable
    downloadTableData = {
        columns: [],
        data: [],
    };

    @action
    async dataManageBrandFranchiseRequest({ category, subCategory }) {
        const response = await dataManageBrandFranchiseRequest({
            category,
            sub_category: subCategory,
            skuFilter: true
        });
        if (response.code === '00000') {
            this.dataManageBrand = response.data.map(item => ({...item, name: item.display}));
        }
    }

    @action
    async dataManageAttributeRequest({ categoryList, brandList, franchiseList }) {
        const response = await dataManageAttributeRequest({
            categoryList,
            brandList,
            franchiseList,
        });
        if (response.code === '00000') {
            this.attributeList = response.data;
        }
    }

    @action
    async dataManageDownloadHistoryListRequest({
		type
	}) {
		this.downloadHistoryList = []
        const response = await dataManageDownloadHistoryListRequest({
			type
		});
        if (response.code === '00000') {
            if (response.data.length) {
                const arr = groupedByNumber(2, response.data, {});
                this.downloadHistoryList = arr;
            }
        }
    }

    @action
    async dataManageDownloadUrlRequest({ uid }) {
        return await dataManageDownloadUrlRequest({ uid });
	}
	
	@action
	clearTable() {
		this.downloadTableData = {
            columns: [],
            data: [],
        };
	}

    @action
    async dataManageDownloadOverviewRequest({ categoryList, brandList, franchiseList, startDate, endDate, attributeDataList, modelList, subCategoryList }) {
        const response = await dataManageDownloadOverviewRequest({
            categoryList,
            brandList,
            franchiseList,
            startDate,
            endDate,
            attributeDataList,
            modelList,
            subCategoryList
        });
        if (response.code === '00000') {
            const columns = response.data[0].map((item) => ({
                name: item,
                label: item,
                minWidth: 120,
            }));
            const data = response.data.slice(1).map((list) => {
                const obj = {};
                list.forEach((item, index) => {
                    obj[columns[index].name] = item;
                });
                return obj;
            });
            this.downloadTableData = {
                columns,
                data,
            };
        }
        return response;
    }

    @action
    async dataManageDownloadSubmitRequest({ categoryList, brandList, franchiseList, startDate, endDate, attributeDataList, modelList, subCategoryList }) {
        return await dataManageDownloadSubmitRequest({
            categoryList,
            brandList,
            franchiseList,
            startDate,
            endDate,
            attributeDataList,
            modelList,
            subCategoryList
        });
	}
	
	@action
	async dataManageDownloadTemplateRequest() {
		return await dataManageDownloadTemplateRequest()
	}

	@action
	async dataManageDownloadProductCodeUploadRequest(data) {
		return await dataManageDownloadProductCodeUploadRequest(data)
	}

	@action
	async dataManageDownloadProductCodeOverviewRequest(data) {
		const response =  await dataManageDownloadProductCodeOverviewRequest(data)
		if (response.code === '00000') {

			const columns = response.data[0].map((item) => ({
				name: item,
				label: item,
				minWidth: 120,
			}));
			const data = response.data.slice(1).map((list) => {
				const obj = {};
				list.forEach((item, index) => {
					obj[columns[index].name] = item;
				});
				return obj;
			});
			this.downloadTableData = {
				columns,
				data,
			};
		}
		return response
	}

	@action
	async dataManageUploadTemplateRequest() {
		return await dataManageUploadTemplateRequest()
	}

	@action
	async dataManageUploadRequest(data) {
		return await dataManageUploadRequest(data)
	}

	@action
	async dataManageUploadConfirmRequest({
        confirmItemList,
        uploadBatchId
	}) {
		return await dataManageUploadConfirmRequest({
            confirmItemList,
            uploadBatchId
		})
    }
    
    @action
    async dataManageUploadCancelRequest({
        uploadBatchId
    }) {
        return await dataManageUploadCancelRequest({
            uploadBatchId
        })
    }
}
