import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';

import PrivateRouter from './router/PrivateRouter';
import routers, { routerWhiteList } from './router/routers';
import { handleRouters } from './utils/index';

@inject('rootStore', 'commonStore', 'userStore')
@observer
export default class App extends React.Component {
    state = {
        width: null,
        height: null,
        isCenter: false,
        permissions: [],
    };
    async componentWillMount() {
        if (['/reset', '/login'].indexOf(window.location.pathname) === -1) {
            await this.props.commonStore.timeRangeMonthRequest();
            await this.props.commonStore.timeRangeWeekRequest();
            await this.props.commonStore.timeRangeSkuLcaRequest();
            await this.props.commonStore.timeRangeSkuPriceRequest();
            const response = await this.props.userStore.userInfoRequest();
            if (response.code === '00000') {
                this.setState({
                    permissions: response.data.permissionCodes,
                });
            }
        }
    }

    componentDidMount() {
        this.handleSize();
        window.addEventListener('beforeunload', () => {
            this.props.commonStore.setDownloadDialog(false);
            this.props.rootStore.setToLocal();
        });
        window.addEventListener('resize', () => {
            this.handleSize();
        });
    }

    handleSize = () => {
        this.props.commonStore.setIsResize(true);
        const path = window.location.pathname;
        const dHeight = document.body.clientHeight;
        const dWidth = document.body.clientWidth;
        if (path === '/login' || path === '/reset') {
            this.setState({
                // width: dWidth + 'px',
                height: dHeight + 'px',
                width: '100%',
            });
        } else if (path === '/top-sku' || path === '/sub-category') {
            if (dWidth <= 1440) {
                this.setState({
                    width: '1440px',
                    height: 'auto',
                });
            } else if (dHeight <= 910) {
                this.setState({
                    width: '1440px',
                    height: 'auto',
                });
            } else {
                const width = (1440 / 910) * dHeight;
                this.setState({
                    width: width + 'px',
                    height: 'auto',
                });
                if (width < dWidth) {
                    this.setState({
                        isCenter: true,
                    });
                } else {
                    this.setState({
                        isCenter: false,
                    });
                }
            }
        } else if (dHeight <= 910) {
            this.setState({
                height: '910px',
                width: '1440px',
            });
            if (dWidth <= 1440) {
                this.setState({
                    isCenter: false,
                });
            } else {
                this.setState({
                    isCenter: true,
                });
            }
        } else {
            const width = (1440 / 910) * dHeight;
            this.setState({
                width: width + 'px',
                height: dHeight + 'px',
            });
            if (width < dWidth) {
                this.setState({
                    isCenter: true,
                });
            } else {
                this.setState({
                    isCenter: false,
                });
            }
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', () => {});
    }

    render() {
        const { width, height, isCenter, permissions } = this.state;
        const documentInfo = this.props.commonStore.documentInfo;
        const isResize = this.props.commonStore.isResize;
        const userInfo = this.props.userStore.userInfo || {};
        const { permissionCodes } = userInfo;
        const codes = permissionCodes || permissions;

        const routes = handleRouters(codes, routers, routerWhiteList);

        return (
            <React.Fragment>
                <div
                    className={cx({
                        'flex align-center justify-center': isResize ? isCenter : documentInfo.isCenter,
                        hidden: document.body.clientWidth >= 14440,
                    })}
                >
                    <div style={{ width: isResize ? width : documentInfo.width, height: isResize ? height : documentInfo.height }}>
                        <PrivateRouter routers={routes} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
