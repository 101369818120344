import * as React from 'react';
import ReactDOM from 'react-dom';
import ResizeObserver from 'resize-observer-polyfill';

const withChart = () => (ComponentHoc) =>
    class extends React.Component {
        state = {
            width: null,
            height: null,
        };

        _updateSize = () => {
            const element = this._getElement();
            if (element) {
                const rect = element.getBoundingClientRect();
                const { width, height } = rect;
                if (
                    this.state.width !== width ||
                    this.state.height !== height
                ) {
                    this.setState({ width, height });
                }
            }
        };

        _getElement = () => {
            return ReactDOM.findDOMNode(this);
        };

        _initResizeObserver() {
            this._ro = new ResizeObserver((entries, observer) => {
                const element = this._getElement();
                for (const entry of entries) {
                    if (entry.target === element) {
                        this._updateSize();
                        return;
                    }
                }
            });
        }

        _updateResizeObserver() {
            const element = this._getElement();
            if (this._currentElement !== element) {
                this._currentElement = element;
                this._ro.observe(this._currentElement);
            }
        }

        componentDidUpdate() {
            this._updateResizeObserver();
            this._updateSize();
        }

        componentWillUnmount() {
            this._teardownResizeObserver();
            this._teardownQueryMediaListener();
        }

        _teardownResizeObserver() {
            if (this._ro) {
                this._ro.disconnect();
                this._ro = null;
            }
        }

        _initMediaQueryListener() {
            if (window.matchMedia) {
                this._mql = window.matchMedia('print');
                this._mql.addListener(this._updateSize);
            }
        }
        _teardownQueryMediaListener() {
            if (this._mql) {
                this._mql.removeListener(this._updateSize);
                this._mql = null;
            }
        }

        componentDidMount() {
            this._initMediaQueryListener();
            this._initResizeObserver();
            this._updateResizeObserver();
            this._updateSize();
        }

        render() {
            return <ComponentHoc {...this.props} {...this.state} />;
        }
    };

export default withChart;
