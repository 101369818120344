import { withStyles, createStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const StyledTextField = withStyles((theme) =>
    createStyles({
        root: {
            marginTop: '8px',
            '& .MuiOutlinedInput-root': {
                borderRaduis: '20px',
            },
            '& .MuiOutlinedInput-inputMarginDense': {
                paddingTop: '6.5px',
                paddingBottom: '6.5px',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#fff',
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#fff',
            },
            '& .Mui-focused': {
                color: '#fff',
            },
            '& .MuiInputBase-root': {
                color: 'rgba(0,0,0,0.3)',
                backgroundColor: ' rgba(16, 16, 16, 0.14)',
            },
            '& .MuiFormLabel-root': {
                color: 'rgba(255,255,255,0.5)',
                fontSize: '12px',
                fontFamily: 'Helvetica',
            },
            '& fieldset': {
                border: '1px solid rgba(255, 255, 255, 0.13)',
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid #fff',
            },
            '& .MuiInput-underline:after': {
                borderBottom: '1px solid #FF773A',
            },
        },
    })
)(TextField);

export default StyledTextField;
