import {
    observable,
    action,
    // computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';
import _ from 'underscore';
import { brandDictRequest, franchiseModelDictRequest, franchiseNikeDictRequest, skuDictOverviewDefaultRequest, skuDictOverviewRequest, skuDictNikeDefaultRequest, skuDictNikeRequest, timeRangeMonthRequest, timeRangeWeekRequest, timeRangeSkuLcaRequest, timeRangeSkuPriceRequest, skuDictLcaSearch, skuAttributeDictDefaultRequest, skuAttributeDictRequest, skuAttributeDictHistoryLatestRequest, skuDictImgUrlRequest } from '../api/common';
import { sortByLetter } from './utils';

export class CommonStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
	}
	
	@observable
	menuName = '';

    @observable
    documentInfo = {};

    @observable
    isResize = false;

    @observable
    isDialogOpen = false;

    @observable
    brandDict = [];

    @observable
    brandDefault = [];

    @observable
    franchiseDict = [];

    @observable
    franchiseDefault = [];

    @observable
    franchiseNikeDict = [];

    @observable
    franchiseNikeDefault = [];

    @observable
    skuDict = [];

    @observable
	skuDefault = [];
	
	@observable
	skuAttributeDict = [];

	@observable
	skuAttributeDictDefault = [];

    @observable
    timeRangeMonth = {};

    @observable
    timeRangeWeek = {};

    @observable
    timeRangeSkuLca = {};

    @observable
    timeRangeSkuPrice = {};

    @action
    async brandDictRequest() {
        const response = await brandDictRequest();
        const { code, data } = response;
        if (code === '00000') {
            this.brandDict = data;
            const groupData = _.groupBy(data, (d) => d.defaultFlag);
            const arr = groupData[1];
            this.brandDefault = arr.map((item) => item.value);
        }
    }

    @action
    async franchiseDictRequest() {
        const response = await franchiseModelDictRequest();
        const { code, data } = response;
        if (code === '00000') {
            const temp = [];
            this.franchiseDict = data.sort(sortByLetter).map((item) => {
                const obj = {
                    defaultFlag: item.defaultFlag,
                    name: item.display,
                    position: 1,
                    value: item.value,
                    children: (item.extraData || []).sort(sortByLetter).map((sub) => ({
                        defaultFlag: sub.defaultFlag,
                        name: sub.display,
                        value: sub.value,
                        position: 2,
                        // isLeaf: true,
                        parentName: item.display,
                        parentValue: item.value,
                        children: (sub.extraData || []).sort(sortByLetter).map((last) => ({
                            defaultFlag: last.defaultFlag,
                            name: last.display,
                            value: last.value,
                            position: 3,
                            isLeaf: true,
                            children: [],
                            parentName: sub.display,
                            parentValue: sub.value,
                        })),
                    })),
                };
                (item.extraData || []).sort(sortByLetter).forEach((sub) => {
                    // if (sub.defaultFlag === 1) {
                    //     const parent = {
                    //         defaultFlag: item.defaultFlag,
                    //         name: item.display,
                    //         value: item.value,
                    //         position: 1,
                    //         children: (item.extraData || []).map((s) => ({
                    //             defaultFlag: s.defaultFlag,
                    //             name: s.display,
                    //             value: s.value,
                    //             position: 2,
                    //             isLeaf: true,
                    //             children: [],
                    //         })),
                    //     };
                    //     const child = {
                    //         defaultFlag: 1,
                    //         name: sub.display,
                    //         value: sub.value,
                    //         position: 2,
                    //         isLeaf: true,
                    //         children: [],
                    //     };
                    //     temp.push([parent, child]);
                    // }
                    (sub.extraData || []).sort(sortByLetter).forEach((last) => {
                        if (last.defaultFlag === 1) {
                            const firstParent = {
                                defaultFlag: item.defaultFlag,
                                name: item.display,
                                value: item.value,
                                position: 1,
                                children: (item.extraData || []).map((s) => ({
                                    defaultFlag: s.defaultFlag,
                                    name: s.display,
                                    value: s.value,
                                    position: 2,
                                    // isLeaf: true,
                                    children: [],
                                })),
                            };
                            const secondParent = {
                                defaultFlag: sub.defaultFlag,
                                name: sub.display,
                                value: sub.value,
                                position: 2,
                                children: (sub.extraData || []).map((s) => ({
                                    defaultFlag: s.defaultFlag,
                                    name: s.display,
                                    value: s.value,
                                    position: 3,
                                    isLeaf: true,
                                    children: [],
                                })),
                            };
                            const child = {
                                defaultFlag: 1,
                                name: last.display,
                                value: last.value,
                                position: 3,
                                isLeaf: true,
                                children: [],
                            };
                            temp.push([firstParent, secondParent, child]);
                        }
                    })
                });
                return obj;
            });
            this.franchiseDefault = temp;
        }
    }

    @action
    async franchiseNikeDictRequest() {
        const response = await franchiseNikeDictRequest();
        const { code, data } = response;
        if (code === '00000') {
            this.franchiseNikeDict = data;
            const arr = [];
            data.forEach((item) => {
                (item.extraData || []).forEach((sub) => {
                    if (sub.defaultFlag === 1) {
                        arr.push([item, sub]);
                    }
                });
            });
            this.franchiseNikeDefault = arr;
        }
    }

    @action
    async skuDictOverviewDefaultRequest() {
        return await skuDictOverviewDefaultRequest();
    }

    @action
    async skuDictOverviewRequest(data = {}) {
        return await skuDictOverviewRequest(data);
    }

    @action
    async skuDictImgUrlRequest(data) {
        return await skuDictImgUrlRequest(data)
    }

    @action
    async skuDictNikeDefaultRequest() {
        return await skuDictNikeDefaultRequest();
    }

    @action
    async skuDictNikeRequest(data = {}) {
        return await skuDictNikeRequest(data);
    }

    @action
    async timeRangeMonthRequest() {
        const response = await timeRangeMonthRequest();
        const { data, code } = response;
        if (code === '00000') {
            this.timeRangeMonth = data;
        }
    }

    @action
    async timeRangeWeekRequest() {
        const response = await timeRangeWeekRequest();
        const { code, data } = response;
        if (code === '00000') {
            this.timeRangeWeek = data;
        }
    }

    @action
    async timeRangeSkuLcaRequest() {
        const response = await timeRangeSkuLcaRequest();
        const { code, data } = response;
        if (code === '00000') {
            this.timeRangeSkuLca = data;
        }
    }

    @action
    async timeRangeSkuPriceRequest() {
        const response = await timeRangeSkuPriceRequest();
        const { code, data } = response;
        if (code === '00000') {
            this.timeRangeSkuPrice = data;
        }
    }

    @action
    async skuDictLcaSearch({ productCode, startDate, endDate }) {
        return await skuDictLcaSearch({
            productCode,
            startDate,
            endDate,
        });
	}
	
	@action
	async skuAttributeDictDefaultRequest() {
		const response = await skuAttributeDictDefaultRequest()
		if (response.code === '00000') {
			this.skuAttributeDictDefault = response.data;
		}
		return response
	}

	@action
	async skuAttributeDictHistoryLatestRequest() {
		return await skuAttributeDictHistoryLatestRequest()
	}

	@action
	async skuAttributeDictRequest() {
		const response = await skuAttributeDictRequest()
		const { code, data } = response;
		if (code === '00000') {
			const list = data.sort(sortByLetter).map(item => ({
				name: item.display,
				value: item.value,
				children: (item.extraData || []).sort(sortByLetter).map(sub => ({
					name: sub.display,
					value: sub.value
				}))
			}))
			const attributeList = []
			for(let i = 0; i< 6; i++) {
				attributeList.push({
					name: `Attribute ${i + 1}`,
					value: i + 1,
					children: list
				})
			}
			this.skuAttributeDict = attributeList;
		}
	}

	@action
	setMenuName(name) {
		this.menuName = name;
	}

    @action
    setDocumentInfo(info) {
        this.documentInfo = info;
    }

    @action
    setIsResize(boolean) {
        this.isResize = boolean;
    }

    @action
    setDownloadDialog(boolean) {
        this.isDialogOpen = boolean;
    }
}
