import * as React from 'react';
import { TableRow } from '@material-ui/core';
import TableWrapper from './TableWrapper';
import StyledTableCell from './StyledTableCell';
import StyledTableRow from './StyledTableRow';

const DownloadTable = ({ columns, data, total, changePage, isLoading, onOperator, current }) => {
    const renderHeader = () => {
        return (
            <TableRow>
                {columns.map((column) => (
                    <StyledTableCell
                        key={column.name}
                        align={column.align}
                        style={{
                            minWidth: column.minWidth || column.width,
                            maxWidth: column.maxWidth || column.width,
                            position: column.position,
                            left: column.left,
                            right: column.right,
                            width: column.width,
                        }}
                    >
                        {column.label}
                    </StyledTableCell>
                ))}
            </TableRow>
        );
    };

    const handleTd = (row, column) => {
        const item = row[column.name];

        return item || '--';
    };

    const renderBody = () => {
        return (
            <React.Fragment>
                {data.map((row, index) => (
                    <StyledTableRow key={index}>
                        {columns.map((column, i) => {
                            return (
                                <StyledTableCell
                                    key={`${index}_${i}`}
                                    style={{
                                        wordBreak: 'break-all',
                                        position: column.position,
                                        left: column.left,
                                        right: column.right,
                                    }}
                                >
                                    {handleTd(row, column)}
                                </StyledTableCell>
                            );
                        })}
                    </StyledTableRow>
                ))}
            </React.Fragment>
        );
    };

    return <TableWrapper headerCellRender={() => renderHeader()} bodyCellRender={() => renderBody()} total={total} changePage={changePage} isLoading={isLoading} current={current} />;
};

export default DownloadTable;
