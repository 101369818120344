import * as echarts from 'echarts';

const treemapColor = ['#323A40', '#657179', '#8E989F', '#B6BEC3', '#DEE2E6'];
export const treemapRender = (element, props) => {
    const data = props.data.itemList.map((item) => ({
        name: item.brandClean,
        value: Number(item.demand),
        percent: item.brandShare,
    }));
    const chartElement = document.createElement('div');
    chartElement.classList.add('absolute');
    chartElement.style.width = props.width + 'px';
    chartElement.style.height = props.height + 'px';
    element.appendChild(chartElement);
    const chart = echarts.init(chartElement);
    chart.setOption({
        grid: {
            left: '0%',
            top: '0%',
            right: '0%',
            bottom: '0%',
        },
        tooltip: {
            show: false,
        },
        series: [
            {
                type: 'treemap',
                data: data.map((d, i) => {
                    if (d.name === 'NIKE') {
                        d.itemStyle = {
                            color: '#FF7334',
                        };
                    } else if (d.name === 'ADIDAS') {
                        d.itemStyle = {
                            color: '#357BDA',
                        };
                    } else if (d.name === 'CONVERSE') {
                        d.itemStyle = {
                            color: '#4C972F',
                        };
                    } else if (d.name === 'JORDAN') {
                        d.itemStyle = {
                            color: '#B98B00',
                        };
                    } else if (d.name === "ANTA") {
                        d.itemStyle = {
                            color: '#EF8BB4',
                        };
                    } else if (d.name === 'DR.MARTENS') {
                        d.itemStyle = {
                            color: '#2795A2',
                        };
                    } else if (d.name === "LINING") {
                        d.itemStyle = {
                            color: '#84CD9A',
                        };
                    } else if (d.name === 'TIMBERLAND') {
                        d.itemStyle = {
                            color: '#FF5F60',
                        };
                    } else if (d.name === 'VANS') {
                        d.itemStyle = {
                            color: '#EAC733',
                        };
                    } else {
                        const index = i % 5;
                        d.itemStyle = {
                            color: treemapColor[index],
                        };
                    }
                    return d;
                }),
                width: '100%',
                height: '100%',
                roam: false,
                nodeClick: false,
                breadcrumb: {
                    show: false,
                },
                label: {
                    position: 'insideTopLeft',
                    fontWeight: 'bold',
                    fontFamily: 'Helvetica-Bold, Helvetica',
                    fontSize: 12,
                    color: '#fff',
                    formatter: (params) => {
                        return `${(Number(params.data.percent) * 100).toFixed(2)}%\n${params.data.name}`;
                    },
                },
                itemStyle: {
                    // borderWidth: 2,
                    borderColor: '#000',
                    gapWidth: 2,
                },
            },
        ],
    });
};
