import * as React from 'react';
import cx from 'classnames';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Person, ExpandLess, ExpandMore, MoreVert } from '@material-ui/icons';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import '../assets/css/home.css';

const StyledListItem = withStyles({
    root: {
        '&.MuiListItem-gutters': {
            paddingLeft: '6px',
            paddingRight: '6px',
        },
        '&.MuiListItem-root': {
            height: '50px',
            marginTop: '10px',
        },
    },
})(ListItem);

const StyledCollapseListItem = withStyles({
    root: {
        '&.MuiListItem-gutters': {
            paddingLeft: '46px',
            paddingRight: '10px',
        },
        '&.MuiListItem-root': {
            height: '50px',
        },
    },
})(ListItem);

const StyledListItemIcon = withStyles({
    root: {
        '&.MuiListItemIcon-root': {
            minWidth: 0,
        },
    },
})(ListItemIcon);

const StyledListItemText = withStyles({
    root: {
        '& .MuiTypography-body1': {
            fontSize: '14px',
        },
        '&.MuiListItemText-root': {
            // marginBottom: 0,
        },
    },
})(ListItemText);

const StyledSubListItemText = withStyles({
    root: {
        '& .MuiTypography-body1': {
            fontSize: '12px',
        },
        '&.MuiListItemText-root': {
            // marginBottom: 0,
        },
    },
})(ListItemText);

const navList = [
    {
        name: 'MARKET OVERVIEW',
        alias: 'overview',
        children: [],
        icon: 'overview',
        activeIcon: 'overview-active',
        permission: 'market_overview:index',
    },
    {
        name: 'Sub-Category Overview',
        alias: 'sub-category',
        children: [],
        icon: 'sub-category',
        activeIcon: 'sub-category-active',
        permission: 'sub_category_overview:index',
    },
    {
        name: 'Brand Analysis',
        alias: 'brand',
        children: [],
        icon: 'brand',
        activeIcon: 'brand-active',
        permission: 'brand_analysis:index',
    },
    {
        name: 'Franchise Analysis',
        alias: 'franchise',
        children: [
            {
                name: 'Franchise Overview',
                alias: 'franchise-overview',
                children: [],
                permission: 'franchise_analysis:overview',
            },
            {
                name: 'Nike Franchise LCA',
                alias: 'franchise-lca',
                children: [],
                permission: 'franchise_analysis:nike',
            },
        ],
        icon: 'franchise',
        activeIcon: 'franchise-active',
    },
    {
        name: 'Gender Analysis',
        alias: 'gender',
        children: [],
        icon: 'gender',
        activeIcon: 'gender-active',
        permission: 'gender_analysis:index',
    },
    {
        name: 'SKU Analysis',
        alias: 'sku',
        children: [
            {
                name: 'SKU Overview',
                alias: 'sku-overview',
                children: [],
                permission: 'sku_analysis:overview',
            },
            {
                name: 'TOP SKUs',
                alias: 'top-sku',
                children: [],
                permission: 'sku_analysis:top',
            },
            {
                name: 'NIKE Product Attribute',
                alias: 'nike-attribute',
                children: [],
                permission: 'sku_analysis:nike_product',
            },
            {
                name: 'NIKE New Launch SKU',
                alias: 'nike-launch',
                children: [],
                permission: 'sku_analysis:nike_new',
            },
        ],
        icon: 'sku',
        activeIcon: 'sku-active',
    },
    {
        name: 'Attribute Exploration',
        alias: 'explor',
        children: [],
        icon: 'explor',
        activeIcon: 'explor-active',
        permission: 'attribute_exploration:index',
    },
    {
        name: 'Data Management',
        alias: 'manage',
        children: [
            {
                name: 'Download Data',
                alias: 'data-download',
                children: [],
                permission: 'data_management:download',
            },
            {
                name: 'Upload Data',
                alias: 'data-upload',
                children: [],
                permission: 'data_management:upload',
            },
        ],
        icon: 'manage',
        activeIcon: 'manage-active',
    },
    {
        name: 'System Management',
        alias: 'system',
        children: [
            {
                name: 'User Management',
                alias: 'user',
                children: [],
                permission: 'user:management',
            },
            {
                name: 'Role Management',
                alias: 'role',
                children: [],
                permission: 'role_permission:management',
            },
        ],
        icon: 'gender',
        activeIcon: 'gender-active',
    },
];

const pathObj = {
    'franchise-overview': 'franchise',
    'franchise-lca': 'franchise',
    'sku-overview': 'sku',
    'top-sku': 'sku',
    'nike-attribute': 'sku',
    'nike-launch': 'sku',
    user: 'system',
    role: 'system'
};

const StyledMenu = withStyles({
    paper: {
        background: 'rgba(223, 223, 223, 0)',
        padding: '10px',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} {...props} />);

@withRouter
@inject('userStore', 'commonStore')
export default class Home extends React.Component {
    state = {
        openNav: '',
        active: '',
        subActive: '',
        anchorEle: null,
        nav: [],
    };
    componentDidMount() {
        const { userInfo } = this.props.userStore;
        const { permissionCodes } = userInfo || {};
        const list = navList.filter((list) => {
            if (list.children.length) {
                const children = list.children.filter((f) => (permissionCodes || []).indexOf(f.permission) !== -1 && f);
                if (children.length) {
                    list.children = children;
                    return list;
                }
                return false;
            } else if (list.permission && (permissionCodes || []).indexOf(list.permission) !== -1) {
                return list;
            }
            return false;
        });
        this.setState({
            nav: list,
        });

        const pathname = this.props.location.pathname;
        const arr = pathname.split('/').filter((f) => f);
        const path = arr[0] || '';
        this.handleSize(path);
        if (path.indexOf('sub-category') !== -1 || (path.indexOf('-') === -1 && ['user', 'role'].indexOf(path) === -1)) {
            this.setState({
                active: path,
            });
        } else {
            this.setState({
                subActive: path,
                active: pathObj[path],
            });
        }
    }

    handleExpandable = (item) => {
        const { openNav } = this.state;
        this.setState({
            openNav: item === openNav ? '' : item,
        });
    };

    handleSize = (path) => {
        this.props.commonStore.setIsResize(false);
        const dHeight = document.body.clientHeight;
        const dWidth = document.body.clientWidth;
        if (path === '/login' || path === '/reset') {
            this.props.commonStore.setDocumentInfo({
                // width: dWidth + 'px',
                height: dHeight + 'px',
                width: '100%',
            });
        } else if (path === '/top-sku' || path === '/sub-category') {
            if (dWidth <= 1440) {
                this.props.commonStore.setDocumentInfo({
                    width: '1440px',
                    height: 'auto',
                });
            } else if (dHeight <= 910) {
                this.props.commonStore.setDocumentInfo({
                    width: '1440px',
                    height: 'auto',
                });
            } else {
                const width = (1440 / 910) * dHeight;
                if (width < dWidth) {
                    this.props.commonStore.setDocumentInfo({
                        isCenter: true,
                        width: width + 'px',
                        height: 'auto',
                    });
                } else {
                    this.props.commonStore.setDocumentInfo({
                        isCenter: false,
                        width: width + 'px',
                        height: 'auto',
                    });
                }
            }
        } else if (dHeight <= 910) {
            if (dWidth <= 1440) {
                this.props.commonStore.setDocumentInfo({
                    height: '910px',
                    width: '1440px',
                    isCenter: false,
                });
            } else {
                this.props.commonStore.setDocumentInfo({
                    height: '910px',
                    width: '1440px',
                    isCenter: true,
                });
            }
        } else {
            const width = (1440 / 910) * dHeight;
            if (width < dWidth) {
                this.props.commonStore.setDocumentInfo({
                    width: width + 'px',
                    height: dHeight + 'px',
                    isCenter: true,
                });
            } else {
                this.props.commonStore.setDocumentInfo({
                    width: width + 'px',
                    height: dHeight + 'px',
                    isCenter: false,
                });
            }
        }
    };

    handleListItemClick = (item, parent) => {
        if (parent) {
            this.setState({
                subActive: item,
                active: parent,
            });
        } else {
            this.setState({
                active: item,
                openNav: '',
            });
        }
        this.props.history.push(`/${item}`);
        this.handleSize(`/${item}`);
    };

    handleChangePassword = () => {
        this.props.history.push('/modify');
        this.handleSize('/modify');
        this.setState({
            anchorEle: null,
        });
    };

    handleLogout = async () => {
        const response = await this.props.userStore.logoutRequest();
        if (response.code === '00000') {
            window.localStorage.removeItem('dewu_token');
            window.localStorage.removeItem('t');
            this.props.history.push('/login');
            this.props.userStore.clearUserInfo();
        }
	};
	
	getMenuName = (name) => {
		let menu = ''
		navList.forEach(item => {
			if (item.alias === name) {
				menu = item.name
			} else if (item.children.length) {
				item.children.forEach(child => {
					if (child.alias === name) {
						menu = child.name
					}
				})
			}
		})
		return menu;
	}

    handleDownload = () => {
		const name = this.props.location.pathname.replace('/', '');
		const menuName = this.getMenuName(name);
        const path = ['/overview', '/brand', '/franchise-overview', '/franchise-lca', '/gender', '/sku-overview', '/top-sku', '/nike-attribute', '/nike-launch', '/sub-category', '/explor'];
        if (path.indexOf(this.props.location.pathname) !== -1) {
            this.props.commonStore.setDownloadDialog(true);
            this.props.commonStore.setMenuName(menuName);
		}
    };

    render() {
        const { openNav, active, subActive, anchorEle, nav } = this.state;
        const { userInfo } = this.props.userStore;
        const list = nav;

        return (
            <div className="menu border-box flex flex-column">
                <div className="logo-box border-box flex justify-center">
                    <div className="logo" />
                </div>
                <div className="menu-list flex-1 overflow-y">
                    <List component="nav">
                        {list.map((nav) => (
                            <React.Fragment key={nav.alias}>
                                <StyledListItem
                                    className={cx({
                                        'nav-item-active': active === nav.alias && !nav.children.length,
                                        'nav-item-active-2': (active === nav.alias || openNav === nav.alias) && nav.children.length,
                                    })}
                                    button={true}
                                    onClick={() => (nav.children.length ? this.handleExpandable(nav.alias) : this.handleListItemClick(nav.alias))}
                                >
                                    <StyledListItemIcon>
                                        <div
                                            className={cx('flex align-center justify-center mr10 icon-box', {
                                                'icon-wrapper-active': active === nav.alias,
                                            })}
                                        >
                                            <div
                                                className={cx('icon-wrapper', {
                                                    [`icon-${nav.icon}`]: active !== nav.alias,
                                                    [`icon-${nav.activeIcon}`]: active === nav.alias,
                                                })}
                                            />
                                        </div>
                                    </StyledListItemIcon>
                                    <StyledListItemText
                                        className={cx('nav-text', {
                                            'nav-text-active': active === nav.alias,
                                        })}
                                        primary={nav.name}
                                    />
                                    {(nav.children.length && (openNav === nav.alias ? <ExpandLess /> : <ExpandMore />)) || null}
                                </StyledListItem>
                                {(nav.children.length && (
                                    <Collapse in={openNav === nav.alias} timeout="auto" unmountOnExit={true}>
                                        <List component="div" disablePadding={true}>
                                            {nav.children.map((sub, i) => (
                                                <React.Fragment key={sub.alias}>
                                                    <StyledCollapseListItem
                                                        className={cx({
                                                            'nav-subItem-active': (active === nav.alias || openNav === nav.alias) && i !== nav.children.length - 1,
                                                            'nav-subItem-active-2': (active === nav.alias || openNav === nav.alias) && i === nav.children.length - 1,
                                                        })}
                                                        button={true}
                                                        onClick={() => this.handleListItemClick(sub.alias, nav.alias)}
                                                    >
                                                        <StyledSubListItemText
                                                            className={cx('nav-sub-text', {
                                                                'nav-sub-text-active': subActive === sub.alias && active === nav.alias,
                                                            })}
                                                            primary={sub.name}
                                                        />
                                                    </StyledCollapseListItem>
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </Collapse>
                                )) ||
                                    null}
                            </React.Fragment>
                        ))}
                    </List>
                </div>
                <div className="nav-button-group flex align-center justify-center">
                    <div className="nav-icon mr30 icon-info hover" />
                    <div className="nav-icon icon-download hover" onClick={() => this.handleDownload()} />
                    {/* <div className="nav-icon icon-notification hover" /> */}
                </div>
                <div className="account border-box flex align-center justify-between">
                    <div className="flex align-center">
                        <div className="avator flex align-center justify-center">
                            <Person style={{ color: '#000' }} />
                        </div>
                        <div className="flex-1 keep-all">{(userInfo && userInfo.nickname) || ''}</div>
                    </div>
                    <div
                        className="flex align-center hover"
                        onClick={(e) =>
                            this.setState({
                                anchorEle: e.currentTarget,
                            })
                        }
                    >
                        <MoreVert fontSize="small" />
                    </div>
                    <StyledMenu
                        keepMounted
                        anchorEl={anchorEle}
                        open={Boolean(anchorEle)}
                        onClose={() =>
                            this.setState({
                                anchorEle: null,
                            })
                        }
                    >
                        <div className="user-info-item flex align-center justify-center mb4 hover" onClick={() => this.handleChangePassword()}>
                            Change Password
                        </div>
                        <div className="user-info-item flex align-center justify-center hover" onClick={() => this.handleLogout()}>
                            Log out
                        </div>
                    </StyledMenu>
                </div>
            </div>
        );
    }
}
