import * as React from 'react';
import cx from 'classnames';
import { Visualization } from '../visualization/index';
import { observer, inject } from 'mobx-react';
import _ from 'underscore';
import moment from 'moment';

import SkuDropBox from '../components/SkuDropBox';
import CommonDialog from '../components/CommonDownloadDialog';

import { downloadXlsx, getColsWpx } from '../../utils/index';
import {
	launchSkuColumns,
	handleOverviewLaunchSkuData
} from '../../utils/skuAnalysisDownload';
import '../../assets/css/sku.css';

@inject('skuStore', 'commonStore', 'userStore')
@observer
export default class NikeNewLaunchSku extends React.Component {
    state = {
		selectedList: [],
		selectedShowList: [],
        selectOrigin: [],
        searchList: [],
        brandList: [],
        franchiselist: [],
        skuList: [],
        startDate: null,
        endDate: null,
        type: 'DAY',
        originalDate: null,
		colors: {},
		showTemp: false
    };

    async componentWillMount() {
        const result = await this.props.commonStore.skuDictNikeDefaultRequest();
        if (result.code === '00000') {
            const idList = result.data.map((f) => f.productCode);
            await this.props.skuStore.skuAnalysisLaunchRequest({
                productCodeList: idList,
            });
            const groupData = _.groupBy(result.data, (d) => d.franchise);
            const arr = Object.entries(groupData).map(([key, value]) => ({
                franchise: key,
                list: value,
            }));
            this.setState({
				selectedList: arr,
				selectedShowList: arr,
                selectOrigin: result.data,
            });
        }

        const response = await this.props.commonStore.skuDictNikeRequest();
        if (response.code === '00000') {
            this.setState({
                brandList: response.data.sort((a, b) => a.brand.localeCompare(b.brand)),
            });
        }
    }

    changeColor = (color) => {
        this.setState({
            colors: color,
        });
    };

    handleConfirm = async (selected) => {
        const groupData = _.groupBy(selected, (d) => d.franchise);
        const arr = Object.entries(groupData).map(([key, value]) => ({
            franchise: key,
            list: value,
        }));
        this.setState({
			selectedList: arr,
			selectedShowList: arr,
			selectOrigin: selected,
			showTemp: false
        });

        const idList = selected.map((f) => f.productCode);
        await this.props.skuStore.skuAnalysisLaunchRequest({
            productCodeList: idList,
        });
    };

    handleDownloadConfirm = (type) => {
		const userInfo = this.props.userStore.userInfo;
		const menuName = this.props.commonStore.menuName;
		const name = `${menuName.replace(/ /g, '_')}_${userInfo.nickname || ''}_${moment().format('YYYY-MM-DD HH:mm:ss')}`
        if (type === 'xlsx') {
			const { skuLaunch } = this.props.skuStore;
			const options = {
				'!merges': [],
				'!cols': getColsWpx(6)
			}
			let sheetData = []

			const column = launchSkuColumns;
			const { data, colsNumber } = handleOverviewLaunchSkuData(column, skuLaunch);
			sheetData = [...sheetData, ...data];
			options['!merges'].push({
				s: { r: 0, c: 0 },
				e: { r: 0, c: colsNumber - 1 }
			})

			downloadXlsx(sheetData, menuName, name, options);
			this.props.commonStore.setDownloadDialog(false);
			this.props.commonStore.setMenuName('');
        }
    };

    handleDownloadCancel = () => {
		this.props.commonStore.setDownloadDialog(false);
		this.props.commonStore.setMenuName('');
    };

    handleRemove = async (item) => {
        const { selectOrigin } = this.state;
        const filterData = selectOrigin.filter((f) => f.diaSkuId !== item.diaSkuId);
        const groupData = _.groupBy(filterData, (d) => d.franchise);
        const arr = Object.entries(groupData).map(([key, value]) => ({
            franchise: key,
            list: value,
        }));
        this.setState({
			selectedList: arr,
			selectedShowList: arr,
            selectOrigin: filterData,
        });
        const idList = filterData.map((f) => f.productCode);
        await this.props.skuStore.skuAnalysisLaunchRequest({
            productCodeList: idList,
        });
    };

    handleChangeBrand = async (brand) => {
        const { startDate, endDate } = this.state;
        const franchise = await this.props.commonStore.skuDictNikeRequest({
            brand: brand.brand,
            startDate,
            endDate,
        });
        if (franchise.code === '00000') {
            this.setState({
                franchiseList: franchise.data.sort((a, b) => a.franchise.localeCompare(b.franchise)),
            });
        }
    };

    handleChangeFranchise = async (item) => {
        const { startDate, endDate } = this.state;
        const sku = await this.props.commonStore.skuDictNikeRequest({
            brand: item.brand,
            franchise: item.franchise,
            startDate,
            endDate,
        });
        if (sku.code === '00000') {
            this.setState({
                skuList: sku.data.sort((a, b) => a.productCode.localeCompare(b.productCode)),
            });
        }
    };

    handleSearch = async (value) => {
        const { startDate, endDate } = this.state;
        const response = await this.props.commonStore.skuDictLcaSearch({ productCode: value, startDate: startDate || undefined, endDate: endDate || undefined });
        if (response.code === '00000') {
            this.setState({
                searchList: response.data,
            });
        }
    };

    handleDateConfirm = async (selected, type, original, searchValue) => {
        this.setState({
            startDate: selected[0],
            endDate: selected[1],
            type,
            originalDate: {
                startDate: selected[0],
                endDate: selected[1],
                origin: original,
            },
        });
        const response = await this.props.commonStore.skuDictLcaSearch({ productCode: searchValue || undefined, startDate: selected[0], endDate: selected[1] });
        if (response.code === '00000') {
            this.setState({
                searchList: response.data,
            });
        }
    };

    handleRefresh = async (searchValue) => {
        this.setState({
            startDate: null,
            endDate: null,
            originalDate: null,
        });
        if (searchValue) {
            const response = await this.props.commonStore.skuDictLcaSearch({ productCode: searchValue });
            if (response.code === '00000') {
                this.setState({
                    searchList: response.data,
                });
            }
        }
	};
	
	handleSelectProduct = (newList) => {
		const groupData = _.groupBy(newList, (d) => d.franchise);
        const arr = Object.entries(groupData).map(([key, value]) => ({
            franchise: key,
            list: value,
        }));
		this.setState({
			showTemp: true,
			selectedShowList: arr
		})
	}

	handleSelectCancel = () => {
		const { selectedList } = this.state
		this.setState({
			showTemp: false,
			selectedShowList: selectedList
		})
	}

    render() {
        const { selectedList, selectOrigin, searchList, brandList, franchiseList, skuList, startDate, endDate, type, originalDate, colors, selectedShowList, showTemp } = this.state;
        const VisualizationComponentBubble = Visualization.get('bubble_chart');
        const { isDialogOpen, timeRangeSkuLca } = this.props.commonStore;
		const { skuLaunch } = this.props.skuStore;
		
        return (
            <div className="flex-1 flex flex-column hidden p20 sku-bg relative" ref={(_f) => (this.ele = _f)}>
                <div className="absolute top-sku-bg-filter"></div>
                <div className="flex-1 flex flex-column z-index-2 full-height">
					<div className="flex align-center title-box">
						<div className="sku-launch-title"></div>
					</div>
                    <div className="flex-1 hidden card-box border-box flex flex-column">
                        <div className="card-content full-height flex p20 border-box flex-1 hidden">
                            <div className="mr10 flex flex-column border-box">
                                <div className="flex align-center full-width">
                                    <div className="launch-sku-select-box border-box flex-1 flex align-center justify-center">
                                        <SkuDropBox
											title="Select SKU"
											dayRange={timeRangeSkuLca}
											onRefresh={this.handleRefresh}
											onDateConfirm={this.handleDateConfirm}
											searchList={searchList}
											brandList={brandList}
											onChangeBrand={this.handleChangeBrand}
											franchiseList={franchiseList}
											onChangeFranchise={this.handleChangeFranchise}
											skuList={skuList}
											onConfirm={this.handleConfirm}
											onSearch={this.handleSearch}
											currentList={selectOrigin}
											position="right"
											currentStartDate={startDate}
											currentEndDate={endDate}
											currentType={type}
											currentOrigin={originalDate}
											onSelect={this.handleSelectProduct}
											onCancel={this.handleSelectCancel}
										></SkuDropBox>
                                    </div>
                                </div>
                                <div className="flex-1 my10 overflow-y">
									{
										!showTemp ? selectedList.map((list, index) => (
											<React.Fragment key={index}>
												<div className="sku-legend-group">{list.franchise}</div>
												{list.list.map((item, i) => (
													<div className={cx('nike-sku-legend-item flex align-center justify-between py4 px8 border-box')} key={`${index}-${i}`}>
														<div className="flex align-center">
															<div className="legend-dot" style={{ background: colors[`${list.franchise}&${item.productCode}`] || '#343434' }} />
															<div>{item.productCode}</div>
														</div>
														<div className="sku-semi-check ml20 hover" onClick={() => this.handleRemove(item)}></div>
													</div>
												))}
											</React.Fragment>
										)) : selectedShowList.map((list, index) => (
												<React.Fragment key={index}>
													<div className="sku-legend-group">{list.franchise}</div>
													{list.list.map((item, i) => (
														<div className={cx('nike-sku-legend-item flex align-center justify-between py4 px8 border-box')} key={`${index}-${i}`}>
															<div className="flex align-center">
																<div className="legend-dot" style={{ background: colors[`${list.franchise}&${item.productCode}`] || '#343434' }} />
																<div>{item.productCode}</div>
															</div>
															<div className="sku-semi-check ml20 hover"></div>
														</div>
													))}
												</React.Fragment>
										))
									}
                                </div>
                            </div>
                            <div className="flex-1 nike-sku-visualization p10">
                                <VisualizationComponentBubble data={skuLaunch} changeColor={this.changeColor} from="sku" xName={'Premium Price Index'} yName={'Unit Sold Index'} />
                            </div>
                        </div>
                    </div>
                </div>
                <CommonDialog commonOpen={isDialogOpen} onConfirm={this.handleDownloadConfirm} onCancel={this.handleDownloadCancel} disable='pdf'></CommonDialog>
            </div>
        );
    }
}
