import * as React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import { DropzoneArea } from 'material-ui-dropzone';
import '../../assets/css/dataManage.css';
import Pagination from '@material-ui/lab/Pagination';
import { Dialog } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CommonDialog from '../components/StyledDialog';
import { downloadTemplate } from '../../utils/index';

const StyledDialog = withStyles((theme) => ({
    root: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.76)',
        },
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
        },
    },
}))(Dialog);

const StyledPagination = withStyles((theme) =>
    createStyles({
        root: {
			'& .MuiPaginationItem-root': {
				color: 'rgba(255, 255, 255, 0.65)',
				height: '22px',
				minWidth: '22px'
			},
			'& .MuiPaginationItem-page.Mui-selected': {
				backgroundColor: '#FFFFFF',
				color: '#000'
			}
        },
    })
)(Pagination);

@inject('dataManageStore')
@observer
export default class DataUpload extends React.Component {
    state = {
        showDetails: false,
        file: null,
        uploadSucceed: false,
		times: 3,
		confirmOpen: false,
		cautionOpen: false,
		showMessage: false,
		message: '',
		showMessageArr: false,
		messageArr: [],
		data: [],
		currentColunn: {},
		selectColumn: {},
		cancelOpen: false,
		checkOpen: false,
		selectList: [],
		caution: false,
		cautionMessage: '',
		currentBatchId: '',
		totalPage: 0
    };

    handleUploadChange = (files) => {
        if (files.length) {
            this.setState({
                file: files[0],
			});
			this.uploadData(files[0])
        }
	};
	
	uploadData = async (file) => {
		const response = await this.props.dataManageStore.dataManageUploadRequest({
			attachment: file
		})
		const { code, data, message } = response
		if (code !== '00000') {
			this.setState({
				file: null,
				uploadSucceed: false,
			})
		}
		if (code === '00000') {
			this.setState({
				uploadSucceed: true,
				data,
				currentColumn: data.nikeInventoryDataList.length ? data.nikeInventoryDataList[0]: {},
				currentBatchId: data.uploadBatchId,
				totalPage: data.nikeInventoryDataList.length || 0
			})
			this.handleTimeCount()
		} else if (code === 'DATA_1010') {
			this.setState({
				cautionOpen: true,
				showMessage: true,
				showMessageArr: false,
				message,
				messageArr: []
			})
		} else if (code === 'DATA_1012') {
			this.setState({
				caution: true,
				cautionMessage: message
			})
		} else if (code === 'DATA_1011') {
			const errorArr = Array.from(new Set(data.map(error => error.errorMessage)))
			this.setState({
				showMessageArr: true,
				showMessage: false,
				message: '',
				messageArr: errorArr,
				cautionOpen: true
			})
		}
	}

    handleTimeCount = () => {
        let count = 4;
        this.timer = setInterval(() => {
            count -= 1;
            if (count >= 0) {
                this.setState({
                    times: count,
                });
            } else {
                clearInterval(this.timer);
				count = 4;
				this.setState({
					showDetails: true,
					times: 3
				})
            }
        }, 1000);
	};
	
	handleTemplateDownload = async () => {
		const response = await this.props.dataManageStore.dataManageUploadTemplateRequest()
		if (response) {
			downloadTemplate(response, 'nike_inventory_template')
		}
	}

	handleChangePagination = (e, page) => {
		const { data } = this.state;
		this.setState({
			currentColumn: data.nikeInventoryDataList[page - 1],
		})
	}

	handleSelectColumn = (item, selectColumn) => {
		if (selectColumn[item.univStyleColorCd] === item.dataId) {
			const select = {
				...selectColumn,
				[item.univStyleColorCd]: null
			}
			this.setState({
				selectColumn: select
			})
		} else {
			const select = {
				...selectColumn,
				[item.univStyleColorCd]: item.dataId
			}
			this.setState({
				selectColumn: select
			})
		}
	}

	handleColumnCheck = () => {
		const { selectColumn, data } = this.state;
		const select = Object.values(selectColumn).filter(f => f);
		if (select.length === data.nikeInventoryDataList.length) {
			const selectList = [];
			data.nikeInventoryDataList.forEach(list => {
				list.nikeInventoryDataItemList.forEach(item => {
					if (select.indexOf(item.dataId) !== -1) {
						selectList.push(item)
					}
				})
			})
			this.setState({
				selectList,
				confirmOpen: true
			})
		} else {
			this.setState({
				checkOpen: true
			})
		}
	}

	handleCheck = () => {
		const { selectColumn, data } = this.state;
		const select = Object.values(selectColumn).filter(f => f);
		const selectList = [];
		data.nikeInventoryDataList.forEach(list => {
			list.nikeInventoryDataItemList.forEach(item => {
				if (select.indexOf(item.dataId) !== -1) {
					selectList.push(item)
				}
			})
		})
		this.setState({
			selectList,
			confirmOpen: true,
			checkOpen: false
		})
	}

	handleColumnConfirm = async () => {
		const { selectList, currentBatchId } = this.state;
		const confirmList = selectList.map(item => ({
			dataId: item.dataId,
			univStyleColorCd: item.univStyleColorCd
		}))
		const params = {
			confirmItemList: confirmList,
			uploadBatchId: currentBatchId
		}
		const response = await this.props.dataManageStore.dataManageUploadConfirmRequest(params)
		const { code, message } = response
		if (code === '00000') {
			this.setState({
				confirmOpen: false,
				showDetails: false,
				file: null,
				uploadSucceed: false,
				selectList: [],
				selectColumn: {},
			})
		} else if (code === 'DATA_1012') {
			this.setState({
				caution: true,
				cautionMessage: message,
				confirmOpen: false
			})
		}
	}

	handleCancelUpload = async () => {
		this.setState({
			showDetails: false,
			uploadSucceed: false,
			file: null,
			cancelOpen: false,
			selectColumn: {}
		})
		await this.props.dataManageStore.dataManageUploadCancelRequest({
			uploadBatchId: this.state.currentBatchId
		})
	}

    render() {
        const { showDetails, file, uploadSucceed, times, confirmOpen, cautionOpen, message, showMessage, showMessageArr, messageArr, data, currentColumn, selectColumn, cancelOpen, checkOpen, selectList, caution, cautionMessage } = this.state;
        return (
            <div className="flex-1 flex flex-column hidden p20 data-manage-bg align-center">
                {
					!showDetails ? (
						<div
							className={cx('flex-1 flex flex-column align-center card-box full-height full-width')}
						>
							<div className="upload-logo"></div>
							<div className="upload-title-desc mb10">Drop Your Data Here</div>
							<div className="file-desc mb30">xlsx files are allowed</div>
							<div className="upload-box">
								<div style={{display: !file && !uploadSucceed ? 'block': 'none'}}>
									<DropzoneArea
										filesLimit={1}
										showAlerts={false}
										maxFileSize={10 * 1024 * 1024}
										showPreviewsInDropzone={false}
										acceptedFiles={['.xlsx']}
										dropzoneText="Drag and drop or browse to choose a file"
										onChange={(files) => {
											this.handleUploadChange(files);
										}}
									/>
									<div className="template-download flex align-center justify-center mt20">
										<div className="mr6">Data Template:</div>
										<div className="mr6 template-hover" onClick={() => this.handleTemplateDownload()}>nike_inventory_template.xlsx</div>
										<div className="template-icon"></div>
									</div>
								</div>
								<div className="upload-content-box flex flex-column" style={{display: file && !uploadSucceed ? 'flex': 'none'}}>
									<div className="text-center upload-title">uploading</div>
									<div className="flex align-center px50 mb26">
										<div className="download-file-icon mr16"></div>
										<div className="flex-1 flex flex-column justify-between upload-file-text">
											<div className="mb6">{(file && file.name) || ''}</div>
											{/* <div>{(file && Math.floor(file.size / 1024 / 1024) + 'MB') || ''}</div> */}
										</div>
									</div>
								</div>
								<div className="upload-content-box flex flex-column" style={{display: uploadSucceed ? 'flex': 'none'}}>
									<div className="flex align-center justify-center mt50 mb40">
										<div className="upload-succeed-icon"></div>
									</div>
									<div className="upload-succeed-desc text-center">
										<div>Uploaded successfully</div>
										<div>
											It will return automatically after <span style={{ color: 'red' }}>{times}</span> seconds
										</div>
									</div>
								</div>
							</div>
						</div>
					): (
						<div className="flex-1 flex flex-column align-center card-box full-height full-width py40">
							<div className="flex-1 flex flex-column full-width px24 hidden">
								<div className="upload-check-title mb20 flex align-center justify-center">Check Your Data</div>
								<div className="flex align-center justify-center">
									<div className="upload-check-icon mr10"></div>
									{/* <div>以下字段跟云端重复，请选择要替换的data</div> */}
									<div>{this.state.totalPage} sets of duplicate records are found. Please double confirm.</div>
								</div>
								<div className="check-data-box flex flex-1 hidden">
									<div className="check-data-left mr6 my20"></div>
									<div className="flex-1 check-data-content mr6 px26 flex flex-column hidden">
										<div className="check-data-content-title mb20 pt26 text-center">univ_style_color_cd：{currentColumn ? currentColumn.univStyleColorCd: ''}</div>
										<div className="flex-1 overflow-y">
											<div className="flex flex-wrap">
												{
													currentColumn && (currentColumn.nikeInventoryDataItemList || []).filter(f => f.dataType === 2).map((item, index) => (
														<div key={index} className={cx("column-box p10 flex flex-column mb16", {
															'column-color': selectColumn[item.univStyleColorCd] !== item.dataId,
															'column-color-select': selectColumn[item.univStyleColorCd] === item.dataId,
														})}>
															<div className="flex-1 flex align-center justify-between">
																<div className="flex align-center">
																	<div className='column-desc'>description：</div>
																	<div className='column-desc-value'>{item.description}</div>
																	{
																		item.valid === 1 ? <div className="column-applied flex align-center justify-center ml8">Applied</div>: null
																	}
																</div>
																<div className={cx({
																		"column-select-icon": selectColumn[item.univStyleColorCd] === item.dataId,
																		"column-unselect-icon": selectColumn[item.univStyleColorCd] !== item.dataId,
																	})}
																	onClick={() => this.handleSelectColumn(item, selectColumn)}
																></div>
															</div>
															<div className="flex-1 flex align-center">
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>launch_date：</div>
																	<div className="column-desc-value">{item.launchDate}</div>
																</div>
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>launchmethod：</div>
																	<div className="column-desc-value">{item.launchMethod}</div>
																</div>
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>intent：</div>
																	<div className="column-desc-value">{item.intent}</div>
																</div>
															</div>
															<div className="flex-1 flex align-center">
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>inventory：</div>
																	<div className="column-desc-value">{item.inventory}</div>
																</div>
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>Upload_account：</div>
																	<div className="column-desc-value">{item.updatedBy}</div>
																</div>
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>utime：</div>
																	<div className="column-desc-value">{item.updateTime}</div>
																</div>
															</div>
														</div>
													))
												}
											</div>
											<div className="divide-line"></div>
											<div className="database-list-title text-center py12">Records in Database</div>
											<div className="flex flex-wrap">
												{
													currentColumn && (currentColumn.nikeInventoryDataItemList || []).filter(f => f.dataType === 1).map((item, index) => (
														<div key={index} className={cx("column-box p10 flex flex-column mb16", {
															'column-color': selectColumn[item.univStyleColorCd] !== item.dataId,
															'column-color-select': selectColumn[item.univStyleColorCd] === item.dataId,
														})}>
															<div className="flex-1 flex align-center justify-between">
																<div className="flex align-center">
																	<div className='column-desc'>description：</div>
																	<div className='column-desc-value'>{item.description}</div>
																	{
																		item.valid === 1 ? <div className="column-applied flex align-center justify-center ml8">Applied</div>: null
																	}
																</div>
																<div className={cx({
																		"column-select-icon": selectColumn[item.univStyleColorCd] === item.dataId,
																		"column-unselect-icon": selectColumn[item.univStyleColorCd] !== item.dataId,
																	})}
																	onClick={() => this.handleSelectColumn(item, selectColumn)}
																></div>
															</div>
															<div className="flex-1 flex align-center">
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>launch_date：</div>
																	<div className="column-desc-value">{item.launchDate}</div>
																</div>
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>launchmethod：</div>
																	<div className="column-desc-value">{item.launchMethod}</div>
																</div>
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>intent：</div>
																	<div className="column-desc-value">{item.intent}</div>
																</div>
															</div>
															<div className="flex-1 flex align-center">
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>inventory：</div>
																	<div className="column-desc-value">{item.inventory}</div>
																</div>
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>Upload_account：</div>
																	<div className="column-desc-value">{item.updatedBy}</div>
																</div>
																<div className="flex-1 flex align-center">
																	<div className='column-desc'>utime：</div>
																	<div className="column-desc-value">{item.updateTime}</div>
																</div>
															</div>
														</div>
													))
												}
											</div>
										</div>
										<div className="my10 flex align-center justify-center">
											<StyledPagination count={data.nikeInventoryDataList.length} shape="rounded" onChange={this.handleChangePagination} />
										</div>
									</div>
									<div className="check-data-right my20"></div>
								</div>
							</div>
							<div className="flex align-center justify-center">
								<div className="upload-buttons flex align-center justify-center mr40 hover" onClick={() => this.setState({
									cancelOpen: true
								})}>Cancel</div>
								<div className="upload-buttons flex align-center justify-center hover" onClick={() => this.handleColumnCheck()}>Comfirm</div>
							</div>
						</div>
					)
				}
				<StyledDialog maxWidth="lg" fullWidth={true} open={confirmOpen}>
					<div className="full-width flex align-center justify-center">
						<div className="dialog-content-box flex flex-column" style={{ width: '1072px', padding: '24px 8px 24px 24px' }}>
							<div className="upload-confirm-title mb10 text-center">Confirm submission</div>
							<div className="py6 flex flex-wrap">
								{
									selectList.map((item, index) => (
										<div key={index} className="upload-confirm-column-item mr16">
											<div className="column-item-title mb6">univ_style_color_cd：{item.univStyleColorCd}</div>
											<div className="column-confirm-box p10 flex flex-column column-confirm-color mb16">
												<div className="flex-1 flex align-center justify-between">
													<div className="flex align-center">
														<div className='column-desc'>description：</div>
														<div className='column-desc-value'>{item.description}</div>
													</div>
												</div>
												<div className="flex-1 flex align-center">
													<div className="flex-1 flex align-center">
														<div className='column-desc'>launch_date：</div>
														<div className="column-desc-value">{item.launchDate}</div>
													</div>
													<div className="flex-1 flex align-center">
														<div className='column-desc'>launchmethod：</div>
														<div className="column-desc-value">{item.launchMethod}</div>
													</div>
													<div className="flex-1 flex align-center">
														<div className='column-desc'>intent：</div>
														<div className="column-desc-value">{item.intent}</div>
													</div>
												</div>
												<div className="flex-1 flex align-center">
													<div className="flex-1 flex align-center">
														<div className='column-desc'>inventory：</div>
														<div className="column-desc-value">{item.inventory}</div>
													</div>
													<div className="flex-1 flex align-center">
														<div className='column-desc'>Upload_account：</div>
														<div className="column-desc-value">{item.updatedBy}</div>
													</div>
													<div className="flex-1 flex align-center">
														<div className='column-desc'>utime：</div>
														<div className="column-desc-value">{item.updateTime}</div>
													</div>
												</div>
											</div>
										</div>
									))
								}
							</div>
							<div className="flex align-center justify-center full-width">
								<div className="upload-button-item flex align-center justify-center hover mr10" onClick={() => this.setState({ confirmOpen: false })}>
									Cancel
								</div>
								<div className="upload-button-item flex align-center justify-center hover" onClick={() => this.handleColumnConfirm()}>
									Confirm
								</div>
							</div>
						</div>
					</div>
                </StyledDialog>

				<CommonDialog open={cautionOpen}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-caution-icon mb10"></div>
                        {
							(showMessage &&
								<div>
									<div className="dialog-content-title mb6">CAUTION</div>
									<div className="dialog-content-desc text-center mb16">{message}</div>
								</div>
							) || null
						}
						{
							(showMessageArr &&
								<ul className="mb16 ml20">
									{
										messageArr.map((message, index) => (
											<li className="dialog-content-desc mb6" key={index}>
												{message}
											</li>
										))
									}
								</ul>
							) || null
						}

                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 upload-button-item flex align-center justify-center hover mr10" onClick={() => this.setState({
								cautionOpen: false,
								showMessage: false,
								showMessageArr: false,
								message: '',
								messageArr: []
							})}>
                                Cancel
                            </div>
                            <div className="flex-1 upload-button-item flex align-center justify-center hover" onClick={() => this.handleTemplateDownload()}>
								Download Template
                            </div>
                        </div>
                    </div>
                </CommonDialog>
				<CommonDialog open={cancelOpen}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-caution-icon mb10"></div>
						<div className="dialog-content-title mb6">CAUTION</div>
						<div className="dialog-content-desc text-center mb16">Are you sure you want to cancel the upload</div>
                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 upload-button-item flex align-center justify-center hover mr10" onClick={() => this.setState({
								cancelOpen: false
							})}>
                                Cancel
                            </div>
                            <div className="flex-1 upload-button-item flex align-center justify-center hover" onClick={() =>  this.handleCancelUpload()}>
								Comfirm
                            </div>
                        </div>
                    </div>
                </CommonDialog>
				<CommonDialog open={checkOpen}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-caution-icon mb10"></div>
						<div className="dialog-content-title mb6">CAUTION</div>
						<div className="dialog-content-desc text-center mb16">You still have unselected options</div>
                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 upload-button-item flex align-center justify-center hover mr10" onClick={() => this.setState({
								checkOpen: false
							})}>
                                Cancel
                            </div>
                            <div className="flex-1 upload-button-item flex align-center justify-center hover" onClick={() => this.handleCheck()}>
								Check
                            </div>
                        </div>
                    </div>
                </CommonDialog>
				<CommonDialog open={caution}>
                    <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                        <div className="download-caution-icon mb10"></div>
						<div className="dialog-content-title mb6">CAUTION</div>
						<div className="dialog-content-desc text-center mb16">{cautionMessage}</div>
                        <div className="flex align-center justify-between full-width">
                            <div className="flex-1 upload-button-item flex align-center justify-center hover" onClick={() => this.setState({
								caution: false
							})}>
								Confirm
                            </div>
                        </div>
                    </div>
                </CommonDialog>
            </div>
        );
    }
}
