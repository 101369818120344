import {
    observable,
    action,
    computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';
import _ from 'underscore';
import { explorGroupRequest, explorResultRequest, explorGroupDownload, explorGroupSearch, updateSkuSelectRequest, removeGroupRequest, saveGroupDefineRequest, getFranchiseByCategory } from '../api/explor'
import { completeMissingData, sortByLetter } from './utils';
export class ExplorStore {
    constructor() {
        // makeObservable(this);
        makeAutoObservable(this);
    }

	@observable
    explorGroupData = [];

	@observable
	explorResultData = [];

    @observable
    subCategoryIndexData = [];

    @observable
    subCategoryPriceTierTreemap = []

    @observable
    subCategoryPriceTierLine = []

    @observable
	treemapLineDateArr = [];
	
	@observable
	treemapLineDateArrTemp = [];

	@observable
	dict = [];

	@observable
	brandDict = [];

	@observable
	dateArrOrigin = []

	@observable
	dateRangeArr1 = []

	@observable
	dateRangeArr2 = []

	@observable
	dateRangeArr3 = []

	@observable
	dateRangeArr4 = []

	@action
	changeDateRange1(dateRange) {
		this.dateRangeArr1 = dateRange
	}

	@action
	changeDateRange2(dateRange) {
		this.dateRangeArr2 = dateRange
	}
	@action
	changeDateRange3(dateRange) {
		this.dateRangeArr3 = dateRange
	}

	@action
	changeDateRange4(dateRange) {
		this.dateRangeArr4 = dateRange
	}

    @action
	changeTreemapLineDateRange(dateRange) {
		this.treemapLineDateArrTemp = dateRange;
	}

	@action
	async explorGroupRequest() {
		const response = await explorGroupRequest()
		const { code, data } = response;
        if (code === '00000') {
            this.explorGroupData = data;
        }
	}

	@action
	async explorResultRequest({ startDate, endDate, dateType, dataType, groupIdList }) {
		const response = await explorResultRequest({
			startDate,
			endDate,
			dateType,
			dataType,
			groupIdList
		})
		const { code, data } = response;
        if (code === '00000') {
            this.explorResultData = data;
			this.dateArrOrigin = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr1 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr2 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr3 = Array.from(new Set(response.data.map((d) => d.date))).sort();
			this.dateRangeArr4 = Array.from(new Set(response.data.map((d) => d.date))).sort();
        }
	}
	
	@computed
	get explorIndexResult() {
		const ppiTemp = [];
		const rviTemp = [];
		const diTemp = [];
		const demandTemp = [];
		this.explorResultData.forEach((item) => {
			demandTemp.push({
				name: item.groupName,
				date: item.date,
				value: Number(item.demand),
				// yoy: item.demandYoy,
				origin: item,
			});
			ppiTemp.push({
				name: item.groupName,
				date: item.date,
				value: Number(item.ppi),
				// yoy: item.ppiYoy,
				origin: item,
			});
			rviTemp.push({
				name: item.groupName,
				date: item.date,
				value: Number(item.resaleVolumeIndex),
				// yoy: item.resaleVolumeIndexYoy,
				origin: item,
			});
			diTemp.push({
				name: item.groupName,
				date: item.date,
				value: Number(item.diversity),
				// yoy: item.diversityYoy,
				origin: item,
			});
		});
		const de = Object.entries(_.groupBy(demandTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr1, value),
		}));
		const ppi = Object.entries(_.groupBy(ppiTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr2, value),
		}));
		const rvi = Object.entries(_.groupBy(rviTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr3, value),
		}));
		const di = Object.entries(_.groupBy(diTemp, (d) => d.name) || {}).map(([key, value]) => ({
			name: key,
			data: completeMissingData(this.dateRangeArr4, value),
		}));
		return [
			[
				{
					title: 'DEMAND',
					data: de,
					value: 'demand',
					yoy: 'demandYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr1
				},
				{
					title: 'PREMIUM PRICE INDEX',
					data: ppi,
					value: 'ppi',
					yoy: 'ppiYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr2
				},
			],
			[
				{
					title: 'RESALE VOLUME INDEX',
					data: rvi,
					value: 'resaleVolumeIndex',
					yoy: 'resaleVolumeIndexYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr3
				},
				{
					title: 'DIVERSITY INDEX',
					data: di,
					value: 'diversity',
					yoy: 'diversityYoy',
					dateRange: this.dateArrOrigin,
					currentDateRange: this.dateRangeArr4
				},
			],
		];
	}

	@action
	async explorGroupDownload() {
		await explorGroupDownload()
	}

	@action
	async explorGroupSearch({ groupId, search, page }) {
		const response = await explorGroupSearch({
			groupId,
			search,
			page
		})
		const { code } = response;
        if (code === '00000') {
			return response
		}
		return null
	}

	@action
	async updateSkuSelectRequest({ groupId, selectedList }) {
		await updateSkuSelectRequest({
			groupId,
			selectedList
		})
	}

	@action
	async removeGroupRequest({ groupId }) {
		await removeGroupRequest({
			groupId
		})
	}

	@action
	async saveGroupDefineRequest({ groupName, subCategoryList, brandList, franchiseList, modelList, genderList, definitionKeywordList }) {
		const response = await saveGroupDefineRequest({
			groupName,
			subCategoryList,
			brandList,
			franchiseList,
			modelList,
			genderList,
			definitionKeywordList
		})
		return response
	}

	@action
	async getFranchiseByCategory({ sub_category }) {
		const response = await getFranchiseByCategory({
			sub_category
		})
		const { code, data } = response
		if (code === '00000') {
            const temp = [];
			this.brandDict = data
            this.dict = data.sort(sortByLetter).map((item) => {
                const obj = {
                    defaultFlag: item.defaultFlag,
                    name: item.display,
                    position: 1,
                    value: item.value,
                    children: (item.extraData || []).sort(sortByLetter).map((sub) => ({
                        defaultFlag: sub.defaultFlag,
                        name: sub.display,
                        value: sub.value,
                        position: 2,
                        // isLeaf: true,
                        parentName: item.display,
                        parentValue: item.value,
                        children: (sub.extraData || []).sort(sortByLetter).map((last) => ({
                            defaultFlag: last.defaultFlag,
                            name: last.display,
                            value: last.value,
                            position: 3,
                            isLeaf: true,
                            children: [],
                            parentName: sub.display,
                            parentValue: sub.value,
                        })),
                    })),
                };
                (item.extraData || []).sort(sortByLetter).forEach((sub) => {
                    // if (sub.defaultFlag === 1) {
                    //     const parent = {
                    //         defaultFlag: item.defaultFlag,
                    //         name: item.display,
                    //         value: item.value,
                    //         position: 1,
                    //         children: (item.extraData || []).map((s) => ({
                    //             defaultFlag: s.defaultFlag,
                    //             name: s.display,
                    //             value: s.value,
                    //             position: 2,
                    //             isLeaf: true,
                    //             children: [],
                    //         })),
                    //     };
                    //     const child = {
                    //         defaultFlag: 1,
                    //         name: sub.display,
                    //         value: sub.value,
                    //         position: 2,
                    //         isLeaf: true,
                    //         children: [],
                    //     };
                    //     temp.push([parent, child]);
                    // }
                    (sub.extraData || []).sort(sortByLetter).forEach((last) => {
                        if (last.defaultFlag === 1) {
                            const firstParent = {
                                defaultFlag: item.defaultFlag,
                                name: item.display,
                                value: item.value,
                                position: 1,
                                children: (item.extraData || []).map((s) => ({
                                    defaultFlag: s.defaultFlag,
                                    name: s.display,
                                    value: s.value,
                                    position: 2,
                                    // isLeaf: true,
                                    children: [],
                                })),
                            };
                            const secondParent = {
                                defaultFlag: sub.defaultFlag,
                                name: sub.display,
                                value: sub.value,
                                position: 2,
                                children: (sub.extraData || []).map((s) => ({
                                    defaultFlag: s.defaultFlag,
                                    name: s.display,
                                    value: s.value,
                                    position: 3,
                                    isLeaf: true,
                                    children: [],
                                })),
                            };
                            const child = {
                                defaultFlag: 1,
                                name: last.display,
                                value: last.value,
                                position: 3,
                                isLeaf: true,
                                children: [],
                            };
                            temp.push([firstParent, secondParent, child]);
                        }
                    })
                });
                return obj;
            });
        }
		return response
	}
}
